import React, {ReactNode, ReactNodeArray} from "react";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {getNextLink, IAppointmentData} from "../../../reducers/appointments.reducer";
import {getLocalizedText} from "../../../reducers/locale.reducer";
import {getMoreAppointmentsForUrl} from "../../../thunks/appointments.thunk";
import AppointmentRow from "./AppointmentRow";

interface IDispatchFromProps {
    getMoreAppointmentsForUrl: (nextLink: string, tenant: string) => void;
}

interface IProps {
    appointments: IAppointmentData[];
    tenant: string;
}

interface IConnectProps {
    getMessageForKey: (key: string) => string;
    nextLink: string | undefined;
}

interface IDataProps extends IProps, IConnectProps {
}

interface IResultProps extends IDataProps, IDispatchFromProps {
}

class AppointmentsHistory extends React.Component<IResultProps> {

    public render(): React.ReactNode {
        return (
            <div className="customer_search-result">
                <fieldset>
                    <legend>{this.props.getMessageForKey("customerSearch.result.legend")}</legend>
                    <div className="result-content">
                        {this.renderHeader()}
                        {this.props.appointments.length > 0 ? this.renderAppointments() : this.renderNoResults()}
                    </div>
                    {this.renderViewMoreButton()}
                </fieldset>
            </div>);
    }

    private renderNoResults(): ReactNode {
        return (
            <div className="no-results">
                {this.props.getMessageForKey("customerSearch.result.no.results")}
            </div>);
    }

    private renderHeader(): ReactNode {
        return (
            <div className="row column_header">
                <div className="col">
                    {this.props.getMessageForKey("customerSearch.appointmentHistory.date")}
                </div>
                <div className="col">
                    {this.props.getMessageForKey("customerSearch.appointmentHistory.time")}
                </div>
                <div className="col">
                    {this.props.getMessageForKey("customerSearch.appointmentHistory.store")}
                </div>
                <div className="col">
                    {this.props.getMessageForKey("customerSearch.appointmentHistory.type")}
                </div>
                <div className="col">
                    {this.props.getMessageForKey("customerSearch.appointmentHistory.staff")}
                </div>
                <div className="col">
                    {this.props.getMessageForKey("customerSearch.appointmentHistory.status")}
                </div>
                <div className="col"/>
            </div>);
    }

    private renderAppointments(): ReactNodeArray {
        const nodes: ReactNodeArray = [];
        this.props.appointments.forEach((appointment) => {
            nodes.push(<AppointmentRow appointment={appointment} key={appointment.uuid}/>);
        });
        return nodes;
    }

    private renderViewMoreButton(): ReactNode {
        return this.props.nextLink ? <input type="button" className="button-blue-full-bg view_more-btn"
                                            value={this.props.getMessageForKey("customerSearch.form.button.viewMore.label")}
                                            onClick={() => this.viewMoreClickHandler()}/>
            : null;
    }

    private viewMoreClickHandler() {
        this.props.getMoreAppointmentsForUrl(this.props.nextLink ? this.props.nextLink : "", this.props.tenant);
    }

}

const mapStateToProps = (store: any, ownProps: IProps): IDataProps => {
    return {
        appointments: ownProps.appointments,
        getMessageForKey: getLocalizedText(store),
        nextLink: getNextLink(store),
        tenant: ownProps.tenant,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): IDispatchFromProps => {
    return {
        getMoreAppointmentsForUrl: ((nextLink, tenant) => dispatch(getMoreAppointmentsForUrl(nextLink, tenant))),
    };
};

export default connect<IDataProps, IDispatchFromProps, IProps>(
    mapStateToProps,
    mapDispatchToProps,
)(AppointmentsHistory);
