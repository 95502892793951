import {Dispatch} from "redux";
import {fetchAppointmentTypesAction} from "../actions/appointmentTypes.actions";
import {initSelectedAppTypesFilterAction} from "../actions/sidebarFilters.actions";
import {IAppointmentTypeData} from "../reducers/appointmentTypes.reducer";
import {getAppointmentTypes} from "../services/appointmentTypes.service";
import {decreaseLoadingDataCounterAction, increaseLoadingDataCounterAction} from "../actions/genericModal.actions";

export const fetchAppointmentTypes = (storeId: string, tenant: string, dispatch?: Dispatch) => {
    if (dispatch) {
        dispatch(increaseLoadingDataCounterAction());
        return getAppointmentTypes(storeId, tenant)
            .then((data) => processFetchedData(dispatch, data))
            .finally(() => dispatch(decreaseLoadingDataCounterAction()));

    } else {
        return (localDispatch: Dispatch) => {
            localDispatch(increaseLoadingDataCounterAction());
            return getAppointmentTypes(storeId, tenant)
                .then((data) => processFetchedData(localDispatch, data))
                .finally(() => localDispatch(decreaseLoadingDataCounterAction()));
        };
    }
};

function processFetchedData(dispatch: Dispatch, data: any) {
    dispatch(initSelectedAppTypesFilterAction(extractAppTypesIds(data.data)));
    dispatch(fetchAppointmentTypesAction(transformIntoAppointmentTypeData(data.data)));
}

const transformIntoAppointmentTypeData = (data: any[]): IAppointmentTypeData[] => {
    const result: IAppointmentTypeData[] = [];
    data.forEach((appointmentType) => {
        result.push({
            id: appointmentType.id,
            name: appointmentType.attributes.name,
            duration: {
                amount: appointmentType.attributes.duration.amount,
                unit: appointmentType.attributes.duration.unit,
            },
            maxFutureBookingDays: appointmentType.attributes.maxFutureBookingDays,
            roomRequired: appointmentType.attributes.requirements.roomRequired,
            opticianRequired: appointmentType.attributes.requirements.opticianRequired,
            allowRecurringPattern: appointmentType.attributes.allowRecurringPattern,
        });
    });
    return result;
};

const extractAppTypesIds = (data: any[]): string[] => {
    const appTypesIds: string[] = [];
    data.forEach((appointmentType) => {
        appTypesIds.push(appointmentType.id);
    });
    return appTypesIds;
};
