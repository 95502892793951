import {createSelector} from "reselect";
import {FETCH_CONFIG, SelectConfigActionTypes} from "../actions/config.actions";

export interface IFormElement {
    fieldKey: number;
    fieldName: string;
    fieldType: string;
}

export interface IFormElementsCol extends Array<IFormElement> {
}

export interface IFormElementsRow extends Array<IFormElementsCol> {
}

export interface IFormElementsFieldset extends Array<IFormElementsRow> {
}

export interface IConfirmAppointmentModalConfig {
    interval: number;
    maxRetries: number;
}

export interface IConfigReducer {
    formElements: IFormElementsFieldset[];
    confirmAppointmentModal: IConfirmAppointmentModalConfig;
}

const initialState: IConfigReducer = {
    confirmAppointmentModal: {
        interval: 3000,
        maxRetries: 10,
    },
    formElements: [],
};

export default function configReducer(state = initialState, action: SelectConfigActionTypes): IConfigReducer {
    switch (action.type) {
        case FETCH_CONFIG:
            return {
                ...state,
                confirmAppointmentModal: {
                    interval: action.payload.confirmAppointmentModal.interval,
                    maxRetries: action.payload.confirmAppointmentModal.maxRetries,
                },
                formElements: action.payload.formElements,
            };
        default:
            return state;
    }
}

const getConfigState = (state: any) => state.config;
export const getFormConfig = createSelector(getConfigState, (state: IConfigReducer) => state.formElements);
export const getConfirmAppointmentModalConfig = createSelector(getConfigState, (state: IConfigReducer) => state.confirmAppointmentModal);
