import {Dispatch} from "redux";
import {
    bookAppointmentSeriesBookedModalAction,
    bookAppointmentSeriesErrorModalAction,
} from "../actions/genericModal.actions";
import {createAppointmentsSeries, deleteAppointmentsSeries} from "../services/recurring.appointments.service";
import {fetchAppointments} from "./appointments.thunk";

export const createRecurringAppointments = (appointmentSeriesAttributes: any, appointmentSeriesRelationships: any, tenant: string) => {
    return (dispatch: Dispatch) => {
        createAppointmentsSeries(appointmentSeriesAttributes, appointmentSeriesRelationships, tenant)
            .then(() => dispatch(bookAppointmentSeriesBookedModalAction()))
            .catch(() => dispatch(bookAppointmentSeriesErrorModalAction()));
    };
};

export const deleteRecurringAppointment = (seriesId: any, tenant: string, storeId: string, roomId: number, startTime: Date, endTime: Date) => {
    return (dispatch: Dispatch) => {
        deleteAppointmentsSeries(seriesId, tenant)
            .then(() => {
                fetchAppointments(storeId, roomId, startTime, endTime, tenant, dispatch);
            });
    };
};
