import {Dispatch} from "redux";
import {IArrayStringIndex} from "../../@types/types";
import {fetchClosingHoursAction} from "../actions/closinghours.actions";
import {IOpeningHoursPerDate} from "../components/calendar/components/GridColumn";
import {IOpeningHoursData, OpeningHoursType} from "../reducers/closinghours.reducer";
import {getOpeningHours} from "../services/openinghours.service";
import {decreaseLoadingDataCounterAction, increaseLoadingDataCounterAction} from "../actions/genericModal.actions";

export const fetchClosingHours = (storeId: string, tenant: string, startDate: Date,
                                  requestedNumberOfDays: number, dispatch?: Dispatch) => {
    if (dispatch) {
        dispatch(increaseLoadingDataCounterAction());
        return getOpeningHours(storeId, startDate, requestedNumberOfDays, tenant)
            .then((data) => dispatchAction(data.data, dispatch))
            .finally(() => dispatch(decreaseLoadingDataCounterAction()));
    } else {
        return (localDispatch: Dispatch) => {
            localDispatch(increaseLoadingDataCounterAction());
            return getOpeningHours(storeId, startDate, requestedNumberOfDays, tenant)
                .then((data) => dispatchAction(data.data, localDispatch))
                .finally(() => localDispatch(decreaseLoadingDataCounterAction()));
        };
    }
};

const dispatchAction = (data: any[], dispatch: Dispatch) => {
    const closingHoursData = toClosingHoursData(data);
    dispatch(fetchClosingHoursAction(closingHoursData));
};

const toClosingHoursData = (data: any[]): IOpeningHoursPerDate[] => {

    const result: IOpeningHoursPerDate[] = [];
    for (const key of Object.keys(data[0].attributes.hours)) {
        const beginOfDayDate = new Date(key);
        beginOfDayDate.setHours(0, 0, 0, 0);
        const endOfDayDate = new Date(beginOfDayDate);
        endOfDayDate.setDate(beginOfDayDate.getDate() + 1);
        const closingHoursPerDate: IOpeningHoursPerDate = {date: beginOfDayDate, hours: []};

        // process opening/closing hours coming from endpoint
        data[0].attributes.hours[key].forEach((openingHour: any) => {
            closingHoursPerDate.hours.push(
                createOpeningHoursElem(new Date(openingHour.date + "T" + openingHour.from),
                    new Date(openingHour.date + "T" + openingHour.to), openingHour.name,
                    mapOpeningType(openingHour.type)));
        });

        // create closing hours basing on above opening/closing hours
        if (closingHoursPerDate.hours.length === 0) {
            closingHoursPerDate.hours.push(createOpeningHoursElem(beginOfDayDate,
                endOfDayDate, {}, OpeningHoursType.CLOSED));
        } else if (closingHoursPerDate.hours.length === 1) {
            if (isNotMidnight(closingHoursPerDate.hours[0].from)) {
                closingHoursPerDate.hours.push(createOpeningHoursElem(beginOfDayDate,
                    closingHoursPerDate.hours[0].from, {}, OpeningHoursType.CLOSED));
            }
            if (isNotMidnight(closingHoursPerDate.hours[0].to)) {
                closingHoursPerDate.hours.push(createOpeningHoursElem(closingHoursPerDate.hours[0].to,
                    endOfDayDate, {}, OpeningHoursType.CLOSED));
            }
        } else if (closingHoursPerDate.hours.length > 1) {
            const closingHours: IOpeningHoursData[] = [];

            // create first block of closing hours: from begin of the day
            if (isNotMidnight(closingHoursPerDate.hours[0].from)) {
                closingHours.push(createOpeningHoursElem(beginOfDayDate,
                    closingHoursPerDate.hours[0].from, {}, OpeningHoursType.CLOSED));
            }
            // create last block of closing hours: to end of the day
            if (isNotMidnight(closingHoursPerDate.hours[closingHoursPerDate.hours.length - 1].to)) {
                closingHours.push(createOpeningHoursElem(closingHoursPerDate.hours[closingHoursPerDate.hours.length - 1].to,
                    endOfDayDate, {}, OpeningHoursType.CLOSED));
            }
            // create mid blocks
            for (let i = 0; i < closingHoursPerDate.hours.length - 1; i++) {
                if (closingHoursPerDate.hours[i].to.getTime() !== closingHoursPerDate.hours[i + 1].from.getTime()) {
                    closingHours.push(createOpeningHoursElem(closingHoursPerDate.hours[i].to,
                        closingHoursPerDate.hours[i + 1].from, {}, OpeningHoursType.CLOSED));
                }
            }
            closingHoursPerDate.hours = closingHoursPerDate.hours.concat(closingHours);
        }
        closingHoursPerDate.hours = filterHours(closingHoursPerDate.hours);

        result.push(closingHoursPerDate);
    }

    return result.sort(byDateAscendingComparator);
};

const byDateAscendingComparator = (openingHoursPerDate: IOpeningHoursPerDate,
                                   anotherOpeningHoursPerDate: IOpeningHoursPerDate): number => {
    if (openingHoursPerDate.date < anotherOpeningHoursPerDate.date) {
        return -1;
    }
    if (openingHoursPerDate.date > anotherOpeningHoursPerDate.date) {
        return 1;
    }
    return 0;
};

function mapOpeningType(type: OpeningHoursType): OpeningHoursType {
    return type === OpeningHoursType.CLOSED ? OpeningHoursType.CLOSED_SPECIAL : type;
}

function createOpeningHoursElem(from: Date, to: Date, name: IArrayStringIndex,
                                type: OpeningHoursType): IOpeningHoursData {
    return {
        from, name, to, type,
    };
}

function filterHours(openingHours: IOpeningHoursData[]): IOpeningHoursData[] {
    return openingHours.filter((elem) => elem.type !== OpeningHoursType.OPEN);
}

function isNotMidnight(date: Date) {
    return (date.getHours() === 0 && date.getMinutes() !== 0) || date.getHours() !== 0;
}
