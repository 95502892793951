import {Dispatch} from "redux";
import {fetchTimeslotsAction} from "../actions/timeslots.actions";
import {ITimeslotData, ITimeslotDataForDay} from "../reducers/timeslots.reducer";
import {getTimeslots} from "../services/timeslots.service";

export const fetchTimeslots = (storeId: string, appointmentTypeId: string, startDate: Date, tenant: string, roomId: string | null, opticianId: string | null) => {
    return (dispatch: Dispatch) => {
        return getTimeslots(storeId, appointmentTypeId, startDate, tenant, opticianId, roomId)
            .then((data) => {
                dispatch(fetchTimeslotsAction(transformIntoTimeslotData(data.data)));
            });
    };
};

const transformIntoTimeslotData = (data: any[]): ITimeslotDataForDay[] => {
    const result: ITimeslotDataForDay[] = [];
    data.forEach((timeslotsForDay) => {
        const timeslots: ITimeslotData[] = [];
        let id: number = 0;
        timeslotsForDay.attributes.timeSlots.forEach((timeslot: any) => {
            timeslots.push({
                id,
                startTime: new Date(timeslot.startTime),
                endTime: new Date(timeslot.endTime),
            });
            id++;
        });
        // We receive "yyyy-mm-dd", so create a ISO 8601 string from it we can reliably parse
        result.push({date: new Date(timeslotsForDay.attributes.date + "T00:00:00"), timeslots});
    });

    return result;
};
