import {Dispatch} from "redux";
import {fetchStoresAction, selectStoresAction} from "../actions/store.action";
import {IStoreData} from "../reducers/store.reducer";
import {getLocaleConfig} from "../services/locale.service";
import {getStores} from "../services/store.service";
import {fetchAppointmentTypes} from "./appointmentTypes.thunk";
import {fetchClosingHours} from "./closinghours.thunk";
import {fetchRoomsForStore} from "./room.thunk";
import {
    decreaseLoadingDataCounterAction,
    increaseLoadingDataCounterAction,
} from "../actions/genericModal.actions";

export const fetchStores = (tenant: string, startDate: Date, endDate: Date, locale: string) => {
    return (dispatch: Dispatch) => {
        dispatch(increaseLoadingDataCounterAction());
        return getStores(tenant)
            .then((data) => {
                const storeData = transformIntoIStoreData(data.data);
                if (data.data.length > 0) {
                    return getLocaleConfig(tenant)
                        .then((localeConfig) => {
                            const resultLocale = locale && localeConfig.availableLocales && localeConfig.availableLocales.includes(locale) ?
                                locale : localeConfig.defaultLocale;
                            storeData.sort((a, b) => sortStoresByNameComparator(a, b, resultLocale, localeConfig.fallback));
                            dispatch(selectStoresAction(storeData[0]));
                            fetchRoomsForStore(storeData[0].id, tenant, startDate, endDate, dispatch);
                            fetchAppointmentTypes(storeData[0].id, tenant, dispatch);
                            fetchClosingHours(storeData[0].id, tenant, startDate, 7, dispatch);
                            dispatch(fetchStoresAction(storeData));
                        });
                }
            })
            .finally(() => dispatch(decreaseLoadingDataCounterAction()));
    };
};

const transformIntoIStoreData = (data: any[]): IStoreData[] => {
    const result: IStoreData[] = [];
    data.forEach((value) => {
        result.push({
            id: value.id,
            name: value.attributes.name,
            code: value.attributes.code,
            phoneNumber: value.attributes.phoneNumber,
            timezone: value.attributes.timeZone,
            url: value.attributes.url,
        });
    });
    return result;
};

const sortStoresByNameComparator = (a: IStoreData, b: IStoreData, locale: string, fallbackLocale: string) => {
    const nameA = a.name[locale] ? a.name[locale] : a.name[fallbackLocale];
    const nameB = b.name[locale] ? b.name[locale] : b.name[fallbackLocale];

    let result = 0;
    if (nameA > nameB) {
        result = 1;
    } else if (nameA < nameB) {
        result = -1;
    }
    return result;
};
