import {IConfirmAppointmentModalConfig, IFormElementsFieldset} from "../reducers/config.reducer";

export const FETCH_CONFIG = "FETCH_CONFIG";

interface IConfigPayload {
    formElements: IFormElementsFieldset[];
    confirmAppointmentModal: IConfirmAppointmentModalConfig;
}

interface IFetchConfig {
    type: typeof FETCH_CONFIG;
    payload: IConfigPayload;
}

export type SelectConfigActionTypes = IFetchConfig;

export function fetchConfig(config: IConfigPayload): IFetchConfig {
    return {
        payload: config,
        type: FETCH_CONFIG,
    };
}
