import React from "react";
import {connect} from "react-redux";
import {GridStyle} from "../reducers/gridstyle.reducer";
import "./DateSelector.scss";
import {
    DateSelectorBaseComponent,
    dateSelectorBaseMapStateToProps,
    dateSelectorMapDispatchToProps,
    IDateSelectorBaseDataProps,
    IDateSelectorBaseDispatchFromProps,
    IDateSelectorBaseProps,
} from "./base/DateSelectorBase";
import {findMonday} from "../util/date.util";
import {addDays} from "date-fns";

export class DateSelectorComponent extends DateSelectorBaseComponent {

    public render(): React.ReactNode {
        let dateString;
        if (this.props.selectedDate) {
            if (this.props.selectedGridStyle === GridStyle.CALENDAR_DAY || this.props.selectedGridStyle === GridStyle.MULTIROOM_DAY) {
                const options = {weekday: "long", year: "numeric", month: "long", day: "numeric"};
                dateString = this.props.selectedDate.startRangeDate.toLocaleDateString(this.props.calendarLocaleFormat, options);
            } else {
                const endDate = new Date(this.props.selectedDate.startRangeDate);
                endDate.setDate(this.props.selectedDate.startRangeDate.getDate() + this.props.selectedDate.range - 1);
                const options = {year: "numeric", month: "long", day: "numeric"};
                if (this.props.selectedDate.startRangeDate.getMonth() === endDate.getMonth()) {
                    dateString = this.props.selectedDate.startRangeDate.getDate() + " - " + endDate.toLocaleDateString(this.props.calendarLocaleFormat, options);
                } else {
                    dateString = this.props.selectedDate.startRangeDate.toLocaleDateString(this.props.calendarLocaleFormat, options) + " - " + endDate.toLocaleDateString(this.props.calendarLocaleFormat, options);
                }
            }
        }

        return (
            <div className="date_selector">
                <div className="date_selector_arrows ui-element">
                    <div className={"button grid_selector_today"}>
                        <button className={"grid_selector_today_text selected btn btn-primary"} onClick={() => this.onSelectCurrentDay()}>{this.props.getMessageForKey("gridSelector.today")}</button>
                    </div>
                    <div className="date_selector_element button"><span className="fa fa-angle-left"
                                                                        onClick={() => this.onChangeDateBackward()}/>
                    </div>
                    <div className="separator">|</div>
                    <div className="date_selector_element button"><span className="fa fa-angle-right"
                                                                        onClick={() => this.onChangeDateForward()}/>
                    </div>
                </div>
                <div className="date_selector_element">{dateString}</div>
            </div>
        );
    }

    protected onSelectCurrentDay(): void {
        let newDateRange;
        if  (this.props.selectedGridStyle === GridStyle.CALENDAR_WEEK || this.props.selectedGridStyle === GridStyle.MULTIROOM_WEEK) {
             newDateRange = {
                selectedDate: new Date(),
                startRangeDate:  findMonday(new Date()),
                range: 7,
            };
        } else {
            newDateRange = {
                selectedDate: new Date(),
                startRangeDate:   new Date(),
                range: 1,
            };
        }

        this.props.setAdjustSidebarFlag(true);
        this.props.changeDateRange(newDateRange);
        this.refreshData(newDateRange);
    }

    private onChangeDateForward(): void {
        if (this.props.selectedDate !== undefined) {
            this.props.setAdjustSidebarFlag(true);
            const range = (this.props.selectedGridStyle === GridStyle.CALENDAR_WEEK || this.props.selectedGridStyle === GridStyle.MULTIROOM_WEEK) ? 7 : 1;
            const newDateRange = {
                selectedDate: addDays(this.props.selectedDate.selectedDate, range),
                startRangeDate: addDays(this.props.selectedDate.startRangeDate, range),
                range,
            };
            this.props.changeDateRange(newDateRange);
            this.refreshData(newDateRange);
        }
    }

    private onChangeDateBackward(): void {
        if (this.props.selectedDate !== undefined) {
            this.props.setAdjustSidebarFlag(true);
            const range = (this.props.selectedGridStyle === GridStyle.CALENDAR_WEEK || this.props.selectedGridStyle === GridStyle.MULTIROOM_WEEK) ? 7 : 1;
            const newDateRange = {
                    selectedDate: addDays(this.props.selectedDate.selectedDate, -range),
                    startRangeDate: addDays(this.props.selectedDate.startRangeDate, -range),
                    range,
                };
            this.props.changeDateRange(newDateRange);
            this.refreshData(newDateRange);
        }
    }
}

// @ts-ignore
export default connect<IDateSelectorBaseDataProps, IDateSelectorBaseDispatchFromProps, IDateSelectorBaseProps>(
    dateSelectorBaseMapStateToProps,
    dateSelectorMapDispatchToProps,
)(DateSelectorComponent);
