import React from "react";

export default class NotFound extends React.Component {

    public render(): React.ReactNode {
        return (
            <div>
                NotFound
            </div>
        );
    }
}
