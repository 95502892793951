import React from "react";
import {RouteComponentProps} from "react-router";
import {NavLink, withRouter} from "react-router-dom";
import "./HeaderIcon.scss";

interface IHeaderIconProps extends RouteComponentProps<any> {
    image: any;
    name: string;
    alt: string;
    onClick?: () => void;
    highlight?: boolean;
    link?: boolean;
    hidden?: boolean;
}

class HeaderIcon extends React.Component<IHeaderIconProps> {
    public render(): React.ReactNode {
        if (this.props.hidden) {
            return <></>;
        }
        const redirectUrl = this.props.match.url + "/" + this.props.alt;
        return (
            <>
                {this.props.link ?
                    <NavLink to={redirectUrl} isActive={() => this.isActive()} className="header_icon">
                        {this.getImage()}
                    </NavLink> :
                        <div className={this.getHeaderIconClass()} onClick={this.props.onClick}>
                            {this.getImage()}
                        </div>
                }
            </>
        );
    }

    private getImage() {
        return <>
            <img src={this.props.image} className="header_icon_img" alt={this.props.alt}
                 height="30px" width="30px"/>
            <div className="header_icon_name">{this.props.name}</div>
        </>;
    }

    private isActive() {
        return this.props.location.pathname.includes(this.props.alt);
    }

    private getHeaderIconClass(): string {
        return this.props.highlight ? "header_icon highlighted" : "header_icon";
    }

}

export default withRouter(HeaderIcon);
