import React, {ReactNode} from "react";
import {connect} from "react-redux";
import Select, {ValueType} from "react-select-newest";
import {ThunkDispatch} from "redux-thunk";
import {getLocalizedText} from "../reducers/locale.reducer";
import {updateAppointment} from "../thunks/appointments.thunk";
import "./CancelAppointmentModal.scss";

interface IDropdownItem {
    id: string;
    name: string;
}

interface ISelectDropdownItem {
    label: string;
    value: string;
}

interface IState {
    selectedReasonId: string | undefined;
}

interface IProps {
    appointmentId: string;
    tenant: string;
    closeModal: () => void;
}

interface IConnectProps {
    getLocalizedText: (key: string) => string;
}

interface IDispatchFromProps {
    updateAppointment: (appointmentId: string, attrs: any, tenant: string) => void;
}

interface IDataProps extends IProps, IConnectProps {
}

interface IResultProps extends IDataProps, IDispatchFromProps {
}

export class CancelAppointmentModalComponent extends React.Component<IResultProps, IState> {

    constructor(props: IResultProps) {
        super(props);
        this.getNoOptionsMessage = this.getNoOptionsMessage.bind(this);
        this.state = {
            selectedReasonId: undefined,
        };
    }

    public render(): React.ReactNode {
        return (
            <div className="cancel-appointment-modal-container">
                <div className="background_greyed">
                    <div className="cancel-appointment-modal">
                        <div className="float-right close-popup" onClick={() => this.props.closeModal()}>&times;</div>
                        <h1 className="cancel-appointment-modal-header">{this.props.getLocalizedText("cancelAppointmentModal.header")}</h1>
                        <div
                            className="cancel-dropdown-label">{this.props.getLocalizedText("cancelAppointmentModal.label")}</div>
                        {this.renderSelectDropdown()}
                        {this.renderPreValidation()}
                        <div className="buttons">
                            <button className="back_button" onClick={() => this.props.closeModal()}>
                                {this.props.getLocalizedText("cancelAppointmentModal.button.back")}
                            </button>
                            <button className="confirm_button" onClick={() => this.sendCancelRequest()}
                                    disabled={this.isCancelButtonDisabled()}>
                                {this.props.getLocalizedText("cancelAppointmentModal.button.confirm")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderPreValidation(): ReactNode {
        if (!this.state.selectedReasonId) {
            return <div
                className="dropdown-prevalidation">{this.props.getLocalizedText("cancelAppointmentModal.validation")}</div>;
        }
    }

    private renderSelectDropdown(): ReactNode {
        return (
            <Select
                noOptionsMessage={this.getNoOptionsMessage}
                placeholder={this.props.getLocalizedText("cancelAppointmentModal.dropdown.placeholder")}
                onChange={(item: ValueType<ISelectDropdownItem>) => this.selectDropdownValue(item)}
                isSearchable={false}
                options={this.getSelectDropdownContent()}
            />
        );
    }

    private getNoOptionsMessage(): string {
        return this.props.getLocalizedText("form.options.noOptionsMessage") || "No options";
    }

    private selectDropdownValue(selected: ValueType<ISelectDropdownItem>): void {
        this.setState({selectedReasonId: (selected as ISelectDropdownItem).value});
    }

    private getSelectDropdownContent(): ISelectDropdownItem[] {
        const dropdownItems: unknown = this.props.getLocalizedText("cancelAppointmentModal.dropdown");
        const dropdownItemArray: IDropdownItem[] = dropdownItems as IDropdownItem[];
        const result: ISelectDropdownItem[] = [];
        if (dropdownItemArray) {
            dropdownItemArray.forEach((item) =>
                result.push({label: item.name, value: item.id}));
        }
        return result;
    }

    private isCancelButtonDisabled(): boolean {
        return this.state.selectedReasonId === undefined;
    }

    private sendCancelRequest(): void {
        if (this.state.selectedReasonId) {
            this.props.updateAppointment(this.props.appointmentId, this.prepareCancelAttributes(), this.props.tenant);
            this.props.closeModal();
        }
    }

    private prepareCancelAttributes(): object {
        return {
            status: "CANCEL",
            cancellationReasonCode: this.state.selectedReasonId ? this.state.selectedReasonId : "",
        };
    }
}

const mapStateToProps = (store: any, ownProps: IProps): IDataProps => {
    return {
        appointmentId: ownProps.appointmentId,
        closeModal: ownProps.closeModal,
        tenant: ownProps.tenant,
        getLocalizedText: getLocalizedText(store),
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): IDispatchFromProps => {
    return {
        updateAppointment: (appointmentId: string, attrs: any, tenant: string) => {
            dispatch(updateAppointment(appointmentId, attrs, tenant));
        },
    };
};

export default connect<IDataProps, IDispatchFromProps, IProps>(
    mapStateToProps,
    mapDispatchToProps,
)(CancelAppointmentModalComponent);
