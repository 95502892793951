import {createSelector} from "reselect";
import { GRID_STYLE_CHANGE, GridstyleActionTypes } from "../actions/gridstyle.action";

export enum GridStyle {
  MULTIROOM_DAY,
  MULTIROOM_WEEK,
  CALENDAR_DAY,
  CALENDAR_WEEK,
}

interface IGridStyleReducer {
  selectedGridStyle: GridStyle | undefined;
}

const initialState: IGridStyleReducer = {
  selectedGridStyle: GridStyle.CALENDAR_WEEK,
};

export default function gridStyleReducer(state = initialState, action: GridstyleActionTypes): IGridStyleReducer {
  switch (action.type) {
    case GRID_STYLE_CHANGE:
      return {
        ...state,
        selectedGridStyle: action.payload,
      };
    default:
      return state;
  }
}

const getGridStyleState = (state: any) => state.gridstyle;
export const getSelectedGridStyle = createSelector(getGridStyleState, (state: IGridStyleReducer) => state.selectedGridStyle);
