import React, {ReactNode, ReactNodeArray} from "react";
import {connect} from "react-redux";
import {getLocalizedText} from "../../../reducers/locale.reducer";

interface IProps {
    emptyHeaderColumns: number;
}

interface IConnectProps {
    getMessageForKey: (key: string) => string;
}

interface IResultProps extends IProps, IConnectProps {
}

export class CustomerSearchResultHeader extends React.Component<IResultProps> {

    public render(): React.ReactNode {
        return this.renderHeader();
    }

    private renderHeader(): ReactNode {
        return (
            <div className="row column_header">
                <div className="col">
                    {this.props.getMessageForKey("customerSearch.result.column.firstName")}
                </div>
                <div className="col">
                    {this.props.getMessageForKey("customerSearch.result.column.lastName")}
                </div>
                <div className="col">
                    {this.props.getMessageForKey("customerSearch.result.column.email")}
                </div>
                <div className="col">
                    {this.props.getMessageForKey("customerSearch.result.column.phone")}
                </div>
                <div className="col">
                    {this.props.getMessageForKey("customerSearch.result.column.dateOfBirth")}
                </div>
                {this.renderEmptyCols()}
            </div>);
    }

    private renderEmptyCols(): ReactNodeArray {
        const emptyCols: ReactNodeArray = [];
        for (let i = 0; i < this.props.emptyHeaderColumns; i++) {
            emptyCols.push(<div className="col empty" key={i}/>);
        }
        return emptyCols;
    }
}

const mapStateToProps = (store: any, ownProps: IProps): IResultProps => {
    return {
        emptyHeaderColumns: ownProps.emptyHeaderColumns,
        getMessageForKey: getLocalizedText(store),
    };
};

export default connect<IResultProps, any, any>(
    mapStateToProps,
    undefined,
)(CustomerSearchResultHeader);
