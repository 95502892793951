import React from "react";

interface ICustomerLookupProps {
    image: any;
    message: string;
    alt: string;
}

export default class CustomerLookup extends React.Component<ICustomerLookupProps> {

    public render(): React.ReactNode {
        return (
            <div>
                <img src={this.props.image} alt={this.props.alt}
                     height="25px" width="25px"/>
                {this.props.message}
            </div>);
    }
}
