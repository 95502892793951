import {Dispatch} from "redux";
import {fetchRoomsAction, selectRoomAction} from "../actions/room.actions";
import {IRoomData} from "../reducers/room.reducer";
import {getRooms} from "../services/room.service";
import {fetchAppointments} from "./appointments.thunk";
import {decreaseLoadingDataCounterAction, increaseLoadingDataCounterAction} from "../actions/genericModal.actions";

export const fetchRoomsForStore = (storeId: string, tenant: string, startDate: Date,
                                   endDate: Date, dispatch?: Dispatch) => {
    if (dispatch) {
        dispatch(increaseLoadingDataCounterAction());
        return getRooms(storeId, tenant)
            .then((data) => runDispatches(data.data, dispatch, storeId, tenant, startDate, endDate))
            .finally(() => dispatch(decreaseLoadingDataCounterAction()));
    } else {
        return (localDispatch: Dispatch) => {
            localDispatch(increaseLoadingDataCounterAction());
            return getRooms(storeId, tenant)
                .then((data) => runDispatches(data.data, localDispatch, storeId, tenant, startDate, endDate))
                .finally(() => localDispatch(decreaseLoadingDataCounterAction()));
        };
    }
};

const runDispatches = (data: any[], dispatch: Dispatch, storeId: string,
                       tenant: string, startDate: Date, endDate: Date) => {
    const roomData = transformToRoomData(data);
    dispatch(fetchRoomsAction(roomData));
    if (data.length > 0) {
        dispatch(selectRoomAction(roomData[0]));
        fetchAppointments(storeId, roomData[0].id, startDate, endDate, tenant, dispatch);
    }
};

const transformToRoomData = (data: any[]): IRoomData[] => {
    const result: IRoomData[] = [];
    data.forEach((value) => {
        const appointmentTypeIds: string[] = [];
        value.relationships.appointmentTypes.data.forEach((appointmentTypeIncludes: any) => {
            appointmentTypeIds.push(appointmentTypeIncludes.id);
        });
        result.push({
            id: value.id,
            name: value.attributes.name,
            appointmentTypeIds,
        });
    });
    return result;
};
