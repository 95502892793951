import React, {FormEvent} from "react";

interface IProps {
    label: string;
    fieldName: string;
    onChange: (event: FormEvent<HTMLTextAreaElement>) => void;
    disabled: boolean;
    value: string;
    className: string;
}

export default class Textarea extends React.Component<IProps> {

    public render(): React.ReactNode {
        return (
            <>
                <div className="group-label">
                    <label>{this.props.label}</label>
                </div>
                <textarea name={this.props.fieldName}
                          id={this.props.fieldName}
                          disabled={this.props.disabled}
                          maxLength={255}
                          className={this.props.className}
                          onChange={(event) => this.props.onChange(event)}
                          value={this.props.value}/>
            </>);
    }

}
