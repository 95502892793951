import React from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {getCancelledAppointmentToggle} from "../reducers/sidebar.filters.reducer";
import {updateToggleCancelledAppointmentsAction} from "../actions/sidebarFilters.actions";


interface IConnectProps {
    toggleCancelledAppointment?: boolean;
}

interface IDispatchFromProps {
    updateToggleCancelledAppointments?:(toggle: boolean) => void;
}
interface IProps {
    label: string;
    description: string;
}

interface IDataProps extends IProps, IConnectProps {
    toggleCancelledAppointment?: any;
}

export interface IResultProps extends IDataProps, IDispatchFromProps {
    updateToggleCancelledAppointments?: any
}

export class CancelledFilter extends React.Component<IResultProps> {


    public render(): React.ReactNode {
        return (
        <li className="sidebar-elem-border">
            <div className="appointment-filter">
                <div className="filter-header cancelled">
                    <span className="filter-label">{this.props.label}</span>
                        <label className="container" htmlFor="cancelInput">
                        <span>{this.props.description}</span>
                        <input type="checkbox" id='cancelInput' checked={this.props.toggleCancelledAppointment} onChange={()=> this.props.updateToggleCancelledAppointments(!this.props.toggleCancelledAppointment)} />
                        <span className="checkmark cancelled"/>
                        </label>
                </div>
            </div>
        </li>
    )
}
}

const mapStateToProps = (store: any, ownProps: IProps): IDataProps => {
    return {
        label: ownProps.label,
        description: ownProps.description,
        toggleCancelledAppointment: getCancelledAppointmentToggle(store)
    };
};
const mapDispatchToProps = (dispatch: Dispatch): IDispatchFromProps => {
    return {
        updateToggleCancelledAppointments: (toggle) => dispatch(updateToggleCancelledAppointmentsAction(toggle))
    };
};
export default connect<IDataProps, IDispatchFromProps, IProps, void>(
    mapStateToProps,
    mapDispatchToProps
)(CancelledFilter);
