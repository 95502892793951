import { GridStyle } from "../reducers/gridstyle.reducer";
import { IEmptyAction } from "./action.actions";

export const GRID_STYLE_CHANGE = "GRID_STYLE_CHANGE";

interface IGridStyleChangeAction {
  type: typeof GRID_STYLE_CHANGE;
  payload: GridStyle;
}

export type GridstyleActionTypes = IGridStyleChangeAction | IEmptyAction;

export function selectGridStyleAction(store: GridStyle): IGridStyleChangeAction {
  return{
    payload: store,
    type: GRID_STYLE_CHANGE,
  };
}
