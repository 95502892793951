import {AppointmentModal, AppointmentModalMode} from "../AppointmentModal";
import * as EmailValidator from "email-validator";

export class AppointmentModalValidator {

    private static readonly timePattern = new RegExp("^([0-1][0-9]|2[0-3]):[0-5][0|5]$");

    public static isFormValid(modal: AppointmentModal): boolean {
        return !this.submitDisabled(modal);
    }

    private static submitDisabled(modal: AppointmentModal): boolean {
        if (modal.state.overrideAvailability) {
            return this.submitDisabledInOverrideMode(modal);
        } else if (modal.state.recurringMode) {
            return this.submitDisabledInRecurringMode(modal);
        } else if (modal.props.mode === AppointmentModalMode.EDIT) {
            return this.submitDisabledInEditMode(modal);
        } else {
            return this.submitDisabledInStandardMode(modal);
        }
    }

    public static isEmpty(inputValue: string): boolean {
        return inputValue === undefined || /^\s*$/.test(inputValue)
    }

    public static isNotEmpty(inputValue: string): boolean {
        return !this.isEmpty(inputValue);
    }

    public static isAppointmentDateInvalid(appointmentDate: Date | null, appointmentDateValidationActivated: boolean) {
        return appointmentDateValidationActivated && appointmentDate === null;
    }

    public static isOverrideStartDateTimeInvalid(overrideStartDateTime: Date | null, overrideStartDateTimeValidationActivated: boolean): boolean {
        return (overrideStartDateTimeValidationActivated && overrideStartDateTime === null)
            || (overrideStartDateTime !== null && this.isTimeInvalid(overrideStartDateTime.toTimeString().substr(0, 5)));
    }

    public static isOverrideEndDateTimeInvalid(overrideEndDateTime: Date | null, overrideStartDateTime: Date | null, overrideEndDateTimeValidationActivated: boolean): boolean {
        return (overrideEndDateTimeValidationActivated && overrideEndDateTime === null)
            || (overrideEndDateTime !== null && this.isTimeInvalid(overrideEndDateTime.toTimeString().substr(0, 5)))
            || this.endDateIsLessOrEqualsStartDate(overrideEndDateTime, overrideStartDateTime);
    }

    public static isRecurringStartDateInvalid(recurringStartDate: Date | null, recurringStartDateValidationActivated: boolean): boolean {
        return recurringStartDateValidationActivated && recurringStartDate === null;
    }

    public static recurringDaysOfWeekInvalid(selectedDays: Set<string>, recurringDaysOfWeekValidationActivated: boolean) {
        return recurringDaysOfWeekValidationActivated && selectedDays.size === 0;
    }

    public static isRecurringEndDateInvalid(recurringEndDate: Date | null, recurringStartDate: Date | null, recurringEndDateValidationActivated: boolean): boolean {
        return (recurringEndDateValidationActivated && recurringEndDate === null) || this.endDateIsLessOrEqualsStartDate(recurringEndDate, recurringStartDate);
    }

    public static isRecurringEndTimeInvalid(recurringEndTime: string, recurringStartTime: string): boolean {
        return !this.timePattern.test(recurringEndTime) || this.endTimeIsLessOrEqualsStartTime(recurringEndTime, recurringStartTime);
    }

    public static isRecurringStartTimeInvalid(recurringStartTime: string): boolean {
        return this.isTimeInvalid(recurringStartTime);
    }

    public static isRecurringIntervalInvalid(interval: string): boolean {
        return isNaN(Number(interval))
            || Number(interval) < 1
            || Number(interval) > 53;
    }

    private static endTimeIsLessOrEqualsStartTime(recurringEndTime: string, recurringStartTime: string): boolean {
        if (this.timePattern.test(recurringEndTime) && this.timePattern.test(recurringStartTime)) {
            return Number(recurringEndTime.substr(0, 2)) < Number(recurringStartTime.substr(0, 2))
                || (Number(recurringEndTime.substr(0, 2)) === Number(recurringStartTime.substr(0, 2))
                    && Number(recurringEndTime.substr(3, 2)) <= Number(recurringStartTime.substr(3, 2)));
        }
        return false;
    }

    private static isTimeInvalid(time: string): boolean {
        return !this.timePattern.test(time);
    }

    private static endDateIsLessOrEqualsStartDate(endDate: Date | null, startDate: Date | null): boolean {
        if (endDate !== null && startDate !== null) {
            return endDate <= startDate;
        } else {
            return false;
        }
    }

    public static emailAddressInvalid(notificationChannel: string, email: string, overrideAvailability: boolean = false): boolean {
        let invalid: boolean;
        if (notificationChannel === "email" && !overrideAvailability) {
            invalid = !EmailValidator.validate(email);
        } else {
            invalid = this.notEmptyEmailAddressInvalid(email);
        }
        return invalid;
    }

    public static phoneNumberInvalid(notificationChannel: string, phone: string, overrideAvailability: boolean = false): boolean {
        let invalid: boolean;
        if (notificationChannel === "sms" && !overrideAvailability) {
            invalid = this.isEmpty(phone) || isNaN(Number(phone));
        } else {
            invalid = this.notEmptyPhoneInvalid(phone);
        }
        return invalid;
    }

    public static isNumberAdditionalFieldValid(fieldValue: any, overrideAvailability: boolean, mandatory: boolean): boolean {
        let valid: boolean;
        if (overrideAvailability) {
            valid = this.isNotEmpty(fieldValue) ? !isNaN(fieldValue) : true;
        } else {
            if (mandatory) {
                valid = this.isNotEmpty(fieldValue) && !isNaN(fieldValue);
            } else {
                valid = !isNaN(fieldValue);
            }
        }
        return valid;
    }

    public static isCheckboxAdditionalFieldValid(checked: boolean, overrideAvailability: boolean, mandatory: boolean) {
        let valid: boolean = true;
        if (!overrideAvailability && mandatory) {
            valid = checked;
        }
        return valid;
    }

    public static isTextAdditionalFieldValid(fieldValue: any, overrideAvailability: boolean, mandatory: boolean): boolean {
        let valid: boolean = true;
        if (!overrideAvailability && mandatory) {
            valid = this.isNotEmpty(fieldValue);
        }
        return valid;
    }

    public static isChileRutValid(fieldValue: any, overrideAvailability: boolean, mandatory: boolean): boolean {
        if (!overrideAvailability && mandatory && this.isEmpty(fieldValue)) {
            return false;
        }
        if (!/^[0-9]{5,8}[-|‐][0-9kK]$/.test(fieldValue)) {
            return false;
        }
        fieldValue = fieldValue.replace("‐", "-");
        const rut = fieldValue.split('-');
        const rutNumber = rut[0];
        let checkDigit = rut[1];
        if (checkDigit === 'K') {
            checkDigit = 'k';
        }
        return checkDigit === this.calculateCheckDigit(rutNumber);
    }

    private static calculateCheckDigit(T: any) {
        let M = 0;
        let S = 1;
        for (; T; T = Math.floor(T / 10)) {
            S = (S + T % 10 * (9 - M++ % 6)) % 11;
        }
        return S ? (S - 1).toString() : 'k';
    }

    private static notEmptyPhoneInvalid(phone: string) {
        let invalid = false;
        if (this.isNotEmpty(phone)) {
            invalid = isNaN(Number(phone));
        }
        return invalid;
    }

    private static notEmptyEmailAddressInvalid(email: string): boolean {
        let invalid = false;
        if (this.isNotEmpty(email)) {
            invalid = !EmailValidator.validate(email);
        }
        return invalid;
    }

    private static anyNumberAdditionalFieldInvalid(modal: AppointmentModal): boolean {
        let invalid = false;
        const numberFieldLabels: string[] = [];
        modal.props.additionalFields.forEach((field) => {
            if (field.dataType === "NUMBER") {
                numberFieldLabels.push(field.label);
            }
        });
        for (const label of numberFieldLabels) {
            if (this.isNotEmpty(modal.state.additionalFields.get(label)) && isNaN(modal.state.additionalFields.get(label))) {
                invalid = true;
                break;
            }
        }
        return invalid;
    }

    private static submitDisabledInStandardMode(modal: AppointmentModal): boolean {
        return modal.state.appointmentType === null
            || modal.state.startTime === null
            || modal.state.appointmentDate === null
            || this.anyMandatoryAdditionalFieldMissing(modal)
            || this.customerDataInvalid(modal)
            || this.anyNumberAdditionalFieldInvalid(modal);
    }

    private static submitDisabledInOverrideMode(modal: AppointmentModal): boolean {
        return this.isRoomOrOpticianMissing(modal)
            || this.isOverrideStartDateTimeInvalid(modal.state.overrideStartDateTime, true)
            || this.isOverrideEndDateTimeInvalid(modal.state.overrideEndDateTime, modal.state.overrideStartDateTime, true)
            || this.anyNumberAdditionalFieldInvalid(modal)
            || this.notEmptyEmailAddressInvalid(modal.state.email)
            || this.notEmptyPhoneInvalid(modal.state.phone);
    }

    private static submitDisabledInRecurringMode(modal: AppointmentModal): boolean {
        return this.isRecurringStartDateInvalid(modal.state.recurringAppointmentsStartDate, true)
            || this.isRecurringEndDateInvalid(modal.state.recurringAppointmentsEndDate, modal.state.recurringAppointmentsStartDate, true)
            || this.recurringDaysOfWeekInvalid(modal.state.selectedDaysOfWeek, true)
            || this.isRecurringIntervalInvalid(String(modal.state.interval))
            || this.isRecurringStartTimeInvalid(modal.state.recurringAppointmentsStartTime)
            || this.isRecurringEndTimeInvalid(modal.state.recurringAppointmentsEndTime, modal.state.recurringAppointmentsStartTime);
    }

    private static submitDisabledInEditMode(modal: AppointmentModal): boolean {
        return this.anyMandatoryAdditionalFieldMissing(modal)
            || this.customerDataInvalid(modal)
            || this.anyNumberAdditionalFieldInvalid(modal);
    }

    private static isRoomOrOpticianMissing(modal: AppointmentModal): boolean {
        if (modal.props.getSelectedAppointmentType !== undefined) {
            if (modal.props.getSelectedAppointmentType.opticianRequired) {
                if (modal.state.optician === null) {
                    return true;
                }
            }
            if (modal.props.getSelectedAppointmentType.roomRequired) {
                if (modal.state.diary === null) {
                    return true;
                }
            }
        } else {
            return true;
        }
        return false;
    }

    private static anyMandatoryAdditionalFieldMissing(modal: AppointmentModal): boolean {
        let anyFieldMissing: boolean = false;
        if (modal.props.additionalFields) {
            const mandatoryAdditionalFields = modal.props.additionalFields.filter((field) => field.mandatory);
            mandatoryAdditionalFields.forEach((field) => {
                if (!modal.state.additionalFields.has(field.label)) {
                    anyFieldMissing = true;
                    return;
                }

            });
        }
        return anyFieldMissing;
    }

    private static customerDataInvalid(modal: AppointmentModal): boolean {
        return this.isEmpty(modal.state.firstName)
            || this.isEmpty(modal.state.lastName)
            || this.isEmpty(modal.state.notificationChannel)
            || this.isEmpty(modal.state.notificationLanguage)
            || this.emailAddressInvalid(modal.state.notificationChannel, modal.state.email)
            || this.phoneNumberInvalid(modal.state.notificationChannel, modal.state.phone);
    }
}
