import {IAppointmentData} from "../../reducers/appointments.reducer";
import {ISelectedDateAndRange} from "../../reducers/selecteddate.reducer";
import {incrementDate, isSameDate} from "../../util/date.util";
import {IAppointmentsPerDate} from "./components/GridDayColumn";
import {addDays} from "date-fns";

export function getAppointmentsPerDay(appointments: IAppointmentData[], startDate: ISelectedDateAndRange): IAppointmentsPerDate[] {
    const initialAppointments: IAppointmentData[][] = [[], [], [], [], [], [], []];
    appointments.forEach((appointment) => {
        if (!isSameDate(appointment.startTime, appointment.endTime)) {
            processMultiDayAppointment(initialAppointments, appointment, startDate);
        } else {
            const appointmentIndex = getAppointmentIndex(appointment.startTime, startDate.startRangeDate);
            if (appointmentIndex >= 0 && appointmentIndex < initialAppointments.length) {
                initialAppointments[appointmentIndex].push(appointment);
            }
        }
    });
    const result: IAppointmentsPerDate[] = [];
    for (let i = 0; i < initialAppointments.length; i++) {
        const date = addDays(startDate.startRangeDate, i);
        date.setHours(0, 0, 0, 0);
        result.push({
            appointments: initialAppointments[i],
            date,
        });
    }
    return result;
}

export function getAppointmentsForSelectedDay(appointments: IAppointmentData[], selectedDate: ISelectedDateAndRange): IAppointmentsPerDate {

    const date = new Date(selectedDate.startRangeDate);
    date.setHours(0, 0, 0, 0);

    const initialAppointments: IAppointmentData[] = [];
    appointments.forEach((appointment) => {
        if (appointment.endTime.getTime() > date.getTime() &&
            appointment.startTime.getTime() < (date.getTime() + 24 * 60 * 60 * 1000)) { // 24 hours * 60 minutes * 60 seconds* 1000 milliseconds = 1 day
            initialAppointments.push(appointment);
        }
    });

    const result: IAppointmentsPerDate = {appointments: initialAppointments, date};

    return result;
}

function processMultiDayAppointment(initialAppointments: IAppointmentData[][], appointment: IAppointmentData, startDate: ISelectedDateAndRange) {
    let dayBetweenDate: Date = new Date(appointment.startTime);
    let i = 0;
    while (!isSameDate(dayBetweenDate, incrementDate(appointment.endTime)) && i < 7) {
        const appointmentCopy: IAppointmentData = {...appointment};
        const appointmentIndex = getAppointmentIndex(dayBetweenDate, startDate.startRangeDate);
        if (appointmentIndex >= 0 && appointmentIndex < initialAppointments.length &&
            !(isSameDate(dayBetweenDate, appointment.endTime) && appointment.endTime.getHours() === 0 && appointment.endTime.getMinutes() === 0)) {

            initialAppointments[appointmentIndex].push(appointmentCopy);
            i++;
        }
        dayBetweenDate = incrementDate(dayBetweenDate);
    }
}

function getAppointmentIndex(appointmentStartDate: Date, weekStartDate: Date): number {
    const startDate = Date.UTC(weekStartDate.getFullYear(), weekStartDate.getMonth(), weekStartDate.getDate(), 0, 0, 0, 0);
    const appointmentStart = Date.UTC(appointmentStartDate.getFullYear(), appointmentStartDate.getMonth(), appointmentStartDate.getDate(), 0, 0, 0, 0);

    return (appointmentStart - startDate) / (1000 * 3600 * 24); // ms per second * seconds per hour * hours per day
}
