import axios, {AxiosInstance, AxiosResponse} from "axios";
import {encodeSquareBrackets} from "../util/url.util";
import {getBearerToken, getRefreshToken, setBearerToken, setRefreshToken} from "../config/requestConfig";
import {KeycloakInstance} from "keycloak-js";
import { setApplicationError } from "../thunks/applicationError.thunk";
import { ApplicationErrorType, HttpErrorData } from "../reducers/applicationError.reducer";
import store from "./../store";

let keycloakAuthUrl: string;

let requestForTokenPending = false;

let keycloak: KeycloakInstance;

export function setKeycloakAuthUrl(keycloakAuthUrlValue: string) {
    keycloakAuthUrl = keycloakAuthUrlValue;
}

export function getKeycloakAuthUrl() {
    return keycloakAuthUrl;
}

export function getKeycloakInstance(): KeycloakInstance {
    return keycloak;
}

export function setKeycloakInstance(keycloakInstance: KeycloakInstance) {
    keycloak = keycloakInstance;
}

export function getPagingAxiosInstance(): AxiosInstance {
    if (!pagingAxiosInstanceInitialized) {
        pagingAxiosInstance.interceptors.response.use(undefined, (error) => loginRedirectRequestHandler(error));
        pagingAxiosInstance.interceptors.response.use(
            (response: any) => pagingHandler(response), (error: any) => errorHandler(error));
        pagingAxiosInstanceInitialized = true;
    }
    return pagingAxiosInstance;
}

export function getStandardAxiosInstance(): AxiosInstance {
    if (!standardAxiosInstanceInitialized) {
        standardAxiosInstance.interceptors.response.use(undefined, (error) => loginRedirectRequestHandler(error));
        standardAxiosInstance.interceptors.response.use(undefined, (error: any) => errorHandler(error));
        standardAxiosInstanceInitialized = true;
    }
    return standardAxiosInstance;
}

const pagingAxiosInstance = axios.create();
const standardAxiosInstance = axios.create();
let pagingAxiosInstanceInitialized = false;
let standardAxiosInstanceInitialized = false;

async function loginRedirectRequestHandler(error: any) {
    if ((keycloak && (keycloak as KeycloakInstance).isTokenExpired(0)) || (error.response && error.response.status === 401)) {
        if (getRefreshToken().length > 0) {
            if (!requestForTokenPending) {
                requestForTokenPending = true;
                return new Promise((resolve, reject) => getKeycloakInstance().updateToken(0)
                    .then(() => {
                        setBearerToken(getKeycloakInstance().token as string);
                        setRefreshToken(getKeycloakInstance().refreshToken as string);
                        requestForTokenPending = false;
                        error.response.config.headers.authorization = "Bearer " + getKeycloakInstance().token;
                        resolve(axios(error.response.config));
                    })
                    .catch(() => {
                        setBearerToken("");
                        setRefreshToken("");
                        requestForTokenPending = false;
                        reject();
                    }));
            } else {
                return new Promise(async (resolve) => {
                    while (requestForTokenPending) {
                        await new Promise((r) => setTimeout(r, 100));
                    }
                    error.response.config.headers.authorization = "Bearer " + getBearerToken();
                    resolve(axios(error.response.config));
                });
            }
        } else {
            reload();
        }
    } else {
        return Promise.reject(error);
    }
}

function reload() {
    window.location.reload();
}

async function errorHandler(error: AxiosResponse) {
    store.dispatch(setApplicationError({
        type: ApplicationErrorType.ERROR_MESSAGE_HTTP,
        data: {
            message: String(error),
            method: error.config.method,
            url: error.config.url,
            requestData: error.data
        }
    } as HttpErrorData));
    return Promise.reject(error);
}

async function pagingHandler(response: any) {
    if (response && response.data) {
        let responseData = response.data;
        const resultData: any[] = responseData.data;

        while (responseData.links && !(responseData.links.next === undefined)) {
            response.config.url = encodeSquareBrackets(responseData.links.next);
            const axiosResponse = await axios(response.config);
            responseData = axiosResponse.data;
            resultData.push(...responseData.data);
        }
        if (response.data.data) {
            response.data.data = resultData;
        }
    }

    return response;
}
