import React, {CSSProperties, ReactNodeArray} from "react";
import {IAppointmentData} from "../../../reducers/appointments.reducer";
import {incrementDateAndResetTime, isSameDate} from "../../../util/date.util";
import Appointment from "./Appointment";
import ClosingHours from "./ClosingHours";
import {IAppointmentColumn, IAppointmentsPerDate, IAppointmentsToRender, IOpeningHoursPerDate} from "./GridColumn";

export function getAppointmentClassName(appointmentData: IAppointmentData, availableSubstatuses: string[]) {
    if (availableSubstatuses.includes(appointmentData.substatus)) {
        return "appointment " + appointmentData.substatus.toLowerCase();
    } else if(appointmentData.substatus === "CANCEL"){
        return "appointment cancel";
    } else {
        return "appointment undefined";
    }
}

export function getColumnPercentOffset(numberOfColumns: number, column: number) {
    return 100.0 / numberOfColumns * column;
}

export function getPercentWidth(numberOfColumns: number): number {
    return 100.0 / numberOfColumns;
}

export function getAppointmentStyle(startOffset: number, heightOfAppointment: number, percentWidth: number,
                                    columnPercentOffset: number): CSSProperties {
    return {
        top: startOffset + "px",
        left: columnPercentOffset + "%",
        width: percentWidth + "%",
        height: heightOfAppointment + "px",
    };
}

export function getAppointmentsToRender(appointmentsPerDate: IAppointmentsPerDate): IAppointmentsToRender {
    let numberOfColumns = 1;
    const appointmentsInColumns: IAppointmentColumn[] = [];
    appointmentsPerDate.appointments.forEach((appointment) => {
        appointmentsInColumns.push({appointmentData: appointment, column: 0});
    });

    for (let i = 0; i < appointmentsInColumns.length; i++) {
        for (let j = i + 1; j < appointmentsInColumns.length; j++) {
            if (haveAppointmentsIntersection(appointmentsInColumns[i].appointmentData, appointmentsInColumns[j].appointmentData)) {
                appointmentsInColumns[j].column = appointmentsInColumns[i].column + 1;
                numberOfColumns = appointmentsInColumns[j].column + 1;
            }
        }
    }
    return {
        appointments: appointmentsInColumns,
        numberOfColumns,
    };
}

export function haveAppointmentsIntersection(appointment1: IAppointmentData, appointment2: IAppointmentData): boolean {
    return (appointment1.startTime.getTime() === appointment2.startTime.getTime() && appointment1.endTime.getTime() === appointment2.endTime.getTime()) || // start and end times equal
        (appointment1.startTime > appointment2.startTime && appointment1.startTime < appointment2.endTime) || // appointment1 starts on appointment2
        (appointment2.startTime > appointment1.startTime && appointment2.startTime < appointment1.endTime) || // appointment2 starts on appointment1
        (appointment1.endTime > appointment2.startTime && appointment1.endTime < appointment2.endTime) || // appointment1 ends in appointment2
        (appointment2.endTime > appointment1.startTime && appointment2.endTime < appointment1.endTime); // appointment2 ends in appointment1
}

export function calculateOffsetAndHeight(cellHeight: number, fromDate: Date, toDate: Date, endOfDay: Date, processingDate: Date) {
    let startOffset;
    let height;
    if (fromDate.getTime() === processingDate.getTime()) {
        startOffset = 0;
    } else {
        startOffset = getStartOffset(cellHeight, fromDate);
    }
    if (toDate.getTime() === endOfDay.getTime()) {
        const endTime = new Date();
        endTime.setHours(0, 0);
        height = getHeight(cellHeight, fromDate, endTime);
    } else {
        height = getHeight(cellHeight, fromDate, toDate);
    }
    return {startOffset, height};
}

export function getHeight(cellHeight: number, startTime: Date, endTime: Date): number {
    const endHours = endTime.getHours() === 0 && endTime.getMinutes() === 0 ? 24 : endTime.getHours();
    const minutes = (endHours - startTime.getHours()) * 60 + (endTime.getMinutes() - startTime.getMinutes());
    return cellHeight * minutes / 5; // 5min per row
}

export function getStartOffset(cellHeight: number, date: Date): number {
    const minutesFromBeginningOfDay = date.getHours() * 60 + date.getMinutes();
    return cellHeight * minutesFromBeginningOfDay / 5; // 5min per row
}

// cell height * 24 hours * five minute segments in one hour
export function calculateGridHeight(cellHeight: number): number {
    return cellHeight * 24 * 12;
}

// @ts-ignore
export function prepareAppointmentsToRender(appointmentsPerDate: IAppointmentsPerDate, availableSubstatuses: string[], cellHeight: number, tenant: string): ReactNodeArray {
    const appointments: ReactNodeArray = [];
    const appointmentsToRender = getAppointmentsToRender(appointmentsPerDate);

    const percentWidth = getPercentWidth(appointmentsToRender.numberOfColumns);
    appointmentsToRender.appointments.forEach((appointment) => {
        let startOffset;
        let heightOfAppointment;
        const endOfDay = incrementDateAndResetTime(appointmentsPerDate.date);
        const columnPercentOffset = getColumnPercentOffset(appointmentsToRender.numberOfColumns, appointment.column);
        if (!isSameDate(appointment.appointmentData.startTime, appointmentsPerDate.date)
            && !isSameDate(appointment.appointmentData.endTime, appointmentsPerDate.date)) { // multi day appointment - days between start day and end day
            startOffset = 0;
            heightOfAppointment = getHeight(cellHeight, appointmentsPerDate.date, endOfDay);
        } else if (appointment.appointmentData.startTime < appointmentsPerDate.date) { // cross-midnight on start of day
            const startTime = new Date();
            startTime.setHours(0, 0);
            startOffset = 0;
            heightOfAppointment = getHeight(cellHeight, startTime, appointment.appointmentData.endTime);
        } else if (appointment.appointmentData.endTime > endOfDay) { // cross-midnight on end of day
            const endTime = new Date();
            endTime.setHours(0, 0);
            startOffset = getStartOffset(cellHeight, appointment.appointmentData.startTime);
            heightOfAppointment = getHeight(cellHeight, appointment.appointmentData.startTime, endTime);
        } else {
            const offsetAndHeight = calculateOffsetAndHeight(cellHeight, appointment.appointmentData.startTime, appointment.appointmentData.endTime,
                endOfDay, appointmentsPerDate.date);
            startOffset = offsetAndHeight.startOffset;
            heightOfAppointment = offsetAndHeight.height;
        }
        appointments.push(<Appointment
            className={getAppointmentClassName(appointment.appointmentData, availableSubstatuses)}
            appointmentData={appointment.appointmentData}
            style={getAppointmentStyle(startOffset, heightOfAppointment,
                percentWidth, columnPercentOffset)}
            tenant={tenant}
            startOffset={startOffset}
            appointmentHeight={heightOfAppointment}
            gridHeight={calculateGridHeight(cellHeight)}
            key={appointment.appointmentData.uuid}/>);
    });
    return appointments;
}

export function getGridCellClassForIteration(iteration: number): string {
    return iteration % 4 === 0 ? "grid_cell wider_border" : "grid_cell";
}

export function renderClosingHours(closingHoursPerDate: IOpeningHoursPerDate, cellHeight: number): ReactNodeArray {
    const closingHours: ReactNodeArray = [];

    if (closingHoursPerDate) {
        closingHoursPerDate.hours.forEach((hours, index) => {
            const endOfDay = incrementDateAndResetTime(closingHoursPerDate.date);
            const offsetAndHeight = calculateOffsetAndHeight(cellHeight, hours.from, hours.to, endOfDay,
                closingHoursPerDate.date);
            closingHours.push(<ClosingHours closingHoursData={hours}
                                            style={{
                                                height: offsetAndHeight.height + "px",
                                                top: offsetAndHeight.startOffset + "px",
                                                width: "100%",
                                            }}
                                            key={hours.toString() + index}/>);
        });
    }
    return closingHours;
}

export function calculateScrollOffset(calendarBodyRef: HTMLDivElement, cellHeight: number, date: Date): number {
    let result = getStartOffset(cellHeight, date);
    result -= calendarBodyRef.clientHeight / 2;
    return result;
}
