import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import AppointmentModal, {AppointmentModalMode} from "../../../modal/AppointmentModal";
import {getAdditionalFields, IAdditionalFieldData} from "../../../reducers/additionalFields.reducer";
import {IAppointmentData} from "../../../reducers/appointments.reducer";
import {getLocalizedText, getSelectedLocale} from "../../../reducers/locale.reducer";
import {getSelectedRoom, IRoomData} from "../../../reducers/room.reducer";
import {getSelectedDate, ISelectedDateAndRange} from "../../../reducers/selecteddate.reducer";
import {getSelectedStore, IStoreData} from "../../../reducers/store.reducer";
import {fetchAdditionalFieldsForAppointmentType} from "../../../thunks/additionalFields.thunk";
import {fetchAppointments} from "../../../thunks/appointments.thunk";
import "./AppointmentDetails.scss";
import moment from "moment-timezone";

interface IState {
    showRebookAppointmentModal: boolean;
    showRescheduleAppointmentModal: boolean;
    showEditCustomerModal: boolean;
    flipAppointmentDetailCardModal: boolean,
}

interface IProps {
    appointment: IAppointmentData;
    appointmentSubstatus: string;
    closePopup: () => void;
    showCancelModal: () => void;
    showCancelSeriesModal: () => void;
    tenant: string;
}

interface IConnectProps {
    getLocalizedText: (key: string) => string;
    selectedLocale: string | undefined;
    startDate: ISelectedDateAndRange | undefined;
    selectedRoom: IRoomData | undefined;
    selectedStore: IStoreData | undefined;
    additionalFields: IAdditionalFieldData[];
}

interface IDispatchFromProps {
    fetchAppointments: (storeId: string, roomId: number, startTime: Date, endTime: Date, tenant: string) => void;
    dispatchFetchAdditionalFields: (appointmentTypeId: string, tenantId: string) => void;
}

interface IDataProps extends IProps, IConnectProps {
}

export interface IResultProps extends IDataProps, IDispatchFromProps {
}

export class AppointmentDetailsComponent extends React.Component<IResultProps, IState> {
    private popupRef: any;
    private detailCardRef: any;

    constructor(props: IResultProps) {
        super(props);
        this.state = {
            showRebookAppointmentModal: false,
            showEditCustomerModal: false,
            showRescheduleAppointmentModal: false,
            flipAppointmentDetailCardModal: false,
        };
        this.detailCardRef = React.createRef()
    }

    public componentDidMount() {
        document.addEventListener("mousedown", this.clickHandler);
        document.addEventListener("keydown", this.escFunction);
        this.props.dispatchFetchAdditionalFields(this.props.appointment.appointmentType.id, this.props.tenant);
        const appointmentDetailElement = this.detailCardRef.current.getBoundingClientRect();
        if(( appointmentDetailElement.x + appointmentDetailElement.width ) > window.innerWidth) this.flipAppointmentDetailCardModal();
    }

    public componentWillUnmount() {
        document.removeEventListener("mousedown", this.clickHandler);
        document.removeEventListener("keydown", this.escFunction);
    }

    public render(): React.ReactNode {
        return (
            <>
                <div className={`appointment-details ${this.state.flipAppointmentDetailCardModal ? 'appointment-details--align-left' : ''}`} ref={(node) => {
                return this.popupRef = node}}>
                    <div className="float-right close-popup" onClick={() => this.handleClose()}>&times;</div>
                    <div className="appointment-details-canvas" ref={this.detailCardRef}>
                        <div className="appointment-details-data">
                            <div className="appointment-type row">
                                {this.props.selectedLocale ? this.props.appointment.appointmentType.name[this.props.selectedLocale] : ""}
                            </div>

                            {this.renderCustomerSection()}
                            {this.renderDateSection()}
                            {this.renderLocationSection()}
                            {this.renderStatusSection()}
                            {this.renderNotesSection()}
                            {this.renderCustomerNotesSection()}

                        </div>
                        {this.renderButtons()}
                    </div>
                </div>
                {this.state.showRescheduleAppointmentModal ?
                    <AppointmentModal closePopup={() => this.closeRescheduleAppointmentModal()}
                                      tenant={this.props.tenant}
                                      mode={AppointmentModalMode.RESCHEDULE}
                                      appointment={this.props.appointment}
                                      refreshMatrixOnClose={true}
                                      additionalFieldsForAppTypeFromAppointment={this.props.additionalFields}
                    /> : null
                }
                {this.state.showEditCustomerModal ?
                    <AppointmentModal closePopup={() => this.closeEditCustomerModal()}
                                      tenant={this.props.tenant}
                                      mode={AppointmentModalMode.EDIT}
                                      appointment={this.props.appointment}
                                      refreshMatrixOnClose={true}
                                      additionalFieldsForAppTypeFromAppointment={this.props.additionalFields}
                    />
                    : null
                }
                {this.state.showRebookAppointmentModal ?
                    <AppointmentModal closePopup={() => this.closeRebookAppointmentModal()}
                                      tenant={this.props.tenant}
                                      mode={AppointmentModalMode.REBOOK}
                                      appointment={this.props.appointment}
                                      refreshMatrixOnClose={true}
                                      additionalFieldsForAppTypeFromAppointment={this.props.additionalFields}
                    /> : null
                }
            </>
        );
    }

    private flipAppointmentDetailCardModal(): void {
        this.setState({
            flipAppointmentDetailCardModal: true,
        });
    }

    private showEditCustomerModal(): void {
        this.setState({
            showEditCustomerModal: true,
        });
    }

    private closeEditCustomerModal(): void {
        this.setState({
            showEditCustomerModal: false,
        });
    }

    private closeRescheduleAppointmentModal(): void {
        this.setState({showRescheduleAppointmentModal: false});
    }

    private closeRebookAppointmentModal(): void {
        this.props.closePopup();
        this.setState({showRebookAppointmentModal: false});
    }

    private showRescheduleModal(): void {
        this.setState({showRescheduleAppointmentModal: true});
    }

    private showRebookModal(): void {
        this.setState({showRebookAppointmentModal: true});
    }

    private showCancelModal(): void {
        this.props.showCancelModal();
        this.handleClose();
    }

    private showCancelSeriesModal(): void {
        this.props.showCancelSeriesModal();
        this.handleClose();
    }

    private handleClose() {
        this.props.closePopup();
    }

    private clickHandler = (event: MouseEvent) => {
        if (this.popupRef && !this.popupRef.contains(event.target) &&
            !this.state.showRescheduleAppointmentModal && !this.state.showEditCustomerModal && !this.state.showRebookAppointmentModal) {
            this.handleClose();
        }
    }

    private escFunction = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            this.handleClose();
        }
    }

    private renderCustomerSection(): ReactNode {
        if (this.props.appointment !== undefined && this.props.appointment.customer !== undefined) {
            if ((!this.props.appointment.customer.firstName || this.props.appointment.customer.firstName === "") &&
                (!this.props.appointment.customer.lastName || this.props.appointment.customer.lastName === "")) {
                return null;
            } else {
                return (
                    <div className={"customer row"}>
                        <div
                            className="appointment-details-label customer-label col-4">{this.props.getLocalizedText("appointmentDetails.labels.customer")}</div>
                        <div
                            className="appointment-details-value customer-name-value col-8">{(this.props.appointment.customer.firstName ? this.props.appointment.customer.firstName : "") + " "
                        + (this.props.appointment.customer.lastName ? this.props.appointment.customer.lastName : "")}</div>
                    </div>
                );
            }
        }
    }

    private renderDateSection(): ReactNode {
        const dateLocale = this.props.selectedLocale?.replace("_", "-");
        if (this.props.appointment.startTime.getDate() === this.props.appointment.endTime.getDate() &&
            this.props.appointment.startTime.getMonth() === this.props.appointment.endTime.getMonth() &&
            this.props.appointment.startTime.getFullYear() === this.props.appointment.endTime.getFullYear()) {
            const startDateObject = (new Intl.DateTimeFormat(dateLocale, {
                weekday: "long",
                day: "numeric",
                month: "long",
                year: "numeric",
            }).formatToParts(this.props.appointment.startTime))
            return (
                <div className={"date-time row"}>
                    <div
                        className="appointment-details-label date-time-label col-4">{this.props.getLocalizedText("appointmentDetails.labels.dateTime")}</div>
                    <div
                        className="appointment-details-value date-time-value col-8">
                            {startDateObject.map((datePart, index) =>
                                <span key={index} className={datePart.type}>{datePart.value}</span>
                            )}
                        <br/>
                        {this.props.appointment.startTime.toLocaleTimeString(dateLocale, {
                            hour12: false,
                            hour: "numeric",
                            minute: "numeric",
                        })} - {this.props.appointment.endTimeWithoutWrapTime.toLocaleTimeString(dateLocale, {
                        hour12: false,
                        hour: "numeric",
                        minute: "numeric",
                    })}
                    </div>
                </div>
            );
        } else {
            return (
                <div className={"date-time row"}>
                    <div
                        className="appointment-details-label date-time-label col-4">{this.props.getLocalizedText("appointmentDetails.labels.dateTime")}</div>
                    <div
                        className="appointment-details-value date-time-value col-8">
                        {this.props.appointment.startTime.toLocaleDateString(dateLocale, {
                            month: "long",
                            day: "numeric",
                        })} - {this.props.appointment.endTimeWithoutWrapTime.toLocaleDateString(dateLocale, {
                        month: "long",
                        day: "numeric",
                    })}<br/>
                        {this.props.appointment.startTime.toLocaleTimeString(dateLocale, {
                            hour12: false,
                            hour: "numeric",
                            minute: "numeric",
                        })} - {this.props.appointment.endTimeWithoutWrapTime.toLocaleTimeString(dateLocale, {
                        hour12: false,
                        hour: "numeric",
                        minute: "numeric",
                    })}
                    </div>
                </div>
            );
        }
    }

    private renderLocationSection(): ReactNode {
        if (this.props.appointment.room && !this.hasOnlyWhitespaces(this.props.appointment.room.name)) {
            return (
                <div className={"location row"}>
                    <div
                        className="appointment-details-label location-label col-4">{this.props.getLocalizedText("appointmentDetails.labels.location")}</div>
                    <div
                        className="appointment-details-value location-value col-8">{this.props.appointment.room ? this.props.appointment.room.name : ""}</div>
                </div>);
        } else {
            return null;
        }
    }

    private renderStatusSection(): ReactNode {
        return (
            <div className={"status row"}>
                <div
                    className="appointment-details-label status-label col-4">{this.props.getLocalizedText("appointmentDetails.labels.status")}</div>
                <div
                    className="appointment-details-value status-value col-8">{this.props.appointmentSubstatus}</div>
            </div>
        );
    }

    private renderNotesSection(): ReactNode {
        if (this.props.appointment.updateComment && !this.hasOnlyWhitespaces(this.props.appointment.updateComment)) {
            return (
                <div>
                    <div
                        className={"appointment-details-label notes-label row"}>{this.props.getLocalizedText("appointmentDetails.labels.notes")}</div>
                    <div
                        className={"appointment-details-value notes-value row"}> {this.props.appointment.updateComment}</div>
                </div>
            );
        } else {
            return null;
        }
    }

    private renderCustomerNotesSection(): ReactNode {
        if (this.props.appointment.customerComment && !this.hasOnlyWhitespaces(this.props.appointment.customerComment)) {
            return (
                <div>
                    <div
                        className={"appointment-details-label customer-notes-label row"}>{this.props.getLocalizedText("appointmentDetails.labels.customerNotes")}</div>
                    <div
                        className={"appointment-details-value customer-notes-value row"}>{this.props.appointment.customerComment}</div>
                </div>
            );
        } else { return null; }
    }

    private renderButtons(): ReactNode {
        if (this.props.appointment.status ==='CANCEL') {
            return null;
        } else if (this.props.appointment.appointmentSeriesId === null) {
            return (<div className="appointment-details-buttons">
                <div className="row">
                    <div className="buttons float-right">
                        {this.isAppointmentStartTimeInThePast() ?
                            <div className="rebook-button bottom-button"
                                 onClick={() => this.showRebookModal()}>
                                {this.props.getLocalizedText("appointmentDetails.buttons.rebook")}
                            </div>
                            : <>
                                <div className="reschedule-button bottom-button"
                                     onClick={() => this.showRescheduleModal()}>
                                    {this.props.getLocalizedText("appointmentDetails.buttons.reschedule")}
                                </div>
                                <div onClick={() => this.showEditCustomerModal()}
                                     className="edit-button bottom-button">
                                    {this.props.getLocalizedText("appointmentDetails.buttons.edit")}
                                </div>
                            </>
                        }
                        <div className="cancel-button bottom-button" onClick={() => this.showCancelModal()}>
                            {this.props.getLocalizedText("appointmentDetails.buttons.cancel")}
                        </div>
                    </div>
                </div>
            </div>);
        } else {
            return (<div className="appointment-details-buttons">
                <div className="row">
                    <div className="buttons float-right">
                        <div className="cancel-button bottom-button" onClick={() => this.showCancelModal()}>
                            {this.props.getLocalizedText("appointmentDetails.buttons.cancelOccurrence")}
                        </div>
                        <div className="cancel-series-button bottom-button"
                             onClick={() => this.showCancelSeriesModal()}>
                            {this.props.getLocalizedText("appointmentDetails.buttons.cancelSeries")}
                        </div>
                    </div>
                </div>
            </div>);
        }
    }

    private hasOnlyWhitespaces(checkedString: string): boolean {
        const copyOfCheckedString = String(checkedString);
        return copyOfCheckedString.trim().length === 0;
    }

    private isAppointmentStartTimeInThePast(): boolean {
        if (this.props.selectedStore) {
            return (moment().tz(this.props.selectedStore.timezone).isAfter(moment(this.props.appointment.startTime)));
        }
        return false;
    }

}

const mapStateToProps = (store: any, ownProps: IProps): IDataProps => {
    return {
        appointment: ownProps.appointment,
        appointmentSubstatus: ownProps.appointmentSubstatus,
        closePopup: ownProps.closePopup,
        getLocalizedText: getLocalizedText(store),
        selectedLocale: getSelectedLocale(store),
        showCancelModal: ownProps.showCancelModal,
        showCancelSeriesModal: ownProps.showCancelSeriesModal,
        tenant: ownProps.tenant,
        startDate: getSelectedDate(store),
        selectedRoom: getSelectedRoom(store),
        selectedStore: getSelectedStore(store),
        additionalFields: getAdditionalFields(store),
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): IDispatchFromProps => {
    return {
        dispatchFetchAdditionalFields: (appointmentTypeId, tenantId) => dispatch(fetchAdditionalFieldsForAppointmentType(appointmentTypeId, tenantId)),
        fetchAppointments: (storeId, roomId, startTime, endTime, tenant) => fetchAppointments(storeId, roomId, startTime, endTime, tenant, dispatch),
    };
};

export default connect<IDataProps, IDispatchFromProps, IProps>(
    mapStateToProps,
    mapDispatchToProps,
)(AppointmentDetailsComponent);
