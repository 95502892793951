import React, { ReactNodeArray } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { selectRoomAction } from "../actions/room.actions";
import { getSelectedLocale } from "../reducers/locale.reducer";
import { getAvailableRooms, getSelectedRoom, IRoomData } from "../reducers/room.reducer";
import { getSelectedDate, ISelectedDateAndRange } from "../reducers/selecteddate.reducer";
import { getSelectedStore, IStoreData } from "../reducers/store.reducer";
import { fetchAppointments } from "../thunks/appointments.thunk";
import "./RoomSelector.scss";

interface IProps {
    tenant: string;
}

interface IConnectProps {
    selectedRoom: IRoomData | undefined;
    availableRooms: IRoomData[] | undefined;
    selectedLocale: string | undefined;
    selectedStore: IStoreData | undefined;
    selectedDate: ISelectedDateAndRange | undefined;
}

interface IDispatchFromProps {
    selectRoom: (room: IRoomData) => void;
    fetchAppointments: (storeId: string, roomId: number, startTime: Date, endTime: Date, tenant: string) => void;
}

interface IDataProps extends IProps, IConnectProps {

}

interface IResultProps extends IDataProps, IDispatchFromProps {

}

export class RoomSelectorComponent extends React.Component<IResultProps> {

    public render(): React.ReactNode {
        return (
            <div className="room-selector">
                {this.getButtons()}
            </div>
        );
    }

    private getButtons(): ReactNodeArray {
        const buttons: ReactNodeArray = [];
        if (this.props.availableRooms !== undefined) {
            this.props.availableRooms.forEach((room) => {
                if (this.props.selectedLocale !== undefined) {
                    buttons.push(
                        <Button className={this.isButtonSelected(room) ? "btn selected" : "btn"}
                                onClick={this.onSelectRoom(room)} key={room.id}>
                            {room.name}
                        </Button>);
                }
            });
        }
        return buttons;
    }

    private isButtonSelected(room: IRoomData): boolean {
        return this.props.selectedRoom ? room.id === this.props.selectedRoom.id : false;
    }

    private onSelectRoom(room: IRoomData) {
        return () => {
            this.props.selectRoom(room);
            if (this.props.selectedStore && this.props.selectedDate) {
                const endDate = new Date(this.props.selectedDate.startRangeDate);
                endDate.setDate(this.props.selectedDate.startRangeDate.getDate() + this.props.selectedDate.range);
                this.props.fetchAppointments(this.props.selectedStore.id, room.id, this.props.selectedDate.startRangeDate, endDate, this.props.tenant);
            }
        };
    }
}

const mapStateToProps = (store: any, ownProps: IProps): IDataProps => {
    return {
        availableRooms: getAvailableRooms(store),
        selectedRoom: getSelectedRoom(store),
        selectedLocale: getSelectedLocale(store),
        selectedDate: getSelectedDate(store),
        selectedStore: getSelectedStore(store),
        tenant: ownProps.tenant,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchFromProps => {
    return {
        selectRoom: ((room) => dispatch(selectRoomAction(room))),
        fetchAppointments: ((storeId, roomId, startTime, endTime, tenant) => fetchAppointments(storeId, roomId, startTime, endTime, tenant, dispatch)),
    };
};

export default connect<IConnectProps, IDispatchFromProps, IProps>(
    mapStateToProps,
    mapDispatchToProps,
)(RoomSelectorComponent);
