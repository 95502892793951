import React, {CSSProperties, ReactNodeArray} from "react";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import CancelAppointmentModal from "../../../modal/CancelAppointmentModal";
import {IAppointmentData} from "../../../reducers/appointments.reducer";
import {getSelectedLocale} from "../../../reducers/locale.reducer";
import {updateAppointment} from "../../../thunks/appointments.thunk";
import "./Appointment.scss";
import AppointmentDetails from "./AppointmentDetails";
import Icon from "../../Icon";
import {getAvailableSubstatuses, getSubstatusName} from "../../../reducers/substatusesConfig.reducer";
import CancelAppointmentSeriesModal from "../../../modal/CancelAppointmentSeriesModal";
import {
    getSelectedAppointmentTypesFilter,
    getSelectedSubstatusesFilter,
} from "../../../reducers/sidebar.filters.reducer";

interface IProps {
    tenant: string;
    className: string;
    appointmentData: IAppointmentData;
    style: CSSProperties;
    startOffset: number;
    appointmentHeight: number;
    gridHeight: number;
}

interface IDispatchFromProps {
    updateAppointment: (appointmentId: string, attrs: any, tenant: string) => void;
}

interface IConnectProps {
    availableSubstatuses: string[];
    selectedSubstatusesFilter: string[];
    selectedAppointmentTypesFilter: string[];
    getSubstatusName: (substatus: string) => string;
    selectedLocale?: string;
}

interface IState {
    dropdownVisible: boolean;
    dropdownHeight: number;
    detailsVisible: boolean;
    showCancelModal: boolean;
    showCancelSeriesModal: boolean;
}

interface IDataProps extends IProps, IConnectProps {
}

interface IResultProps extends IDataProps, IDispatchFromProps {
}

export class AppointmentComponent extends React.Component<IResultProps, IState> {
    private dropdownNode: any;

    constructor(props: IResultProps) {
        super(props);
        this.state = {
            dropdownHeight: 0,
            dropdownVisible: false,
            detailsVisible: false,
            showCancelModal: false,
            showCancelSeriesModal: false,
        };
    }

    public render(): React.ReactNode {
        return (
            <div className={this.props.className + " " + this.resolveSidebarFiltersBasedClass()}
                 style={this.props.style}>
                <div className="appointment-content"
                     style={{ height: this.props.style.height }}
                     onClick={() => this.openAppointmentDetails()}>
                    <div className="customer-name">
                        {this.renderCustomer()}
                    </div>
                    <div className="appointment-type-name">
                        {this.props.selectedLocale ? this.props.appointmentData.appointmentType.name[this.props.selectedLocale] : ""}
                        {this.getTimeRange(this.props.appointmentData.startTime, this.props.appointmentData.endTime, "en-GB")}
                    </div>
                    { this.props.appointmentData.status !== 'CANCEL' ?
                    <div className="appointment-substatus" onClick={(e) => this.toggleAppointmentStatusesDropdown(e)}>
                        <div className="appointment-substatus-content">
                            {this.props.getSubstatusName(this.props.appointmentData.substatus) + "  "}
                        </div>
                        <Icon image={process.env.PUBLIC_URL + "/statics/img/icons/chevron.svg"} height={"14px"}
                              width={"14px"} styleName={"chevron"}/>

                        <div className={this.getDropdownContentClass()} ref={(node) => {
                            this.dropdownNode = node;
                        }}
                             style={{ top: this.calculateDropdownTopMargin() + "px" }}>
                            {this.getAppointmentStatuses()}
                        </div>
                    </div>
                    : null }
                </div>

                {this.state.detailsVisible ? <AppointmentDetails appointment={this.props.appointmentData}
                                                                 appointmentSubstatus={this.props.getSubstatusName(this.props.appointmentData.substatus)}
                                                                 closePopup={() => this.closeAppointmentDetails()}
                                                                 showCancelModal={() => this.showCancelModal()}
                                                                 showCancelSeriesModal={() => this.showCancelSeriesModal()}
                                                                 tenant={this.props.tenant}/> : null}
                {this.state.showCancelModal ?
                    <CancelAppointmentModal appointmentId={this.props.appointmentData.uuid}
                                            closeModal={() => this.closeCancelModal()}
                                            tenant={this.props.tenant}/> : null}
                {this.state.showCancelSeriesModal ?
                    <CancelAppointmentSeriesModal appointmentSeriesId={this.props.appointmentData.appointmentSeriesId}
                                                  closeModal={() => this.closeCancelSeriesModal()}
                                                  tenant={this.props.tenant}/> : null}
            </div>
        );
    }

    public componentDidMount() {
        if(this.props.appointmentData.status !== 'CANCEL') this.setState({dropdownHeight: this.dropdownNode.clientHeight});
        document.addEventListener("mousedown", this.onMousedownDropdownCloseHandler);
        document.addEventListener("keydown", this.onEscDropdownCloseHandler);
    }

    public componentWillUnmount() {
        document.removeEventListener("mousedown", this.onMousedownDropdownCloseHandler);
        document.removeEventListener("keydown", this.onEscDropdownCloseHandler);
    }

    private renderCustomer() {
        if (this.props.appointmentData.customer !== undefined) {
            return <div>
                {(this.props.appointmentData.customer.firstName ? this.props.appointmentData.customer.firstName : "") + " "
                + (this.props.appointmentData.customer.lastName ? this.props.appointmentData.customer.lastName : "")}
            </div>;
        }
    }

    private onMousedownDropdownCloseHandler = (event: MouseEvent) => {
        if (this.dropdownNode && !this.dropdownNode.contains(event.target)) {
            this.setState({
                dropdownVisible: false,
            });
        }
    }

    private onEscDropdownCloseHandler = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            this.setState({
                dropdownVisible: false,
            });
        }
    }

    private calculateDropdownTopMargin(): number {
        let resultMargin: number = 0;
        if (this.dropdownNode) {
            const bottomOffset: number = this.props.gridHeight - this.props.startOffset - this.props.appointmentHeight;
            if (bottomOffset < this.state.dropdownHeight) {
                resultMargin = bottomOffset - this.state.dropdownHeight + 30;
            }
        }
        return resultMargin;
    }

    private getDropdownContentClass(): string {
        return "appointment-dropdown-content ".concat(this.state.dropdownVisible ? "show" : "");
    }

    private getTimeFormatted(time: Date, locales: string): string {
        return time.toLocaleTimeString(locales, {
            hour: "2-digit",
            minute: "2-digit",
        });
    }

    private getTimeRange(startTime: Date, endTime: Date, locales: string): string {
        return ", " + this.getTimeFormatted(startTime, locales) + " - " + this.getTimeFormatted(endTime, locales);
    }

    private getAppointmentStatuses(): ReactNodeArray {
        const nodes: ReactNodeArray = [];
        this.props.availableSubstatuses.forEach((appointmentStatus: any) =>
            nodes.push(
                <div className="dropdown-item" key={appointmentStatus}
                     onClick={(event) => this.onDropdownItemClick(event, appointmentStatus)}>
                    {this.props.getSubstatusName(appointmentStatus)}
                </div>),
        );
        return nodes;
    }

    private onDropdownItemClick(event: React.MouseEvent, appointmentStatus: any) {
        this.toggleAppointmentStatusesDropdown();
        this.props.updateAppointment(this.props.appointmentData.uuid, {substatus: appointmentStatus}, this.props.tenant);
    }

    private toggleAppointmentStatusesDropdown(e?: React.MouseEvent) {
        if (this.isAppointmentClickable()) {
            this.setState({
                dropdownVisible: !this.state.dropdownVisible,
            });
            if (e) {
                e.stopPropagation();
            }
        }
    }

    private openAppointmentDetails() {
        if (this.isAppointmentClickable()) {
            this.setState({
                detailsVisible: true,
            });
        }
    }

    private closeAppointmentDetails() {
        this.setState({
            detailsVisible: false,
        });
    }

    private closeCancelModal(): void {
        this.setState({showCancelModal: false});
    }

    private closeCancelSeriesModal(): void {
        this.setState({showCancelSeriesModal: false});
    }

    private showCancelModal(): void {
        this.setState({
            showCancelModal: true,
        });
    }

    private resolveSidebarFiltersBasedClass(): string {
        return this.isAppointmentCoveredByFilters() ?
            "filtered" : "grayed-out";
    }

    private isAppointmentCoveredByFilters(): boolean {
        return ((this.props.selectedSubstatusesFilter.includes(this.props.appointmentData.substatus) ||
            (this.props.selectedSubstatusesFilter.includes("UNDEFINED") && !this.props.availableSubstatuses.includes(this.props.appointmentData.substatus))) &&
            this.props.selectedAppointmentTypesFilter.includes(this.props.appointmentData.appointmentType.id));
    }

    private isAppointmentClickable(): boolean {
        return this.isAppointmentCoveredByFilters();
    }

    private showCancelSeriesModal(): void {
        this.setState({
            showCancelSeriesModal: true,
        });
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): IDispatchFromProps => {
    return {
        updateAppointment: (appointmentId: string, attrs: any, tenant: string) => {
            dispatch(updateAppointment(appointmentId, attrs, tenant));
        },
    };
};

const mapStateToProps = (store: any, ownProps: IProps): IDataProps => {
    return {
        availableSubstatuses: getAvailableSubstatuses(store),
        getSubstatusName: getSubstatusName(store),
        selectedLocale: getSelectedLocale(store),
        className: ownProps.className,
        appointmentData: ownProps.appointmentData,
        style: ownProps.style,
        tenant: ownProps.tenant,
        startOffset: ownProps.startOffset,
        appointmentHeight: ownProps.appointmentHeight,
        gridHeight: ownProps.gridHeight,
        selectedAppointmentTypesFilter: getSelectedAppointmentTypesFilter(store),
        selectedSubstatusesFilter: getSelectedSubstatusesFilter(store),
    };
};

export default connect<IDataProps, IDispatchFromProps, IProps>(
    mapStateToProps,
    mapDispatchToProps,
)(AppointmentComponent);
