import React from "react";
import {connect} from "react-redux";
import {getAppointmentsToDisplayOnCalendar, getNonCancelledAppointments, IAppointmentData} from "../../reducers/appointments.reducer";
import {getCancelledAppointmentToggle} from "../../reducers/sidebar.filters.reducer";
import {getClosingHours} from "../../reducers/closinghours.reducer";
import {ISelectedDateAndRange} from "../../reducers/selecteddate.reducer";
import "./Calendar.scss";
import CalendarBodyComponent from "./components/CalendarBody";
import DateHeader from "./components/DateHeader";
import {IOpeningHoursPerDate} from "./components/GridColumn";
import {getAppointmentsPerDay} from "./Calendar.util";
import {getCalendarLocaleFormat} from "../../reducers/locale.reducer";

interface IProps {
    tenant: string;
    startDate: ISelectedDateAndRange;
    cellHeight: number;
}

interface IConnectProps {
    allAppointments: IAppointmentData[];
    allAppointmentsMinusCancelled: IAppointmentData[];
    toggleCancelledAppointment: boolean;
    closingHours: IOpeningHoursPerDate[];
    calendarLocaleFormat: string | undefined;
}

interface IResultProps extends IProps, IConnectProps {
}

export class CalendarComponent extends React.Component<IResultProps> {
    public render(): React.ReactNode {
        return (
            <div className="grid_calendar">
                <DateHeader startDate={this.props.startDate} locale={this.props.calendarLocaleFormat}/>
                <CalendarBodyComponent cellHeight={this.props.cellHeight}
                                       tenant={this.props.tenant}
                                       appointmentsPerDay={getAppointmentsPerDay(!this.props.toggleCancelledAppointment ? this.props.allAppointmentsMinusCancelled : this.props.allAppointments, this.props.startDate)}
                                       closingHoursPerDay={this.props.closingHours}/>
            </div>
        );
    }
}

const mapStateToProps = (store: any, ownProps: IProps): IResultProps => {
    return {
        allAppointments: getAppointmentsToDisplayOnCalendar(store),
        allAppointmentsMinusCancelled: getNonCancelledAppointments(store),
        cellHeight: ownProps.cellHeight,
        closingHours: getClosingHours(store),
        toggleCancelledAppointment: getCancelledAppointmentToggle(store),
        startDate: ownProps.startDate,
        tenant: ownProps.tenant,
        calendarLocaleFormat: getCalendarLocaleFormat(store),
    };
};

export default connect<IResultProps, void, IProps>(
    mapStateToProps,
)(CalendarComponent);
