import React, {ReactNodeArray} from "react";
import {IAppointmentData} from "../../../reducers/appointments.reducer";
import {IOpeningHoursData} from "../../../reducers/closinghours.reducer";
import "./GridDayColumn.scss";

import {getGridCellClassForIteration, prepareAppointmentsToRender, renderClosingHours} from "./GridColumn.util";
import {getAvailableSubstatuses} from "../../../reducers/substatusesConfig.reducer";
import {connect} from "react-redux";

export interface IAppointmentsPerDate {
    date: Date;
    appointments: IAppointmentData[];
}

export interface IOpeningHoursPerDate {
    date: Date;
    hours: IOpeningHoursData[];
}

interface IProps {
    tenant: string;
    cellHeight: number;
    appointmentsPerDate: IAppointmentsPerDate;
    closingHoursPerDate: IOpeningHoursPerDate;
}

interface IConnectProps {
    availableSubstatuses: string[];
}

interface IDataProps extends IProps, IConnectProps {

}

export class GridDayColumnComponent extends React.Component<IDataProps> {

    public render(): React.ReactNode {
        return (
            <div className="grid_day_column">
                {this.renderGrid()}
                {renderClosingHours(this.props.closingHoursPerDate, this.props.cellHeight)}
                {this.renderAppointments()}
            </div>
        );
    }

    private renderGrid(): ReactNodeArray {
        const cells: ReactNodeArray = [];
        const numberOfCellsToDisplay = 24 * 60 / 5; // hours per day, minutes per hour, 5 minute per cell
        for (let i = 0; i < numberOfCellsToDisplay; i++) {
            cells.push(<div className={getGridCellClassForIteration(i)}
                            style={{height: this.props.cellHeight}} key={i}/>);
        }
        return cells;
    }

    private renderAppointments(): ReactNodeArray {
        const appointments: ReactNodeArray = prepareAppointmentsToRender(this.props.appointmentsPerDate, this.props.availableSubstatuses, this.props.cellHeight, this.props.tenant);

        return appointments
    }
}

const mapStateToProps = (store: any, ownProps: IProps): IDataProps => {
    return {
        availableSubstatuses: getAvailableSubstatuses(store),
        appointmentsPerDate: ownProps.appointmentsPerDate,
        cellHeight: ownProps.cellHeight,
        closingHoursPerDate: ownProps.closingHoursPerDate,
        tenant: ownProps.tenant,
    };
};

export default connect<IDataProps, void, IProps>(
    mapStateToProps,
)(GridDayColumnComponent);
