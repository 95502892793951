import moment from "moment";
import React, {ReactNodeArray} from "react";
import "./HourColumn.scss";

interface IProps {
    cellHeight: number;
}

export default class HourColumn extends React.Component<IProps> {

    public render(): React.ReactNode {
        return (
            <div className="hour_column">
                {this.renderHours()}
            </div>
        );
    }

    private renderHours(): ReactNodeArray {
        const hours: ReactNodeArray = [];
        const date = moment();
        const hourHeightOffset = 12;
        const numberOfIntervalsPerDay = 24 * 60 / 20; // hours per day, minutes per hour, 20 minutes per interval
        date.hour(0);
        date.minute(20);
        date.second(0);

        hours.push(<div className="hour" style={{height: 4 * this.props.cellHeight - hourHeightOffset}} key={0}/>);

        for (let i = 1; i < numberOfIntervalsPerDay; i++) {
            hours.push(<div className="hour"
                            style={{height: this.getHeight(numberOfIntervalsPerDay, i, hourHeightOffset)}} key={i}>
                {date.format("HH:mm")}</div>);
            date.add(20, "minutes");
        }
        return hours;
    }

    private getHeight(numberOfIntervals: number, iteration: number, heightOffset: number): number {
        if (iteration !== numberOfIntervals - 1) {
            return 4 * this.props.cellHeight;
        } else {
            return 4 * this.props.cellHeight + heightOffset - 3;
        }
    }
}
