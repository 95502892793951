import {addFilterWithWildcardIfValuePresent, getConfigPerEnv, preparePatchPostRequest} from "../config/requestConfig";
import {getPagingAxiosInstance, getStandardAxiosInstance} from "./axios.config";

export const patchCustomer = (customerId: string, tenant: string, attributes: any) => {
    return getPagingAxiosInstance().patch(`/customers/${customerId}`,
        preparePatchPostRequest("customers", attributes), getConfigPerEnv(tenant))
        .then((response) => response.data);
};

export const createCustomer = (attributes: any, tenant: string) => {
    return getPagingAxiosInstance().post(`/customers`, preparePatchPostRequest("customers", attributes), getConfigPerEnv(tenant))
        .then((response) => response.data);
};

export const searchCustomersForAttributes = (firstName: string | null,
                                             lastName: string | null,
                                             email: string | null,
                                             phoneNumber: string | null,
                                             tenant: string,
                                             pageLimit: number) => {
    return getStandardAxiosInstance().get(`/customers?page%5Blimit%5D=` + pageLimit
        + addFilterWithWildcardIfValuePresent("firstName", firstName)
        + addFilterWithWildcardIfValuePresent("lastName", lastName)
        + addFilterWithWildcardIfValuePresent("email", email)
        + addFilterWithWildcardIfValuePresent("phoneNumber", phoneNumber),
        getConfigPerEnv(tenant))
        .then((response) => response.data);
};

export const searchCustomersForUrl = (url: string, tenant: string) => {
    return getStandardAxiosInstance().get(url, getConfigPerEnv(tenant))
        .then((response) => response.data);
};
