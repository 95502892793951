export const FETCH_SUBSTATUSES_CONFIG = "FETCH_SUBSTATUSES_CONFIG";

interface IFetchConfig {
    type: typeof FETCH_SUBSTATUSES_CONFIG;
    payload: string[];
}

export type FetchSubstatusesConfig = IFetchConfig;

export function fetchSubstatusesConfig(config: string[]): IFetchConfig {
    return {
        payload: config,
        type: FETCH_SUBSTATUSES_CONFIG,
    };
}