import React from "react";
import { ISelectedDateAndRange } from "../../reducers/selecteddate.reducer";
import Calendar from "../calendar/Calendar";
import RoomSelector from "../RoomSelector";

interface IProps {
    startDate: ISelectedDateAndRange | undefined;
    tenant: string;
}

export class CalendarWeekGrid extends React.Component<IProps> {

    public render(): React.ReactNode {
        return (
            <div className="week_grid">
                <RoomSelector tenant={this.props.tenant}/>
                {this.props.startDate ? <Calendar tenant={this.props.tenant}
                                                  startDate={this.props.startDate}
                                                  cellHeight={30}/> : null}
            </div>
        );
    }

}
