import {combineReducers} from "redux";
import additionalFieldsReducer from "./additionalFields.reducer";
import appointmentReducer from "./appointments.reducer";
import appointmentTypeReducer from "./appointmentTypes.reducer";
import closingHoursReducer from "./closinghours.reducer";
import configReducer from "./config.reducer";
import customersReducer from "./customers.reducer";
import genericModalReducer from "./generic.modal.reducer";
import gridStyleReducer from "./gridstyle.reducer";
import localeReducer from "./locale.reducer";
import opticiansReducer from "./opticians.reducer";
import paginationReducer from "./pagination.reducer";
import roomReducer from "./room.reducer";
import selectedDateReducer from "./selecteddate.reducer";
import sidebarFiltersReducer from "./sidebar.filters.reducer";
import storeReducer from "./store.reducer";
import substatusesConfigReducer from "./substatusesConfig.reducer";
import timeslotsReducer from "./timeslots.reducer";
import applicationErrorsReducer from "./applicationError.reducer";

export const reducer = combineReducers({
    appointments: appointmentReducer,
    appointmentTypes: appointmentTypeReducer,
    config: configReducer,
    gridstyle: gridStyleReducer,
    locale: localeReducer,
    opticians: opticiansReducer,
    closingHours: closingHoursReducer,
    room: roomReducer,
    selectedDate: selectedDateReducer,
    store: storeReducer,
    timeslots: timeslotsReducer,
    additionalFields: additionalFieldsReducer,
    customers: customersReducer,
    pagination: paginationReducer,
    substatusesConfig: substatusesConfigReducer,
    genericModal: genericModalReducer,
    sidebarFilters: sidebarFiltersReducer,
    applicationErrors: applicationErrorsReducer
});
