import React from "react";
import "./CalendarBody.scss";
import GridColumn, {IAppointmentsPerDate, IOpeningHoursPerDate} from "./GridColumn";
import {calculateScrollOffset} from "./GridColumn.util";
import HourColumn from "./HourColumn";

interface IProps {
    tenant: string;
    cellHeight: number;
    appointmentsPerDay: IAppointmentsPerDate[];
    closingHoursPerDay: IOpeningHoursPerDate[];
}

export default class CalendarBody extends React.Component<IProps> {
    private calendarBodyRef: HTMLDivElement | null = null;

    public componentDidMount(): void {
        if (this.calendarBodyRef) {
            this.calendarBodyRef.scrollTop = calculateScrollOffset(this.calendarBodyRef, this.props.cellHeight, new Date());
        }
    }

    public render(): React.ReactNode {
        return (
            <div className="calendar_body" ref={(ref) => this.calendarBodyRef = ref}>
                <div className="calendar_canvas">
                    <HourColumn cellHeight={this.props.cellHeight}/>
                    <GridColumn cellHeight={this.props.cellHeight}
                                appointmentsPerDate={this.props.appointmentsPerDay[0]}
                                closingHoursPerDate={this.props.closingHoursPerDay[0]}
                                tenant={this.props.tenant}/>
                    <GridColumn cellHeight={this.props.cellHeight}
                                appointmentsPerDate={this.props.appointmentsPerDay[1]}
                                closingHoursPerDate={this.props.closingHoursPerDay[1]}
                                tenant={this.props.tenant}/>
                    <GridColumn cellHeight={this.props.cellHeight}
                                appointmentsPerDate={this.props.appointmentsPerDay[2]}
                                closingHoursPerDate={this.props.closingHoursPerDay[2]}
                                tenant={this.props.tenant}/>
                    <GridColumn cellHeight={this.props.cellHeight}
                                appointmentsPerDate={this.props.appointmentsPerDay[3]}
                                closingHoursPerDate={this.props.closingHoursPerDay[3]}
                                tenant={this.props.tenant}/>
                    <GridColumn cellHeight={this.props.cellHeight}
                                appointmentsPerDate={this.props.appointmentsPerDay[4]}
                                closingHoursPerDate={this.props.closingHoursPerDay[4]}
                                tenant={this.props.tenant}/>
                    <GridColumn cellHeight={this.props.cellHeight}
                                appointmentsPerDate={this.props.appointmentsPerDay[5]}
                                closingHoursPerDate={this.props.closingHoursPerDay[5]}
                                tenant={this.props.tenant}/>
                    <GridColumn cellHeight={this.props.cellHeight}
                                appointmentsPerDate={this.props.appointmentsPerDay[6]}
                                closingHoursPerDate={this.props.closingHoursPerDay[6]}
                                tenant={this.props.tenant}/>
                </div>
            </div>
        );
    }

}
