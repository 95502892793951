import Immutable from "immutable";
import React, {FormEvent, ReactNode} from "react";
import {Button} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {cleanLastCreatedUpdatedAppointmentAction} from "../actions/appointments.actions";
import {selectAppointmentTypeAction} from "../actions/appointmentTypes.actions";
import {cleanOpticiansAction} from "../actions/opticians.actions";
import {cleanTimeslotsAction} from "../actions/timeslots.actions";
import CustomerLookup from "../components/CustomerLookup";
import CustomerSearchModal from "../components/search/modal/CustomerSearchModal";
import {getAdditionalFields, IAdditionalFieldData} from "../reducers/additionalFields.reducer";
import {getLastCreatedOrUpdatedAppointment, IAppointmentData} from "../reducers/appointments.reducer";
import {
    getAppointmentTypes,
    getSelectedAppointmentType,
    IAppointmentTypeData,
} from "../reducers/appointmentTypes.reducer";
import {getFormConfig} from "../reducers/config.reducer";
import {ICustomerData} from "../reducers/customers.reducer";
import {
    getAvailableLocales,
    getCalendarLocale,
    getFallbackLocale,
    getLocalizedText,
    getSelectedLocale
} from "../reducers/locale.reducer";
import {getOpticians, IOpticianData} from "../reducers/opticians.reducer";
import {getRoomsForAppointmentType, getSelectedRoom, IRoomData} from "../reducers/room.reducer";
import {getSelectedDate, ISelectedDateAndRange} from "../reducers/selecteddate.reducer";
import {getSelectedStore, IStoreData} from "../reducers/store.reducer";
import {
    getTimeslotsAvailabilityPerDays,
    getTimeslotsForDay,
    ITimeslotAvailabilityPerDay,
    ITimeslotData,
} from "../reducers/timeslots.reducer";
import {fetchAdditionalFieldsForAppointmentType} from "../thunks/additionalFields.thunk";
import {
    createAppointmentWithCustomer,
    createAppointmentWithExistingCustomer,
    fetchAppointments,
    updateCustomerAndRescheduleOrCreateAppointment,
    updateCustomer,
    updateAppointment
} from "../thunks/appointments.thunk";
import {fetchOpticians} from "../thunks/opticians.thunk";
import {createRecurringAppointments} from "../thunks/recurringAppointments.thunk";
import {fetchTimeslots} from "../thunks/timeslots.thunk";
import {isSameDate, fixDatePickerDate} from "../util/date.util";
import "./AppointmentModal.scss";
import {
    getAppointmentTypeListContent,
    getDurationAmount,
    getDurationUnit,
    getGenderContent,
    getNotificationChannelContent,
    getNotificationLanguageContent,
    getOpticiansListContent,
    getRoomsListContent,
    getStoreName,
    getTimeslotsListContent,
    initializeAdditionalFields_new,
    isFieldDisabled,
    isInRebookMode,
    isInRescheduleMode,
    isInEditMode,
    onAppointmentDateChange,
    onAppointmentTypeChange,
    onDiaryChange,
    onEndDateTimeChange,
    onOpticianChange,
    onStartDateTimeChange,
    prepareAppointmentData,
    prepareAppointmentRelationships,
    prepareCustomerData_new,
    prepareRecurringAppointmentsData,
    prepareRecurringAppointmentsRelationships,
    renderAdditionalFields_new,
    resolveDayClassName,
} from "./AppointmentModal.utils";
import ConfirmAppointmentModalHandler from "./ConfirmAppointmentModalHandler";
import GenericModal from "./GenericModal";
import {fetchAvailableTimeslotsApproximation} from "../thunks/timeslotCounter.thunk";
import {pendingModalAction} from "../actions/genericModal.actions";
import Dropdown from "./Dropdown";
import Input from "./Input";
import Textarea from "./Textarea";
import {AppointmentModalValidator} from "./validator/AppointmentModalValidator";
import Checkbox from "./Checkbox";

export enum AppointmentModalMode {
    NEW,
    RESCHEDULE,
    REBOOK,
    EDIT
}

export enum DaysOfWeek {
    MONDAY = "MONDAY",
    TUESDAY = "TUESDAY",
    WEDNESDAY = "WEDNESDAY",
    THURSDAY = "THURSDAY",
    FRIDAY = "FRIDAY",
    SATURDAY = "SATURDAY",
    SUNDAY = "SUNDAY",
}

interface IProps {
    tenant: string;
    closePopup: () => void;
    mode: AppointmentModalMode;
    appointment?: IAppointmentData | undefined;
    additionalFieldsForAppTypeFromAppointment: IAdditionalFieldData[];
    selectedCustomer?: ICustomerData;
    refreshMatrixOnClose?: boolean;
}

export interface IDropdownItem {
    label: string | number;
    value: string | number;
}

interface IState {
    showModal: boolean;
    store: string;
    optician: string | null;
    diary: number | null;
    appointmentType: string | null;
    appointmentDate: Date | null;
    appointmentDateValidationActivated: boolean;
    startTime: number | null;
    overrideAvailability: boolean;
    recurringMode: boolean;
    durationValue: number | null;
    durationUnit: number | null;
    gender: string;
    firstName: string;
    lastName: string;
    customerDateOfBirth: Date | null;
    email: string;
    phone: string;
    additionalNotes: string;
    notificationRequired: boolean;
    notificationChannel: string;
    notificationLanguage: string;
    customerComment: string;
    overrideStartDateTime: Date | null;
    overrideStartDateTimeValidationActivated: boolean;
    overrideEndDateTime: Date | null;
    overrideEndDateTimeValidationActivated: boolean;
    recurringAppointmentsStartDate: Date | null;
    recurringAppointmentsEndDate: Date | null;
    selectedDaysOfWeek: Set<string>;
    selectedDaysOfWeekValidationActivated: boolean;
    recurringStartDateValidationActivated: boolean;
    recurringEndDateValidationActivated: boolean;
    recurringAppointmentsStartTime: string;
    recurringAppointmentsEndTime: string;
    interval: number;
    additionalFields: Immutable.Map<string, any>;
    showConfirmAppointmentModal: boolean;
    showCustomerSearchModal: boolean;
    disableCustomerChanging: boolean;
}

interface IConnectProps {
    getMessageForKey: (key: string) => string;
    getAppointmentModalConfigForTenant: IFormElementsFieldset[];
    getSelectedStore: IStoreData | undefined;
    getSelectedLocale: string;
    getFallbackLocale: string;
    getSelectedDate: ISelectedDateAndRange | undefined;
    getSelectedAppointmentType: IAppointmentTypeData | undefined;
    getAppointmentTypes: IAppointmentTypeData[];
    getRoomsForAppointmentType: (appointmentTypeId: string) => IRoomData[];
    getTimeslotsForDate: (date: Date) => ITimeslotData[];
    opticiansForAppointmentType: IOpticianData[];
    lastCreatedOrUpdatedAppointment: IAppointmentData | undefined;
    getAvailableNotificationLanguage: string[];
    additionalFields: IAdditionalFieldData[];
    getTimeslotsAvailabilityPerDays: ITimeslotAvailabilityPerDay[];
    selectedRoom: IRoomData | undefined;
    startDate: ISelectedDateAndRange | undefined;
    calendarLocale: Locale | undefined;
}

interface IDispatchProps {
    dispatchSelectAppointmentType: (appointmentTypeData: IAppointmentTypeData | undefined) => void;
    dispatchFetchAdditionalFields: (appointmentTypeId: string, tenantId: string) => void;
    dispatchFetchOpticians: (storeId: string, appointmentTypeId: string, tenantId: string) => void;
    dispatchCleanOpticians: () => void;
    dispatchCleanTimeslots: () => void;
    dispatchFetchTimeslots: (storeId: string, appointmentTypeId: string, startDate: Date, tenantId: string, roomId: string | null, opticianId: string | null) => void;
    createAppointmentWithCustomer: (appointmentAttributes: any, appointmentRelationships: any, customerAttributes: any, tenant: string) => void;
    dispatchCleanLastCreatedOrUpdatedAppointment: () => void;
    updateCustomerAndRescheduleOrCreateAppointment: (appointmentAttributes: any, appointmentRelationships: any,
                                                     updatedCustomer: any, tenant: string, customerId: string) => void;
    updateCustomer: (customerId: string, appointmentId: string, attr: any, tenant: string) => void;
    updateAppointment: (appointmentId: string, attr: any, tenant: string) => void;
    createAppointmentWithExistingCustomer: (appointmentAttributes: any, appointmentRelationships: any,
                                            customerId: string, tenant: string) => void;
    createRecurringAppointments: (appointmentSeriesAttributes: any, appointmentSeriesRelationships: any, tenant: string) => void;
    dispatchFetchAvailableTimeslotsApproximation: (storeId: string, appointmentTypeId: string, startDate: Date, tenantId: string, roomId: string | null, opticianId: string | null) => void;
    setPendingModal: () => void;
    fetchAppointments: (storeId: string, roomId: number, startTime: Date, endTime: Date, tenant: string) => void;
}

interface IDataProps extends IProps, IConnectProps {
}

interface IResultProps extends IDataProps, IDispatchProps {
}

interface IFormElement {
    fieldKey: number;
    fieldName: string;
    fieldType: string;
}

interface IFormElementsCol extends Array<IFormElement> {
}

interface IFormElementsRow extends Array<IFormElementsCol> {
}

interface IFormElementsFieldset extends Array<IFormElementsRow> {
}

export class AppointmentModal extends React.Component<IResultProps, IState> {

    public static additionalFieldPrefix: string = "additionalField_";

    private selectedCustomer: ICustomerData | null;

    constructor(props: IResultProps) {
        super(props);
        this.state = {
            showModal: true,
            store: "",
            optician: null,
            diary: null,
            appointmentType: null,
            appointmentDate: null,
            appointmentDateValidationActivated: false,
            startTime: null,
            overrideAvailability: false,
            recurringMode: false,
            durationValue: null,
            durationUnit: null,
            gender: "",
            firstName: "",
            lastName: "",
            customerDateOfBirth: null,
            email: "",
            phone: "",
            additionalNotes: "",
            notificationRequired: true,
            notificationChannel: "email",
            notificationLanguage: "",
            customerComment: "",
            overrideEndDateTime: null,
            overrideEndDateTimeValidationActivated: false,
            overrideStartDateTime: null,
            overrideStartDateTimeValidationActivated: false,
            recurringStartDateValidationActivated: false,
            recurringEndDateValidationActivated: false,
            recurringAppointmentsStartDate: null,
            recurringAppointmentsEndDate: null,
            selectedDaysOfWeekValidationActivated: false,
            selectedDaysOfWeek: new Set<string>(),
            recurringAppointmentsStartTime: "",
            recurringAppointmentsEndTime: "",
            interval: 1,
            additionalFields: Immutable.Map({}),
            showConfirmAppointmentModal: false,
            showCustomerSearchModal: false,
            disableCustomerChanging: false,
        };
        this.selectedCustomer = null;
    }

    public render(): React.ReactNode {
        const additionalFieldsExist = this.props.additionalFields !== undefined && this.props.additionalFields.length > 0;
        const isFormElementTopExist = Array.isArray(this.props.getAppointmentModalConfigForTenant[0]) && this.props.getAppointmentModalConfigForTenant[0].length;
        const isFormElementBottomExist = Array.isArray(this.props.getAppointmentModalConfigForTenant[1]) && this.props.getAppointmentModalConfigForTenant[1].length && !this.state.recurringMode;

        const isFormValid = AppointmentModalValidator.isFormValid(this);
        return (
            <div>
                {this.state.showModal ?
                    <div className="modal-body">
                        <div className="container-fluid">
                            <div className="popup">
                                <div className="popup_inner">
                                    <form id="appointmentForm">
                                        <div className="row">
                                            <div className="col">
                                                <button onClick={() => this.onClose()}
                                                        className="link-button float-right">&times;</button>
                                            </div>
                                        </div>
                                        <div className="row px-2">
                                            <div className="col px-0">
                                                <h2>{this.resolveHeaderText()}</h2>
                                            </div>
                                        </div>
                                        { this.props.mode !== AppointmentModalMode.EDIT ?
                                            <div className="row px-2">
                                                <fieldset className="redo-fieldset">
                                                    <legend
                                                        className="redo-legend">{this.props.getMessageForKey("appointment.modal.top.fieldSet")}</legend>
                                                    {isFormElementTopExist ? this.props.getAppointmentModalConfigForTenant[0].map((formElementRow, index) => (
                                                        <div key={"top" + index} className="row">
                                                            {formElementRow.map((formElementCol, i) => (
                                                                <div key={i} className="col">
                                                                    {formElementCol.map((formElement, j) => (
                                                                        <div key={j}>
                                                                            {this.getProperFormElement(formElement)}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )) : <div/>}
                                                </fieldset>
                                            </div>
                                        : null }

                                        {isFormElementBottomExist ?
                                            <div className="row px-2">
                                                <fieldset className="redo-fieldset">
                                                    <legend
                                                        className="redo-legend">{this.props.getMessageForKey("appointment.modal.bottom.fieldSet")}</legend>
                                                    {this.props.getAppointmentModalConfigForTenant[1].map((formElementRow, index) => (
                                                        <div key={"bottom" + index} className="row">
                                                            {formElementRow.map((formElementCol, i) => (
                                                                <div key={i} className="col">
                                                                    {formElementCol.map((formElement, j) => (
                                                                        <div key={j} className="form-element">
                                                                            {this.getProperFormElement(formElement)}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))}
                                                    {additionalFieldsExist ? renderAdditionalFields_new(this, this.props.additionalFields) :
                                                        <div/>}
                                                </fieldset>
                                            </div> : <div/>}

                                        <div className="row row-form-button">
                                            <div className="col">
                                                <button type="button"
                                                        className={"btn btn-cancel"}
                                                        onClick={() => this.onClose()}>
                                                    {this.props.getMessageForKey("appointment.modal.button.cancel")}
                                                </button>
                                                <Button
                                                    className={isFormValid ? "btn" : "btn-disabled"}
                                                    onClick={() => this.handleSubmit()}
                                                    disabled={!isFormValid}>
                                                    {this.resolveSubmitButtonText()}
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div/>}
                {this.state.recurringMode ? this.renderConfirmRecurringAppointmentsModal() : this.renderConfirmAppointmentModal()}
                {this.renderCustomerSearchModal()}
            </div>);
    }

    public componentDidMount() {
        if (this.props.appointment !== undefined && (isInRescheduleMode(this) || isInRebookMode(this) || isInEditMode(this))) {
            this.selectedCustomer = this.props.appointment.customer;
            this.setState({appointmentType: this.props.appointment.appointmentType.id});
            onAppointmentTypeChange(this, this.props.appointment.appointmentType.id, true);
            if (this.props.appointment.customer !== undefined) {
                this.setState({
                    email: this.props.appointment.customer.email,
                    gender: this.props.appointment.customer.gender,
                    phone: this.props.appointment.customer.phoneNumber,
                    firstName: this.props.appointment.customer.firstName,
                    lastName: this.props.appointment.customer.lastName,
                });
                if (this.props.appointment.customer.dateOfBirth) {
                    this.setState({customerDateOfBirth: new Date(`${this.props.appointment.customer.dateOfBirth}T00:00:00`)});
                }
            }
            this.setState({
                additionalNotes: this.props.appointment.updateComment,
                notificationChannel: this.props.appointment.preferredChannelOfCommunication,
                notificationLanguage: this.props.appointment.preferredLanguage,
                customerComment: this.props.appointment.customerComment,
                notificationRequired: this.props.appointment.notificationRequired,
            });
            initializeAdditionalFields_new(this);
        } else {
            this.setState({
                notificationLanguage: this.props.getSelectedLocale,
            });
        }
        this.fillInRebookMode();
        if (this.props.selectedCustomer !== undefined) {
            this.onCustomerSelectHandler(this.props.selectedCustomer);
            this.setState({disableCustomerChanging: true});
        }
        document.addEventListener("keydown", this.enterFunction);
    }

    private enterFunction = (event: KeyboardEvent) => {
        if (event.key === "Enter") {
            event.preventDefault();

            const inputs = document.querySelectorAll('#appointmentForm input, #appointmentForm textarea');
            const submittedInput = event.target as HTMLElement;
            for (let i = 0; i < inputs.length; i++) {
                if (submittedInput.id === inputs[i].id) {
                    let nextInput = this.getNextInput(i, inputs);
                    let offset = 1;
                    while (nextInput.hasAttribute("disabled") && offset < inputs.length) {
                        nextInput = this.getNextInput(i + offset, inputs);
                        offset++;
                    }
                    nextInput.focus();
                    break;
                }
            }
        }
    }

    private getNextInput(order: number, customerInputs: NodeListOf<Element>) {
        const nextInput = order + 1 < customerInputs.length ? customerInputs[order + 1] : customerInputs[order + 1 - customerInputs.length];
        return nextInput as HTMLElement;
    }

    private fillInRebookMode(): void {
        if (isInRebookMode(this) && this.props.appointment) {
            if (this.props.appointment.optician && this.props.appointment.optician.id !== "") {
                this.setState({optician: this.props.appointment.optician.id});
            }
            if (this.props.appointment.room) {
                this.setState({diary: this.props.appointment.room.id});
            }
        }
    }

    private renderConfirmRecurringAppointmentsModal(): ReactNode {
        return this.state.showConfirmAppointmentModal ?
            <GenericModal tenant={this.props.tenant}
                          closeModal={() => this.onGenericModalClose()}
                          handleErrorModal={() => this.onGenericModalErrorHandler()}
            /> : null;
    }

    private renderConfirmAppointmentModal() {
        return this.state.showConfirmAppointmentModal ?
            <>
                <ConfirmAppointmentModalHandler tenant={this.props.tenant}/>
                <GenericModal tenant={this.props.tenant}
                              closeModal={() => this.onGenericModalClose()}
                              handleErrorModal={() => this.onGenericModalErrorHandler()}
                              handleTimeslotNotAvailableErrorModal={() => this.onTimeslotNotAvailableErrorHandler()}
                />
            </> : null;
    }

    private onClose() {
        if (this.props.refreshMatrixOnClose) {
            this.refreshAppointmentMatrix();
        }
        this.props.dispatchCleanLastCreatedOrUpdatedAppointment();
        this.props.closePopup();
        this.props.dispatchSelectAppointmentType(undefined);
    }

    private isFieldValueChanged(fieldName: string, newValue: string): boolean {
        const oldValue = String((this.state as any)[fieldName]);
        return oldValue !== newValue;
    }

    private isAppointmentTypeFieldDisabled(): boolean {
        return this.props.getSelectedStore === undefined || isInRescheduleMode(this);
    }

    private handleSubmit() {
        this.props.setPendingModal();
        let customerData = null;
        switch (this.props.mode) {
            case AppointmentModalMode.NEW:
            case AppointmentModalMode.REBOOK:
                if (this.selectedCustomer !== null) {
                    if (this.customerRequiresUpdate(this.selectedCustomer)) {
                        customerData = prepareCustomerData_new(this);
                        this.props.updateCustomerAndRescheduleOrCreateAppointment(prepareAppointmentData(this),
                            prepareAppointmentRelationships(this, false), customerData, this.props.tenant, this.selectedCustomer.id);
                    } else {
                        this.props.createAppointmentWithExistingCustomer(prepareAppointmentData(this),
                            prepareAppointmentRelationships(this, false), this.selectedCustomer.id, this.props.tenant);
                    }
                } else if (this.state.recurringMode) {
                    this.props.createRecurringAppointments(
                        prepareRecurringAppointmentsData(this),
                        prepareRecurringAppointmentsRelationships(this), this.props.tenant);
                } else {
                    customerData = prepareCustomerData_new(this);
                    this.props.createAppointmentWithCustomer(prepareAppointmentData(this),
                        prepareAppointmentRelationships(this, false), customerData, this.props.tenant);
                }
                break;
            case AppointmentModalMode.RESCHEDULE:
                if (this.props.appointment !== undefined && this.props.appointment.customer !== undefined) {
                    if (this.customerRequiresUpdate(this.props.appointment.customer)) {
                        customerData = {
                            email: this.state.email,
                            phoneNumber: this.state.phone,
                        };
                        this.props.updateCustomerAndRescheduleOrCreateAppointment(prepareAppointmentData(this),
                            prepareAppointmentRelationships(this, true), customerData, this.props.tenant, this.props.appointment.customer.id);
                    } else {
                        this.props.updateCustomerAndRescheduleOrCreateAppointment(prepareAppointmentData(this),
                            prepareAppointmentRelationships(this, true), customerData, this.props.tenant, this.props.appointment.customer.id);
                    }
                }
                break;
            case AppointmentModalMode.EDIT:
                if (this.selectedCustomer !== null && this.props.appointment !== undefined && this.customerRequiresUpdate(this.selectedCustomer)) {
                    customerData = prepareCustomerData_new(this);
                    this.props.updateCustomer(this.selectedCustomer.id, this.props.appointment.uuid, customerData, this.props.tenant);
                }
                if (this.props.appointment && this.props.appointment?.updateComment !== this.state.additionalNotes ) {
                    this.props.updateAppointment(this.props.appointment.uuid, {updateComment: this.state.additionalNotes}, this.props.tenant)
                }
                break;
        }
        this.setState({
            showConfirmAppointmentModal: true,
            showModal: false,
        });
    }

    private getProperFormElement(formElement: any) {
        if (formElement !== undefined && formElement.fieldType !== undefined) {
            switch (formElement.fieldType) {
                case "AppointmentType": {
                    return <div className="form-element">
                        <Dropdown
                            errorClassName="dropdown-error"
                            checkIfNotEmptyOnBlur={true}
                            overrideAvailability={this.state.overrideAvailability}
                            mandatory={true}
                            placeholder={this.props.getMessageForKey("appointment.modal.form.placeholder.select")}
                            label={this.props.getMessageForKey("appointment.modal.form.label.type")}
                            availableValues={getAppointmentTypeListContent(this)}
                            fieldName="appointmentType"
                            value={this.state.appointmentType}
                            onChange={(selectedItem: IDropdownItem, fieldName: string) => this.onDropdownValueChange(selectedItem, fieldName)}
                            disabled={this.isAppointmentTypeFieldDisabled()}
                        />
                    </div>;
                }
                case "Diary": {
                    return <div className="form-element">
                        <Dropdown
                            errorClassName="dropdown-error"
                            checkIfNotEmptyOnBlur={(this.state.overrideAvailability || this.state.recurringMode) && this.props.getSelectedAppointmentType && this.props.getSelectedAppointmentType.roomRequired}
                            overrideAvailability={this.state.overrideAvailability}
                            recurringMode={this.state.recurringMode}
                            appointmentType={this.state.appointmentType}
                            mandatory={this.props.getSelectedAppointmentType && (this.state.overrideAvailability || this.state.recurringMode) ? this.props.getSelectedAppointmentType.roomRequired : false}
                            placeholder={this.props.getMessageForKey("appointment.modal.form.placeholder.select")}
                            label={this.props.getMessageForKey("appointment.modal.form.label.diary")}
                            availableValues={getRoomsListContent(this)}
                            fieldName="diary"
                            value={this.state.diary}
                            disabled={isFieldDisabled(this)}
                            onChange={(selectedItem: IDropdownItem, fieldName: string) => this.onDropdownValueChange(selectedItem, fieldName)}
                        />
                    </div>;
                }
                case "Optician": {
                    return <div className="form-element">
                        <Dropdown
                            errorClassName="dropdown-error"
                            checkIfNotEmptyOnBlur={(this.state.overrideAvailability || this.state.recurringMode) && this.props.getSelectedAppointmentType && this.props.getSelectedAppointmentType.opticianRequired}
                            overrideAvailability={this.state.overrideAvailability}
                            recurringMode={this.state.recurringMode}
                            appointmentType={this.state.appointmentType}
                            mandatory={this.props.getSelectedAppointmentType && (this.state.overrideAvailability || this.state.recurringMode) ? this.props.getSelectedAppointmentType.opticianRequired : false}
                            placeholder={this.props.getMessageForKey("appointment.modal.form.placeholder.select")}
                            label={this.props.getMessageForKey("appointment.modal.form.label.optician")}
                            availableValues={getOpticiansListContent(this)}
                            fieldName="optician"
                            value={this.state.optician}
                            disabled={isFieldDisabled(this)}
                            onChange={(selectedItem: IDropdownItem, fieldName: string) => this.onDropdownValueChange(selectedItem, fieldName)}
                        />
                    </div>;
                }
                case "StartTime": {
                    return this.renderStartTimeField();
                }
                case "Chooser": {
                    return <div className="form-element">
                        <Dropdown
                            placeholder={getStoreName(this)}
                            label={this.props.getMessageForKey("appointment.modal.form.label.store")}
                            availableValues={[]}
                            fieldName=""
                            mandatory={true}
                            disabled={true}
                            onChange={(selectedItem: IDropdownItem, fieldName: string) => this.onDropdownValueChange(selectedItem, fieldName)}
                        />
                    </div>;
                }
                case "FirstName": {
                    return <div className="form-element">
                        <Input fieldName="firstName"
                               type="text"
                               overrideAvailability={this.state.overrideAvailability}
                               mandatory={!this.state.overrideAvailability}
                               className="form-control input-sm"
                               onChange={(event: FormEvent<HTMLInputElement>) => this.onFormInputChange(event)}
                               isValid={(value) => AppointmentModalValidator.isNotEmpty(value) || this.state.overrideAvailability}
                               disabled={isInRescheduleMode(this) || this.state.disableCustomerChanging}
                               value={this.state.firstName}
                               errorClassName="input-error"
                               label={this.props.getMessageForKey("appointment.modal.form.label.firstName")}/>
                    </div>;
                }
                case "LastName": {
                    return <div className="form-element">
                        <Input fieldName="lastName"
                               type="text"
                               overrideAvailability={this.state.overrideAvailability}
                               mandatory={!this.state.overrideAvailability}
                               className="form-control input-sm"
                               onChange={(event: FormEvent<HTMLInputElement>) => this.onFormInputChange(event)}
                               isValid={(value) => AppointmentModalValidator.isNotEmpty(value) || this.state.overrideAvailability}
                               disabled={isInRescheduleMode(this) || this.state.disableCustomerChanging}
                               value={this.state.lastName}
                               errorClassName="input-error"
                               label={this.props.getMessageForKey("appointment.modal.form.label.lastName")}/>
                    </div>;
                }
                case "Phone": {
                    return <div className="form-element">
                        <Input fieldName="phone"
                               type="text"
                               overrideAvailability={this.state.overrideAvailability}
                               notificationChannel={this.state.notificationChannel}
                               isValid={(value) => !AppointmentModalValidator.phoneNumberInvalid(this.state.notificationChannel, value, this.state.overrideAvailability)}
                               mandatory={this.isPhoneNumberMandatory()}
                               className="form-control input-sm"
                               placeholder={this.props.getMessageForKey("appointment.modal.form.placeholder.phone")}
                               onChange={(event: FormEvent<HTMLInputElement>) => this.onFormInputChange(event)}
                               disabled={this.state.disableCustomerChanging}
                               value={this.state.phone}
                               errorClassName="input-error"
                               label={this.props.getMessageForKey("appointment.modal.form.label.phone")}/>
                    </div>;
                }
                case "Email": {
                    return <div className="form-element">
                        <Input fieldName="email"
                               value={this.state.email}
                               type="text"
                               overrideAvailability={this.state.overrideAvailability}
                               notificationChannel={this.state.notificationChannel}
                               isValid={(value) => !AppointmentModalValidator.emailAddressInvalid(this.state.notificationChannel, value, this.state.overrideAvailability)}
                               mandatory={this.isEmailAddressMandatory()}
                               className="form-control input-sm"
                               errorClassName="input-error"
                               onChange={(event: FormEvent<HTMLInputElement>) => this.onFormInputChange(event)}
                               disabled={this.state.disableCustomerChanging}
                               label={this.props.getMessageForKey("appointment.modal.form.label.email")}/>
                    </div>;
                }
                case "DateOfBirth": {
                    return <div className="form-element">
                        <div className="group-label">
                            <label>{this.props.getMessageForKey("appointment.modal.form.label.dateOfBirth")}</label>
                        </div>

                        <DatePicker selected={this.state.customerDateOfBirth}
                                    id="dateOfBirth"
                                    onChange={(date) => this.setState({customerDateOfBirth: fixDatePickerDate(date)})}
                                    maxDate={new Date()}
                                    dateFormat="dd MMMM yyyy"
                                    placeholderText={this.props.getMessageForKey("appointment.modal.form.placeholder.date")}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className={isInRescheduleMode(this) || this.state.disableCustomerChanging ? "form-control datepicker__input rdf disabled" : "form-control datepicker__input rdf"}
                                    disabled={isInRescheduleMode(this) || this.state.disableCustomerChanging}
                                    locale={this.props.calendarLocale}/>
                    </div>;
                }

                case "AppointmentDate": {
                    return this.renderAppointmentDateField(this.props.getTimeslotsAvailabilityPerDays);
                }

                case "Empty": {
                    return <div className="form-element"/>;
                }

                case "Duration": {
                    // Not sure why a dropdown is used for duration unit, this isn't passed to the backend - Jorick
                    return (<div className="form-element">
                        {this.state.overrideAvailability || this.state.recurringMode ?
                            <div className="row"/> :
                            <div className="row">
                                <div className="col">
                                    <Dropdown
                                        placeholder={this.props.getMessageForKey("appointment.modal.form.placeholder.duration")}
                                        label={this.props.getMessageForKey("appointment.modal.form.label.duration")}
                                        availableValues={getDurationAmount(this)}
                                        fieldName="durationValue"
                                        value={this.state.durationValue}
                                        disabled={isFieldDisabled(this)}
                                        onChange={(selectedItem: IDropdownItem, fieldName: string) => this.onDropdownValueChange(selectedItem, fieldName)}
                                    />
                                </div>
                                <div className="col">
                                    <Dropdown
                                        placeholder={this.props.getMessageForKey("appointment.modal.form.placeholder.duration")}
                                        label="&nbsp;"
                                        availableValues={getDurationUnit(this)}
                                        fieldName="durationUnit"
                                        value={this.state.durationUnit}
                                        disabled={isFieldDisabled(this)}
                                        onChange={(selectedItem: IDropdownItem, fieldName: string) => this.onDropdownValueChange(selectedItem, fieldName)}
                                    />
                                </div>
                            </div>}
                    </div>);
                }

                case "Gender": {
                    return (<div className="form-element">
                        <div className="appointment-flex">
                            <div style={{width: "120px"}}>
                                <Dropdown
                                    placeholder={this.props.getMessageForKey("appointment.modal.form.placeholder.select")}
                                    label={this.props.getMessageForKey("appointment.modal.form.label.gender")}
                                    availableValues={getGenderContent(this)}
                                    fieldName="gender"
                                    value={this.state.gender}
                                    disabled={isInRescheduleMode(this) || this.state.disableCustomerChanging}
                                    onChange={(selectedItem: IDropdownItem, fieldName: string) => this.onDropdownValueChange(selectedItem, fieldName)}
                                />
                            </div>
                            { !isInEditMode(this) ?
                                <div className="">
                                    {this.renderCustomerLookup()}
                                </div>
                            : null }
                        </div>
                    </div>);
                }

                case "AdditionalNotes": {
                    return <div className="form-element">
                        <div className="appointment-text-area">
                            <Textarea
                                label={this.props.getMessageForKey("appointment.modal.form.label.additionalNotes")}
                                fieldName="additionalNotes"
                                className="form-control"
                                onChange={(event: FormEvent<HTMLTextAreaElement>) => this.onFormInputChange(event)}
                                disabled={false}
                                value={this.state.additionalNotes}/>
                        </div>
                    </div>;
                }

                case "CustomerComment": {
                    return <div className="form-element">
                        <div className="appointment-text-area">
                            <Textarea
                                label={this.props.getMessageForKey("appointment.modal.form.label.customerComment")}
                                fieldName="customerComment"
                                className="form-control"
                                onChange={(event: FormEvent<HTMLTextAreaElement>) => this.onFormInputChange(event)}
                                disabled={true}
                                value={this.state.customerComment}/>
                        </div>
                    </div>;
                }

                case "NotificationRequired": {
                    return (
                        <div className="form-element">
                            <div className="group-label">
                                <label>{this.props.getMessageForKey("appointment.modal.form.label.notificationRequired")}</label>
                            </div>
                            <div className="checkbox">
                                <label htmlFor="noNotificationId">
                                    {!this.state.notificationRequired ?
                                        <input name="noNotification" type="checkbox" id="noNotificationId"
                                               className="notification_required_chooser"
                                               onChange={() => this.handleNotificationRequiredCheckbox()} checked/> :
                                        <input name="noNotification" type="checkbox" id="noNotificationId"
                                               className="notification_required_chooser"
                                               onChange={() => this.handleNotificationRequiredCheckbox()}/>}
                                    <span>{this.props.getMessageForKey("appointment.modal.form.label.no.notification")}</span>
                                </label>
                            </div>
                        </div>);
                }

                case "NotificationLanguage": {
                    return <div className="form-element">
                        <Dropdown
                            placeholder={this.props.getMessageForKey("appointment.modal.form.placeholder.select")}
                            label={this.props.getMessageForKey("appointment.modal.form.label.notificationLanguage")}
                            availableValues={getNotificationLanguageContent(this)}
                            fieldName="notificationLanguage"
                            disabled={false}
                            mandatory={!this.state.overrideAvailability}
                            value={this.state.notificationLanguage}
                            onChange={(selectedItem: IDropdownItem, fieldName: string) => this.onDropdownValueChange(selectedItem, fieldName)}
                        />
                    </div>;
                }

                case "NotificationChannel": {
                    return <div className="form-element">
                        <Dropdown
                            placeholder={this.props.getMessageForKey("appointment.modal.form.placeholder.select")}
                            label={this.props.getMessageForKey("appointment.modal.form.label.notificationChannel")}
                            availableValues={getNotificationChannelContent(this)}
                            fieldName="notificationChannel"
                            disabled={false}
                            mandatory={!this.state.overrideAvailability}
                            value={this.state.notificationChannel}
                            onChange={(selectedItem: IDropdownItem, fieldName: string) => this.onDropdownValueChange(selectedItem, fieldName)}
                        />
                    </div>;
                }

                case "Checkboxes": {
                    return <div className="form-element">
                        <div className="row">
                            <div className="col">
                                <div className="group-label">
                                    <label/>
                                </div>
                                <div className={this.state.recurringMode ? "checkbox disabled" : "checkbox"}>
                                    <label htmlFor="overrideAvailability">
                                        <input name="overrideAvailability" type="checkbox" id="overrideAvailability"
                                               checked={this.state.overrideAvailability}
                                               onChange={() => this.toggleOverrideAvailability()}
                                               disabled={this.state.recurringMode}/>
                                        <span>{this.props.getMessageForKey("appointment.modal.form.label.override.availability")}</span>
                                    </label>
                                </div>
                            </div>
                            {this.props.getSelectedAppointmentType && this.props.getSelectedAppointmentType.allowRecurringPattern && this.props.mode === AppointmentModalMode.NEW ?
                                <div className="col">
                                    <div className="group-label">
                                        <label/>
                                    </div>
                                    <div className={this.state.overrideAvailability ? "checkbox disabled" : "checkbox"}>
                                        <label htmlFor="recurringMode">
                                            <input name="recurringMode" type="checkbox" id="recurringMode"
                                                   checked={this.state.recurringMode}
                                                   onChange={() => this.toggleRecurringMode()}
                                                   disabled={this.state.overrideAvailability}/>
                                            <span>{this.props.getMessageForKey("appointment.modal.form.label.recurring.mode")}</span>
                                        </label>
                                    </div>
                                </div> : <></>}
                        </div>
                    </div>;
                }
                case "RecurringPattern": {
                    return this.state.recurringMode ?
                        <div className="form-element">
                            <Dropdown
                                placeholder={this.props.getMessageForKey("appointment.modal.form.weekly")}
                                label={this.props.getMessageForKey("appointment.modal.form.label.recurring.pattern") + "*"}
                                availableValues={[]}
                                fieldName=""
                                disabled={true}
                                onChange={(selectedItem: IDropdownItem, fieldName: string) => this.onDropdownValueChange(selectedItem, fieldName)}/>
                        </div> : <></>;
                }
                case "Interval": {
                    return (
                        this.state.recurringMode ?
                            <div className="form-element">
                                <div className="group-label">
                                    <label/>
                                </div>
                                <div className="row interval">
                                    <div className="col-2">
                                        <span>{this.props.getMessageForKey("appointment.modal.form.label.every")}</span>
                                    </div>
                                    <div className="col-4">
                                        <Input type="number"
                                               fieldName="interval"
                                               onChange={(event: FormEvent<HTMLInputElement>) => this.onFormInputChange(event)}
                                               disabled={false}
                                               min={1}
                                               max={53}
                                               errorClassName="input-error"
                                               value={String(this.state.interval)}
                                               className="form-control input-sm"
                                               isValid={(value) => !AppointmentModalValidator.isRecurringIntervalInvalid(value)}/>
                                    </div>
                                    <div className="col-6">
                                        <span>{this.props.getMessageForKey("appointment.modal.form.label.weeks") + "*"}</span>
                                    </div>
                                </div>
                            </div>
                            : <></>);
                }
                case "DaysOfWeek": {
                    return (
                        this.state.recurringMode ?
                            <div className="form-element">
                                <span
                                    className="following-week-days">{this.props.getMessageForKey("appointment.modal.form.label.following.week-days") + "*"}</span>
                                <fieldset
                                    className={AppointmentModalValidator.recurringDaysOfWeekInvalid(this.state.selectedDaysOfWeek, this.state.selectedDaysOfWeekValidationActivated) ?
                                        "days-of-week-fieldset error" : "days-of-week-fieldset standard"}>
                                    {this.renderDaysOfWeek()}
                                </fieldset>
                            </div> : <></>);
                }
                case "StartDate": {
                    return (
                        this.state.recurringMode ?
                            <div className="form-element">
                                <div className="group-label">
                                    <label>{this.props.getMessageForKey("appointment.modal.form.label.start.date") + "*"}</label>
                                </div>
                                <DatePicker
                                    selected={this.state.recurringAppointmentsStartDate}
                                    onChange={(date) => this.setState({recurringAppointmentsStartDate: fixDatePickerDate(date)})}
                                    minDate={new Date()}
                                    onBlur={() => this.setState({recurringStartDateValidationActivated: true})}
                                    dateFormat="dd MMMM yyyy"
                                    placeholderText={this.props.getMessageForKey("appointment.modal.form.placeholder.start.date")}
                                    className={AppointmentModalValidator.isRecurringStartDateInvalid(this.state.recurringAppointmentsStartDate, this.state.recurringStartDateValidationActivated) ?
                                        "form-control datepicker__input rdf datepicker-error" : "form-control datepicker__input rdf"}
                                    locale={this.props.calendarLocale}/>
                            </div> : <></>);
                }
                case "EndDate": {
                    return (
                        this.state.recurringMode ?
                            <div className="form-element">
                                <div className="group-label">
                                    <label>{this.props.getMessageForKey("appointment.modal.form.label.end.date") + "*"}</label>
                                </div>
                                <DatePicker
                                    selected={this.state.recurringAppointmentsEndDate}
                                    onChange={(date) => this.setState({recurringAppointmentsEndDate: fixDatePickerDate(date)})}
                                    minDate={new Date()}
                                    onBlur={() => this.setState({recurringEndDateValidationActivated: true})}
                                    dateFormat="dd MMMM yyyy"
                                    placeholderText={this.props.getMessageForKey("appointment.modal.form.placeholder.end.date")}
                                    className={AppointmentModalValidator.isRecurringEndDateInvalid(this.state.recurringAppointmentsEndDate, this.state.recurringAppointmentsStartDate,
                                        this.state.recurringEndDateValidationActivated) ? "form-control datepicker__input rdf datepicker-error" : "form-control datepicker__input rdf"}
                                    locale={this.props.calendarLocale}/>
                            </div> : <></>);
                }

                default: {
                    return <div/>;
                }
            }
        }
    }

    private onFormInputChange(event: FormEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>): void {
        this.updateFormValue(event.currentTarget.name, event.currentTarget.value);
    }

    private onDropdownValueChange(selectedItem: IDropdownItem, fieldName: string): void {
        if (this.isFieldValueChanged(fieldName, String(selectedItem.value))) {
            this.updateFormValue(fieldName, selectedItem.value);
        }
    }

    private updateFormValue(fieldName: string, fieldValue: string | number, clearSelections: boolean = true) {
        const updatedField: any = {};
        updatedField[fieldName] = fieldValue;
        this.setState(updatedField);

        switch (fieldName) {
            case "appointmentType": {
                onAppointmentTypeChange(this, String(fieldValue), clearSelections);
                break;
            }
            case "optician": {
                onOpticianChange(this, String(fieldValue));
                break;
            }
            case "diary": {
                onDiaryChange(this, Number(fieldValue));
                break;
            }
        }
    }

    private renderStartTimeField() {
        if (this.state.recurringMode) {
            return (<div className="form-element">
                <Input className="form-control input-sm"
                       type="text"
                       label={this.props.getMessageForKey("appointment.modal.form.label.end.time")}
                       mandatory={true}
                       disabled={false}
                       startTime={this.state.recurringAppointmentsStartTime}
                       isValid={(value) => !AppointmentModalValidator.isRecurringEndTimeInvalid(value, this.state.recurringAppointmentsStartTime)}
                       errorClassName="input-error"
                       fieldName="recurringAppointmentsEndTime"
                       value={this.state.recurringAppointmentsEndTime}
                       onChange={(event) => this.onFormInputChange(event)}
                       placeholder={this.props.getMessageForKey("appointment.modal.form.placeholder.end.time")}/>
            </div>);
        } else if (this.state.overrideAvailability) {
            return (
                <div className="form-element">
                    <div className="group-label">
                        <label>{this.props.getMessageForKey("appointment.modal.form.label.end.date.time") + "*"}</label>
                    </div>
                    {/* Don't use the function fixDatePickerDate because we're dealing with datetime format */}
                    <DatePicker
                        selected={this.state.overrideEndDateTime ? this.state.overrideEndDateTime : undefined}
                        timeInputLabel={this.props.getMessageForKey("appointment.modal.form.placeholder.datepicker.time")}
                        dateFormat="dd MMMM yyyy HH:mm"
                        showTimeInput
                        onChange={(date) => onEndDateTimeChange(this, date)}
                        minDate={new Date()}
                        onBlur={() => this.setState({overrideEndDateTimeValidationActivated: true})}
                        placeholderText={this.props.getMessageForKey("appointment.modal.form.placeholder.end.date.time")}
                        disabled={isFieldDisabled(this)}
                        className={isFieldDisabled(this) ? "form-control datepicker__input rdf disabled" :
                            AppointmentModalValidator.isOverrideEndDateTimeInvalid(this.state.overrideEndDateTime, this.state.overrideStartDateTime, this.state.overrideEndDateTimeValidationActivated) ?
                                "form-control datepicker__input rdf datepicker-error" : "form-control datepicker__input rdf"}
                        locale={this.props.calendarLocale}/>
                </div>);
        } else {
            return <div className="form-element">
                <Dropdown
                    placeholder={this.props.getMessageForKey("appointment.modal.form.placeholder.time")}
                    label={this.props.getMessageForKey("appointment.modal.form.label.startTime")}
                    mandatory={true}
                    availableValues={getTimeslotsListContent(this)}
                    fieldName="startTime"
                    value={this.state.startTime}
                    disabled={this.isTimeslotFieldDisabled()}
                    checkIfNotEmptyOnBlur={true}
                    appointmentType={this.state.appointmentType}
                    errorClassName="dropdown-error"
                    onChange={(selectedItem: IDropdownItem, fieldName: string) => this.onDropdownValueChange(selectedItem, fieldName)}
                />
            </div>;
        }
    }

    private renderAppointmentDateField(timeslotsAvailabilityPerDays: ITimeslotAvailabilityPerDay[]): ReactNode {
        if (this.state.recurringMode) {
            return (
                <div className="form-element">
                    <Input className="form-control input-sm"
                           type="text"
                           label={this.props.getMessageForKey("appointment.modal.form.label.start.time")}
                           mandatory={true}
                           disabled={false}
                           isValid={(value) => !AppointmentModalValidator.isRecurringStartTimeInvalid(value)}
                           errorClassName="input-error"
                           fieldName="recurringAppointmentsStartTime"
                           value={this.state.recurringAppointmentsStartTime}
                           onChange={(event) => this.onFormInputChange(event)}
                           placeholder={this.props.getMessageForKey("appointment.modal.form.placeholder.start.time")}/>
                </div>);
        } else if (this.state.overrideAvailability) {
            return (<div className="form-element">
                <div className="group-label">
                    <label>{this.props.getMessageForKey("appointment.modal.form.label.start.date.time") + "*"}</label>
                </div>
                {/* Don't use the function fixDatePickerDate because we're dealing with datetime format */}
                <DatePicker
                    selected={!this.state.overrideStartDateTime ? undefined : this.state.overrideStartDateTime}
                    dateFormat="dd MMMM yyyy HH:mm"
                    showTimeInput
                    timeInputLabel={this.props.getMessageForKey("appointment.modal.form.placeholder.datepicker.time")}
                    onChange={(date) => onStartDateTimeChange(this, date)}
                    minDate={new Date()}
                    onBlur={() => this.setState({overrideStartDateTimeValidationActivated: true})}
                    placeholderText={this.props.getMessageForKey("appointment.modal.form.placeholder.start.date.time")}
                    disabled={isFieldDisabled(this)}
                    className={isFieldDisabled(this) ? "form-control datepicker__input rdf disabled" :
                        AppointmentModalValidator.isOverrideStartDateTimeInvalid(this.state.overrideStartDateTime, this.state.overrideStartDateTimeValidationActivated) ?
                            "form-control datepicker__input rdf datepicker-error" : "form-control datepicker__input rdf"}
                    dayClassName={() => "react-datepicker__day"}
                    locale={this.props.calendarLocale}/>
            </div>);
        } else {
            return (<div className="form-element">
                <div className="group-label">
                    <label>{this.props.getMessageForKey("appointment.modal.form.label.date") + "*"}</label>
                </div>
                <DatePicker
                    selected={this.state.appointmentDate}
                    dateFormat="dd MMMM yyyy"
                    onChange={(date) => onAppointmentDateChange(this, fixDatePickerDate(date))}
                    minDate={new Date()}
                    placeholderText={this.props.getMessageForKey("appointment.modal.form.placeholder.date")}
                    disabled={isFieldDisabled(this)}
                    onBlur={() => this.setState({appointmentDateValidationActivated: true})}
                    className={isFieldDisabled(this) ? "form-control datepicker__input rdf disabled" :
                        AppointmentModalValidator.isAppointmentDateInvalid(this.state.appointmentDate, this.state.appointmentDateValidationActivated) ?
                            "form-control datepicker__input rdf datepicker-error" : "form-control datepicker__input rdf"}
                    dayClassName={(date: Date | null) => resolveDayClassName(date, timeslotsAvailabilityPerDays)}
                    locale={this.props.calendarLocale}/>
            </div>);
        }
    }

    private isTimeslotFieldDisabled(): boolean {
        return this.state.appointmentDate === null;
    }

    private renderDaysOfWeek(): ReactNode {
        return (<div className="row">
            <div className="col">
                <Checkbox fieldName="monday"
                          onChange={(event) => this.handleDaysOfWeekSelect(event)}
                          checked={this.state.selectedDaysOfWeek.has(DaysOfWeek.MONDAY)}
                          className="day-of-week"
                          value={DaysOfWeek.MONDAY}
                          description={this.props.getMessageForKey("appointment.modal.form." + DaysOfWeek.MONDAY)}
                          isValid={() => true}/>
                <Checkbox fieldName="saturday"
                          onChange={(event) => this.handleDaysOfWeekSelect(event)}
                          checked={this.state.selectedDaysOfWeek.has(DaysOfWeek.SATURDAY)}
                          className="day-of-week"
                          value={DaysOfWeek.SATURDAY}
                          description={this.props.getMessageForKey("appointment.modal.form." + DaysOfWeek.SATURDAY)}
                          isValid={() => true}/>
            </div>
            <div className="col">
                <Checkbox fieldName="tuesday"
                          onChange={(event) => this.handleDaysOfWeekSelect(event)}
                          checked={this.state.selectedDaysOfWeek.has(DaysOfWeek.TUESDAY)}
                          className="day-of-week"
                          value={DaysOfWeek.TUESDAY}
                          description={this.props.getMessageForKey("appointment.modal.form." + DaysOfWeek.TUESDAY)}
                          isValid={() => true}/>
                <Checkbox fieldName="sunday"
                          onChange={(event) => this.handleDaysOfWeekSelect(event)}
                          checked={this.state.selectedDaysOfWeek.has(DaysOfWeek.SUNDAY)}
                          className="day-of-week"
                          value={DaysOfWeek.SUNDAY}
                          description={this.props.getMessageForKey("appointment.modal.form." + DaysOfWeek.SUNDAY)}
                          isValid={() => true}/>
            </div>
            <div className="col">
                <Checkbox fieldName="wednesday"
                          onChange={(event) => this.handleDaysOfWeekSelect(event)}
                          checked={this.state.selectedDaysOfWeek.has(DaysOfWeek.WEDNESDAY)}
                          className="day-of-week"
                          value={DaysOfWeek.WEDNESDAY}
                          description={this.props.getMessageForKey("appointment.modal.form." + DaysOfWeek.WEDNESDAY)}
                          isValid={() => true}/>
            </div>
            <div className="col">
                <Checkbox fieldName="thursday"
                          onChange={(event) => this.handleDaysOfWeekSelect(event)}
                          checked={this.state.selectedDaysOfWeek.has(DaysOfWeek.THURSDAY)}
                          className="day-of-week"
                          value={DaysOfWeek.THURSDAY}
                          description={this.props.getMessageForKey("appointment.modal.form." + DaysOfWeek.THURSDAY)}
                          isValid={() => true}/>
            </div>
            <div className="col">
                <Checkbox fieldName="friday"
                          onChange={(event) => this.handleDaysOfWeekSelect(event)}
                          checked={this.state.selectedDaysOfWeek.has(DaysOfWeek.FRIDAY)}
                          className="day-of-week"
                          value={DaysOfWeek.FRIDAY}
                          description={this.props.getMessageForKey("appointment.modal.form." + DaysOfWeek.FRIDAY)}
                          isValid={() => true}/>
            </div>
        </div>);
    }

    private handleDaysOfWeekSelect(event: FormEvent<HTMLInputElement>) {
        const daysOfWeekUpdated = new Set<string>(this.state.selectedDaysOfWeek);
        if (this.state.selectedDaysOfWeek.has(event.currentTarget.value)) {
            daysOfWeekUpdated.delete(event.currentTarget.value);
        } else {
            daysOfWeekUpdated.add(event.currentTarget.value);
        }
        this.setState({
            selectedDaysOfWeek: daysOfWeekUpdated,
            selectedDaysOfWeekValidationActivated: true,
        });
    }

    private renderCustomerLookup() {
        return isInRescheduleMode(this) || isInRebookMode(this) || this.state.disableCustomerChanging ? <></> :
            <div className="customer-lookup" onClick={() => this.onCustomerLookupClickHandler()}>
                <CustomerLookup image={process.env.PUBLIC_URL + "/statics/img/customer-lookup-blue.svg"}
                                message={this.props.getMessageForKey("appointment.modal.customer.lookup")}
                                alt="search"/>
            </div>;
    }

    private onGenericModalClose(): void {
        this.onClose();
        this.setState({
            showConfirmAppointmentModal: false,
        });
    }

    private refreshAppointmentMatrix(): void {
        if (this.props.getSelectedStore && this.props.selectedRoom && this.props.startDate) {
            const endDate = new Date(this.props.startDate.startRangeDate);
            endDate.setDate(this.props.startDate.startRangeDate.getDate() + this.props.startDate.range);
            this.props.fetchAppointments(this.props.getSelectedStore.id, this.props.selectedRoom.id,
                new Date(this.props.startDate.startRangeDate), endDate, this.props.tenant);
        }
    }

    private onGenericModalErrorHandler(): void {
        this.setState({
            showConfirmAppointmentModal: false,
            showModal: true,
        });
    }

    private onTimeslotNotAvailableErrorHandler(): void {
        if (this.state.appointmentDate && this.props.getSelectedStore && this.state.appointmentType) {
            const opticianId = this.state.optician === "0" ? null : this.state.optician;
            const roomId = this.state.diary === 0 || this.state.diary === undefined ? null : this.state.diary;
            this.props.dispatchFetchTimeslots(this.props.getSelectedStore.id, this.state.appointmentType, this.state.appointmentDate, this.props.tenant, String(roomId), opticianId);
            this.setState({startTime: null});
        }
        this.setState({
            showConfirmAppointmentModal: false,
            showModal: true,
        });
    }

    private customerRequiresUpdate(customer: ICustomerData) {
        return customer.email !== this.state.email
            || customer.phoneNumber !== this.state.phone
            || customer.lastName !== this.state.lastName
            || customer.firstName !== this.state.firstName
            || customer.gender !== this.state.gender
            || (customer.dateOfBirth === null && this.state.customerDateOfBirth !== null)
            || (customer.dateOfBirth !== null && !isSameDate(new Date(customer.dateOfBirth), this.state.customerDateOfBirth));
    }

    private resolveSubmitButtonText(): string {
        return isInRescheduleMode(this) ?
            this.props.getMessageForKey("appointment.modal.reschedule.button.save")
            : this.props.getMessageForKey("appointment.modal.new.button.save");
    }

    private resolveHeaderText() {
        if(isInRescheduleMode(this)) {
            return this.props.getMessageForKey("appointment.modal.reschedule.h2");
        }
        if (isInEditMode(this)) {
            return this.props.getMessageForKey("editCustomer.modal.h2");
        }
        return this.props.getMessageForKey("appointment.modal.new.h2");
    }

    private handleNotificationRequiredCheckbox() {
        this.setState({
            notificationRequired: !this.state.notificationRequired,
        });
    }

    private toggleOverrideAvailability() {
        if (!this.state.overrideAvailability) { // means it was false before and will be activated
            this.resetDiaryAndOpticianInNoPreferenceWasSelected();
        } else {
            this.setState({
                appointmentDateValidationActivated: false,
            });
        }
        this.setState({
            overrideStartDateTimeValidationActivated: this.state.overrideStartDateTime !== null,
            overrideEndDateTimeValidationActivated: this.state.overrideEndDateTime !== null,
            overrideAvailability: !this.state.overrideAvailability,
        });
    }

    private toggleRecurringMode() {
        if (!this.state.recurringMode) { // means it was false before and will be activated
            this.resetDiaryAndOpticianInNoPreferenceWasSelected();
        }
        this.setState({
            recurringStartDateValidationActivated: this.state.recurringAppointmentsStartDate !== null,
            recurringEndDateValidationActivated: this.state.recurringAppointmentsEndDate !== null,
            selectedDaysOfWeekValidationActivated: false,
            appointmentDateValidationActivated: false,
            recurringMode: !this.state.recurringMode,
        });
    }

    private resetDiaryAndOpticianInNoPreferenceWasSelected() {
        if (this.state.diary === 0) {
            this.setState({diary: null});
        }
        if (this.state.optician === "0") {
            this.setState({optician: null});
        }
    }

    private onCustomerLookupClickHandler() {
        this.setState({showCustomerSearchModal: true, showModal: false});
    }

    private renderCustomerSearchModal() {
        return this.state.showCustomerSearchModal ?
            <CustomerSearchModal tenant={this.props.tenant}
                                 backToAppointmentModal={() => this.backFromCustomerSearchModal()}
                                 selectCustomer={(customer: ICustomerData) => this.onCustomerSelectHandler(customer)}/> : null;
    }

    private backFromCustomerSearchModal() {
        this.setState({showCustomerSearchModal: false, showModal: true});
    }

    private onCustomerSelectHandler(selectedCustomer: ICustomerData) {
        this.selectedCustomer = selectedCustomer;
        this.setState({
            email: selectedCustomer.email,
            phone: selectedCustomer.phoneNumber,
            firstName: selectedCustomer.firstName,
            lastName: selectedCustomer.lastName,
            gender: selectedCustomer.gender,
            notificationLanguage: this.props.getSelectedLocale,
            notificationChannel: "email",
            customerDateOfBirth: selectedCustomer.dateOfBirth ? new Date(selectedCustomer.dateOfBirth) : null,
            showCustomerSearchModal: false,
            showModal: true,
        });
    }

    private isPhoneNumberMandatory() {
        return this.state.notificationChannel === "sms" && !this.state.overrideAvailability;
    }

    private isEmailAddressMandatory() {
        return this.state.notificationChannel === "email" && !this.state.overrideAvailability;
    }
}

const mapStateToProps = (store: any, ownProps: IProps): IDataProps => {
    return {
        closePopup: ownProps.closePopup,
        getAppointmentModalConfigForTenant: getFormConfig(store),
        getMessageForKey: getLocalizedText(store),
        getSelectedDate: getSelectedDate(store),
        getSelectedStore: getSelectedStore(store),
        getSelectedLocale: getSelectedLocale(store),
        getFallbackLocale: getFallbackLocale(store),
        getAppointmentTypes: getAppointmentTypes(store),
        getSelectedAppointmentType: getSelectedAppointmentType(store),
        getRoomsForAppointmentType: getRoomsForAppointmentType(store),
        getTimeslotsForDate: getTimeslotsForDay(store),
        opticiansForAppointmentType: getOpticians(store),
        lastCreatedOrUpdatedAppointment: getLastCreatedOrUpdatedAppointment(store),
        tenant: ownProps.tenant,
        mode: ownProps.mode,
        appointment: ownProps.appointment,
        getAvailableNotificationLanguage: getAvailableLocales(store),
        additionalFields: getAdditionalFields(store),
        additionalFieldsForAppTypeFromAppointment: ownProps.additionalFieldsForAppTypeFromAppointment,
        selectedCustomer: ownProps.selectedCustomer,
        getTimeslotsAvailabilityPerDays: getTimeslotsAvailabilityPerDays(store),
        selectedRoom: getSelectedRoom(store),
        startDate: getSelectedDate(store),
        refreshMatrixOnClose: ownProps.refreshMatrixOnClose,
        calendarLocale: getCalendarLocale(store),
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): IDispatchProps => {
    return {
        dispatchSelectAppointmentType: (appointmentTypeData) =>
            dispatch(selectAppointmentTypeAction(appointmentTypeData)),
        dispatchFetchOpticians: (storeId, appointmentTypeId, tenant) =>
            dispatch(fetchOpticians(storeId, appointmentTypeId, tenant)),
        dispatchCleanOpticians: () => dispatch(cleanOpticiansAction()),
        dispatchCleanTimeslots: () => dispatch(cleanTimeslotsAction()),
        dispatchFetchTimeslots: (storeId, appointmentTypeId, startDate, tenant, roomId, opticianId?) =>
            dispatch(fetchTimeslots(storeId, appointmentTypeId, startDate, tenant, roomId, opticianId)),
        createAppointmentWithCustomer: (appointmentAttributes, appointmentRelationships, customerAttributes, tenant) =>
            dispatch(createAppointmentWithCustomer(appointmentAttributes, appointmentRelationships, customerAttributes, tenant)),
        dispatchCleanLastCreatedOrUpdatedAppointment: () => dispatch(cleanLastCreatedUpdatedAppointmentAction()),
        updateCustomerAndRescheduleOrCreateAppointment: (appointmentAttributes, appointmentRelationships, updatedCustomer, tenant, customerId) =>
            dispatch(updateCustomerAndRescheduleOrCreateAppointment(appointmentAttributes, appointmentRelationships, updatedCustomer, tenant, customerId)),
        updateCustomer: (customerId, appointmentId, attr, tenant) => dispatch(updateCustomer(customerId, appointmentId, attr, tenant)),
        updateAppointment: (appointmentId, attr, tenant) => dispatch(updateAppointment(appointmentId, attr, tenant)),
        dispatchFetchAdditionalFields: (appointmentTypeId, tenantId) => dispatch(fetchAdditionalFieldsForAppointmentType(appointmentTypeId, tenantId)),
        createAppointmentWithExistingCustomer: (appointmentAttributes, appointmentRelationships, customerId, tenant) =>
            dispatch(createAppointmentWithExistingCustomer(appointmentAttributes, appointmentRelationships, customerId, tenant)),
        createRecurringAppointments: (appointmentSeriesAttributes, appointmentSeriesRelationships, tenant) =>
            dispatch(createRecurringAppointments(appointmentSeriesAttributes, appointmentSeriesRelationships, tenant)),
        dispatchFetchAvailableTimeslotsApproximation: (storeId, appointmentTypeId, startDate, tenant, roomId, opticianId?) =>
            dispatch(fetchAvailableTimeslotsApproximation(storeId, appointmentTypeId, startDate, tenant, roomId, opticianId)),
        fetchAppointments: (storeId, roomId, startTime, endTime, tenant) => fetchAppointments(storeId, roomId, startTime, endTime, tenant, dispatch),
        setPendingModal: () => dispatch(pendingModalAction()),
    };
};

export default connect<IDataProps, IDispatchProps, IProps>(
    mapStateToProps,
    mapDispatchToProps,
)(AppointmentModal);
