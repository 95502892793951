import {IOpticianData} from "../reducers/opticians.reducer";
import {IEmptyAction} from "./action.actions";

export const FETCH_OPTICIANS = "FETCH_OPTICIANS";
export const CLEAN_OPTICIANS = "CLEAN_OPTICIANS";

interface IFetchOpticians {
    type: typeof FETCH_OPTICIANS;
    payload: IOpticianData[];
}

interface ICleanOpticians {
    type: typeof CLEAN_OPTICIANS;
}

export type OpticianActionTypes = IFetchOpticians | ICleanOpticians | IEmptyAction;

export function fetchOpticiansAction(opticians: IOpticianData[]): IFetchOpticians {
    return {
        payload: opticians,
        type: FETCH_OPTICIANS,
    };
}

export function cleanOpticiansAction(): ICleanOpticians {
    return {
        type: CLEAN_OPTICIANS,
    };
}