import {IStoreData} from "../reducers/store.reducer";
import {IEmptyAction} from "./action.actions";

export const FETCH_STORES = "FETCH_STORES";
export const SELECT_STORE = "SELECT_STORE";

interface IFetchStoresAction {
    type: typeof FETCH_STORES;
    payload: IStoreData[];
}

interface ISelectStoreAction {
    type: typeof SELECT_STORE;
    payload: IStoreData;
}

export type StoreActionTypes = ISelectStoreAction | IFetchStoresAction | IEmptyAction;

export function fetchStoresAction(stores: IStoreData[]): IFetchStoresAction {
    return {
        payload: stores,
        type: FETCH_STORES,
    };
}

export function selectStoresAction(store: IStoreData): ISelectStoreAction {
    return {
        payload: store,
        type: SELECT_STORE,
};

}
