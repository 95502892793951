import { IAppointmentData, IAppointmentsPayload } from "../reducers/appointments.reducer";
import {IEmptyAction} from "./action.actions";

export const FETCH_APPOINTMENTS = "FETCH_APPOINTMENTS";
export const CLEAN_APPOINTMENTS = "CLEAN_APPOINTMENTS";
export const UPDATE_APPOINTMENT = "UPDATE_APPOINTMENT";
export const FETCH_LAST_CREATED_UPDATED_APPOINTMENT = "FETCH_LAST_CREATED_UPDATED_APPOINTMENT";
export const CLEAN_LAST_CREATED_UPDATED_APPOINTMENT = "CLEAN_LAST_CREATED_UPDATED_APPOINTMENT";
export const FETCH_SINGLE_APPOINTMENT = "FETCH_SINGLE_APPOINTMENT";
export const FETCH_APPOINTMENTS_FOR_CUSTOMER = "FETCH_APPOINTMENTS_FOR_CUSTOMER";
export const FETCH_MORE_APPOINTMENTS_FOR_URL = "FETCH_MORE_APPOINTMENTS_FOR_URL";
export const FETCH_APPOINTMENTS_FOR_URL = "FETCH_APPOINTMENTS_FOR_URL";

interface IFetchAppointments {
    type: typeof FETCH_APPOINTMENTS;
    payload: IAppointmentData[];
}

interface IUpdateAppointment {
    type: typeof UPDATE_APPOINTMENT;
    payload: IAppointmentData;
}

interface ICleanAppointments {
    type: typeof CLEAN_APPOINTMENTS;
}

interface IFetchLastCreatedUpdatedAppointment {
    type: typeof FETCH_LAST_CREATED_UPDATED_APPOINTMENT;
    payload: IAppointmentData;
}

interface IFetchSingleAppointment {
    type: typeof FETCH_SINGLE_APPOINTMENT;
    payload: IAppointmentData;
}

interface ICleanLastCreatedUpdatedAppointment {
    type: typeof CLEAN_LAST_CREATED_UPDATED_APPOINTMENT;
}

interface IFetchAppointmentsForCustomer {
    type: typeof FETCH_APPOINTMENTS_FOR_CUSTOMER;
    payload: IAppointmentsPayload;
}

interface IFetchMoreAppointmentsForUrl {
    type: typeof FETCH_MORE_APPOINTMENTS_FOR_URL;
    payload: IAppointmentsPayload;
}

interface IFetchAppointmentsForUrl {
    type: typeof FETCH_APPOINTMENTS_FOR_URL;
    payload: IAppointmentsPayload;
}

export type AppointmentActionTypes =
    IFetchAppointments
    | ICleanAppointments
    | IUpdateAppointment
    | IFetchLastCreatedUpdatedAppointment
    | ICleanLastCreatedUpdatedAppointment
    | IFetchSingleAppointment
    | IFetchAppointmentsForCustomer
    | IFetchMoreAppointmentsForUrl
    | IFetchAppointmentsForUrl
    | IEmptyAction;

export function fetchAppointmentsAction(appointments: IAppointmentData[]): IFetchAppointments {
    return {
        payload: appointments,
        type: FETCH_APPOINTMENTS,
    };
}

export function cleanAppointmentsAction(): ICleanAppointments {
    return {
        type: CLEAN_APPOINTMENTS,
    };
}

export function updateAppointmentAction(updatedAppointment: IAppointmentData): IUpdateAppointment {
    return {
        payload: updatedAppointment,
        type: UPDATE_APPOINTMENT,
    };
}

export function updateAppointmentWithCustomerAction(updatedAppointment: IAppointmentData): IUpdateAppointment {
    return {
        payload: updatedAppointment,
        type: UPDATE_APPOINTMENT,
    };
}

export function fetchLastCreatedUpdatedAppointmentAction(appointment: IAppointmentData): IFetchLastCreatedUpdatedAppointment {
    return {
        payload: appointment,
        type: FETCH_LAST_CREATED_UPDATED_APPOINTMENT,
    };
}

export function fetchSingleAppointmentAction(appointment: IAppointmentData): IFetchSingleAppointment {
    return {
        payload: appointment,
        type: FETCH_SINGLE_APPOINTMENT,
    };
}

export function cleanLastCreatedUpdatedAppointmentAction(): ICleanLastCreatedUpdatedAppointment {
    return {
        type: CLEAN_LAST_CREATED_UPDATED_APPOINTMENT,
    };
}

export function fetchMoreAppointmentsForUrlAction(appointmentsPayload: IAppointmentsPayload): IFetchMoreAppointmentsForUrl {
    return {
        payload: appointmentsPayload,
        type: FETCH_MORE_APPOINTMENTS_FOR_URL,
    };
}

export function fetchAppointmentsForUrlAction(appointmentsPayload: IAppointmentsPayload): IFetchAppointmentsForUrl {
    return {
        payload: appointmentsPayload,
        type: FETCH_APPOINTMENTS_FOR_URL,
    };
}
