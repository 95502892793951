import { ApplicationErrorData } from './../reducers/applicationError.reducer';

export const SET_APPLICATION_ERROR = "SET_APPLICATION_ERROR";
export const CLEAR_APPLICATION_ERRORS = "CLEAR_APPLICATION_ERRORS";

export interface ApplicationErrorAction {
    type: typeof SET_APPLICATION_ERROR;
    payload: ApplicationErrorData;
}

export function SetApplicationErrorAction(errorData: ApplicationErrorData): ApplicationErrorAction {
    return {
        type: SET_APPLICATION_ERROR,
        payload: errorData
    };
}