import React from "react";
import {getSelectedDate, ISelectedDateAndRange} from "../../reducers/selecteddate.reducer";
import {getSelectedGridStyle, GridStyle} from "../../reducers/gridstyle.reducer";
import {getSelectedStore, IStoreData} from "../../reducers/store.reducer";
import {getSelectedRoom, IRoomData} from "../../reducers/room.reducer";
import {getCalendarLocaleFormat, getLocalizedText, getSelectedLocale} from "../../reducers/locale.reducer";
import {Dispatch} from "redux";
import {
    changeDateRangeAction,
    selectedDateChangeAction,
    selectWeekChangeAction,
    setAdjustSidebarToDatepickerFlagAction,
} from "../../actions/selecteddate.action";
import {fetchAppointments} from "../../thunks/appointments.thunk";
import {fetchClosingHours} from "../../thunks/closinghours.thunk";
import {addDays} from "date-fns";

export interface IDateSelectorBaseProps {
    tenant: string;
    onGridStyleSelect?: (store: GridStyle) => void;
}

export interface IDateSelectorBaseConnectProps {
    selectedDate: ISelectedDateAndRange;
    selectedLocale: string | undefined;
    getMessageForKey: (key: string) => string;
    selectedGridStyle: GridStyle | undefined;
    selectedStore: IStoreData | undefined;
    selectedRoom: IRoomData | undefined;
    calendarLocaleFormat: string | undefined;
}

export interface IDateSelectorBaseDispatchFromProps {
    selectCurrentWeekDate: (date: ISelectedDateAndRange) => void;
    changeSelectedDate: (date: ISelectedDateAndRange) => void;
    changeDateRange: (date: ISelectedDateAndRange) => void;
    fetchAppointments: (storeId: string, roomId: number, startTime: Date, endTime: Date, tenant: string) => void;
    fetchClosingHours: (storeId: string, tenant: string, startDate: Date, requestedNumberOfDays: number) => void;
    setAdjustSidebarFlag: (flagVal: boolean) => void;
}

export interface IDateSelectorBaseDataProps extends IDateSelectorBaseProps, IDateSelectorBaseConnectProps {
}

export interface IDateSelectorBaseResultProps extends IDateSelectorBaseDataProps, IDateSelectorBaseDispatchFromProps {
}

export class DateSelectorBaseComponent extends React.Component<IDateSelectorBaseResultProps> {

    protected refreshData(selectedDateAndRange: ISelectedDateAndRange): void {
        this.fetchAppointments(selectedDateAndRange);
        this.fetchClosingHours(selectedDateAndRange);
    }

    protected fetchClosingHours(selectedDateAndRange: ISelectedDateAndRange): void {
        if (this.props.selectedStore) {
            this.props.fetchClosingHours(this.props.selectedStore.id, this.props.tenant, selectedDateAndRange.startRangeDate, selectedDateAndRange.range);
        }
    }

    private fetchAppointments(selectedDateAndRange: ISelectedDateAndRange): void {
        if (this.props.selectedStore && this.props.selectedRoom && this.props.selectedDate) {
            const endDate = addDays(selectedDateAndRange.startRangeDate, selectedDateAndRange.range);
            this.props.fetchAppointments(this.props.selectedStore.id, this.props.selectedRoom.id,
                selectedDateAndRange.startRangeDate, endDate, this.props.tenant);
        }
    }
}

export const dateSelectorBaseMapStateToProps = (store: any, ownProps: IDateSelectorBaseProps): IDateSelectorBaseDataProps => {
    return {
        getMessageForKey: getLocalizedText(store),
        selectedDate: getSelectedDate(store),
        onGridStyleSelect: ownProps.onGridStyleSelect,
        selectedLocale: getSelectedLocale(store),
        selectedGridStyle: getSelectedGridStyle(store),
        selectedStore: getSelectedStore(store),
        selectedRoom: getSelectedRoom(store),
        tenant: ownProps.tenant,
        calendarLocaleFormat: getCalendarLocaleFormat(store),
    };
};

export const dateSelectorMapDispatchToProps = (dispatch: Dispatch): IDateSelectorBaseDispatchFromProps => {
    return {
        changeSelectedDate: ((date) => dispatch(selectedDateChangeAction(date))),
        selectCurrentWeekDate: ((date) => dispatch(selectWeekChangeAction(date.startRangeDate))),
        changeDateRange: ((date) => dispatch(changeDateRangeAction(date))),
        fetchAppointments: ((storeId, roomId, startTime, endTime, tenant) => fetchAppointments(storeId, roomId, startTime, endTime, tenant, dispatch)),
        fetchClosingHours: ((storeId, tenant, startDate, requestedNumberOfDays) => fetchClosingHours(storeId, tenant, startDate, requestedNumberOfDays, dispatch)),
        setAdjustSidebarFlag: (flagVal) => dispatch(setAdjustSidebarToDatepickerFlagAction(flagVal)),
    };
};
