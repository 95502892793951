import React, {CSSProperties} from "react";
import {connect} from "react-redux";
import {IOpeningHoursData} from "../../../reducers/closinghours.reducer";
import {getSelectedLocale} from "../../../reducers/locale.reducer";
import "./ClosingHours.scss";

interface IProps {
    closingHoursData: IOpeningHoursData;
    style: CSSProperties;
}

interface IConnectProps {
    selectedLocale?: string;
}

interface IResultProps extends IProps, IConnectProps {}

export class ClosingHoursComponent extends React.Component<IResultProps> {

    private static getClassName(openingHoursData: IOpeningHoursData) {
        return "closinghours ".concat(openingHoursData ? openingHoursData.type.toLowerCase() : "");
    }

    public render(): React.ReactNode {
        return (
            <div className={ClosingHoursComponent.getClassName(this.props.closingHoursData)} style={this.props.style}>
                <div className="closinghours-content" style={{height: this.props.style.height}}>
                    <div className="closinghours-name">
                        {this.props.selectedLocale ? this.props.closingHoursData.name[this.props.selectedLocale] : ""}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store: any, ownProps: IProps): IResultProps => {
    return {
        closingHoursData: ownProps.closingHoursData,
        selectedLocale: getSelectedLocale(store),
        style: ownProps.style,
    };
};

export default connect<IResultProps, void, IProps>(
    mapStateToProps,
)(ClosingHoursComponent);
