export const APPOINTMENT_BOOKED_MODAL = "APPOINTMENT_BOOKED_MODAL";
export const BOOK_APPOINTMENT_MAX_RETRIES_REACHED_MODAL = "BOOK_APPOINTMENT_MAX_RETRIES_REACHED_MODAL";
export const PENDING_MODAL = "PENDING_MODAL";
export const BOOK_APPOINTMENTS_SERIES_BOOKED_MODAL = "BOOK_APPOINTMENTS_SERIES_BOOKED_MODAL";
export const BOOK_APPOINTMENTS_SERIES_ERROR_MODAL = "BOOK_APPOINTMENTS_SERIES_ERROR_MODAL";
export const ERROR_MODAL = "ERROR_MODAL";
export const TIMESLOT_NOT_AVAILABLE_MODAL = "TIMESLOT_NOT_AVAILABLE_MODAL";
export const INCREASE_LOADING_DATA_COUNTER = "INCREASE_LOADING_DATA_COUNTER";
export const DECREASE_LOADING_DATA_COUNTER = "DECREASE_LOADING_DATA_COUNTER";

interface IAppointmentBookedModal {
    type: typeof APPOINTMENT_BOOKED_MODAL;
}

interface IBookAppointmentMaxRetriesReachedModal {
    type: typeof BOOK_APPOINTMENT_MAX_RETRIES_REACHED_MODAL;
}

interface IBookAppointmentsSeriesBookedModal {
    type: typeof BOOK_APPOINTMENTS_SERIES_BOOKED_MODAL;
}

interface IPendingModal {
    type: typeof PENDING_MODAL;
}

interface IBookAppointmentSeriesErrorModal {
    type: typeof BOOK_APPOINTMENTS_SERIES_ERROR_MODAL;
}

interface IErrorModal {
    type: typeof ERROR_MODAL;
}

interface ITimeslotNotAvailableModal {
    type: typeof TIMESLOT_NOT_AVAILABLE_MODAL;
}

interface IIncreaseLoadingDataCounter {
    type: typeof INCREASE_LOADING_DATA_COUNTER;
}

interface IDecreaseLoadingDataCounter {
    type: typeof DECREASE_LOADING_DATA_COUNTER;
}

export type GenericModalActionTypes =
    IAppointmentBookedModal |
    IBookAppointmentsSeriesBookedModal |
    IPendingModal |
    IErrorModal |
    IBookAppointmentMaxRetriesReachedModal |
    IBookAppointmentSeriesErrorModal |
    ITimeslotNotAvailableModal |
    IIncreaseLoadingDataCounter |
    IDecreaseLoadingDataCounter;

export function appointmentBookedModalAction(): IAppointmentBookedModal {
    return {
        type: APPOINTMENT_BOOKED_MODAL,
    };
}

export function bookAppointmentMaxRetriesReachedModalAction(): IBookAppointmentMaxRetriesReachedModal {
    return {
        type: BOOK_APPOINTMENT_MAX_RETRIES_REACHED_MODAL,
    };
}

export function pendingModalAction(): IPendingModal {
    return {
        type: PENDING_MODAL,
    };
}

export function bookAppointmentSeriesBookedModalAction(): IBookAppointmentsSeriesBookedModal {
    return {
        type: BOOK_APPOINTMENTS_SERIES_BOOKED_MODAL,
    };
}

export function bookAppointmentSeriesErrorModalAction(): IBookAppointmentSeriesErrorModal {
    return {
        type: BOOK_APPOINTMENTS_SERIES_ERROR_MODAL,
    };
}

export function errorModalAction(): IErrorModal {
    return {
        type: ERROR_MODAL,
    };
}

export function timeslotNotAvailableModalAction(): ITimeslotNotAvailableModal {
    return {
        type: TIMESLOT_NOT_AVAILABLE_MODAL,
    };
}

export function increaseLoadingDataCounterAction(): IIncreaseLoadingDataCounter {
    return {
        type: INCREASE_LOADING_DATA_COUNTER,
    };
}

export function decreaseLoadingDataCounterAction(): IDecreaseLoadingDataCounter {
    return {
        type: DECREASE_LOADING_DATA_COUNTER,
    };
}
