export function findMonday(date: Date): Date {
    const monday = new Date(date);
    const minusDays = (monday.getDay() === 0 ? 7 : monday.getDay()) - 1; // sunday is 0
    monday.setDate(monday.getDate() - minusDays);
    return monday;
}

export function incrementDateAndResetTime(date: Date): Date {
    const incrementedDate: Date = incrementDate(date);
    incrementedDate.setHours(0, 0, 0, 0);
    return incrementedDate;
}

export function incrementDate(date: Date): Date {
    const localDate = new Date(date);
    localDate.setDate(date.getDate() + 1);
    return localDate;
}

export function isSameDate(date: Date, anotherDate: Date | null): boolean {
    let result = false;
    if (anotherDate !== null) {
        if (date.getDate() === anotherDate.getDate()
            && date.getMonth() === anotherDate.getMonth()
            && date.getFullYear() === anotherDate.getFullYear()) {
            result = true;
        }
    }
    return result;
}

/**
 * When the datepicker returns only a date, it tends to do that in UTC...
 * UPDATE: The datepicker is completely random when it comes to timezones,
 * this fixes things for now
 * DO NOT USE when datepicker is used in conjunction with a time
 * See https://github.com/Hacker0x01/react-datepicker/issues/1018
 *
 * @param date The date object to fix (if null is passed, null is returned)
 */
export function fixDatePickerDate(date: Date | null): Date | null {
    if (date != null) {
        date.setHours(0);
        return date;
    }
    return null;
}

/**
 * This function creates an ISO 8601 formatted date string. This differs from the
 * built-in function toISOString(), since this function returns the datetime in
 * the local time, instead of zulu time. Example: 2020-10-12T14:25:04
 *
 * @param date A date that should be formatted in a local string
 */
export function toLocalIsoDate(d: Date): string {
    // Trim the excess milliseconds and zulu time indicator: .000Z
    return new Date((d.getTime() - d.getTimezoneOffset() * 60000)).toISOString().slice(0, -5);
}