import {Dispatch} from "redux";
import {fetchCalendarLocale} from "../../actions/locale.actions";

const localeMap: Map<string, string> = new Map([
    ["bg", "bg"],
    ["cs_CZ", "cs"],
    ["de_DE", "de"],
    ["el_GR", "el"],
    ["en", "en-GB"],
    ["es_CL", "es"],
    ["es_CO", "es"],
    ["es_ES", "es"],
    ["es_MX", "es"],
    ["es_PE", "es"],
    ["es_UY", "es"],
    ["hu_HU", "hu"],
    ["it_IT", "it"],
    ["pt_BR", "pt-BR"],
    ["pt_PT", "pt"],
]);

export function initializeCalendarLocale(locale: string, dispatch: Dispatch) {
    let resolvedLocale = localeMap.get(locale);
    resolvedLocale = resolvedLocale === undefined ? locale : resolvedLocale;
    import("date-fns/locale/" + resolvedLocale).then((result) => {
        const calendarLocale = result;
        setWeekStart(calendarLocale);
        dispatch(fetchCalendarLocale(calendarLocale, resolvedLocale));
    });
}

function setWeekStart(locale: Locale) {
    if (locale.options) {
        locale.options.weekStartsOn = 1;
    }
}
