import {IAppointmentTypeData} from "../reducers/appointmentTypes.reducer";
import {IEmptyAction} from "./action.actions";

export const SELECT_APPOINTMENT_TYPE = "SELECT_APPOINTMENT_TYPE";
export const FETCH_APPOINTMENT_TYPES = "FETCH_APPOINTMENT_TYPES";

interface ISelectAppointmentTypeAction {
    type: typeof SELECT_APPOINTMENT_TYPE;
    payload: IAppointmentTypeData | undefined;
}

interface IFetchAppointmentTypesAction {
    type: typeof FETCH_APPOINTMENT_TYPES;
    payload: IAppointmentTypeData[];
}

export type AppointmentTypeActionTypes = ISelectAppointmentTypeAction | IFetchAppointmentTypesAction | IEmptyAction;

export function selectAppointmentTypeAction(appointmentType: IAppointmentTypeData | undefined): ISelectAppointmentTypeAction {
    return {
        payload: appointmentType,
        type: SELECT_APPOINTMENT_TYPE,
    };
}

export function fetchAppointmentTypesAction(appointmentTypes: IAppointmentTypeData[]): IFetchAppointmentTypesAction {
    return {
        payload: appointmentTypes,
        type: FETCH_APPOINTMENT_TYPES,
    };
}
