import React, {ReactNode} from "react";
import {Button} from "react-bootstrap";
import {connect} from "react-redux";
import {getConfirmAppointmentModalConfig, IConfirmAppointmentModalConfig} from "../reducers/config.reducer";
import {GenericModalMode, getModalMode} from "../reducers/generic.modal.reducer";
import {getLocalizedText} from "../reducers/locale.reducer";
import "./GenericModal.scss";

interface IState {
    showSpinner: boolean;
    title: string;
    descriptionLine1: string;
    descriptionLine2: string;
    image: string;
}

interface IProps {
    tenant: string;
    closeModal: () => void;
    handleErrorModal: () => void;
    handleTimeslotNotAvailableErrorModal?: () => void;
}

interface IConnectProps {
    getConfigForConfirmAppointmentModal: IConfirmAppointmentModalConfig;
    getMessageForKey: (key: string) => string;
    modalMode: GenericModalMode | undefined;
}

interface IResultProps extends IProps, IConnectProps {
}

export class GenericModal extends React.Component<IResultProps, IState> {

    private previousModalMode: GenericModalMode | undefined;

    constructor(props: Readonly<IResultProps>) {
        super(props);
        this.state = {
            descriptionLine1: "",
            descriptionLine2: "",
            image: "",
            showSpinner: true,
            title: "",
        };
        this.previousModalMode = undefined;
    }

    public render(): React.ReactNode {
        return <div className="modal_container">
            <div className="background_greyed">
                <div className="confirm-modal">
                    {this.renderClosingIcon()}
                    <fieldset className={this.props.modalMode === GenericModalMode.LOADING_DATA ? "no-border" : ""}>
                        <legend>{this.renderLegendTitle()}</legend>
                        {this.state.showSpinner ? this.renderSpinner() : this.renderContent()}
                    </fieldset>
                    {this.renderClosingButton()}
                </div>
            </div>
        </div>;
    }

    public componentDidUpdate(): void {
        this.refreshModalState();
    }

    private renderLegendTitle(): string {
        return this.props.modalMode === GenericModalMode.LOADING_DATA ? "" : this.props.getMessageForKey("confirmAppointment.name");
    }

    private renderContent(): ReactNode {
        return <>
            <div className="row header">
                <div className="col-sm-4">
                    <img src={this.state.image} alt=""/>
                </div>
                <div className="col-sm-8">
                    <h3>{this.state.title}</h3>
                </div>
            </div>
            <div className="row">
                <ul>
                    {this.state.descriptionLine1 !== "" ? <li>{this.state.descriptionLine1}</li> : null}
                    {this.state.descriptionLine2 !== "" ? <li>{this.state.descriptionLine2}</li> : null}
                </ul>
            </div>
        </>;
    }

    private renderSpinner(): ReactNode {
        return (
            <div>
                <div className="loader"/>
                <p>{this.state.title}</p>
            </div>);
    }

    private renderClosingIcon(): ReactNode {
        return this.state.showSpinner ? null :
            <button type="button" className="close" aria-label="Close"
                    onClick={() => this.props.closeModal()}>
                <span aria-hidden="true">&times;</span>
            </button>;
    }

    private renderClosingButton(): ReactNode {
        return this.state.showSpinner ? null :
            <div className="close_button">
                <Button
                    onClick={this.resolveSubmitButtonAction()}>
                    <span>{this.resolveSubmitButtonLabel()}
                    </span>
                </Button>
            </div>;
    }

    private resolveSubmitButtonLabel(): string {
        if (this.isModalInErrorMode()) {
            return this.props.getMessageForKey("confirmAppointment.close.button.label.try.again");
        } else if (this.isTimeslotNotAvailableError()) {
            return this.props.getMessageForKey("confirmAppointment.close.button.label.select.new.timeslot");
        } else {
            return this.props.getMessageForKey("confirmAppointment.close.button.label.close.window");
        }
    }

    private resolveSubmitButtonAction(): () => void {
        if (this.isModalInErrorMode()) {
            return this.props.handleErrorModal;
        } else if (this.isTimeslotNotAvailableError() && this.props.handleTimeslotNotAvailableErrorModal) {
            return this.props.handleTimeslotNotAvailableErrorModal;
        } else {
            return this.props.closeModal;
        }
    }

    private isTimeslotNotAvailableError(): boolean {
        return this.props.modalMode === GenericModalMode.TIMESLOT_NOT_AVAILABLE;
    }

    private isModalInErrorMode(): boolean {
        return this.props.modalMode === GenericModalMode.GENERAL_ERROR ||
            this.props.modalMode === GenericModalMode.SERIES_ERROR;
    }

    private refreshModalState() {
        if (this.previousModalMode !== this.props.modalMode) {
            this.previousModalMode = this.props.modalMode;
            switch (this.props.modalMode) {
                case GenericModalMode.PENDING:
                    this.handlePendingModalMode();
                    break;
                case GenericModalMode.BOOKED:
                    this.handleBookedModalMode();
                    break;
                case GenericModalMode.MAX_RETRIES_REACHED:
                    this.handleMaxRetriesReachedModalMode();
                    break;
                case GenericModalMode.GENERAL_ERROR:
                    this.handleGeneralErrorModalMode();
                    break;
                case GenericModalMode.SERIES_BOOKED:
                    this.handleSeriesBookedMode();
                    break;
                case GenericModalMode.SERIES_ERROR:
                    this.handleSeriesErrorMode();
                    break;
                case GenericModalMode.TIMESLOT_NOT_AVAILABLE:
                    this.handleTimeslotNotAvailableMode();
                    break;
                case GenericModalMode.LOADING_DATA:
                    this.handleLoadingDataModalMode();
                    break;
            }
        }
    }

    private setModalState(titleKeySuffix: string, desc1KeySuffix: string, desc2KeySuffix: string, showSpinner: boolean, img: string) {
        this.setState({
            descriptionLine1: desc1KeySuffix === "" ? "" : this.props.getMessageForKey("confirmAppointment.description." + desc1KeySuffix),
            descriptionLine2: desc2KeySuffix === "" ? "" : this.props.getMessageForKey("confirmAppointment.description." + desc2KeySuffix),
            image: img === "" ? "" : process.env.PUBLIC_URL + img,
            showSpinner,
            title: titleKeySuffix === "" ? "" : this.props.getMessageForKey("confirmAppointment.title." + titleKeySuffix),
        });
    }

    private handlePendingModalMode() {
        this.setModalState("pending.initial", "", "", true, "");
    }

    private handleLoadingDataModalMode() {
        this.setModalState("", "", "", true, "");
    }

    private handleMaxRetriesReachedModalMode() {
        this.setModalState("pending.longer", "pending.longer.line1", "pending.longer.line2", false, "/statics/img/warning.svg");
    }

    private handleBookedModalMode() {
        this.setModalState("booked", "booked.line1", "booked.line2", false, "/statics/img/checkmark.svg");
    }

    private handleGeneralErrorModalMode() {
        this.setModalState("error", "error.line1", "error.line2", false, "/statics/img/exclamation_mark.svg");
    }

    private handleSeriesBookedMode() {
        this.setModalState("series.booked", "", "", false, "/statics/img/checkmark.svg");
    }

    private handleSeriesErrorMode() {
        this.setModalState("series.error", "", "", false, "/statics/img/exclamation_mark.svg");
    }

    private handleTimeslotNotAvailableMode() {
        this.setModalState("timeslot.not.available", "timeslot.not.available.line1", "timeslot.not.available.line2", false, "/statics/img/clock.svg");
    }

}

const mapStateToProps = (store: any, ownProps: IProps): IResultProps => {
    return {
        closeModal: ownProps.closeModal,
        getConfigForConfirmAppointmentModal: getConfirmAppointmentModalConfig(store),
        getMessageForKey: getLocalizedText(store),
        handleErrorModal: ownProps.handleErrorModal,
        handleTimeslotNotAvailableErrorModal: ownProps.handleTimeslotNotAvailableErrorModal,
        modalMode: getModalMode(store),
        tenant: ownProps.tenant,
    };
};

export default connect<IResultProps, void, IProps>(
    mapStateToProps,
)(GenericModal);
