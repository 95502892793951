import {IEmptyAction} from "./action.actions";
import {IArrayStringIndex} from "../../@types/types";

export const SELECT_LOCALE = "SELECT_LOCALE";
export const FETCH_MESSAGES = "FETCH_MESSAGES";
export const FETCH_FALLBACK_MESSAGES = "FETCH_FALLBACK_MESSAGES";
export const FETCH_AVAILABLE_LOCALE = "FETCH_AVAILABLE_LOCALE";
export const FETCH_FALLBACK_LOCALE = "FETCH_FALLBACK_LOCALE";

export const FETCH_CALENDAR_LOCALE = "FETCH_CALENDAR_LOCALE";


interface ISelectLocaleAction {
    type: typeof SELECT_LOCALE;
    payload: string;
}

interface IFetchMessages {
    type: typeof FETCH_MESSAGES;
    payload: IArrayStringIndex;
}

interface IFetchFallbackMessages {
    type: typeof FETCH_FALLBACK_MESSAGES;
    payload: IArrayStringIndex;
}

interface IFetchFallbackLocale {
    type: typeof FETCH_FALLBACK_LOCALE;
    payload: string;
}

interface IFetchAvailableLocalesAction {
    type: typeof FETCH_AVAILABLE_LOCALE;
    payload: string[];
}

interface IFetchCalendarLocale {
    type: typeof FETCH_CALENDAR_LOCALE;
    payload: { calendarLocale: Locale, calendarLocaleFormat: string | undefined };
}

export type LocaleActionTypes =
    ISelectLocaleAction
    | IFetchMessages
    | IFetchFallbackMessages
    | IFetchFallbackLocale
    | IFetchAvailableLocalesAction
    | IFetchCalendarLocale
    | IEmptyAction ;

export function selectLocale(locale: string): ISelectLocaleAction {
    return {
        payload: locale,
        type: SELECT_LOCALE,
    };
}

export function fetchMessages(messages: IArrayStringIndex): IFetchMessages {
    return {
        payload: messages,
        type: FETCH_MESSAGES,
    };
}

export function fetchFallbackMessages(messages: IArrayStringIndex): IFetchFallbackMessages {
    return {
        payload: messages,
        type: FETCH_FALLBACK_MESSAGES,
    };
}

export function fetchFallbackLocale(locale: string): IFetchFallbackLocale {
    return {
        payload: locale,
        type: FETCH_FALLBACK_LOCALE,
    };
}

export function fetchAvailableLocales(locales: string[]): IFetchAvailableLocalesAction {
    return {
        payload: locales,
        type: FETCH_AVAILABLE_LOCALE,
    };
}

export function fetchCalendarLocale(calendarLocale: Locale, calendarLocaleFormat: string | undefined): IFetchCalendarLocale {
    return {
        payload: {calendarLocale, calendarLocaleFormat},
        type: FETCH_CALENDAR_LOCALE,
    };
}