import {createSelector} from "reselect";
import {
    CLEAN_TIMESLOTS,
    FETCH_TIMESLOTS,
    FETCH_TIMESLOTS_AVAILABILITY,
    TimeslotsActionsTypes
} from "../actions/timeslots.actions";
import {isSameDate} from "../util/date.util";

export interface ITimeslotDataForDay {
    date: Date;
    timeslots: ITimeslotData[];
}

export interface ITimeslotAvailabilityPerDay {
    date: Date;
    percentageAvailability: number;
}

export interface ITimeslotData {
    id: number;
    startTime: Date;
    endTime: Date;
}

export interface ITimeslotsReducer {
    timeslotsForDays: ITimeslotDataForDay[];
    timeslotAvailabilityForDays: ITimeslotAvailabilityPerDay[];
}

const initialState: ITimeslotsReducer = {
    timeslotsForDays: [],
    timeslotAvailabilityForDays: [],
};

export default function timeslotsReducer(state = initialState, action: TimeslotsActionsTypes): ITimeslotsReducer {
    switch (action.type) {
        case FETCH_TIMESLOTS:
            return {
                ...state,
                timeslotsForDays: action.payload,
            };
        case CLEAN_TIMESLOTS:
            return {
                ...state,
                timeslotsForDays: [],
            };
        case FETCH_TIMESLOTS_AVAILABILITY:
            return {
                ...state,
                timeslotAvailabilityForDays: action.payload,
            };
        default:
            return state;

    }
}

const getTimeslotsState = (state: any) => state.timeslots;

export const getTimeslotsAvailabilityPerDays = createSelector(getTimeslotsState,
    (state: ITimeslotsReducer) => state.timeslotAvailabilityForDays);

export const getTimeslotsForDay = createSelector(getTimeslotsState, (state: ITimeslotsReducer) => {
    return (date: Date): ITimeslotData[] => {
        let timeslots: ITimeslotData[] = [];
        state.timeslotsForDays.forEach((timeslotsForDay) => {
            if (isSameDate(timeslotsForDay.date, date)) {
                timeslots = timeslotsForDay.timeslots;
            }
        });
        return timeslots;
    };
});