import axios from "axios";

export const getMessages = (tenant: string, locale: string) => {
    return axios.get(`/statics/translations/messages-${tenant}-${locale}.json`)
        .then((response) => response.data);
};

export const getLocaleConfig = (tenant: string) => {
    return axios.get(`/statics/config/localeConfig-${tenant}.json`)
        .then((response) => response.data);
};
