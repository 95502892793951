export const TOGGLE_PAGINATION_BUTTONS = "TOGGLE_PAGINATION_BUTTONS";
export const INCREASE_CURRENT_PAGE = "INCREASE_CURRENT_PAGE";
export const DECREASE_CURRENT_PAGE = "DECREASE_CURRENT_PAGE";
export const CALCULATE_NUMBER_OF_PAGES_AND_RESET_CURRENT_PAGE = "CALCULATE_NUMBER_OF_PAGES_AND_RESET_CURRENT_PAGE";

interface ITogglePaginationButtons {
    type: typeof TOGGLE_PAGINATION_BUTTONS;
}

interface IIncreaseCurrentPage {
    type: typeof INCREASE_CURRENT_PAGE;
}

interface IDecreaseCurrentPage {
    type: typeof DECREASE_CURRENT_PAGE;
}

interface ICalculateNumberOfPagesAndResetCurrentPage {
    payload: number;
    type: typeof CALCULATE_NUMBER_OF_PAGES_AND_RESET_CURRENT_PAGE;
}

export type PaginationActionTypes = ITogglePaginationButtons | IIncreaseCurrentPage | IDecreaseCurrentPage | ICalculateNumberOfPagesAndResetCurrentPage;

export function togglePaginationButtonsAction(): ITogglePaginationButtons {
    return {
        type: TOGGLE_PAGINATION_BUTTONS,
    };
}

export function increaseCurrentPageAction(): IIncreaseCurrentPage {
    return {
        type: INCREASE_CURRENT_PAGE,
    };
}

export function decreaseCurrentPageAction(): IDecreaseCurrentPage {
    return {
        type: DECREASE_CURRENT_PAGE,
    };
}

export function calculateNumberOfPagesAndResetCurrentPageAction(payload: number): ICalculateNumberOfPagesAndResetCurrentPage {
    return {
        payload,
        type: CALCULATE_NUMBER_OF_PAGES_AND_RESET_CURRENT_PAGE,
    };
}
