import {getConfigPerEnv, preparePatchPostRequest, preparePatchPostWithRelationsRequest} from "../config/requestConfig";
import {getPagingAxiosInstance, getStandardAxiosInstance} from "./axios.config";

const pageLimit = 100;

export const getAppointmentsInWeek = (storeId: string, roomId: number, startTime: Date, endTime: Date, tenant: string) => {
    return getPagingAxiosInstance().get(`/appointments?filter%5Bstore.id%5D=${storeId}&filter%5Broom.id%5D=${roomId}&filter%5BstartTime%5D%5Bge%5D=${setHourMinuteSecondToZero(startTime).toISOString()}&filter%5BstartTime%5D%5Blt%5D=${setHourMinuteSecondToZero(endTime).toISOString()}&page%5Blimit%5D=${pageLimit}&include=customer,appointmentType,room,appointmentsSeries,optician&sort=startTime`,
        getConfigPerEnv(tenant))
        .then((response) => response.data);
};

export const getAppointmentsCrossWeek = (storeId: string, roomId: number, startTime: Date, endTime: Date, tenant: string) => {
    return getPagingAxiosInstance().get(`/appointments?filter%5Bstore.id%5D=${storeId}&filter%5Broom.id%5D=${roomId}&filter%5BendTime%5D%5Bgt%5D=${setHourMinuteSecondToZero(startTime).toISOString()}&filter%5BendTime%5D%5Ble%5D=${setHourMinuteSecondToZero(endTime).toISOString()}&filter%5BstartTime%5D%5Blt%5D=${setHourMinuteSecondToZero(startTime).toISOString()}&page%5Blimit%5D=${pageLimit}&include=customer,appointmentType,room,appointmentsSeries,optician&sort=startTime`,
        getConfigPerEnv(tenant))
        .then((response) => response.data);
};

export const getAppointmentsOverWeek = (storeId: string, roomId: number, startTime: Date, endTime: Date, tenant: string) => {
    return getPagingAxiosInstance().get(`/appointments?filter%5Bstore.id%5D=${storeId}&filter%5Broom.id%5D=${roomId}&filter%5BstartTime%5D%5Blt%5D=${setHourMinuteSecondToZero(startTime).toISOString()}&filter%5BendTime%5D%5Bgt%5D=${setHourMinuteSecondToZero(endTime).toISOString()}&page%5Blimit%5D=${pageLimit}&include=customer,appointmentType,room,appointmentsSeries,optician&sort=startTime`,
        getConfigPerEnv(tenant))
        .then((response) => response.data);
};

export const getAppointmentsForUser = (userId: string, tenant: string, pageLimitForCustomer: number) => {
    return getStandardAxiosInstance().get(`/appointments?filter%5Bcustomer.id%5D=${userId}&page%5Blimit%5D=${pageLimitForCustomer}&include=customer,appointmentType,room,appointmentsSeries,optician,store`,
        getConfigPerEnv(tenant))
        .then((response) => response.data);
};

export const getAppointmentsForUrl = (url: string, tenant: string) => {
    return getPagingAxiosInstance().get(url, getConfigPerEnv(tenant))
        .then((response) => response.data);
};

export const patchAppointment = (appointmentId: string, tenant: string, attributes: any) => {
    return getPagingAxiosInstance().patch(`/appointments/${appointmentId}?include=customer,appointmentType,room,appointmentsSeries`,
        preparePatchPostRequest("appointments", attributes), getConfigPerEnv(tenant))
        .then((response) => response.data);
};

export const createAppointment = (attributes: any, relationships: any, tenant: string) => {
    return getPagingAxiosInstance().post(`/appointments`,
        preparePatchPostWithRelationsRequest("appointments", attributes, relationships), getConfigPerEnv(tenant))
        .then((response) => response.data);
};

export const getSingleAppointment = (appointmentId: string, tenant: string) => {
    return getPagingAxiosInstance().get(`/appointments/${appointmentId}?include=customer,appointmentType,room,appointmentsSeries`, getConfigPerEnv(tenant))
        .then((response) => response.data);
};

const setHourMinuteSecondToZero = (date: Date): Date => {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));
};
