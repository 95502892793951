import {Dispatch} from "redux";
import {ITimeslotAvailabilityPerDay} from "../reducers/timeslots.reducer";
import {getAvailableTimeslotsApproximation} from "../services/timeslotCounter.service";
import {fetchTimeslotAvailabilityAction} from "../actions/timeslots.actions";


export const fetchAvailableTimeslotsApproximation = (storeId: string, appointmentTypeId: string, startDate: Date, tenant: string, roomId: string | null, opticianId: string | null) => {
    const endDate: Date = getEndDate(startDate);

    return (dispatch: Dispatch) => {
        return getAvailableTimeslotsApproximation(storeId, appointmentTypeId, startDate, endDate, tenant, opticianId, roomId)
            .then((data) => {
                dispatch(fetchTimeslotAvailabilityAction(transformIntoTimeslotAvailabilityData(data.data)));
            });
    };
};

const transformIntoTimeslotAvailabilityData = (data: any[]): ITimeslotAvailabilityPerDay[] => {
    const result: ITimeslotAvailabilityPerDay[] = [];
    data.forEach((storeWithTimeslots) => {
        const map = new Map(Object.entries(storeWithTimeslots.attributes.perDay));
        map.forEach((val: any, key) => {
            // key is in yyyy-mm-dd format, so parse it in ISO 8601 format
            result.push({
                date: new Date(key + "T00:00:00"),
                percentageAvailability: val.percentageAvailable
            });
        });
    });
    return result;
};

function getEndDate(startDate: Date): Date {

    const endDate: Date = new Date();
    endDate.setMonth(startDate.getMonth() + 2);
    endDate.setDate(0);
    return endDate
}