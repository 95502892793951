import React from "react";
import {Redirect, Route, RouteComponentProps, Switch, withRouter} from "react-router";
import "./BodyContent.scss";
import Diary from "./Diary";
import CustomerSearchPage from "./search/page/CustomerSearchPage";

interface IBodyContentProps extends RouteComponentProps<any> {
    tenant: string;
}

class BodyContent extends React.Component<IBodyContentProps> {

    public render(): React.ReactNode {
        return (
            <>
                <div className="body_content">
                    <Switch>
                        <Route path={this.getUrlRouteForComponentName("diary")}
                               render={() => <Diary tenant={this.props.tenant}/>}/>
                        <Route path={this.getUrlRouteForComponentName("search")}
                               render={(() => <CustomerSearchPage tenant={this.props.tenant}/>)}/>
                        <Redirect from="/" to={this.getDefaultRoute()}/>
                    </Switch>
                </div>
            </>
        );
    }

    private getUrlRouteForComponentName(componentName: string) {
        return this.props.match.url + "/" + componentName;
    }

    private getDefaultRoute() {
        return this.props.match.url + "/diary";
    }

}

export default withRouter(BodyContent);
