import {addDays, getMonth, getYear} from "date-fns";
import React, {ReactFragment} from "react";
import DatePicker from "react-datepicker";
import {connect} from "react-redux";
import CancelledFilter from "../components/CancelledFilter"
import {Dispatch} from "redux";
import {
    selectedDateChangeAction,
    selectSidebarDateChangeAction,
    selectWeekChangeAction,
    setAdjustSidebarToDatepickerFlagAction,
} from "../actions/selecteddate.action";
import {updateAppointmentTypesFilterAction, updateSubstatusesFilterAction} from "../actions/sidebarFilters.actions";
import {getAppointmentTypes, IAppointmentTypeData} from "../reducers/appointmentTypes.reducer";
import {getCalendarLocale, getLocalizedText, getSelectedLocale} from "../reducers/locale.reducer";
import {getSelectedRoom, IRoomData} from "../reducers/room.reducer";
import {getAdjustSidebarToDatepicker, getSelectedDate, ISelectedDateAndRange,} from "../reducers/selecteddate.reducer";
import {getSelectedAppointmentTypesFilter, getSelectedSubstatusesFilter} from "../reducers/sidebar.filters.reducer";
import {getSelectedStore, IStoreData} from "../reducers/store.reducer";
import {getAvailableSubstatuses, getSubstatusName} from "../reducers/substatusesConfig.reducer";
import {fetchAppointments} from "../thunks/appointments.thunk";
import {fetchClosingHours} from "../thunks/closinghours.thunk";
import {findMonday} from "../util/date.util";
import "./Sidebar.scss";
import AppointmentFilter, {IAppointmentFilterData} from "./AppointmentFilter";
import {getSelectedGridStyle, GridStyle} from "../reducers/gridstyle.reducer";

interface ISidebarState {
    sidebarCalendarSelectedDate: Date | null;
    sidebarCalendarSelectedMonth: number;
    sidebarCalendarSelectedYear: number;
    showSidebar?: boolean;
    monthChangeIncreaseEnabled: boolean;
    monthChangeDecreaseEnabled: boolean;
    monthChangeForwardEnabled: boolean;
    monthChangeBackwardEnabled: boolean;

}

interface IProps {
    tenant: string;
    height: number;
}

interface IConnectProps {
    availableSubstatuses: string[];
    appointmentTypes: IAppointmentTypeData[];
    selectedStore: IStoreData | undefined;
    selectedRoom: IRoomData | undefined;
    selectedDate: ISelectedDateAndRange;
    getMessageForKey: (key: string) => string;
    selectedGridStyle: GridStyle | undefined;
    getAdjustDatepickerToSidebar: boolean;
    getSubstatusName: (substatus: string) => string;
    selectedAppointmentTypesFilter: string[];
    selectedSubstatusesFilter: string[];
    selectedLocale: string;
    calendarLocale: Locale | undefined;
}

interface IDispatchFromProps {
    selectSidebarDate: (date: ISelectedDateAndRange) => void;
    selectCurrentWeekDate: (date: ISelectedDateAndRange) => void;
    selectCurrentDate: (date: ISelectedDateAndRange) => void;
    setAdjustSidebarFlag: (flagVal: boolean) => void;
    fetchAppointments: (storeId: string, roomId: number, startTime: Date, endTime: Date, tenant: string) => void;
    fetchClosingHours: (storeId: string, tenant: string, startDate: Date, requestedNumberOfDays: number) => void;
    updateSelectedSubstatusesFilter: (substatusFilter: string) => void;
    updateSelectedAppointmentTypesFilter: (appointmentTypeFilter: string) => void;
}

interface IDataProps extends IProps, IConnectProps {
}

export interface IResultProps extends IDataProps, IDispatchFromProps {
}

export class Sidebar extends React.Component<IResultProps, ISidebarState> {
    private decreaseMonthElem: any;
    private increaseMonthElem: any;

    constructor(props: any) {
        super(props);
        const today = new Date();
        this.state = {
            sidebarCalendarSelectedDate: this.props.selectedDate ? this.props.selectedDate.selectedDate : today,
            sidebarCalendarSelectedMonth: this.props.selectedDate ? this.props.selectedDate.selectedDate.getMonth() : today.getMonth(),
            sidebarCalendarSelectedYear: this.props.selectedDate ? this.props.selectedDate.selectedDate.getFullYear() : today.getFullYear(),
            showSidebar: true,
            monthChangeIncreaseEnabled: false,
            monthChangeDecreaseEnabled: false,
            monthChangeForwardEnabled: false,
            monthChangeBackwardEnabled: false,
        };
    }

    public render(): React.ReactNode {
        return (
            <div className="sidebar-component">
                <div style={{height: this.getSidebarHeight()}}
                    className={this.state.showSidebar ? "col no-right-padding sidebar-col sidebar-on" : "col sidebar-col sidebar-off"}>
                    <div className="row">
                        <div className="col no-padding">
                            <div className="button">
                                <button className="btn btn-sidebar"
                                        onClick={() => this.toggleSidebar()}>
                                    <span>{this.state.showSidebar ? <span className="fa fa-angle-double-left"/>
                                        : <span className="fa fa-angle-double-right"/>}</span></button>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col no-padding">
                            {this.state.showSidebar ?
                                <div className="sidebar">
                                    <li className="sidebar-elem-border">
                                        {this.renderCalendarSection()}
                                    </li>
                                    {this.renderCancelledAppointmentToggleSection()}
                                    <li className="sidebar-elem-border">
                                        {this.renderSubstatusesFilterSection()}
                                    </li>
                                    <li className="sidebar-elem-border">
                                        {this.renderAppTypesFilterSection()}
                                    </li>
                                </div> : <div/>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public shouldComponentUpdate(nextProps: Readonly<IResultProps>, nextState: Readonly<ISidebarState>, nextContext: any): boolean {
        if (this.state.sidebarCalendarSelectedMonth !== nextState.sidebarCalendarSelectedMonth) {
            if (nextProps.selectedDate && this.props.selectedDate && this.props.selectedDate.startRangeDate !== nextProps.selectedDate.startRangeDate) {
                return true;
            }
            return this.state.showSidebar !== nextState.showSidebar;
        } else {
            return true;
        }
    }

    private getSidebarHeight() {
        return this.props.height > 0 ? this.props.height : "calc(100vh - 108px)";
    }

    private renderCalendarSection(): ReactFragment {
        return <DatePicker
            renderCustomHeader={this.renderCustomHeader()}
            selected={this.props.selectedDate.selectedDate}
            startDate={this.props.selectedDate.startRangeDate}
            endDate={addDays(this.props.selectedDate.startRangeDate, this.props.selectedDate.range - 1)}
            highlightDates={[new Date()]}
            onChange={(date) => this.handleDateChange(date)}
            locale={this.props.calendarLocale}
            onMonthChange={(date) => this.handleMonthChange(date)}
            calendarClassName="datepicker"
            dayClassName={(date) => this.resolveDayClassName(date)}
            inline
        />;
    }

    private renderAppTypesFilterSection(): ReactFragment {
        return <AppointmentFilter label={this.props.getMessageForKey("sidebar.filters.type.label")}
                                  availableFilterValues={this.prepareAvailableAppTypes()}
                                  selectedFilterValues={this.props.selectedAppointmentTypesFilter}
                                  updateFilter={this.props.updateSelectedAppointmentTypesFilter}/>;
                                }

    private renderSubstatusesFilterSection(): ReactFragment {
        return <AppointmentFilter label={this.props.getMessageForKey("sidebar.filters.status.label")}
                                  availableFilterValues={this.prepareAvailableSubstatuses()}
                                  selectedFilterValues={this.props.selectedSubstatusesFilter}
                                  updateFilter={this.props.updateSelectedSubstatusesFilter}/>;
    }
    private renderCancelledAppointmentToggleSection(): ReactFragment {
        return <CancelledFilter label={this.props.getMessageForKey("sidebar.filters.cancelled.label")} description={this.props.getMessageForKey("sidebar.filters.cancelled.description")} />;
    }

    private prepareAvailableSubstatuses(): IAppointmentFilterData[] {
        const availableSubstatuses: IAppointmentFilterData[] = this.props.availableSubstatuses
            .map((substatus) => this.toFilterData(substatus, this.props.getSubstatusName(substatus)));
        availableSubstatuses.push({
            value: "UNDEFINED",
            name: this.props.getSubstatusName("UNDEFINED"),
        });
        return availableSubstatuses;
    }

    private prepareAvailableAppTypes(): IAppointmentFilterData[] {
        return this.props.appointmentTypes.map((appType) =>
            this.toFilterData(appType.id, appType.name[this.props.selectedLocale]));
    }

    private toFilterData(filterValue: string, filterName: string): IAppointmentFilterData {
        return {
            value: filterValue,
            name: filterName,
        };
    }

    private handleDateChange(date: Date | null): void {
        if (date != null && this.props.selectedDate) {
            this.setState({
                sidebarCalendarSelectedDate: date,
                sidebarCalendarSelectedMonth: date.getMonth(),
                sidebarCalendarSelectedYear: date.getFullYear(),
            });
            if (this.props.selectedGridStyle === GridStyle.CALENDAR_WEEK || this.props.selectedGridStyle === GridStyle.MULTIROOM_WEEK) {
                this.props.selectCurrentWeekDate({
                    selectedDate: new Date(date),
                    startRangeDate: new Date(date),
                    range: 7,
                });
                this.refreshData(findMonday(new Date(date)));
            } else if (this.props.selectedGridStyle === GridStyle.CALENDAR_DAY || this.props.selectedGridStyle === GridStyle.MULTIROOM_DAY) {
                this.props.selectCurrentDate({
                    selectedDate: new Date(date),
                    startRangeDate: new Date(date),
                    range: 1,
                });
                this.refreshData(new Date(date));
            }
        }
    }

    private fetchAppointments(selectedWeekStartDate: Date): void {
        if (this.props.selectedStore && this.props.selectedRoom) {
            const monday = findMonday(selectedWeekStartDate);
            const endDate = new Date(monday);
            endDate.setDate(monday.getDate() + 7);
            this.props.fetchAppointments(this.props.selectedStore.id, this.props.selectedRoom.id,
                monday, endDate, this.props.tenant);
        }
    }

    private fetchClosingHours(selectedStartDate: Date): void {
        if (this.props.selectedStore && this.props.selectedDate) {
            this.props.fetchClosingHours(this.props.selectedStore.id, this.props.tenant, selectedStartDate, 7);
        }
    }

    private refreshData(selectedStartDate: Date): void {
        this.fetchAppointments(selectedStartDate);
        this.fetchClosingHours(selectedStartDate);
    }

    private handleMonthChange(date: Date | null): void {
        this.props.setAdjustSidebarFlag(false);
        if (date != null) {
            this.setState({
                sidebarCalendarSelectedMonth: date.getMonth(),
                sidebarCalendarSelectedYear: date.getFullYear(),
            });
        }
    }

    private resolveDayClassName(date: Date | null): string {
        return date != null && this.isInSelectedMonth(date.getMonth()) ?
            "day in-selected-month" : "day out-of-selected-month";
    }

    private isInSelectedMonth(selectedMonth: number): boolean {
        return selectedMonth === this.props.selectedDate.selectedDate.getMonth();
    }

    private toggleSidebar(): void {
        this.setState({
            showSidebar: !this.state.showSidebar,
        });
    }

    private renderCustomHeader() {
        const months = this.props.getMessageForKey("sidebar.calendar.month.names");

        return ({
                    date,
                    decreaseMonth,
                    increaseMonth,
                }: any) => (

            <div className="date_selector_wrapper">
                <div className="date">{months[getMonth(date)]} {getYear(date)}</div>

                <div className="selectors">
                    <div className="date_selector_element button"><span className="fa fa-angle-left"
                                                                        onClick={decreaseMonth}
                                                                        ref={(elem) => {
                                                                            this.decreaseMonthElem = elem;
                                                                        }}/>
                    </div>
                    <div className="date_selector_element button"><span className="fa fa-angle-right"
                                                                        onClick={increaseMonth}
                                                                        ref={(elem) => {
                                                                            this.increaseMonthElem = elem;
                                                                        }}/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store: any, ownProps: IProps): IDataProps => {
    return {
        availableSubstatuses: getAvailableSubstatuses(store),
        appointmentTypes: getAppointmentTypes(store),
        getSubstatusName: getSubstatusName(store),
        selectedRoom: getSelectedRoom(store),
        selectedStore: getSelectedStore(store),
        tenant: ownProps.tenant,
        selectedDate: getSelectedDate(store),
        getMessageForKey: getLocalizedText(store),
        selectedGridStyle: getSelectedGridStyle(store),
        getAdjustDatepickerToSidebar: getAdjustSidebarToDatepicker(store),
        selectedLocale: getSelectedLocale(store),
        selectedAppointmentTypesFilter: getSelectedAppointmentTypesFilter(store),
        selectedSubstatusesFilter: getSelectedSubstatusesFilter(store),
        calendarLocale: getCalendarLocale(store),
        height: ownProps.height,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchFromProps => {
    return {
        selectSidebarDate: ((date) => dispatch(selectSidebarDateChangeAction(date))),
        selectCurrentWeekDate: ((date) => dispatch(selectWeekChangeAction(date.startRangeDate))),
        selectCurrentDate: ((date) => dispatch(selectedDateChangeAction(date))),
        fetchAppointments: ((storeId, roomId, startTime, endTime, tenant) => fetchAppointments(storeId, roomId, startTime, endTime, tenant, dispatch)),
        fetchClosingHours: ((storeId, tenant, startDate, requestedNumberOfDays) => fetchClosingHours(storeId, tenant, startDate, requestedNumberOfDays, dispatch)),
        setAdjustSidebarFlag: (flagVal) => dispatch(setAdjustSidebarToDatepickerFlagAction(flagVal)),
        updateSelectedAppointmentTypesFilter: ((appointmentTypeFilter) => dispatch(updateAppointmentTypesFilterAction(appointmentTypeFilter))),
        updateSelectedSubstatusesFilter: ((substatusFilter) => dispatch(updateSubstatusesFilterAction(substatusFilter)))
    };
};

export default connect<IDataProps, IDispatchFromProps, IProps>(
    mapStateToProps,
    mapDispatchToProps,
)(Sidebar);
