import {Dispatch} from "redux";
import {initSelectedSubstatusesFilterAction} from "../actions/sidebarFilters.actions";
import {fetchSubstatusesConfig} from "../actions/substatusesConfig.action";
import {getSubstatusesConfig} from "../services/substatusesConfig.service";

export const fetchSubstatusesConfigForTenant = (tenant: string) => {
    return (dispatch: Dispatch) => {
        return getSubstatusesConfig(tenant)
            .then((data) => processFetchedData(dispatch, data));
    };
};

function processFetchedData(dispatch: Dispatch, data: any) {
    dispatch(initSelectedSubstatusesFilterAction(data.availableSubstatuses));
    dispatch(fetchSubstatusesConfig(data.availableSubstatuses));
}
