import {getConfigPerEnv, preparePatchPostWithRelationsRequest} from "../config/requestConfig";
import {getPagingAxiosInstance} from "./axios.config";

export const createAppointmentsSeries = (appointmentSeriesAttributes: any, appointmentSeriesRelationships: any, tenant: string) => {
    return getPagingAxiosInstance().post("/appointments-series", preparePatchPostWithRelationsRequest("appointments-series", appointmentSeriesAttributes, appointmentSeriesRelationships), getConfigPerEnv(tenant))
        .then((response) => response.data);
};

export const deleteAppointmentsSeries = (seriesId: string, tenant: string) => {
    return getPagingAxiosInstance().delete(`/appointments-series/${seriesId}`, getConfigPerEnv(tenant))
        .then((response) => response.data);
};
