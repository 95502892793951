import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { getAppointments, IAppointmentData } from "../../../reducers/appointments.reducer";
import { getCustomers, ICustomerData } from "../../../reducers/customers.reducer";
import { getLocalizedText } from "../../../reducers/locale.reducer";
import CustomerSearchForm, { IFormState } from "../common/CustomerSearchForm";
import CustomerSearchResult, { CustomerRowType, PaginationType } from "../common/CustomerSearchResult";
import "./CustomerSearchPage.scss";
import CustomerInformation from "../common/CustomerInformation";
import AppointmentsHistory from "../common/AppointmentsHistory";
import { getAppointmentsForCustomer } from "../../../thunks/appointments.thunk";
import { ThunkDispatch } from "redux-thunk";
import AppointmentModal, { AppointmentModalMode } from "../../../modal/AppointmentModal";

interface IDispatchProps {
    fetchAppointmentsForCustomer: (customerUuid: string, tenant: string, pageLimit: number) => void;
}

interface IConnectProps {
    getMessageForKey: (key: string) => string;
    customers: ICustomerData[];
    appointments: IAppointmentData[];
}

interface IProps {
    tenant: string;
}

interface IState {
    showResults: boolean;
    showCustomerInfo: boolean;
    showNewAppointmentModal?: boolean;
    selectedCustomer: ICustomerData;
    formState?: IFormState;
}
interface IDataProps extends IProps, IConnectProps {
}

interface IResultProps extends IDataProps, IDispatchProps {
}

export class CustomerSearchPage extends React.Component<IResultProps, IState> {

    public static CUSTOMER_SEARCH_RESULTS_PAGE_PAGE_LIMIT: number = 10;

    constructor(props: Readonly<IResultProps>) {
        super(props);
        this.state = {
            showCustomerInfo: false,
            selectedCustomer: {
                id: "",
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
                gender: "",
                dateOfBirth: "",
            },
            showNewAppointmentModal: false,
            showResults: false,
            formState: undefined,
        };
    }

    public render(): React.ReactNode {
        return (
            <>
            <div className="customer_search-container">
                {this.renderBackToOverview()}
                {this.renderHeader()}
                {this.renderSearchForm()}
                {this.renderCustomerSearchResult()}
            </div>
                {this.state.showNewAppointmentModal ?
                    <AppointmentModal closePopup={() => this.closeModal()}
                                      tenant={this.props.tenant}
                                      mode={AppointmentModalMode.NEW}
                                      refreshMatrixOnClose={false}
                                      selectedCustomer={this.state.selectedCustomer}
                                      additionalFieldsForAppTypeFromAppointment={[]}
                    /> : null
                }
            </>);
    }

    private renderHeader() {
        if (this.state.showCustomerInfo) {
            return <h1>{this.props.getMessageForKey("customerSearch.searchDetails")}</h1>;
        } else {
            return <> <h1>{this.props.getMessageForKey("customerSearch.search")}</h1>
                <h2>{this.props.getMessageForKey("customerSearch.enterDetails")}</h2></>;
        }
    }

    private closeModal(): void {
        this.setState({
            showNewAppointmentModal: false,
        });
    }

    private renderBackToOverview() {
        if (this.state.showCustomerInfo) {
            return <div className="back-to-overview"><div onClick={() => this.backToOverview()}>
                <img src={process.env.PUBLIC_URL + "/statics/img/left-arrow.svg"}
                     alt=""/>&nbsp;&nbsp;
                {this.props.getMessageForKey("customerSearch.customerInformation.backToOverview")}
            </div>
            </div>;
        }
    }

    private showResults(formState: IFormState): void {
        this.setState({ showResults: true, formState });
    }

    private backToOverview(): void {
        this.setState({ showCustomerInfo: false });
    }

    private showCustomerInfoPage(customer: ICustomerData): void {
        this.props.fetchAppointmentsForCustomer(customer.id, this.props.tenant, CustomerSearchPage.CUSTOMER_SEARCH_RESULTS_PAGE_PAGE_LIMIT);
        this.setState({ selectedCustomer: customer, showCustomerInfo: true });
    }

    private renderSearchForm() {
        if (this.state.showCustomerInfo) {
            return <CustomerInformation
                customer={this.state.selectedCustomer}
                tenant={this.props.tenant}
                pageLimit={CustomerSearchPage.CUSTOMER_SEARCH_RESULTS_PAGE_PAGE_LIMIT}
                newAppointmentHandler={(customer: ICustomerData) => this.createNeAppointment(customer)}
            />;
        } else {
            return <CustomerSearchForm tenant={this.props.tenant}
                                       showResults={(fromState: IFormState)  => this.showResults(fromState)}
                                       pageLimit={CustomerSearchPage.CUSTOMER_SEARCH_RESULTS_PAGE_PAGE_LIMIT}
                                       formState={this.state.formState}
            />;
        }
    }

    private renderCustomerSearchResult(): ReactNode {
        if (this.state.showCustomerInfo) {
            return <AppointmentsHistory appointments={this.props.appointments}
                                        tenant={this.props.tenant}
            />;
        } else if (this.state.showResults ) {
            return <CustomerSearchResult customers={this.props.customers}
                                         tenant={this.props.tenant}
                                         emptyHeaderColumns={2}
                                         customerRowType={CustomerRowType.PAGE}
                                         paginationType={PaginationType.VIEW_MORE_BUTTON}
                                         showCustomerInfoPage={(customer: ICustomerData) => this.showCustomerInfoPage(customer)}
                                         newAppointmentHandler={(customer: ICustomerData) => this.createNeAppointment(customer)}
            />;
        }
    }

    private createNeAppointment(customer: ICustomerData): void {
        this.setState({selectedCustomer: customer, showNewAppointmentModal: true});
    }
}

const mapStateToProps = (store: any, ownProps: IProps): IDataProps => {
    return {
        appointments: getAppointments(store),
        customers: getCustomers(store),
        getMessageForKey: getLocalizedText(store),
        tenant: ownProps.tenant,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): IDispatchProps => {
    return {
        fetchAppointmentsForCustomer: ((customerUuid, tenant, pageLimit ) =>
            dispatch(getAppointmentsForCustomer(customerUuid, tenant, pageLimit))),
    };
};

export default connect<IDataProps, IDispatchProps, IProps>(
    mapStateToProps,
    mapDispatchToProps,
)(CustomerSearchPage);
