import {createSelector} from "reselect";
import { SET_APPLICATION_ERROR, CLEAR_APPLICATION_ERRORS } from './../actions/applicationError.action';

export enum ApplicationErrorType {
    ERROR_MESSAGE_HTTP = 'http-error',
    ERROR_MESSAGE_AUTH = 'auth-error'
}

export interface GenericErrorData {
    type: ApplicationErrorType;
    data?: HttpErrorData | any;
}

export interface HttpErrorData extends GenericErrorData {
    type: ApplicationErrorType.ERROR_MESSAGE_HTTP;
    data: {
        message: string;
        method: string;
        url: string;
        requestData: any;
    }
}

export type ApplicationErrorData = GenericErrorData | HttpErrorData

export interface ApplicationErrorsState {
    errors: ApplicationErrorData[];
}

const initialState: ApplicationErrorsState = {
    errors: []
}

export default function applicationErrorsReducer(state = initialState, action: any): any {
    switch (action.type) {
        case SET_APPLICATION_ERROR:
            return {
                ...state,
                errors: [...state.errors, action.payload],
            };
        case CLEAR_APPLICATION_ERRORS:
            return {
                ...state,
                errors: []
            }
        default:
            return state;
    }
}

const getApplicationErrorState = (state: any) => state.applicationErrors;

export const getApplicationErrors = createSelector(getApplicationErrorState,
    (state: ApplicationErrorsState) => state.errors);