import {getConfigPerEnv} from "../config/requestConfig";
import {getPagingAxiosInstance} from "./axios.config";

export const getOpeningHours = (storeId: string, startDate: Date, requestedNumberOfDays: number, tenant: string) => {
    // tslint:disable-next-line:max-line-length
    return getPagingAxiosInstance().get(`/opening-hours?filter%5Bstore%5D=${storeId}&filter%5BstartDate%5D=${convertDateTimeToDate(startDate)}&filter%5BrequestedNumberOfDays%5D=${requestedNumberOfDays}`, getConfigPerEnv(tenant))
        .then((response) => response.data);
};

function convertDateTimeToDate(dateTime: Date): string {
    return dateTime.toISOString().slice(0, 10);
}
