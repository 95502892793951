import Immutable, {List} from "immutable";
import moment from "moment";
import React, {FormEvent, ReactNodeArray} from "react";
import {getStoreName as utilGetStoreName} from "../../app/util/storeName.util";
import {AdditionalFieldDataType, IAdditionalFieldData} from "../reducers/additionalFields.reducer";
import {IAdditionalField} from "../reducers/appointments.reducer";
import {ITimeslotAvailabilityPerDay, ITimeslotData} from "../reducers/timeslots.reducer";
import {AppointmentModal, AppointmentModalMode, IDropdownItem,} from "./AppointmentModal";
import {isSameDate} from "../util/date.util";
import Input from "./Input";
import Checkbox from "./Checkbox";
import {AppointmentModalValidator} from "./validator/AppointmentModalValidator";
import DropdownAdditionalFields from "./DropdownAdditionalFields";
import {toLocalIsoDate} from "./../util/date.util";

export function getStoreName(modal: AppointmentModal): string {
    return utilGetStoreName(modal.props.getSelectedStore, modal.props.getSelectedLocale, modal.props.getFallbackLocale);
}

export function getAppointmentTypeListContent(modal: AppointmentModal): IDropdownItem[] {
    const result: IDropdownItem[] = [];
    modal.props.getAppointmentTypes.forEach((appointmentType) => {
        result.push({
            label: modal.props.getSelectedLocale ? appointmentType.name[modal.props.getSelectedLocale] : "",
            value: appointmentType.id,
        });
    });
    return result;
}

export function getRoomsListContent(modal: AppointmentModal): IDropdownItem[] {
    const result: IDropdownItem[] = [];
    if (modal.props.getSelectedAppointmentType) {
        if (!modal.state.overrideAvailability && !modal.state.recurringMode) {
            result.push({value: 0, label: modal.props.getMessageForKey("appointment.modal.form.room.noPreference")});
        }
        modal.props.getRoomsForAppointmentType(modal.props.getSelectedAppointmentType.id).forEach((room) => {
            result.push({
                label: room.name,
                value: room.id,
            });
        });
    }
    return result.length > 1 || modal.state.overrideAvailability || modal.state.recurringMode ? result : [];
}

export function getOpticiansListContent(modal: AppointmentModal): IDropdownItem[] {
    const result: IDropdownItem[] = [];
    if (modal.props.opticiansForAppointmentType) {
        if (!modal.state.overrideAvailability && !modal.state.recurringMode) {
            result.push({
                value: "0",
                label: modal.props.getMessageForKey("appointment.modal.form.optician.noPreference"),
            });
        }
        modal.props.opticiansForAppointmentType.forEach((optician) => {
            result.push({
                value: optician.id,
                label: optician.title + " " + optician.firstName + " " + optician.lastName,
            });
        });
    }
    return result.length > 1 || modal.state.overrideAvailability || modal.state.recurringMode ? result : [];
}

export function getTimeslotsListContent(modal: AppointmentModal): IDropdownItem[] {
    const result: IDropdownItem[] = [];
    if (modal.props.getSelectedAppointmentType && modal.state.appointmentDate) {
        modal.props.getTimeslotsForDate(modal.state.appointmentDate).forEach((timeslot) => {
            result.push({
                value: timeslot.id,
                label: new Date(timeslot.startTime.getTime())
                    .toLocaleTimeString(undefined, {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: false,
                    }),
            });
        });
    }
    return result;
}

export function getGenderContent(modal: AppointmentModal): IDropdownItem[] {
    const genderOptions: any = Array.from(modal.props.getMessageForKey("appointment.modal.form.gender.options"));
    const result: IDropdownItem[] = [];
    for (const gender of genderOptions) {
        result.push({
            label: gender.label,
            value: gender.id,
        });
    }
    return result;
}

export function getNotificationChannelContent(modal: AppointmentModal): IDropdownItem[] {
    const notificationChannelOptions: any = Array.from(modal.props.getMessageForKey("appointment.modal.form.notificationChannel.options"));
    const result: IDropdownItem[] = [];
    for (const notificationChannel of notificationChannelOptions) {
        result.push({
            label: notificationChannel.label,
            value: notificationChannel.id,
        });
    }
    return result;
}

export function getNotificationLanguageContent(modal: AppointmentModal): IDropdownItem[] {
    const notificationLanguageOptions: any = Array.from(modal.props.getMessageForKey("appointment.modal.form.notificationLanguage.options"));
    const result: IDropdownItem[] = [];
    for (const notificationLanguage of notificationLanguageOptions) {
        result.push({
            label: notificationLanguage.label,
            value: notificationLanguage.id,
        });
    }
    return result;
}

export function getDurationUnit(modal: AppointmentModal): IDropdownItem[] {
    const result: IDropdownItem[] = [];
    if (modal.props.getSelectedAppointmentType) {
        let translatedLabel = modal.props.getMessageForKey(`appointment.modal.form.${modal.props.getSelectedAppointmentType.duration.unit}`);
        // Fallback for when the translation message is missing.
        if (translatedLabel == null || translatedLabel === "") {
            translatedLabel = modal.props.getSelectedAppointmentType.duration.unit;
        }
        result.push({
            label: translatedLabel,
            value: 0,
        });
    }
    return result;
}

export function getDurationAmount(modal: AppointmentModal): IDropdownItem[] {
    const result: IDropdownItem[] = [];
    if (modal.props.getSelectedAppointmentType) {
        result.push({
            label: modal.props.getSelectedAppointmentType.duration.amount,
            value: 0,
        });
    }
    return result;
}

export function onAppointmentTypeChange(modal: AppointmentModal, appointmentTypeId: string | undefined, clearSelections: boolean = true) {
    if (clearSelections) {
        deleteSelectionsOnAppointmentTypeChange(modal);
    }
    modal.props.getAppointmentTypes.forEach((appointmentType) => {
        if (appointmentType.id === appointmentTypeId) {
            modal.props.dispatchSelectAppointmentType(appointmentType);
            modal.setState({
                recurringMode: false,
                durationUnit: 0,
                durationValue: 0,
                appointmentDateValidationActivated: false,
            });
            if (!isInRescheduleMode(modal)) { // fetch only in NEW mode, while in RESCHEDULE mode we get it from parent component
                modal.props.dispatchFetchAdditionalFields(appointmentTypeId, modal.props.tenant);
            }
            modal.props.dispatchCleanOpticians();
            if (modal.props.getSelectedStore) {
                modal.props.dispatchFetchOpticians(modal.props.getSelectedStore.id, appointmentTypeId, modal.props.tenant);
            }
            modal.setState({appointmentDate: null});
            modal.props.dispatchCleanTimeslots();
            triggerDispatchFetchAvailableTimeslotsApproximationAndFetchTimeslots(modal, appointmentTypeId, modal.state.optician, modal.state.diary);
        }
    });
}

function deleteSelectionsOnAppointmentTypeChange(modal: AppointmentModal) {
    modal.setState({
        diary: null,
        optician: null,
        startTime: null,
    });
}

function triggerDispatchFetchAvailableTimeslotsApproximationAndFetchTimeslots(modal: AppointmentModal, appointmentType: string | undefined, optician: string | null, room: number | null) {
    if (modal.props.getSelectedStore) {
        const appointmentTypeId = appointmentType ? appointmentType : modal.state.appointmentType as string;
        const roomId = (room && room !== 0) ? room : null;
        const opticianId = (optician && optician !== "0") ? optician : null;
        if (appointmentTypeId !== undefined) {
            modal.props.dispatchFetchAvailableTimeslotsApproximation(modal.props.getSelectedStore.id, appointmentTypeId, new Date(), modal.props.tenant, String(roomId), opticianId);
            modal.props.dispatchFetchTimeslots(modal.props.getSelectedStore.id, appointmentTypeId, new Date(), modal.props.tenant, String(roomId), opticianId);
        }
    }
}

export function isInRescheduleMode(modal: AppointmentModal): boolean {
    return modal.props.mode === AppointmentModalMode.RESCHEDULE;
}

export function isInRebookMode(modal: AppointmentModal): boolean {
    return modal.props.mode === AppointmentModalMode.REBOOK;
}

export function isInEditMode(modal: AppointmentModal): boolean {
    return modal.props.mode === AppointmentModalMode.EDIT;
}

export function onOpticianChange(modal: AppointmentModal, opticianId: string | null): void {
    modal.props.dispatchCleanTimeslots();
    modal.setState({
        appointmentDate: null,
        startTime: null,
    });
    triggerDispatchFetchAvailableTimeslotsApproximationAndFetchTimeslots(modal, undefined, opticianId, null);
}

export function onDiaryChange(modal: AppointmentModal, room: number | null): void {
    modal.props.dispatchCleanTimeslots();
    modal.setState({
        appointmentDate: null,
        startTime: null,
    });
    triggerDispatchFetchAvailableTimeslotsApproximationAndFetchTimeslots(modal, undefined, null, room);
}

export function initializeAdditionalFields_new(modal: AppointmentModal): void {
    if (modal.props.appointment !== undefined && modal.props.appointment.additionalFields) {
        let additionalFields = Immutable.Map({});
        modal.props.appointment.additionalFields.forEach((additionalField) => {
            additionalFields = additionalFields.set(additionalField.label, additionalField.value);
        });
        modal.setState({additionalFields});
    }
}

export function isAdditionalFieldOfBooleanType(modal: AppointmentModal, additionalField: IAdditionalField): boolean {
    const foundAdditionalField: IAdditionalFieldData | undefined = modal.props.additionalFieldsForAppTypeFromAppointment.find((field) => field.label === additionalField.label);
    return foundAdditionalField !== undefined && foundAdditionalField.dataType === AdditionalFieldDataType.BOOLEAN;
}

export function onAppointmentDateChange(modal: AppointmentModal, date: Date | null): void {
    modal.setState({
        appointmentDate: date,
        startTime: null,
    });
}

export function onStartDateTimeChange(modal: AppointmentModal, date: Date | null): void {
    modal.setState({overrideStartDateTime: date});
}

export function onEndDateTimeChange(modal: AppointmentModal, date: Date | null): void {
    modal.setState({overrideEndDateTime: date});
}

export function getSelectedTimeslot(modal: AppointmentModal): ITimeslotData | null {
    let result: ITimeslotData | null = null;
    const timeslotId = modal.state.startTime;
    if (modal.state.appointmentDate) {
        modal.props.getTimeslotsForDate(modal.state.appointmentDate).forEach((timeslot) => {
            if (timeslot.id === timeslotId) {
                result = timeslot;
            }
        });
    }
    return result;
}

export function prepareAdditionalFieldsData_new(modal: AppointmentModal) {
    const additionalFieldsData: any[] = [];
    modal.state.additionalFields.forEach(((val, key) => {
        additionalFieldsData.push({
            label: key,
            value: val,
        });
    }));
    return additionalFieldsData;
}

export function prepareAppointmentData(modal: AppointmentModal) {
    const selectedTimeslot = getSelectedTimeslot(modal);
    if (!selectedTimeslot && !modal.state.overrideStartDateTime) {
        return {};
    }
    let data = {
        bookingChannel: "backend",
        notificationRequired: modal.state.notificationRequired,
        updateComment: modal.state.additionalNotes,
        manualOverride: modal.state.overrideAvailability,
        site: "appointment",
        preferredLanguage: modal.state.notificationLanguage,
        preferredChannelOfCommunication: modal.state.notificationChannel,
        customerComment: modal.state.customerComment,
        createdBy: "GTBO",
        startTime: "" as null | string,
        endTime: null as null | string,
        fields: prepareAdditionalFieldsData_new(modal),
    }
    if (selectedTimeslot) {
        data.startTime = toLocalIsoDate(selectedTimeslot.startTime);
        data.endTime = toLocalIsoDate(selectedTimeslot.endTime);
    } else if (modal.state.overrideStartDateTime && modal.state.overrideEndDateTime) {
        data.startTime = toLocalIsoDate(modal.state.overrideStartDateTime);
        data.endTime = toLocalIsoDate(modal.state.overrideEndDateTime);
    }
    return data;
}

function groupFieldsByTwoElements(additionalFields: IAdditionalFieldData[]) {
    let rows: Immutable.Map<number, IAdditionalFieldData[]> = Immutable.Map();
    let row = new Array<IAdditionalFieldData>();
    let rowNumber: number = 0;
    let pushedElements: number = 0;
    additionalFields = additionalFields.filter((f) => !f.hidden);
    for (let i = 0; i < additionalFields.length; i++) {
        if (pushedElements < 2) {
            row.push(additionalFields[i]);
            pushedElements++;
            if (pushedElements === 2 || additionalFields.length - 1 === i) {
                rows = rows.set(rowNumber, row);
                if (additionalFields.length - 1 === i) {
                    break;
                }
                pushedElements = 0;
                rowNumber++;
                row = new Array<IAdditionalFieldData>();
            }
        }
    }
    return rows;
}

export function renderAdditionalFields_new(modal: AppointmentModal, additionalFields: IAdditionalFieldData[]) {
    setDefaultDropdownValue(modal, additionalFields);
    resetAdditionalFieldsRelated(modal, additionalFields)
    const rows = groupFieldsByTwoElements(additionalFields);
    const result: ReactNodeArray = [];
    rows.forEach(((formRow, key) => {
        result.push(
            <div key={key} className="row">
                {formRow.map((additionalField, i) => (
                    <React.Fragment key={additionalField.label}>
                        <div className="col">
                            <div className="form-element">
                                {getProperFormElementForAdditionalField_new(modal, additionalField, additionalFields)}
                            </div>
                        </div>
                        {formRow.length === 1 ? <div className="col">
                            <div className="form-element"/>
                        </div> : <></>}
                    </React.Fragment>
                ))}
            </div>);
    }));
    return result;
}

function getProperFormElementForAdditionalField_new(modal: AppointmentModal, additionalField: IAdditionalFieldData, additionalFields: IAdditionalFieldData[]) {
    switch (additionalField.dataType) {
        case AdditionalFieldDataType.BOOLEAN:
            return <Checkbox fieldName={additionalField.label}
                             overrideAvailability={modal.state.overrideAvailability}
                             isValid={(checked) => AppointmentModalValidator.isCheckboxAdditionalFieldValid(checked, modal.state.overrideAvailability, additionalField.mandatory)}
                             errorClassName="checkbox-error"
                             mandatory={additionalField.mandatory && !modal.state.overrideAvailability}
                             onChange={(event: FormEvent<HTMLInputElement>) => onAdditionalFieldInputChange(event, modal)}
                             checked={modal.state.additionalFields.get(additionalField.label) ? JSON.parse(modal.state.additionalFields.get(additionalField.label)) : false}
                             label={additionalField.descriptions[modal.props.getSelectedLocale !== undefined ? modal.props.getSelectedLocale : ""]}
                             description={additionalField.messages[modal.props.getSelectedLocale !== undefined ? modal.props.getSelectedLocale : ""]}/>;
        case AdditionalFieldDataType.NUMBER:
            return <Input fieldName={additionalField.label}
                          className="form-control input-sm"
                          type="text"
                          overrideAvailability={modal.state.overrideAvailability}
                          errorClassName="input-error"
                          isValid={(value) => AppointmentModalValidator.isNumberAdditionalFieldValid(value, modal.state.overrideAvailability, additionalField.mandatory)}
                          onChange={(event: FormEvent<HTMLInputElement>) => onAdditionalFieldInputChange(event, modal)}
                          disabled={false}
                          mandatory={additionalField.mandatory && !modal.state.overrideAvailability}
                          value={modal.state.additionalFields.get(additionalField.label)}
                          label={additionalField.descriptions[modal.props.getSelectedLocale !== undefined ? modal.props.getSelectedLocale : ""]}
                          placeholder={getAdditionalFieldPlaceholder(modal, additionalField)}/>;
        case AdditionalFieldDataType.DROPDOWN_ADDITIONAL_FIELDS:
            return <DropdownAdditionalFields errorClassName="input-error"
                                             checkIfNotEmptyOnBlur={false}
                                             overrideAvailability={modal.state.overrideAvailability}
                                             mandatory={additionalField.mandatory}
                                             placeholder={getAdditionalFieldPlaceholder(modal, additionalField)}
                                             label={additionalField.descriptions[modal.props.getSelectedLocale !== undefined ? modal.props.getSelectedLocale : ""]}
                                             availableValues={getAdditionalFieldsDropdownItems(additionalField.parameters, additionalFields, modal.props.getSelectedLocale)}
                                             value={modal.state.additionalFields.get(additionalField.label)}
                                             disabled={false}
                                             fieldName={additionalField.label}
                                             onChange={(selectedItem: IDropdownItem, fieldName: string) => onDropdownAdditionalFieldsValueChange(selectedItem, fieldName, modal, additionalFields)}
            />
        case AdditionalFieldDataType.TEXT:
            return <Input fieldName={additionalField.label}
                          type="text"
                          overrideAvailability={modal.state.overrideAvailability}
                          errorClassName="input-error"
                          className="form-control input-sm"
                          onChange={(event: FormEvent<HTMLInputElement>) => onAdditionalFieldInputChange(event, modal)}
                          disabled={false}
                          isValid={(value) => AppointmentModalValidator.isTextAdditionalFieldValid(value, modal.state.overrideAvailability, additionalField.mandatory)}
                          mandatory={additionalField.mandatory && !modal.state.overrideAvailability}
                          value={modal.state.additionalFields.get(additionalField.label)}
                          label={additionalField.descriptions[modal.props.getSelectedLocale !== undefined ? modal.props.getSelectedLocale : ""]}
                          placeholder={getAdditionalFieldPlaceholder(modal, additionalField)}/>;
        case AdditionalFieldDataType.CHILE_RUT:
            return <Input fieldName={additionalField.label}
                          type="text"
                          overrideAvailability={modal.state.overrideAvailability}
                          errorClassName="input-error"
                          className="form-control input-sm"
                          onChange={(event: FormEvent<HTMLInputElement>) => onAdditionalFieldInputChange(event, modal)}
                          disabled={false}
                          isValid={(value) => AppointmentModalValidator.isChileRutValid(value, modal.state.overrideAvailability, additionalField.mandatory)}
                          mandatory={additionalField.mandatory && !modal.state.overrideAvailability}
                          value={modal.state.additionalFields.get(additionalField.label)}
                          label={additionalField.descriptions[modal.props.getSelectedLocale !== undefined ? modal.props.getSelectedLocale : ""]}
                          placeholder={getAdditionalFieldPlaceholder(modal, additionalField)}/>;
        default: {
            return <div/>;
        }
    }
}

function onAdditionalFieldInputChange(event: React.FormEvent<HTMLInputElement>, modal: AppointmentModal) {
    const fieldName = event.currentTarget.name;
    const fieldValue = event.currentTarget.type === "checkbox" ? event.currentTarget.checked : event.currentTarget.value;
    let additionalFields: Immutable.Map<string, any> = Immutable.fromJS(modal.state.additionalFields);
    if (fieldValue && (typeof fieldValue === "boolean" || String(fieldValue).length > 0)) {
        additionalFields = additionalFields.set(fieldName, fieldValue);
    } else {
        additionalFields = additionalFields.remove(fieldName);
    }
    modal.setState({additionalFields});
}

export function getAdditionalFieldsDropdownItemsForEditForm(parameters: string[], additionalFields: IAdditionalFieldData[], locale: string) {
    const fields = getAdditionalFieldsDropdownItems(parameters, additionalFields, locale);
    const result = fields.map(f => {
        return {id: f.value, label: f.label}
    });
    return Immutable.fromJS(result);
}

export function getAdditionalFieldsDropdownItems(parameters: string[], additionalFields: IAdditionalFieldData[], locale: string): IDropdownItem[] {
    const result: IDropdownItem[] = [];
    if (parameters) {
        const fields = additionalFields.map((field) => {
            return field.label;
        });
        parameters.forEach((parameter) => {
            if (fields.includes(parameter)) {
                const field = additionalFields.find(f => f.label === parameter);
                result.push({
                    value: parameter,
                    label: field !== undefined ? field.descriptions[locale !== undefined ? locale : ""] : parameter
                });
            }
        })
    }
    return result;
}

function onDropdownAdditionalFieldsValueChange(selectedItem: IDropdownItem, fieldName: string, modal: AppointmentModal, additionalFieldsData: IAdditionalFieldData[]) {
    const fieldValue = selectedItem.value;
    let additionalFields: Immutable.Map<string, any> = Immutable.fromJS(modal.state.additionalFields);
    if (fieldValue) {
        additionalFields = additionalFields.set(fieldName, fieldValue);
        updateAdditionalFieldsRelatedWithDropdown(additionalFieldsData, fieldName, fieldValue);
    } else {
        additionalFields = additionalFields.remove(fieldName);
    }
    modal.setState({additionalFields});
}

function setDefaultDropdownValue(modal: AppointmentModal, additionalFieldsData: IAdditionalFieldData[]) {
    additionalFieldsData.forEach((field) => {
        if (field.dataType === AdditionalFieldDataType.DROPDOWN_ADDITIONAL_FIELDS && !modal.state.additionalFields.get(field.label)) {
            const fieldName = field.label;
            const fieldValue = field.parameters[0];
            updateAdditionalFieldsRelatedWithDropdown(additionalFieldsData, fieldName, fieldValue);
            let additionalFields: Immutable.Map<string, any> = Immutable.fromJS(modal.state.additionalFields);
            additionalFields = additionalFields.set(fieldName, fieldValue);
            modal.setState({additionalFields});
        }
    });
}

function resetAdditionalFieldsRelated(modal: AppointmentModal, additionalFieldsData: IAdditionalFieldData[]) {
    additionalFieldsData.forEach((field) => {
        if (field.dataType === AdditionalFieldDataType.DROPDOWN_ADDITIONAL_FIELDS) {
            const fieldName = field.label;
            const fieldValue = modal.state.additionalFields.get(field.label) || field.parameters[0]
            updateAdditionalFieldsRelatedWithDropdown(additionalFieldsData, fieldName, fieldValue);
        }
    });
}

function updateAdditionalFieldsRelatedWithDropdown(additionalFieldsData: IAdditionalFieldData[], fieldName: string, fieldValue: string | number) {
    const dropdown = additionalFieldsData.find(f => f.label === fieldName);
    if (dropdown) {
        dropdown.parameters.forEach(item => {
            const field = additionalFieldsData.find(f => f.label === item);
            if (field) {
                field.hidden = field.label !== fieldValue;
                field.mandatory = dropdown.mandatory && field.label === fieldValue;
            }
        });
    }
}

function getAdditionalFieldPlaceholder(modal: AppointmentModal, additionalField: IAdditionalFieldData): string {
    if (additionalField.messages) {
        return additionalField.messages[modal.props.getSelectedLocale !== undefined ?
            modal.props.getSelectedLocale : ""];
    } else {
        return "";
    }
}

export function anyMandatoryAdditionalFieldMissing(overrideAvailability: boolean,
                                                   additionalFields: IAdditionalFieldData[],
                                                   formValues: Immutable.Map<string, any> | undefined,
                                                   recurringMode: boolean): boolean {
    let anyFieldMissing: boolean = false;
    if (!overrideAvailability && additionalFields && !recurringMode) {
        const mandatoryAdditionalFields = additionalFields.filter((field) => field.mandatory);
        mandatoryAdditionalFields.forEach((field) => {
            if (formValues !== undefined) {
                formValues.forEach(((value, key) => {
                    if (key.includes(field.label)) {
                        if ((List.isList(value) && value.size === 0) || (!List.isList(value) && value === "")) {
                            anyFieldMissing = true;
                            return;
                        }
                    }
                }));
                if (!formValues.has(AppointmentModal.additionalFieldPrefix + field.label)) {
                    anyFieldMissing = true;
                    return;
                }
            }
        });
    }
    return anyFieldMissing;
}

export function additionalFieldsEdited(formValues: Immutable.Map<string, any>, formInitialValuesMap: Immutable.Map<string, any>): boolean {
    let edited: boolean = false;
    if (formValues !== undefined) {
        if (formValues.size !== formInitialValuesMap.size) {
            edited = true;
        }
        formValues.forEach(((value, key) => {
            if (key.startsWith(AppointmentModal.additionalFieldPrefix)) {
                formInitialValuesMap.forEach(((valueInit, keyInit) => {
                    if (keyInit === key) {
                        if ((List.isList(valueInit) && List.isList(value) && valueInit.size !== value.size) ||
                            (!List.isList(valueInit) && !List.isList(value) && valueInit !== value)) {
                            edited = true;
                        }
                    }
                }));
            }
        }));
    }
    return edited;
}

export function prepareAppointmentRelationships(modal: AppointmentModal, rescheduleMode: boolean) {
    let appointmentRelationShips: any;
    if (modal.state.optician && Number(modal.state.optician) > 0) {
        if (modal.state.diary && Number(modal.state.diary) > 0) {
            // optician and diary
            appointmentRelationShips = prepareAppointmentRelationshipsWithRoomAndOptician(modal);
        } else {
            // only optician
            appointmentRelationShips = prepareAppointmentRelationshipsWithOptician(modal);
        }
    } else {
        if (modal.state.diary && Number(modal.state.diary) > 0) {
            // only diary
            appointmentRelationShips = prepareAppointmentRelationshipsWithRoom(modal);
        } else {
            // no optician no diary
            appointmentRelationShips = prepareAppointmentRelationshipsWithoutRoomAndOptician(modal);
        }
    }
    if (modal.props.appointment !== undefined && rescheduleMode) {
        appointmentRelationShips.pastAppointment = {
            data: {
                type: "appointments",
                id: modal.props.appointment.uuid,
            },
        };
    }
    return appointmentRelationShips;
}

export function prepareAppointmentRelationshipsWithRoom(modal: AppointmentModal) {
    if (modal.props.getSelectedStore) {
        return {
            store: {
                data: {
                    type: "stores",
                    id: modal.props.getSelectedStore.id,
                },
            },
            appointmentType: {
                data: {
                    type: "appointment-types",
                    id: modal.state.appointmentType,
                },
            },
            customer: {
                data: {
                    type: "customers",
                    id: undefined,
                },
            },
            consumerSelectedRoom: {
                data: {
                    type: "rooms",
                    id: modal.state.diary,
                },
            },
            pastAppointment: undefined,
        };
    } else {
        return {};
    }
}

export function prepareAppointmentRelationshipsWithOptician(modal: AppointmentModal) {
    if (modal.props.getSelectedStore) {
        return {
            store: {
                data: {
                    type: "stores",
                    id: modal.props.getSelectedStore.id,
                },
            },
            appointmentType: {
                data: {
                    type: "appointment-types",
                    id: modal.state.appointmentType,
                },
            },
            customer: {
                data: {
                    type: "customers",
                    id: undefined,
                },
            },
            consumerSelectedOptician: {
                data: {
                    type: "opticians",
                    id: modal.state.optician,
                },
            },
            pastAppointment: undefined,
        };
    } else {
        return {};
    }
}

export function prepareAppointmentRelationshipsWithRoomAndOptician(modal: AppointmentModal) {
    if (modal.props.getSelectedStore) {
        return {
            store: {
                data: {
                    type: "stores",
                    id: modal.props.getSelectedStore.id,
                },
            },
            appointmentType: {
                data: {
                    type: "appointment-types",
                    id: modal.state.appointmentType,
                },
            },
            customer: {
                data: {
                    type: "customers",
                    id: undefined,
                },
            },
            consumerSelectedOptician: {
                data: {
                    type: "opticians",
                    id: modal.state.optician,
                },
            },
            consumerSelectedRoom: {
                data: {
                    type: "rooms",
                    id: modal.state.diary,
                },
            },
            pastAppointment: undefined,
        };
    } else {
        return {};
    }
}

export function prepareAppointmentRelationshipsWithoutRoomAndOptician(modal: AppointmentModal) {
    if (modal.props.getSelectedStore) {
        return {
            store: {
                data: {
                    type: "stores",
                    id: modal.props.getSelectedStore.id,
                },
            },
            appointmentType: {
                data: {
                    type: "appointment-types",
                    id: modal.state.appointmentType,
                },
            },
            customer: {
                data: {
                    type: "customers",
                    id: undefined,
                },
            },
            pastAppointment: undefined,
        };
    } else {
        return {};
    }

}

// this function will be removed after EditCustomerModal refactor (replace dynamic forms with standard HTML form)
export function prepareCustomerData(formValues: Immutable.Map<string, any> | undefined, dateOfBirth: Date | null): object {
    if (formValues !== undefined) {
        return {
            title: formValues.get("gender"),
            firstName: formValues.get("firstName"),
            lastName: formValues.get("lastName"),
            email: formValues.get("email"),
            phoneNumber: formValues.get("phone"),
            dateOfBirth: dateOfBirth ? new Date(Date.UTC(dateOfBirth.getFullYear(), dateOfBirth.getMonth(), dateOfBirth.getDate(), 0, 0, 0, 0)) : null,
        };
    } else {
        return {};
    }
}

export function prepareCustomerData_new(modal: AppointmentModal): object {
    return {
        title: modal.state.gender,
        firstName: modal.state.firstName,
        lastName: modal.state.lastName,
        email: modal.state.email,
        phoneNumber: modal.state.phone,
        dateOfBirth: modal.state.customerDateOfBirth ? new Date(Date.UTC(modal.state.customerDateOfBirth.getFullYear(), modal.state.customerDateOfBirth.getMonth(), modal.state.customerDateOfBirth.getDate(), 0, 0, 0, 0)) : null,
    };
}

export function prepareRecurringAppointmentsData(modal: AppointmentModal) {
    if (modal.state.recurringAppointmentsStartDate && modal.state.recurringAppointmentsEndDate) {
        return {
            startTime: modal.state.recurringAppointmentsStartTime,
            endTime: modal.state.recurringAppointmentsEndTime,
            startDateOfCycle: moment(modal.state.recurringAppointmentsStartDate).format("YYYY-MM-DD"),
            endDateOfCycle: moment(modal.state.recurringAppointmentsEndDate).format("YYYY-MM-DD"),
            patternType: "WEEKLY",
            interval: modal.state.interval,
            daysOfWeek: Array.from(modal.state.selectedDaysOfWeek),
        };

    }
}

export function prepareRecurringAppointmentsRelationships(modal: AppointmentModal) {
    const relationships = {store: {}, appointmentType: {}, room: {}, optician: {}};
    if (modal.props.getSelectedStore) {
        relationships.store = {
            data: {
                type: "stores",
                id: modal.props.getSelectedStore.id,
            },
        };
    }
    if (modal.props.getSelectedAppointmentType) {
        relationships.appointmentType = {
            data: {
                type: "appointment-types",
                id: modal.props.getSelectedAppointmentType.id,
            },
        };
    }
    const room = modal.state.diary === 0 || !modal.state.diary ? null : modal.state.diary;
    if (room) {
        relationships.room = {
            data: {
                type: "rooms",
                id: room,
            },
        };
    }
    const optician = modal.state.optician === "0" || !modal.state.optician ? null : modal.state.optician;
    if (optician) {
        relationships.optician = {
            data: {
                type: "opticians",
                id: optician,
            },
        };
    }
    return relationships;

}

export function resolveDayClassName(date: Date | null, timeslotsAvailabilityPerDays: ITimeslotAvailabilityPerDay[]): string {
    let dateHasAvailableTimeslots: boolean = false;
    if (date) {
        timeslotsAvailabilityPerDays.forEach((timeslotsAvailabilityPerDay) => {
            if (isSameDate(date, timeslotsAvailabilityPerDay.date) && timeslotsAvailabilityPerDay.percentageAvailability !== 0) {
                dateHasAvailableTimeslots = true;
            }
        });
    }
    return dateHasAvailableTimeslots ? "react-datepicker__day" : "day-grayed-out";
}

export function isFieldDisabled(modal: AppointmentModal): boolean {
    return modal.props.getSelectedAppointmentType === undefined;
}
