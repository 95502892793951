import React from "react";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {
    appointmentBookedModalAction,
    bookAppointmentMaxRetriesReachedModalAction, timeslotNotAvailableModalAction,
} from "../actions/genericModal.actions";
import {getLastCreatedOrUpdatedAppointment, IAppointmentData} from "../reducers/appointments.reducer";
import {getConfirmAppointmentModalConfig, IConfirmAppointmentModalConfig} from "../reducers/config.reducer";
import {fetchSingleAppointment} from "../thunks/appointments.thunk";

interface IProps {
    tenant: string;
}

interface IConnectProps {
    getConfigForConfirmAppointmentModal: IConfirmAppointmentModalConfig;
    lastCreatedOrUpdatedAppointment: IAppointmentData | undefined;
}

interface IDispatchProps {
    fetchSingleAppointment: (appointmentId: string, tenant: string) => void;
    setAppointmentBookedModal: () => void;
    setMaxRetriesReachedModal: () => void;
    setTimeslotNotAvailableModal: () => void;
}

interface IDataProps extends IProps, IConnectProps {
}

interface IResultProps extends IDataProps, IDispatchProps {
}

export class ConfirmAppointmentModalHandler extends React.Component<IResultProps> {
    private interval: any;
    private retries: number;
    private readonly maxRetries: number;

    constructor(props: Readonly<IResultProps>) {
        super(props);
        this.retries = 0;
        this.maxRetries = this.props.getConfigForConfirmAppointmentModal.maxRetries;
    }

    public render(): React.ReactNode {
        return null;
    }

    public componentDidMount(): void {
        this.interval = setInterval(() => this.refreshAppointment(), this.props.getConfigForConfirmAppointmentModal.interval);
    }

    public componentWillUnmount(): void {
        clearInterval(this.interval);
    }

    private refreshAppointment() {
        if (this.props.lastCreatedOrUpdatedAppointment !== undefined) {
            if (this.props.lastCreatedOrUpdatedAppointment.status === "BOOKED") {
                this.handleAppointmentBooked();
            } else if (this.props.lastCreatedOrUpdatedAppointment.status === "FAILED") {
                this.handleAppointmentFailed();
            } else if (this.retries < this.maxRetries) {
                this.retry(this.props.lastCreatedOrUpdatedAppointment.uuid);
            } else {
                this.handleMaxRetriesReached();
            }
        }
    }

    private retry(appointmentUuid: string) {
        this.props.fetchSingleAppointment(appointmentUuid, this.props.tenant);
        this.retries++;
    }

    private handleMaxRetriesReached() {
        this.props.setMaxRetriesReachedModal();
        clearInterval(this.interval);
    }

    private handleAppointmentBooked() {
        this.props.setAppointmentBookedModal();
        clearInterval(this.interval);
    }

    private handleAppointmentFailed() {
        this.props.setTimeslotNotAvailableModal();
        clearInterval(this.interval);
    }
}

const mapStateToProps = (store: any, ownProps: IProps): IDataProps => {
    return {
        getConfigForConfirmAppointmentModal: getConfirmAppointmentModalConfig(store),
        lastCreatedOrUpdatedAppointment: getLastCreatedOrUpdatedAppointment(store),
        tenant: ownProps.tenant,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): IDispatchProps => {
    return {
        fetchSingleAppointment: (appointmentId, tenant) => dispatch(fetchSingleAppointment(appointmentId, tenant)),
        setAppointmentBookedModal: () => dispatch(appointmentBookedModalAction()),
        setMaxRetriesReachedModal: () => dispatch(bookAppointmentMaxRetriesReachedModalAction()),
        setTimeslotNotAvailableModal: () => dispatch(timeslotNotAvailableModalAction()),
    };
};

export default connect<IDataProps, IDispatchProps, IProps>(
    mapStateToProps,
    mapDispatchToProps,
)(ConfirmAppointmentModalHandler);
