import {IRoomData} from "../reducers/room.reducer";
import {IEmptyAction} from "./action.actions";

export const SELECT_ROOM = "SELECT_ROOM";
export const FETCH_ROOMS = "FETCH_ROOMS";

interface ISelectRoomAction {
    type: typeof SELECT_ROOM;
    payload: IRoomData;
}

interface IFetchRoomsAction {
    type: typeof FETCH_ROOMS;
    payload: IRoomData[];
}

export type RoomActionTypes = ISelectRoomAction | IFetchRoomsAction | IEmptyAction;

export function selectRoomAction(room: IRoomData): ISelectRoomAction {
    return {
        payload: room,
        type: SELECT_ROOM,
    };
}

export function fetchRoomsAction(rooms: IRoomData[]): IFetchRoomsAction {
    return {
        payload: rooms,
        type: FETCH_ROOMS,
    };
}
