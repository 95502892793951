import {Dispatch} from "redux";
import {fetchOpticiansAction} from "../actions/opticians.actions";
import {IOpticianData} from "../reducers/opticians.reducer";
import {getOpticians} from "../services/opticians.service";

export const fetchOpticians = (storeId: string, appointmentTypeId: string, tenant: string) => {
    return (dispatch: Dispatch) => {
        return getOpticians(storeId, appointmentTypeId, tenant)
            .then((data) => {
                dispatch(fetchOpticiansAction(transformIntoOpticianData(data.data)));
            });
    };
};

const transformIntoOpticianData = (data: any[]): IOpticianData[] => {
    const result: IOpticianData[] = [];
    data.forEach((value) => {
        result.push({
            id: value.id,
            firstName: value.attributes.firstName,
            lastName: value.attributes.lastName,
            title: value.attributes.title,
        });
    });
    return result;
};
