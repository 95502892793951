import React from "react";
import { connect } from "react-redux";
import { GridStyle } from "../reducers/gridstyle.reducer";
import "./GridStyleSelector.scss";
import { findMonday } from "../util/date.util";
import {
    DateSelectorBaseComponent,
    dateSelectorBaseMapStateToProps,
    dateSelectorMapDispatchToProps,
    IDateSelectorBaseDataProps,
    IDateSelectorBaseDispatchFromProps,
    IDateSelectorBaseProps,
} from "./base/DateSelectorBase";

export class GridStyleSelector extends DateSelectorBaseComponent {

    public render(): React.ReactNode {
        return (
            <div className="grid_selector ui-element">
                <div
                    className={"hidden grid_selector_name " + (this.props.selectedGridStyle === GridStyle.MULTIROOM_DAY || this.props.selectedGridStyle === GridStyle.MULTIROOM_WEEK ? "grid_selector_active" : "grid_selector_inactive")}>
                    <span
                        onClick={() => this.changeToMultiroom()}>{this.props.getMessageForKey("gridSelector.mulitRoom")}</span>
                </div>
                <div className="hidden separator">|</div>
                <div
                    className={"hidden grid_selector_name " + (this.props.selectedGridStyle === GridStyle.CALENDAR_DAY || this.props.selectedGridStyle === GridStyle.CALENDAR_WEEK ? "grid_selector_active" : "grid_selector_inactive")}>
                    <span
                        onClick={() => this.changeToCalendar()}>{this.props.getMessageForKey("gridSelector.calendar")}</span>
                </div>
                <div  onClick={() => this.changeToDay()} className={"grid_selector_toggle grid_selector_day " + (this.props.selectedGridStyle === GridStyle.CALENDAR_DAY || this.props.selectedGridStyle === GridStyle.MULTIROOM_DAY ? "grid_selector_day_active" : "")}>
                    <span>{this.props.getMessageForKey("gridSelector.day")}</span>
                </div>
                <div onClick={() => this.changeToWeek()} className={"grid_selector_toggle grid_selector_week " + (this.props.selectedGridStyle === GridStyle.MULTIROOM_WEEK || this.props.selectedGridStyle === GridStyle.CALENDAR_WEEK ? "grid_selector_week_active" : "")}>
                    <span>{this.props.getMessageForKey("gridSelector.week")}</span>
                </div>
            </div>
        );
    }

    private changeToMultiroom = () => {
        if (this.props.selectedGridStyle === GridStyle.CALENDAR_WEEK) {
            //@ts-ignore
            this.props.onGridStyleSelect(GridStyle.MULTIROOM_WEEK);
        } else if (this.props.selectedGridStyle === GridStyle.CALENDAR_DAY) {
            //@ts-ignore
            this.props.onGridStyleSelect(GridStyle.MULTIROOM_DAY);
        }
    }


    private changeToCalendar = () => {
        if (this.props.selectedGridStyle === GridStyle.MULTIROOM_WEEK) {
            //@ts-ignore
            this.props.onGridStyleSelect(GridStyle.CALENDAR_WEEK);
        } else if (this.props.selectedGridStyle === GridStyle.MULTIROOM_DAY) {
            //@ts-ignore
            this.props.onGridStyleSelect(GridStyle.CALENDAR_DAY);
        }
    }

    private changeToDay = () => {
        if (this.props.selectedGridStyle === GridStyle.MULTIROOM_WEEK) {
            //@ts-ignore
            this.props.onGridStyleSelect(GridStyle.MULTIROOM_DAY);
        } else if (this.props.selectedGridStyle === GridStyle.CALENDAR_WEEK && this.props.selectedDate) {
            const newRange = {
                selectedDate: this.props.selectedDate.selectedDate,
                startRangeDate: this.props.selectedDate.selectedDate,
                range: 1,
            };
            this.props.changeDateRange(newRange);
            this.refreshData(newRange);
            //@ts-ignore
            this.props.onGridStyleSelect(GridStyle.CALENDAR_DAY);
        }

    }

    private changeToWeek = () => {
        if (this.props.selectedGridStyle === GridStyle.MULTIROOM_DAY) {
            //@ts-ignore
            this.props.onGridStyleSelect(GridStyle.MULTIROOM_WEEK);
        } else if (this.props.selectedGridStyle === GridStyle.CALENDAR_DAY && this.props.selectedDate) {
            const newRange = {
                selectedDate: this.props.selectedDate.selectedDate,
                startRangeDate: findMonday(this.props.selectedDate.selectedDate),
                range: 7,
            };
            this.props.changeDateRange(newRange);
            this.refreshData(newRange);
            //@ts-ignore
            this.props.onGridStyleSelect(GridStyle.CALENDAR_WEEK);
        }
    }
}

// @ts-ignore
export default connect<IDateSelectorBaseDataProps, IDateSelectorBaseDispatchFromProps, IDateSelectorBaseProps>(
    dateSelectorBaseMapStateToProps,
    dateSelectorMapDispatchToProps,
)(GridStyleSelector);
