import {createSelector} from "reselect";
import {
    APPOINTMENT_BOOKED_MODAL,
    BOOK_APPOINTMENT_MAX_RETRIES_REACHED_MODAL,
    BOOK_APPOINTMENTS_SERIES_BOOKED_MODAL,
    BOOK_APPOINTMENTS_SERIES_ERROR_MODAL,
    DECREASE_LOADING_DATA_COUNTER,
    ERROR_MODAL,
    GenericModalActionTypes,
    INCREASE_LOADING_DATA_COUNTER,
    PENDING_MODAL,
    TIMESLOT_NOT_AVAILABLE_MODAL,
} from "../actions/genericModal.actions";

export enum GenericModalMode {
    BOOKED = "BOOKED",
    PENDING = "PENDING",
    MAX_RETRIES_REACHED = "MAX_RETRIES_REACHED",
    SERIES_BOOKED = "SERIES_BOOKED",
    SERIES_ERROR = "SERIES_ERROR",
    GENERAL_ERROR = "GENERAL_ERROR",
    TIMESLOT_NOT_AVAILABLE = "TIMESLOT_NOT_AVAILABLE",
    LOADING_DATA = "LOADING_DATA",
}

export interface IGenericModalReducer {
    loadingDataCounter: number;
    modalMode: GenericModalMode | undefined;
}

const initialState: IGenericModalReducer = {
    loadingDataCounter: 0,
    modalMode: undefined,
};

export default function genericModalReducer(state = initialState, action: GenericModalActionTypes): IGenericModalReducer {
    switch (action.type) {
        case APPOINTMENT_BOOKED_MODAL:
            return {
                ...state,
                modalMode: GenericModalMode.BOOKED,
            };
        case BOOK_APPOINTMENT_MAX_RETRIES_REACHED_MODAL:
            return {
                ...state,
                modalMode: GenericModalMode.MAX_RETRIES_REACHED,
            };
        case PENDING_MODAL:
            return {
                ...state,
                modalMode: GenericModalMode.PENDING,
            };
        case BOOK_APPOINTMENTS_SERIES_BOOKED_MODAL:
            return {
                ...state,
                modalMode: GenericModalMode.SERIES_BOOKED,
            };
        case BOOK_APPOINTMENTS_SERIES_ERROR_MODAL:
            return {
                ...state,
                modalMode: GenericModalMode.SERIES_ERROR,
            };
        case ERROR_MODAL:
            return {
                ...state,
                modalMode: GenericModalMode.GENERAL_ERROR,
            };
        case TIMESLOT_NOT_AVAILABLE_MODAL:
            return {
                ...state,
                modalMode: GenericModalMode.TIMESLOT_NOT_AVAILABLE,
            };
        case INCREASE_LOADING_DATA_COUNTER: {
            return {
                ...state,
                loadingDataCounter: state.loadingDataCounter + 1,
            };
        }
        case DECREASE_LOADING_DATA_COUNTER: {
            return {
                ...state,
                loadingDataCounter: state.loadingDataCounter - 1,
            };
        }
        default:
            return state;
    }
}

const getGenericModalState = (state: any) => state.genericModal;

export const getModalMode = createSelector(getGenericModalState,
    (state: IGenericModalReducer) => state.loadingDataCounter > 0 ? GenericModalMode.LOADING_DATA : state.modalMode);

export const isLoadingData = createSelector(getGenericModalState,
    (state: IGenericModalReducer) => state.loadingDataCounter > 0);
