import React, {FormEvent} from "react";

interface IState {
    valid: boolean;
}

interface IProps {
    type: string;
    fieldName: string;
    onChange: (event: FormEvent<HTMLInputElement>) => void;
    disabled: boolean;
    value: string;
    className: string;
    isValid: (value: string) => boolean;
    min?: number;
    max?: number;
    label?: string;
    placeholder?: string;
    mandatory?: boolean;
    errorClassName?: string;
    overrideAvailability?: boolean; // to detect change in parent component
    notificationChannel?: string; // to detect change from parent component
    startTime?: string; // to detect change from parent component
}

export default class Input extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps>) {
        super(props);
        this.state = {
            valid: true,
        };
    }

    public componentDidMount(): void {
        if (this.props.value && this.props.value !== "") {
            this.validate(this.props.value);
        }
    }

    public componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (this.anyParentPropChanged(prevProps)) {
            if (this.props.value && this.props.value !== "") {
                this.validate(this.props.value);
            } else {
                this.setState({valid: true});
            }
        }
    }

    private anyParentPropChanged(prevProps: IProps) {
        return this.overrideAvailabilityChanged(prevProps)
            || this.notificationChannelChanged(prevProps)
            || this.startTimeChanged(prevProps);
    }

    private notificationChannelChanged(prevProps: IProps) {
        return prevProps.notificationChannel !== this.props.notificationChannel;
    }

    private overrideAvailabilityChanged(prevProps: IProps) {
        return prevProps.overrideAvailability !== this.props.overrideAvailability;
    }

    private startTimeChanged(prevProps: IProps) {
        return prevProps.startTime !== this.props.startTime;
    }

    public render(): React.ReactNode {
        return (
            <>
                {this.props.label ?
                    <div className="group-label">
                        <label>{this.props.label.concat(this.props.mandatory ? "*" : "")}</label>
                    </div> : <></>}
                <input type={this.props.type}
                       name={this.props.fieldName}
                       id={this.props.fieldName}
                       disabled={this.props.disabled}
                       onChange={(event) => this.onChange(event)}
                       onBlur={(event) => this.onBlur(event)}
                       value={this.props.value ? this.props.value : ""}
                       placeholder={this.props.placeholder}
                       className={this.props.className.concat(" ").concat(this.state.valid ? "" : this.props.errorClassName ? this.props.errorClassName : "")}
                       min={this.props.min}
                       max={this.props.max}
                       maxLength={255}/>
            </>);
    }

    private onBlur(event: React.FocusEvent<HTMLInputElement>) {
        this.validate(event.currentTarget.value);
    }

    private onChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.validate(event.currentTarget.value);
        this.props.onChange(event);
    }

    private validate(value: string) {
        this.setState({valid: this.props.isValid(value)});
    }
}
