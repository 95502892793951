import {createSelector} from "reselect";
import {
    INITIALIZE_APP_TYPES_FILTER,
    INITIALIZE_SUBSTATUS_FILTER,
    SidebarFiltersActionTypes,
    UPDATE_APPOINTMENT_TYPE_FILTER,
    UPDATE_SUBSTATUS_FILTER,
    TOGGLE_NON_CANCELLED_APPOINTMENTS
} from "../actions/sidebarFilters.actions";

export interface ISidebarFiltersReducer {
    selectedAppointmentTypesIds: string[];
    selectedSubstatuses: string[];
    toggleCancelledAppointment: boolean;
}

const initialState: ISidebarFiltersReducer = {
    selectedAppointmentTypesIds: [],
    selectedSubstatuses: [],
    toggleCancelledAppointment: false
};

export default function sidebarFiltersReducer(state = initialState, action: SidebarFiltersActionTypes): ISidebarFiltersReducer {
    switch (action.type) {
        case UPDATE_APPOINTMENT_TYPE_FILTER:
            return {
                ...state,
                selectedAppointmentTypesIds: updateFilters(state.selectedAppointmentTypesIds, action.payload),
            };
            case UPDATE_SUBSTATUS_FILTER:
                return {
                    ...state,
                    selectedSubstatuses: updateFilters(state.selectedSubstatuses, action.payload),
                };
            case INITIALIZE_SUBSTATUS_FILTER:
                return {
                    ...state,
                    selectedSubstatuses: addUndefinedStatus(action.payload),
                };
            case TOGGLE_NON_CANCELLED_APPOINTMENTS:
                return {
                    ...state,
                    toggleCancelledAppointment: action.payload
                };
        case INITIALIZE_APP_TYPES_FILTER:
            return {
                ...state,
                selectedAppointmentTypesIds: action.payload,
            };
        default:
            return state;
    }
}

function addUndefinedStatus(substatuses: string[]): string[] {
    const substatusesWithUndefinedSubstatus = [...substatuses];
    substatusesWithUndefinedSubstatus.push("UNDEFINED");
    return substatusesWithUndefinedSubstatus;
}

function updateFilters(filters: string[], filterValue: string): string[] {
    const filterUpdated = new Set<string>(filters);
    if (filterUpdated.has(filterValue)) {
        filterUpdated.delete(filterValue);
    } else {
        filterUpdated.add(filterValue);
    }
    return Array.from(filterUpdated);
}

const getSidebarFiltersState = (state: any) => state.sidebarFilters;

export const getSelectedAppointmentTypesFilter = createSelector(getSidebarFiltersState,
    (state: ISidebarFiltersReducer) => state.selectedAppointmentTypesIds);

export const getSelectedSubstatusesFilter = createSelector(getSidebarFiltersState,
    (state: ISidebarFiltersReducer) => state.selectedSubstatuses);

export const getCancelledAppointmentToggle = createSelector(getSidebarFiltersState,
    (state: ISidebarFiltersReducer) => state.toggleCancelledAppointment);