import React from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {selectGridStyleAction} from "../actions/gridstyle.action";
import AppointmentModal, {AppointmentModalMode} from "../modal/AppointmentModal";
import {getSelectedGridStyle, GridStyle} from "../reducers/gridstyle.reducer";
import {getSelectedRoom, IRoomData} from "../reducers/room.reducer";
import {getSelectedDate, ISelectedDateAndRange} from "../reducers/selecteddate.reducer";
import {getSelectedStore, IStoreData} from "../reducers/store.reducer";
import {fetchAppointments} from "../thunks/appointments.thunk";
import DateSelector from "./DateSelector";
import "./Diary.scss";
import {CalendarDayGrid} from "./grids/CalendarDayGrid";
import {CalendarWeekGrid} from "./grids/CalendarWeekGrid";
import {MultiRoomDayGrid} from "./grids/MultiRoomDayGrid";
import {MultiRoomWeekGrid} from "./grids/MultiRoomWeekGrid";
import GridStyleSelector from "./GridStyleSelector";
import Sidebar from "./Sidebar";
import {cleanAdditionalFieldsAction} from "../actions/additionalFields.action";

interface IProps {
    showPopup?: boolean;
    tenant: string;
}

interface IConnectProps {
    selectedGridStyle: GridStyle | undefined;
    startDate: ISelectedDateAndRange | undefined;
    selectedRoom: IRoomData | undefined;
    getSelectedStore: IStoreData | undefined;
}

interface IDispatchFromProps {
    selectGridStyle: (gridStyle: GridStyle) => void;
    fetchAppointments: (storeId: string, roomId: number, startTime: Date, endTime: Date, tenant: string) => void;
    dispatchCleanAdditionalFields: () => void;
}

interface IDataProps extends IProps, IConnectProps {
}

export interface IResultProps extends IDataProps, IDispatchFromProps {
}

interface IDiaryState {
    showNewAppointmentModal: boolean;
    windowHeight: number;
}

export class Diary extends React.Component<IResultProps, IDiaryState> {

    private diaryElem: HTMLDivElement | null;

    constructor(props: IResultProps) {
        super(props);
        this.state = {
            showNewAppointmentModal: false,
            windowHeight: 0,
        };
        this.diaryElem = null;
    }

    public render(): React.ReactNode {
        return (
            <div className="diary_content">
                <div className="sidebar">
                    <Sidebar tenant={this.props.tenant} height={this.diaryElem ? this.diaryElem.offsetHeight : 0}/>
                </div>
                <div className="diary_body" ref={(elem) => (this.diaryElem = elem)}>
                    <button onClick={() => this.showModal()} className="float-btn-appointment-modal">
                        <span className="float-plus">+</span>
                    </button>
                    <div>
                        <DateSelector tenant={this.props.tenant}/>
                        <GridStyleSelector onGridStyleSelect={(gridstyle: any) => this.onGridStyleSelect(gridstyle)}
                                           tenant={this.props.tenant}/>
                    </div>
                    <div>
                        {(() => {
                            switch (this.props.selectedGridStyle) {
                                case GridStyle.MULTIROOM_DAY:
                                    return <MultiRoomDayGrid/>;
                                case GridStyle.MULTIROOM_WEEK:
                                    return <MultiRoomWeekGrid/>;
                                case GridStyle.CALENDAR_DAY:
                                    return <CalendarDayGrid startDate={this.props.startDate}
                                                            tenant={this.props.tenant}/>;
                                case GridStyle.CALENDAR_WEEK:
                                    return <CalendarWeekGrid startDate={this.props.startDate}
                                                             tenant={this.props.tenant}/>;
                                default:
                                    return null;
                            }
                        })()}
                    </div>
                    {this.state.showNewAppointmentModal ?
                        <AppointmentModal closePopup={() => this.closeModal()}
                                          tenant={this.props.tenant}
                                          refreshMatrixOnClose={true}
                                          mode={AppointmentModalMode.NEW}
                                          additionalFieldsForAppTypeFromAppointment={[]}
                        /> : null
                    }
                </div>
            </div>
        );
    }

    public componentDidMount(): void {
        // Load appointments again to prevent display of old filtered appointments
        // TODO: Implement a better method and place to load appointments
        if (this.props.getSelectedStore && this.props.selectedRoom && this.props.startDate) {
            const endDate = new Date(this.props.startDate.startRangeDate);
            endDate.setDate(this.props.startDate.startRangeDate.getDate() + this.props.startDate.range);
            this.props.fetchAppointments(this.props.getSelectedStore.id, this.props.selectedRoom.id,
                new Date(this.props.startDate.startRangeDate), endDate, this.props.tenant);
        }
        window.addEventListener("resize", (e) => this.changeWindowHeight());
    }

    public componentWillUnmount(): void {
        window.removeEventListener("resize", (e) => this.changeWindowHeight());
    }

    private showModal(): void {
        this.props.dispatchCleanAdditionalFields();
        this.setState({
            showNewAppointmentModal: true,
        });
    }

    private closeModal(): void {
        this.setState({
            showNewAppointmentModal: false,
        });
    }

    private onGridStyleSelect(gridStyle: GridStyle): void {
        this.props.selectGridStyle(gridStyle);
    }

    private changeWindowHeight = () => {
        this.setState({windowHeight: window.innerHeight}); // force component to re-render
    }

}

const mapStateToProps = (store: any, ownProps: IProps): IDataProps => {
    return {
        selectedGridStyle: getSelectedGridStyle(store),
        startDate: getSelectedDate(store),
        selectedRoom: getSelectedRoom(store),
        getSelectedStore: getSelectedStore(store),
        tenant: ownProps.tenant,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchFromProps => {
    return {
        selectGridStyle: (gridstyle) => dispatch(selectGridStyleAction(gridstyle)),
        fetchAppointments: (storeId, roomId, startTime, endTime, tenant) => fetchAppointments(storeId, roomId, startTime, endTime, tenant, dispatch),
        dispatchCleanAdditionalFields: () => dispatch(cleanAdditionalFieldsAction()),
    };
};

export default connect<IDataProps, IDispatchFromProps, IProps>(
    mapStateToProps,
    mapDispatchToProps,
)(Diary);
