import React from "react";

interface IIconProps {
    image: any;
    height: string;
    width: string;
    styleName: string;
}

export default class Icon extends React.Component<IIconProps> {

    public render(): React.ReactNode {

        return (
            <div className={this.props.styleName}>
                <img src={this.props.image} alt=""
                     height={this.props.height} width={this.props.width}/>
            </div>);
    }
}