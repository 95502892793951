export const UPDATE_APPOINTMENT_TYPE_FILTER = "UPDATE_APPOINTMENT_TYPE_FILTER";
export const UPDATE_CANCELLED_APPOINTMENT_FILTER = "UPDATE_CANCELLED_APPOINTMENT_FILTER";
export const UPDATE_SUBSTATUS_FILTER = "UPDATE_SUBSTATUS_FILTER";
export const INITIALIZE_SUBSTATUS_FILTER = "UPDATE_SUBSTATUS_FILTERES";
export const INITIALIZE_APP_TYPES_FILTER = "INITIALIZE_APP_TYPES_FILTER";
export const TOGGLE_NON_CANCELLED_APPOINTMENTS = "TOGGLE_NON_CANCELLED_APPOINTMENTS";

interface IUpdateAppointmentTypesFilterAction {
    type: typeof UPDATE_APPOINTMENT_TYPE_FILTER;
    payload: string;
}
interface IUpdateToggleCancelledAppointmentsAction {
    type: typeof TOGGLE_NON_CANCELLED_APPOINTMENTS;
    payload: boolean;
}

interface IUpdateSubstatusesFilterAction {
    type: typeof UPDATE_SUBSTATUS_FILTER;
    payload: string;
}
interface IUpdateCancelledAppointmentFilter {
    type: typeof UPDATE_CANCELLED_APPOINTMENT_FILTER;
    payload: string;
}

interface IInitSelectedSubstatusesFilter {
    type: typeof INITIALIZE_SUBSTATUS_FILTER;
    payload: string[];
}

interface IInitSelectedAppTypesFilter {
    type: typeof INITIALIZE_APP_TYPES_FILTER;
    payload: string[];
}

export type SidebarFiltersActionTypes = IUpdateAppointmentTypesFilterAction | IUpdateSubstatusesFilterAction | IInitSelectedSubstatusesFilter | IInitSelectedAppTypesFilter | IUpdateToggleCancelledAppointmentsAction;

export function updateAppointmentTypesFilterAction(appointmentType: string): IUpdateAppointmentTypesFilterAction {
    return {
        payload: appointmentType,
        type: UPDATE_APPOINTMENT_TYPE_FILTER,
    };
}

export function updateToggleCancelledAppointmentsAction(toggle: boolean): IUpdateToggleCancelledAppointmentsAction {
    return {
        payload: toggle,
        type: TOGGLE_NON_CANCELLED_APPOINTMENTS,
    };
}

export function updateCancelledAppointmentFilterAction(appointmentType: string): IUpdateCancelledAppointmentFilter {
    return {
        payload: appointmentType,
        type: UPDATE_CANCELLED_APPOINTMENT_FILTER,
    };
}

export function updateSubstatusesFilterAction(substatus: string): IUpdateSubstatusesFilterAction {
    return {
        payload: substatus,
        type: UPDATE_SUBSTATUS_FILTER,
    };
}

export function initSelectedSubstatusesFilterAction(substatuses: string[]): IInitSelectedSubstatusesFilter {
    return {
        payload: substatuses,
        type: INITIALIZE_SUBSTATUS_FILTER,
    };
}

export function initSelectedAppTypesFilterAction(appTypes: string[]): IInitSelectedAppTypesFilter {
    return {
        payload: appTypes,
        type: INITIALIZE_APP_TYPES_FILTER,
    };
}
