import React, {ReactNodeArray} from "react";
import {connect} from "react-redux";
import {getLocalizedText} from "../reducers/locale.reducer";
import "./AppointmentFilter.scss";

export interface IAppointmentFilterData {
    value: string;
    name: string;
}

interface IState {
    allSelected: boolean;
    filterExpanded: boolean;
}

interface IProps {
    label: string;
    availableFilterValues: IAppointmentFilterData[];
    selectedFilterValues: string[];
    updateFilter: (filterValue: string) => void;
}

interface IConnectProps {
    getMessageForKey: (key: string) => string;
}

interface IDataProps extends IProps, IConnectProps {
}

export class AppointmentFilter extends React.Component<IDataProps, IState> {

    constructor(props: any) {
        super(props);
        this.state = {
            allSelected: this.props.selectedFilterValues.length === this.props.availableFilterValues.length,
            filterExpanded: true,
        };
    }

    public render(): React.ReactNode {
        return (<div className="appointment-filter">
            <div className="filter-header">
                <span className="filter-label">{this.props.label}</span>
                {this.state.filterExpanded ?
                    <img src={process.env.PUBLIC_URL + "/statics/img/filter_arrow_up.svg"}
                         alt=""
                         onClick={() => this.toggleExpandFilter()}/> :
                    <img src={process.env.PUBLIC_URL + "/statics/img/filter_arrow_down.svg"}
                         alt=""
                         onClick={() => this.toggleExpandFilter()}/>
                }
            </div>
            {this.state.filterExpanded ? this.renderFilterOptions() : null}
        </div>);
    }

    public componentDidUpdate(prevProps: IDataProps, prevState: IState) {
        if (!this.state.allSelected && this.props.selectedFilterValues.length === this.props.availableFilterValues.length) {
            this.setState({allSelected: true});
        }
    }

    private renderFilterOptions(): ReactNodeArray {
        const filterOptions: ReactNodeArray = [];
        filterOptions.push(
            <div key="all">
                <label className="container" htmlFor={"all" + this.props.label}>
                    <span>{this.props.getMessageForKey("sidebar.filters.all")}</span>
                    <input type="checkbox"
                           id={"all" + this.props.label}
                           checked={this.state.allSelected}
                           onChange={() => this.toggleAllFilter()}/>
                    <span className="checkmark"/>
                </label>
            </div>);
        this.props.availableFilterValues.forEach((availableFilterValue) => {
            filterOptions.push(
                <div key={availableFilterValue.value}>
                    <label className="container" htmlFor={availableFilterValue.value}>
                        <span>{availableFilterValue.name}</span>
                        <input type="checkbox" id={availableFilterValue.value}
                               value={availableFilterValue.value}
                               checked={this.state.allSelected || this.props.selectedFilterValues.includes(availableFilterValue.value)}
                               onChange={(event) => this.handleFilterChange(event.currentTarget.value)}/>
                        <span className="checkmark"/>
                    </label>
                </div>);
        });
        return filterOptions;
    }

    private handleFilterChange(filterValue: string) {
        if (this.props.selectedFilterValues.includes(filterValue)) { // means we are deselecting filter
            this.setState({allSelected: false});
        }
        this.props.updateFilter(filterValue);
    }

    private toggleAllFilter() {
        if (this.state.allSelected) { // means we are deselecting "ALL"
            this.props.selectedFilterValues.forEach((selectedFilterValue: string) => {
                this.props.updateFilter(selectedFilterValue);
            });
        } else { // means we are selecting "ALL"
            this.retrieveUnselectedFilterValues()
                .forEach((unselectedFilterValue: string) => {
                    this.props.updateFilter(unselectedFilterValue);
                });
        }
        this.setState({
            allSelected: !this.state.allSelected,
        });
    }

    private retrieveUnselectedFilterValues(): string[] {
        const unselectedFilterValues = [...this.props.availableFilterValues.map((availableFilterValue) => availableFilterValue.value)];
        this.props.selectedFilterValues.forEach((selectedFilterValue: string) => {
            unselectedFilterValues.splice(unselectedFilterValues.indexOf(selectedFilterValue), 1);
        });
        return unselectedFilterValues;
    }

    private toggleExpandFilter() {
        this.setState({filterExpanded: !this.state.filterExpanded});
    }
}

const mapStateToProps = (store: any, ownProps: IProps): IDataProps => {
    return {
        label: ownProps.label,
        availableFilterValues: ownProps.availableFilterValues,
        selectedFilterValues: ownProps.selectedFilterValues,
        updateFilter: ownProps.updateFilter,
        getMessageForKey: getLocalizedText(store),
    };
};

export default connect<IDataProps, void, IProps>(
    mapStateToProps,
)(AppointmentFilter);
