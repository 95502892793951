import {createSelector} from "reselect";
import {FETCH_SUBSTATUSES_CONFIG, FetchSubstatusesConfig} from "../actions/substatusesConfig.action";
import {getLocalizedText} from "./locale.reducer";

export interface ISubstatusesConfigReducer {
    availableSubstatuses: string[];
}

const initialState: ISubstatusesConfigReducer = {
    availableSubstatuses: [],
};

export default function substatusesConfigReducer(state = initialState, action: FetchSubstatusesConfig): ISubstatusesConfigReducer {
    switch (action.type) {
        case FETCH_SUBSTATUSES_CONFIG:
            return {
                ...state,
                availableSubstatuses: action.payload,
            };
        default:
            return state;
    }
}

const getSubstatusesConfigState = (state: any) => state.substatusesConfig;
export const getAvailableSubstatuses = createSelector(getSubstatusesConfigState, (state: ISubstatusesConfigReducer) => state.availableSubstatuses);
export const getSubstatusName = createSelector([getLocalizedText, getSubstatusesConfigState], (localizedTextGetter, state) => {
    return (subtatus: string) => {
        if (state.availableSubstatuses.includes(subtatus)) {
            return localizedTextGetter("appointment.substatus." + subtatus);
        } else {
            return localizedTextGetter("appointment.substatus.UNDEFINED");
        }
    };
});
