import React from "react";

export class MultiRoomDayGrid extends React.Component {

  public render(): React.ReactNode {
    return (
      <div className="day_grid">
        multiroom- day
      </div>
    );
  }

}
