import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {ICustomerData} from "../../../reducers/customers.reducer";
import {getLocalizedText} from "../../../reducers/locale.reducer";

interface IProps {
    customer: ICustomerData;
    newAppointmentHandler: (customer: ICustomerData) => void;
    showCustomerInfoPage: (customer: ICustomerData) => void;
}

interface IConnectProps {
    getMessageForKey: (key: string) => string;
}

interface IResultProps extends IProps, IConnectProps {
}

export class CustomerPageRow extends React.Component<IResultProps> {

    public render(): React.ReactNode {
        return (this.renderCustomer());
    }

    private renderCustomer(): ReactNode {
        return (
            <div className="row customer-row">
                <div className="col">
                    {this.props.customer.firstName}
                </div>
                <div className="col">
                    {this.props.customer.lastName}
                </div>
                <div className="col">
                    {this.props.customer.email}
                </div>
                <div className="col">
                    {this.props.customer.phoneNumber}
                </div>
                <div className="col">
                    {this.props.customer.dateOfBirth}
                </div>
                <div className="col">
                    <input type="button"
                           value={this.props.getMessageForKey("customerSearch.result.button.viewDetails")}
                           className="button-blue-no-bg" onClick={() => this.showCustomerInfoPage()}/>
                </div>
                <div className="col">
                    <input type="button"
                           value={this.props.getMessageForKey("customerSearch.result.button.newAppointment")}
                           className="button-blue-no-bg" onClick={() => this.newAppointmentClickHandler()}/>
                </div>
            </div>
        );
    }

    private newAppointmentClickHandler() {
        this.props.newAppointmentHandler(this.props.customer);
    }

    private showCustomerInfoPage() {
        this.props.showCustomerInfoPage(this.props.customer);
    }
}

const mapStateToProps = (store: any, ownProps: IProps): IResultProps => {
    return {
        customer: ownProps.customer,
        getMessageForKey: getLocalizedText(store),
        newAppointmentHandler: ownProps.newAppointmentHandler,
        showCustomerInfoPage: ownProps.showCustomerInfoPage,
    };
};

export default connect<IResultProps, any, any>(
    mapStateToProps,
    undefined,
)(CustomerPageRow);
