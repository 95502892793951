import {createSelector} from "reselect";
import {
    CustomerActionTypes,
    FETCH_CUSTOMERS,
    FETCH_MORE_CUSTOMERS_FOR_URL,
} from "../actions/customer.actions";
import {encodeSquareBrackets} from "../util/url.util";

export interface ICustomerData {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    gender: string;
    dateOfBirth: string;
}

export interface ICustomerSearchPayload {
    customers: ICustomerData[];
    nextLink: string | undefined;
    prevLink: string | undefined;
}

export interface ICustomersReducer {
    customers: ICustomerData[];
    nextLink: string | undefined;
    prevLink: string | undefined;
}

const initialState: ICustomersReducer = {
    customers: [],
    nextLink: undefined,
    prevLink: undefined,
};

export default function customersReducer(state = initialState, action: CustomerActionTypes): ICustomersReducer {
    switch (action.type) {
        case FETCH_CUSTOMERS:
            return {
                customers: action.payload.customers,
                nextLink: action.payload.nextLink,
                prevLink: action.payload.prevLink,
            };
        case FETCH_MORE_CUSTOMERS_FOR_URL:
            return {
                customers: state.customers.concat(action.payload.customers),
                nextLink: action.payload.nextLink,
                prevLink: action.payload.prevLink,
            };
        default:
            return state;
    }
}

const getCustomersState = (state: any) => state.customers;
export const getCustomers = createSelector(getCustomersState,
    (state: ICustomersReducer) => state.customers);

export const getNextLink = createSelector(getCustomersState,
    (state: ICustomersReducer) => state.nextLink !== undefined ? encodeSquareBrackets(state.nextLink) : state.nextLink);

export const getPrevLink = createSelector(getCustomersState,
    (state: ICustomersReducer) => state.prevLink !== undefined ? encodeSquareBrackets(state.prevLink) : state.prevLink);
