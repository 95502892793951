import React, {ReactNode, ReactNodeArray} from "react";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {getNextLink, ICustomerData} from "../../../reducers/customers.reducer";
import {getLocalizedText} from "../../../reducers/locale.reducer";
import {getMoreCustomersForUrl} from "../../../thunks/customer.thunk";
import CustomerModalRow from "../modal/CustomerModalRow";
import Pagination from "../modal/Pagination";
import CustomerPageRow from "../page/CustomerPageRow";
import CustomerSearchResultHeader from "./CustomerSearchResultHeader";

export enum PaginationType {
    VIEW_MORE_BUTTON = "VIEW_MORE_BUTTON",
    PREV_NEXT_BUTTONS = "PREV_NEXT_BUTTONS",
}

export enum CustomerRowType {
    PAGE = "PAGE",
    MODAL = "MODAL",
}

interface IDispatchFromProps {
    getMoreCustomersForUrl: (nextLink: string, tenant: string) => void;
}

interface IProps {
    customers: ICustomerData[];
    newAppointmentHandler?: (customer: ICustomerData) => void;
    tenant: string;
    emptyHeaderColumns: number;
    customerRowType: CustomerRowType;
    paginationType: PaginationType;
    selectCustomer?: (ICustomerData: any) => void;
    showCustomerInfoPage?: (ICustomerData: any) => void;
}

interface IConnectProps {
    getMessageForKey: (key: string) => string;
    nextLink: string | undefined;
}

interface IDataProps extends IProps, IConnectProps {
}

interface IResultProps extends IDataProps, IDispatchFromProps {
}

class CustomerSearchResult extends React.Component<IResultProps> {

    public render(): React.ReactNode {
        return (
            <div className="customer_search-result">
                <fieldset>
                    <legend>{this.props.getMessageForKey("customerSearch.result.legend")}</legend>
                    <div className="result-content">
                        {this.renderHeader()}
                        {this.props.customers.length > 0 ? this.renderCustomers() : this.renderNoResults()}
                    </div>
                    {this.renderPagination()}
                </fieldset>
            </div>);
    }

    private renderPagination(): ReactNode {
        if (this.props.paginationType === PaginationType.VIEW_MORE_BUTTON) {
            return this.renderViewMoreButton();
        } else if (this.props.paginationType === PaginationType.PREV_NEXT_BUTTONS) {
            return this.renderPaginationComponent();
        } else {
            return <></>;
        }
    }

    private renderNoResults(): ReactNode {
        return (
            <div className="no-results">
                {this.props.getMessageForKey("customerSearch.result.no.results")}
            </div>);
    }

    private renderHeader(): ReactNode {
        return <CustomerSearchResultHeader emptyHeaderColumns={this.props.emptyHeaderColumns}/>;
    }

    private renderCustomers(): ReactNodeArray {
        const nodes: ReactNodeArray = [];
        if (this.props.customerRowType === CustomerRowType.PAGE) {
            this.props.customers.forEach((customer) => {
                nodes.push(<CustomerPageRow customer={customer}
                                            key={customer.id}
                                            showCustomerInfoPage={this.props.showCustomerInfoPage}
                                            newAppointmentHandler={this.props.newAppointmentHandler}
                />);
            });
        } else {
            this.props.customers.forEach((customer) => {
                nodes.push(<CustomerModalRow customer={customer}
                                             key={customer.id}
                                             selectCustomer={this.props.selectCustomer}
                                             newAppointmentHandler={this.props.newAppointmentHandler}
                />);
            });
        }
        return nodes;
    }

    private renderViewMoreButton(): ReactNode {
        return this.props.nextLink ? <input type="button" className="button-blue-full-bg view_more-btn"
                                            value={this.props.getMessageForKey("customerSearch.form.button.viewMore.label")}
                                            onClick={() => this.viewMoreClickHandler()}/>
            : null;
    }

    private viewMoreClickHandler() {
        this.props.getMoreCustomersForUrl(this.props.nextLink ? this.props.nextLink : "", this.props.tenant);
    }

    private renderPaginationComponent(): ReactNode {
        return <Pagination tenant={this.props.tenant}/>;
    }

}

const mapStateToProps = (store: any, ownProps: IProps): IDataProps => {
    return {
        customerRowType: ownProps.customerRowType,
        customers: ownProps.customers,
        emptyHeaderColumns: ownProps.emptyHeaderColumns,
        getMessageForKey: getLocalizedText(store),
        newAppointmentHandler: ownProps.newAppointmentHandler,
        nextLink: getNextLink(store),
        paginationType: ownProps.paginationType,
        tenant: ownProps.tenant,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): IDispatchFromProps => {
    return {
        getMoreCustomersForUrl: ((url, tenant) => dispatch(getMoreCustomersForUrl(url, tenant))),
    };
};

export default connect<IDataProps, IDispatchFromProps, IProps>(
    mapStateToProps,
    mapDispatchToProps,
)(CustomerSearchResult);
