import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { getLocalizedText } from "../reducers/locale.reducer";
import { getSelectedRoom, IRoomData } from "../reducers/room.reducer";
import { getSelectedDate, ISelectedDateAndRange } from "../reducers/selecteddate.reducer";
import { getSelectedStore, IStoreData } from "../reducers/store.reducer";
import { deleteRecurringAppointment } from "../thunks/recurringAppointments.thunk";
import "./CancelAppointmentSeriesModal.scss";

interface IProps {
    appointmentSeriesId?: string | null;
    tenant: string;
    closeModal: () => void;
}

interface IConnectProps {
    getLocalizedText: (key: string) => string;
    startDate: ISelectedDateAndRange | undefined;
    selectedRoom: IRoomData | undefined;
    selectedStore: IStoreData | undefined;
}

interface IDispatchFromProps {
    deleteAppointmentsSeries: (seriesId: any, tenant: string, storeId: string, roomId: number, startTime: Date, endTime: Date) => void;
}

interface IDataProps extends IProps, IConnectProps {
}

interface IResultProps extends IDataProps, IDispatchFromProps {
}

export class CancelAppointmentSeriesModalComponent extends React.Component<IResultProps> {

    constructor(props: IResultProps) {
        super(props);
        this.state = {
            selectedReasonId: undefined,
        };
    }

    public render(): React.ReactNode {
        return (
            <div className="cancel-appointment-series-modal-container">
                <div className="background_greyed">
                    <div className="cancel-appointment-series-modal">
                        <div className="float-right close-popup" onClick={() => this.props.closeModal()}>&times;</div>
                        <h1 className="cancel-appointment-series-modal-header">{this.props.getLocalizedText("cancelAppointmentSeriesModal.header")}</h1>
                        <div
                            className="cancel-series-label">{this.props.getLocalizedText("cancelAppointmentSeriesModal.label")}</div>
                        <div className="buttons">
                            <button className="no_button" onClick={() => this.props.closeModal()}>
                                {this.props.getLocalizedText("cancelAppointmentSeriesModal.button.no")}
                            </button>
                            <button className="yes_button" onClick={() => this.sendCancelRequest()}>
                                {this.props.getLocalizedText("cancelAppointmentSeriesModal.button.yes")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private sendCancelRequest(): void {
        if (this.props.selectedStore && this.props.selectedRoom && this.props.startDate) {
            const endDate = new Date(this.props.startDate.startRangeDate);
            endDate.setDate(this.props.startDate.startRangeDate.getDate() + this.props.startDate.range);
            this.props.deleteAppointmentsSeries(this.props.appointmentSeriesId, this.props.tenant,
                this.props.selectedStore.id, this.props.selectedRoom.id, new Date(this.props.startDate.startRangeDate), endDate);
            this.props.closeModal();
        }
    }
}

const mapStateToProps = (store: any, ownProps: IProps): IDataProps => {
    return {
        appointmentSeriesId: ownProps.appointmentSeriesId,
        closeModal: ownProps.closeModal,
        tenant: ownProps.tenant,
        getLocalizedText: getLocalizedText(store),
        startDate: getSelectedDate(store),
        selectedRoom: getSelectedRoom(store),
        selectedStore: getSelectedStore(store),
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): IDispatchFromProps => {
    return {
        deleteAppointmentsSeries: (seriesId: any, tenant: string, storeId: string, roomId: number, startTime: Date, endTime: Date) => {
            dispatch(deleteRecurringAppointment(seriesId, tenant, storeId, roomId, startTime, endTime));
        },
    };
};

export default connect<IDataProps, IDispatchFromProps, IProps>(
    mapStateToProps,
    mapDispatchToProps,
)(CancelAppointmentSeriesModalComponent);
