import { ISelectedDateAndRange } from "../reducers/selecteddate.reducer";
import { IEmptyAction } from "./action.actions";

export const SELECTED_DATE_CHANGE = "SELECTED_DATE_CHANGE";
export const SELECT_CURRENT_WEEK = "SELECT_CURRENT_WEEK";
export const SET_ADJUST_SIDEBAR_TO_DATEPICKER_FLAG = "SET_ADJUST_SIDEBAR_TO_DATEPICKER_FLAG";
export const SIDEBAR_SELECTED_DATE_CHANGE = "SIDEBAR_SELECTED_DATE_CHANGE";
export const CHANGE_DATE_RANGE = "CHANGE_DATE_RANGE";

export type SelectedDateActionTypes =
    ISelectedDateChangeAction
    | ISetAdjustSidebarToDatepicker
    | ISelectCurrentWeekAction
    | ISidebarSelectedDateChangeAction
    | IEmptyAction
    | IChangeDateRange;

interface ISelectedDateChangeAction {
    type: typeof SELECTED_DATE_CHANGE;
    payload: ISelectedDateAndRange;
}

interface ISetAdjustSidebarToDatepicker {
    type: typeof SET_ADJUST_SIDEBAR_TO_DATEPICKER_FLAG;
    payload: boolean;
}

interface ISelectCurrentWeekAction {
    type: typeof SELECT_CURRENT_WEEK;
    payload: Date;
}

interface ISidebarSelectedDateChangeAction {
    type: typeof SIDEBAR_SELECTED_DATE_CHANGE;
    payload: ISelectedDateAndRange;

}

interface IChangeDateRange {
    type: typeof CHANGE_DATE_RANGE;
    payload: ISelectedDateAndRange;
}

export function selectWeekChangeAction(date: Date): ISelectCurrentWeekAction {
    return {
        payload: date,
        type: SELECT_CURRENT_WEEK,
    };
}

export function selectedDateChangeAction(date: ISelectedDateAndRange): ISelectedDateChangeAction {
    return {
        payload: date,
        type: SELECTED_DATE_CHANGE,
    };
}

export function selectSidebarDateChangeAction(date: ISelectedDateAndRange): ISidebarSelectedDateChangeAction {
    return {
        payload: date,
        type: SIDEBAR_SELECTED_DATE_CHANGE,
    };
}

export function setAdjustSidebarToDatepickerFlagAction(adjustSidebar: boolean): ISetAdjustSidebarToDatepicker {
    return {
        payload: adjustSidebar,
        type: SET_ADJUST_SIDEBAR_TO_DATEPICKER_FLAG,
    };
}

export function changeDateRangeAction(date: ISelectedDateAndRange): IChangeDateRange {
    return {
        payload: date,
        type: CHANGE_DATE_RANGE,
    };
}
