import React from "react";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {selectStoresAction} from "../actions/store.action";
import {getFallbackLocale, getLocalizedText, getSelectedLocale} from "../reducers/locale.reducer";
import {getSelectedDate, ISelectedDateAndRange} from "../reducers/selecteddate.reducer";
import {getAvailableStores, getSelectedStore, IStoreData} from "../reducers/store.reducer";
import {fetchAppointmentTypes} from "../thunks/appointmentTypes.thunk";
import {fetchClosingHours} from "../thunks/closinghours.thunk";
import {fetchRoomsForStore} from "../thunks/room.thunk";
import "./Header.scss";
import HeaderIcon from "./HeaderIcon";
import StoreDropdown from "./StoreDropdown";
import {getStoreName} from "../util/storeName.util";
import {getKeycloakInstance} from "../services/axios.config";

interface IProps {
    tenant: string;
}

interface IConnectProps {
    getMessageForKey: (key: string) => string;
    availableStores: IStoreData[];
    selectedStore: IStoreData | undefined;
    selectedLocale: string | undefined;
    fallbackLocale: string | undefined;
    selectedDate: ISelectedDateAndRange | undefined;
}

interface IDispatchFromProps {
    selectStore: (store: IStoreData) => void;
    fetchRoomsForStore: (storeId: string, tenant: string, startDate: Date, endDate: Date) => void;
    fetchAppointmentTypes: (storeId: string, tenant: string) => void;
    fetchClosingHours: (storeId: string, tenant: string, startDate: Date, requestedNumberOfDays: number) => void;
}

interface IDataProps extends IProps, IConnectProps {
}

interface IResultProps extends IDataProps, IDispatchFromProps {
}

export class HeaderComponent extends React.Component<IResultProps> {

    public render(): React.ReactNode {
        return (
            <div className="nav shadow p-3 bg-white rounded">
                <nav className="navbar navbar-light">

                    <div className="navbar-brand">
                        <img className="header_logo"
                             src={process.env.PUBLIC_URL + "/statics/img/logo-" + this.props.tenant + ".jpg"}
                             alt="logo" height="50px" width="300px"/>
                    </div>
                    <div className="header-first-icons">
                        <div className="separator"/>
                        <HeaderIcon image={process.env.PUBLIC_URL + "/statics/img/diary.svg"} link={true}
                                    name={this.props.getMessageForKey("header.icons.diary")} alt="diary"/>

                        <HeaderIcon image={process.env.PUBLIC_URL + "/statics/img/search.svg"} link={true}
                                    name={this.props.getMessageForKey("header.icons.search")} alt="search"/>

                        <HeaderIcon hidden={true} image={process.env.PUBLIC_URL + "/statics/img/worklist.svg"}
                                    link={true}
                                    name={this.props.getMessageForKey("header.icons.worklist")} alt="worklist"/>

                        <HeaderIcon hidden={true} image={process.env.PUBLIC_URL + "/statics/img/reports.svg"}
                                    link={true}
                                    name={this.props.getMessageForKey("header.icons.reports")} alt="reports"/>
                    </div>
                    <div className="header-second-icons">
                        <StoreDropdown
                            selectedStoreName={getStoreName(this.props.selectedStore, this.props.selectedLocale, this.props.fallbackLocale)}
                            storeList={this.props.availableStores}
                            locale={this.props.selectedLocale}
                            fallbackLocale={this.props.fallbackLocale}
                            onStoreSelect={(store) => this.onStoreSelect(store)}/>
                        <div className="separator"/>
                        <HeaderIcon image={process.env.PUBLIC_URL + "/statics/img/signout.svg"}
                                    link={false}
                                    onClick={() => this.logout()}
                                    alt="signout"
                                    name={this.props.getMessageForKey("header.icons.signout")}/>

                    </div>
                </nav>
            </div>
        );
    }

    private logout() {
        getKeycloakInstance().logout();
    }

    private onStoreSelect(store: IStoreData): void {
        this.props.selectStore(store);
        this.props.fetchAppointmentTypes(store.id, this.props.tenant);
        if (this.props.selectedDate) {
            const endDate = new Date(this.props.selectedDate.startRangeDate);
            endDate.setDate(this.props.selectedDate.startRangeDate.getDate() + this.props.selectedDate.range);
            this.props.fetchRoomsForStore(store.id, this.props.tenant, this.props.selectedDate.startRangeDate, endDate);
            this.props.fetchClosingHours(store.id, this.props.tenant, this.props.selectedDate.startRangeDate, 7);
        }
    }
}

const mapStateToProps = (store: any, ownProps: IProps): IDataProps => {
    return {
        availableStores: getAvailableStores(store),
        getMessageForKey: getLocalizedText(store),
        selectedDate: getSelectedDate(store),
        selectedLocale: getSelectedLocale(store),
        fallbackLocale: getFallbackLocale(store),
        selectedStore: getSelectedStore(store),
        tenant: ownProps.tenant,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): IDispatchFromProps => {
    return {
        fetchAppointmentTypes: (storeId, tenant) => dispatch(fetchAppointmentTypes(storeId, tenant)),
        fetchClosingHours: (storeId, tenant, startDate, requestedNumberOfDays) =>
            dispatch(fetchClosingHours(storeId, tenant, startDate, requestedNumberOfDays)),
        fetchRoomsForStore: (storeId, tenant, startDate, endDate) =>
            dispatch(fetchRoomsForStore(storeId, tenant, startDate, endDate)),
        selectStore: (store) => dispatch(selectStoresAction(store)),
    };
};

export default connect<IDataProps, IDispatchFromProps, IProps>(
    mapStateToProps,
    mapDispatchToProps,
)(HeaderComponent);
