import React, {ReactNodeArray} from "react";
import {ISelectedDateAndRange} from "../../../reducers/selecteddate.reducer";
import "./DateHeader.scss";
import {isSameDate} from "../../../util/date.util";

interface IProps {
    startDate: ISelectedDateAndRange;
    locale: string | undefined;
}

export default class DateHeader extends React.Component<IProps> {

    public render(): React.ReactNode {
        return (
            <div className="calendar_date_header">
                <div className="hour_column"/>
                {this.renderDays()}
            </div>
        );
    }

    private renderDays(): ReactNodeArray {
        const days: ReactNodeArray = [];
        const optionsName = {weekday: "long"};
        const optionsNumber = {day: "numeric"};
        if (this.props.startDate) {
            const date = new Date(this.props.startDate.startRangeDate);
            for (let i = 0; i < this.props.startDate.range; i++) {
                const activeDay = isSameDate(date, new Date());
                days.push(<div className="day" key={i}>
                    <div className="day_name">{date.toLocaleDateString(this.props.locale, optionsName)}</div>
                    <div className={"day_number " + (activeDay ? "day_active" : "")}
                         key={i}>{date.toLocaleDateString(this.props.locale, optionsNumber)}</div>
                    </div>);
                date.setDate(date.getDate() + 1);
            }
        }
        return days;
    }
}
