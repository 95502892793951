import {Dispatch} from "redux";
import {fetchAdditionalFieldsAction} from "../actions/additionalFields.action";
import {IAdditionalFieldData} from "../reducers/additionalFields.reducer";
import {getAdditionalFieldsForAppointmentType} from "../services/additionalFields.service";

export const fetchAdditionalFieldsForAppointmentType = (appointmentTypeId: string, tenant: string) => {
    return (dispatch: Dispatch) => {
        getAdditionalFieldsForAppointmentType(appointmentTypeId, tenant)
            .then((data) =>
                dispatch(fetchAdditionalFieldsAction(convert(data.data))));
    };
};

const convert = (data: any[]): IAdditionalFieldData[] => {
    const result: IAdditionalFieldData[] = [];
    data.forEach((value) => {
        result.push({
            label: value.attributes.label,
            descriptions: value.attributes.description,
            messages: value.attributes.messages,
            active: value.attributes.active,
            mandatory: value.attributes.mandatory,
            dataType: value.attributes.dataType,
            parameters: value.attributes.parameters ? value.attributes.parameters.split(",").map((p:string) => p.trim()) : undefined,
            hidden: false,
        });
    });

    const fieldsToHide: string[] = [];
    result.forEach(field => {
        if (field.parameters && field.parameters.length > 1) {
            for (let i = 1; i < field.parameters.length; i++) {
                fieldsToHide.push(field.parameters[i]);
            }
        }
    })
    result.forEach(field => {
        field.hidden = fieldsToHide.includes(field.label);
    })
    return result;
};
