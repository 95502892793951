import {createSelector} from "reselect";
import {
    CALCULATE_NUMBER_OF_PAGES_AND_RESET_CURRENT_PAGE,
    DECREASE_CURRENT_PAGE,
    INCREASE_CURRENT_PAGE,
    PaginationActionTypes,
    TOGGLE_PAGINATION_BUTTONS,
} from "../actions/pagination.actions";

export interface IPaginationReducer {
    buttonsEnabled: boolean;
    currentPage: number;
    numberOfPages: number;
}

const initialState: IPaginationReducer = {
    buttonsEnabled: true,
    currentPage: 1,
    numberOfPages: 1,
};

export default function paginationReducer(state = initialState, action: PaginationActionTypes): IPaginationReducer {
    switch (action.type) {
        case TOGGLE_PAGINATION_BUTTONS:
            return {
                ...state,
                buttonsEnabled: !state.buttonsEnabled,
            };
        case INCREASE_CURRENT_PAGE:
            return {
                ...state,
                currentPage: state.currentPage + 1,
            };
        case DECREASE_CURRENT_PAGE:
            return {
                ...state,
                currentPage: state.currentPage - 1,
            };
            case CALCULATE_NUMBER_OF_PAGES_AND_RESET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: 1,
                numberOfPages: action.payload,
            };
        default:
            return state;
    }
}

const getPaginationState = (state: any) => state.pagination;
export const getButtonsEnabled = createSelector(getPaginationState,
    (state: IPaginationReducer) => state.buttonsEnabled);

export const getCurrentPage = createSelector(getPaginationState,
    (state: IPaginationReducer) => state.currentPage);

export const getNumberOfPages = createSelector(getPaginationState,
    (state: IPaginationReducer) => state.numberOfPages);
