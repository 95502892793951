import {Dispatch} from "redux";
import {fetchConfig} from "../actions/config.actions";
import {getConfig} from "../services/config.service";

export const fetchConfigForTenant = (tenant: string) => {
    return (dispatch: Dispatch) => {
        return getConfig(tenant)
            .then((data) => dispatch(fetchConfig(data)));
    };
};
