import {IOpeningHoursPerDate} from "../components/calendar/components/GridColumn";
import {IEmptyAction} from "./action.actions";

export const FETCH_CLOSING_HOURS = "FETCH_CLOSING_HOURS";

interface IFetchClosingHours {
    payload: IOpeningHoursPerDate[];
    type: typeof FETCH_CLOSING_HOURS;
}

export type ClosingHoursActionTypes = IFetchClosingHours | IEmptyAction;

export function fetchClosingHoursAction(closingHours: IOpeningHoursPerDate[]): IFetchClosingHours {
    return {
        type: FETCH_CLOSING_HOURS,
        payload: closingHours,
    };
}
