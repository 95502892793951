import React from "react";
import {connect} from "react-redux";
import {getAppointmentsToDisplayOnCalendar, IAppointmentData, getNonCancelledAppointments} from "../../reducers/appointments.reducer";
import {getClosingHours} from "../../reducers/closinghours.reducer";
import {ISelectedDateAndRange} from "../../reducers/selecteddate.reducer";
import {getCancelledAppointmentToggle} from "../../reducers/sidebar.filters.reducer";
import "./Calendar.scss";
import {IOpeningHoursPerDate} from "./components/GridDayColumn";
import CalendarDayBody from "./components/CalendarDayBody";
import DateDayHeader from "./components/DateDayHeader";
import {getAppointmentsForSelectedDay} from "./Calendar.util";
import {getCalendarLocaleFormat} from "../../reducers/locale.reducer";

interface IProps {
    tenant: string;
    startDate: ISelectedDateAndRange;
    cellHeight: number;
}

interface IConnectProps {
    allAppointments: IAppointmentData[];
    allAppointmentsMinusCancelled: IAppointmentData[];
    toggleCancelledAppointment: boolean;
    closingHours: IOpeningHoursPerDate[];
    calendarLocaleFormat: string | undefined;
}

interface IResultProps extends IProps, IConnectProps {
}

export class CalendarDayComponent extends React.Component<IResultProps> {

    public render(): React.ReactNode {
        return (
            <div className="grid_calendar_day">
                <DateDayHeader startDate={this.props.startDate} locale={this.props.calendarLocaleFormat}/>
                <CalendarDayBody cellHeight={this.props.cellHeight}
                                 tenant={this.props.tenant}
                                 appointmentsPerDay={getAppointmentsForSelectedDay(!this.props.toggleCancelledAppointment ? this.props.allAppointmentsMinusCancelled : this.props.allAppointments, this.props.startDate)}
                                 closingHoursPerDay={this.props.closingHours[0]}/>
            </div>
        );
    }
}

const mapStateToProps = (store: any, ownProps: IProps): IResultProps => {
    return {
        allAppointments: getAppointmentsToDisplayOnCalendar(store),
        allAppointmentsMinusCancelled: getNonCancelledAppointments(store),
        toggleCancelledAppointment: getCancelledAppointmentToggle(store),
        cellHeight: ownProps.cellHeight,
        closingHours: getClosingHours(store),
        startDate: ownProps.startDate,
        tenant: ownProps.tenant,
        calendarLocaleFormat: getCalendarLocaleFormat(store),
    };
};

export default connect<IResultProps, void, IProps>(
    mapStateToProps,
)(CalendarDayComponent);
