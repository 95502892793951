import React from 'react';
import { connect } from "react-redux";
import "./ApplicationErrorModal.scss";
import {
    getApplicationErrors,
    ApplicationErrorData,
    ApplicationErrorType,
    HttpErrorData
} from "../../reducers/applicationError.reducer";

interface Props {
    errors: ApplicationErrorData[]
}

interface State {
}

export class ApplicationErrorModal extends React.Component<Props, State> {

    public render(): React.ReactNode {
        if (this.props.errors.length > 0) {
            return <div className="error-overlay">
                <div className="error-container">
                    <h1>An error has occured</h1>
                    <p>Please try <a href=".">reloading</a> the page. If that doesn't work, please
                    contact your local IT department.</p>
                    <hr />
                    { this.props.errors.map((err, i) => {
                        return <div className="error" key={i}>
                            {this.renderErrorDescription(err)}
                            <hr />
                        </div>
                    })}
                </div>
            </div>
            ;
        } else {
            return null;
        }
    }

    public renderErrorDescription(err: ApplicationErrorData): React.ReactNode {
        switch(err.type) {
            case ApplicationErrorType.ERROR_MESSAGE_HTTP:
                const httpErrorData = err.data as HttpErrorData['data'];
                return <pre className="error-data">
                    <strong>{httpErrorData.message}</strong>
                    <div>{httpErrorData.method}:{httpErrorData.url}</div>
                    <div>{JSON.stringify(httpErrorData.requestData, null, 2)}</div>
                </pre>

            default:
                return <pre className="error-data">
                    <strong>{err.type}</strong><br />
                    {JSON.stringify(err.data, null, 2)}
                </pre>
        }
    }
}


const mapStateToProps = (store: any, ownProps: {}): Props => {
    return {
        errors: getApplicationErrors(store)
    };
};

export default connect<Props, {}, {}>(mapStateToProps)(ApplicationErrorModal);
