import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {getCustomers, ICustomerData} from "../../../reducers/customers.reducer";
import {getLocalizedText} from "../../../reducers/locale.reducer";
import CustomerSearchForm from "../common/CustomerSearchForm";
import CustomerSearchResult, {CustomerRowType, PaginationType} from "../common/CustomerSearchResult";
import "./CustomerSearchModal.scss";

interface IConnectProps {
    getMessageForKey: (key: string) => string;
    customers: ICustomerData[];
}

interface IProps {
    selectCustomer: (customer: ICustomerData) => void;
    tenant: string;
    backToAppointmentModal: () => void;
}

interface IState {
    showResults: boolean;
}

interface IResultProps extends IProps, IConnectProps {
}

export class CustomerSearchModal extends React.Component<IResultProps, IState> {

    public static CUSTOMER_SEARCH_RESULTS_MODAL_PAGE_LIMIT: number = 5;

    constructor(props: Readonly<IResultProps>) {
        super(props);
        this.state = {
            showResults: false,
        };
    }

    public render(): React.ReactNode {
        return <div className="customer_search_modal-container">
            <div className="background_greyed">
                <div className="customer_search_modal">
                    {this.renderClosingIcon()}
                    <div className="back_to_overview" onClick={() => this.onModalClose()}>
                        <img src={process.env.PUBLIC_URL + "/statics/img/left-arrow.svg"}
                             alt=""/><span>{this.props.getMessageForKey("customerSearch.modal.back")}</span>
                    </div>
                    <h1>{this.props.getMessageForKey("customerSearch.modal.newAppointment")}</h1>
                    {this.renderSearchForm()}
                    {this.renderCustomerSearchResult()}
                </div>
            </div>
        </div>;
    }

    private renderClosingIcon(): ReactNode {
        return <div className="close_button">
            <img onClick={() => this.onModalClose()} alt="" src={process.env.PUBLIC_URL + "/statics/img/close.svg"}/>
        </div>;
    }

    private onModalClose() {
        this.props.backToAppointmentModal();
    }

    private showResults(): void {
        this.setState({showResults: true});
    }

    private renderSearchForm() {
        return <CustomerSearchForm tenant={this.props.tenant}
                                   showResults={() => this.showResults()}
                                   pageLimit={CustomerSearchModal.CUSTOMER_SEARCH_RESULTS_MODAL_PAGE_LIMIT}/>;
    }

    private renderCustomerSearchResult(): ReactNode {
        if (this.state.showResults) {
            return <CustomerSearchResult customers={this.props.customers}
                                         tenant={this.props.tenant}
                                         emptyHeaderColumns={1}
                                         customerRowType={CustomerRowType.MODAL}
                                         paginationType={PaginationType.PREV_NEXT_BUTTONS}
                                         selectCustomer={this.props.selectCustomer}/>;
        }
    }

}

const mapStateToProps = (store: any, ownProps: IProps): IResultProps => {
    return {
        backToAppointmentModal: ownProps.backToAppointmentModal,
        customers: getCustomers(store),
        getMessageForKey: getLocalizedText(store),
        selectCustomer: ownProps.selectCustomer,
        tenant: ownProps.tenant,
    };
};

export default connect<IResultProps, any, any>(
    mapStateToProps,
    undefined,
)(CustomerSearchModal);
