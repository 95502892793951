import {createSelector} from "reselect";
import {IArrayStringIndex} from "../../@types/types";
import {ClosingHoursActionTypes, FETCH_CLOSING_HOURS} from "../actions/closinghours.actions";
import {IOpeningHoursPerDate} from "../components/calendar/components/GridColumn";

export enum OpeningHoursType {
    OPEN = "OPEN",
    CLOSED = "CLOSED",
    CLOSED_SPECIAL = "CLOSED_SPECIAL",
}

export interface IOpeningHoursData {
    type: OpeningHoursType;
    from: Date;
    to: Date;
    name: IArrayStringIndex;
}

export interface IClosingHoursReducer {
    closingHours: IOpeningHoursPerDate[];
}

const initialState: IClosingHoursReducer = {
    closingHours: [],
};

// tslint:disable-next-line:max-line-length
export default function closingHoursReducer(state = initialState, action: ClosingHoursActionTypes): IClosingHoursReducer {
    if (action.type === FETCH_CLOSING_HOURS) {
        return {
            closingHours: action.payload,
        };
    } else {
        return state;
    }
}

const getClosingHoursState = (state: any) => state.closingHours;
// tslint:disable-next-line:max-line-length
export const getClosingHours = createSelector(getClosingHoursState, (state: IClosingHoursReducer) => state.closingHours);
