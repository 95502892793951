import {IAdditionalFieldData} from "../reducers/additionalFields.reducer";

export const FETCH_ADDITIONAL_FIELDS = "FETCH_ADDITIONAL_FIELDS";
export const CLEAN_ADDITIONAL_FIELDS = "CLEAN_ADDITIONAL_FIELDS";

interface IFetchAdditionalFields {
    type: typeof FETCH_ADDITIONAL_FIELDS;
    payload: IAdditionalFieldData[];
}

interface ICleanAdditionalFields {
    type: typeof CLEAN_ADDITIONAL_FIELDS;
}

export type AdditionalFieldsActionTypes =
    IFetchAdditionalFields | ICleanAdditionalFields;

export function fetchAdditionalFieldsAction(additionalFields: IAdditionalFieldData[]): IFetchAdditionalFields {
    return {
        payload: additionalFields,
        type: FETCH_ADDITIONAL_FIELDS,
    };
}

export function cleanAdditionalFieldsAction(): ICleanAdditionalFields {
    return {
        type: CLEAN_ADDITIONAL_FIELDS,
    };
}
