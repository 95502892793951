import React, {ReactNode} from "react";
import {ISelectedDateAndRange} from "../../../reducers/selecteddate.reducer";
import "./DateDayHeader.scss";

interface IProps {
    startDate: ISelectedDateAndRange;
    locale: string | undefined;
}

export default class DateDayHeader extends React.Component<IProps> {

    public render(): React.ReactNode {
        return (
            <div className="calendar_day_date_header">
                <div className="hour_column"/>
                {this.renderToday()}
            </div>
        );
    }

    private renderToday(): ReactNode {
        let today: ReactNode;
        const options = {weekday: "long", month: "long", day: "numeric", year: "numeric"};
        if (this.props.startDate) {
            const date = new Date(this.props.startDate.startRangeDate);
            today = (<div className="day" key={0}>{date.toLocaleDateString(this.props.locale, options)}</div>);
            date.setDate(date.getDate() + 1);
        }
        return today;
    }
}
