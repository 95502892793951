import React from "react";
import Select, {ValueType} from "react-select-newest";
import {IDropdownItem} from "./AppointmentModal";

interface IState {
    valid: boolean;
}

interface IProps {
    availableValues: IDropdownItem[];
    placeholder: string;
    label: string;
    fieldName: string;
    onChange: (selectedItem: IDropdownItem, fieldName: string) => void;
    disabled: boolean;
    value?: any;
    mandatory?: boolean;
    errorClassName?: string;
    checkIfNotEmptyOnBlur?: boolean;
    overrideAvailability?: boolean;
    recurringMode?: boolean;
    appointmentType?: string | null;
}

export default class DropdownAdditionalFields extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps>) {
        super(props);
        this.state = {
            valid: true,
        };
    }

    public componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (this.overrideAvailabilityChanged(prevProps) || this.appointmentTypeChanged(prevProps) || this.recurringModeChanged(prevProps)) {
            if (this.props.value) {
                this.validate();
            } else {
                this.setState({valid: true});
            }
        }
    }

    private overrideAvailabilityChanged(prevProps: IProps) {
        return prevProps.overrideAvailability !== this.props.overrideAvailability;
    }

    private recurringModeChanged(prevProps: IProps) {
        return prevProps.recurringMode !== this.props.recurringMode;
    }

    private appointmentTypeChanged(prevProps: IProps) {
        return prevProps.appointmentType !== this.props.appointmentType;
    }

    public render(): React.ReactNode {
        return (
            <>
                <div className="group-label">
                    <label>{this.props.label.concat(this.props.mandatory ? "*" : "")}</label>
                </div>
                <Select
                    placeholder={this.props.placeholder}
                    onChange={(item: ValueType<IDropdownItem>) => this.onChange(item as IDropdownItem)}
                    isSearchable={false}
                    isDisabled={this.props.disabled}
                    className={this.state.valid ? "" : this.props.errorClassName}
                    options={this.props.availableValues}
                    value={this.resolveValue()}
                    onBlur={() => this.onBlur()}
                />
            </>);
    }

    private resolveValue() {
        return this.props.value !== undefined && this.props.value !== null ? this.props.availableValues.find((item: IDropdownItem) => item.value === this.props.value) : null;
    }

    private onChange(item: IDropdownItem | null | undefined) {
        this.setState({valid: true});
        this.props.onChange(item as IDropdownItem, this.props.fieldName);
    }

    private onBlur() {
        this.validate();
    }

    private validate() {
        this.setState({valid: this.props.checkIfNotEmptyOnBlur ? this.props.value !== null && this.props.value !== undefined : true});
    }
}
