import "bootstrap";
import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {Route, RouteComponentProps, Switch} from "react-router-dom";
import {ThunkDispatch} from "redux-thunk";
import "./Main.scss";
import Body from "./pages/Body";
import NotFound from "./pages/NotFound";
import {getSelectedDate, ISelectedDateAndRange} from "./reducers/selecteddate.reducer";
import {getUrlConfig} from "./services/urlConfig.service";
import {fetchConfigForTenant} from "./thunks/config.thunk";
import {fetchLocaleConfigWithMessages} from "./thunks/locale.thunk";
import {fetchStores} from "./thunks/store.thunk";
import {fetchSubstatusesConfigForTenant} from "./thunks/substatusesConfig.thunk";
import {setBaseUrl, setBearerToken, setRefreshToken} from "./config/requestConfig";
import {getKeycloakAuthUrl, setKeycloakAuthUrl, setKeycloakInstance} from "./services/axios.config";
import Keycloak from "keycloak-js";
import {initializeCalendarLocale} from "./components/calendar/CalendarLocaleResolver";

const MAIN_PATH = "/:tenant/:locale/main";
const MAIN_WITHOUT_LOCALE = "/:tenant/main";

interface IState {
    fetchedUrlConfig: boolean;
    receivedBearerToken: boolean;
}

interface IConnectProps {
    selectedDate: ISelectedDateAndRange | undefined;
}

interface IDispatchFromProps {
    fetchConfig: (tenant: string) => void;
    fetchSubstatusesConfig: (tenant: string) => void;
    fetchLocaleConfigWithMessages: (tenant: string, locale: string) => void;
    fetchStores: (tenant: string, startDate: Date, endDate: Date, locale: string) => void;
    initializeCalendarLocale: (locale: string) => void;
}

interface IResultProps extends IConnectProps, IDispatchFromProps {

}

class Main extends React.Component<IResultProps, IState> {
    private firstShow = true;
    private storesFetched = false;
    private keycloakInitialized = false;

    constructor(props: IResultProps) {
        super(props);
        this.renderMain = this.renderMain.bind(this);
        this.state = {fetchedUrlConfig: false, receivedBearerToken: false};
    }

    public render(): React.ReactNode {
        return (
            <div className="main container">
                <Switch>
                    <Route path={MAIN_WITHOUT_LOCALE} render={this.renderMain}/>
                    <Route path={MAIN_PATH} render={this.renderMain}/>
                    <Route path="*" component={NotFound}/>
                </Switch>
            </div>);
    }

    public componentDidMount(): void {
        getUrlConfig().then((response) => {
            setBaseUrl(response.data.baseURL);
            setKeycloakAuthUrl(response.data.keycloakAuthUrl);
            this.setState({
                fetchedUrlConfig: true,
            });
        });
    }

    private initKeycloak(keycloakAuthUrl: string, tenant: string) {
        const keycloak = Keycloak({url: keycloakAuthUrl, realm: "gans-" + tenant, clientId: "agat-app"});
        keycloak.init({onLoad: "login-required"}).success(() => {
            setKeycloakInstance(keycloak);
            if (keycloak.token && keycloak.refreshToken) {
                setBearerToken(keycloak.token);
                setRefreshToken(keycloak.refreshToken);
                this.setState({receivedBearerToken: true});
            }
        });
    }

    private renderMain(route: RouteComponentProps): ReactNode {
        if (this.state.fetchedUrlConfig && this.firstShow) {
            this.props.fetchLocaleConfigWithMessages(this.getTenant(route), this.getLocale(route));
            this.props.fetchConfig(this.getTenant(route));
            this.props.fetchSubstatusesConfig(this.getTenant(route));
            this.props.initializeCalendarLocale(this.getLocale(route));

            if (!this.keycloakInitialized) {
                this.initKeycloak(getKeycloakAuthUrl(), this.getTenant(route));
                this.keycloakInitialized = true;
            }

            if (this.state.receivedBearerToken && this.props.selectedDate && !this.storesFetched) {
                const endDate = new Date(this.props.selectedDate.startRangeDate);
                endDate.setDate(this.props.selectedDate.startRangeDate.getDate() + this.props.selectedDate.range);
                this.props.fetchStores(this.getTenant(route), this.props.selectedDate.startRangeDate, endDate, this.getLocale(route));
                this.storesFetched = true;
                this.firstShow = false;
            }
        }

        return <Body tenant={this.getTenant(route)}/>;
    }

    private getTenant(route: RouteComponentProps): string {
        // @ts-ignore
        return route.match.params.tenant;
    }

    private getLocale(route: RouteComponentProps): string {
        // @ts-ignore
        return route.match.params.locale;
    }

}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): IDispatchFromProps => {
    return {
        fetchLocaleConfigWithMessages: (tenant: string, locale: string) =>
            dispatch(fetchLocaleConfigWithMessages(tenant, locale)),
        fetchConfig: (tenant: string) => dispatch(fetchConfigForTenant(tenant)),
        fetchSubstatusesConfig: (tenant: string) => dispatch(fetchSubstatusesConfigForTenant(tenant)),
        fetchStores: (tenant: string, startDate: Date, endDate: Date, locale: string) => dispatch(fetchStores(tenant, startDate, endDate, locale)),
        initializeCalendarLocale: (locale: string) => initializeCalendarLocale(locale, dispatch),
    };
};

const mapStateToProps = (store: any): IConnectProps => {
    return {
        selectedDate: getSelectedDate(store),
    };
};

export default connect<IConnectProps, IDispatchFromProps, void>(
    mapStateToProps, mapDispatchToProps,
)(Main);
