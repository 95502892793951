import {Dispatch} from "redux";
import {
    fetchAvailableLocales,
    fetchFallbackLocale,
    fetchFallbackMessages,
    fetchMessages,
    selectLocale,
} from "../actions/locale.actions";
import {getLocaleConfig, getMessages} from "../services/locale.service";

export const fetchMessagesForLocaleWithDispatch = (tenant: string, locale: string, dispatch: Dispatch) => {
    dispatch(selectLocale(locale));
    return getMessages(tenant, locale)
        .then((data) => dispatch(fetchMessages(data)));
};

export const fetchFallbackForLocaleWithDispatch = (tenant: string, locale: string, dispatch: Dispatch) => {
        return getMessages(tenant, locale)
            .then((data) => dispatch(fetchFallbackMessages(data)));
};

export const fetchLocaleConfigWithMessages = (tenant: string, locale: string) => {
    return (dispatch: Dispatch) => {
        return getLocaleConfig(tenant)
            .then((configData) => {
                dispatch(fetchAvailableLocales(configData.availableLocales));
                dispatch(fetchFallbackLocale(configData.fallback));
                if (locale && configData.availableLocales && configData.availableLocales.includes(locale)) {
                    fetchMessagesForLocaleWithDispatch(tenant, locale, dispatch);
                } else {
                    fetchMessagesForLocaleWithDispatch(tenant, configData.defaultLocale, dispatch);
                }
                fetchFallbackForLocaleWithDispatch(tenant, configData.fallback, dispatch);
            });
    };
};
