import React from "react";
import {connect} from "react-redux";
import Select, {ValueType} from "react-select-newest";
import {IDropdownItem} from "./AppointmentModal";
import {getLocalizedText} from "../reducers/locale.reducer";

interface IState {
    valid: boolean;
}

interface IProps {
    availableValues: IDropdownItem[];
    placeholder: string;
    label: string;
    fieldName: string;
    onChange: (selectedItem: IDropdownItem, fieldName: string) => void;
    disabled: boolean;
    value?: any;
    mandatory?: boolean;
    errorClassName?: string;
    checkIfNotEmptyOnBlur?: boolean;
    overrideAvailability?: boolean; // prop to detect change in parent component
    recurringMode?: boolean; // prop to detect change in parent component
    appointmentType?: string | null; // prop to detect change in parent component
}

interface IConnectProps {
    getMessageForKey: (key: string) => string;
}

interface IResultProps extends IProps, IConnectProps {
}

export class Dropdown extends React.Component<IResultProps, IState> {

    constructor(props: Readonly<IResultProps>) {
        super(props);
        this.getNoOptionsMessage = this.getNoOptionsMessage.bind(this);
        this.state = {
            valid: true,
        };
    }

    public componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (this.overrideAvailabilityChanged(prevProps) || this.appointmentTypeChanged(prevProps) || this.recurringModeChanged(prevProps)) {
            if (this.props.value) {
                this.validate();
            } else {
                this.setState({valid: true});
            }
        }
    }

    private overrideAvailabilityChanged(prevProps: IProps) {
        return prevProps.overrideAvailability !== this.props.overrideAvailability;
    }

    private recurringModeChanged(prevProps: IProps) {
        return prevProps.recurringMode !== this.props.recurringMode;
    }

    private appointmentTypeChanged(prevProps: IProps) {
        return prevProps.appointmentType !== this.props.appointmentType;
    }

    private getNoOptionsMessage(): string {
        return this.props.getMessageForKey("form.options.noOptionsMessage") || "No options";
    }

    public render(): React.ReactNode {
        return (
            <>
                <div className="group-label">
                    <label>{this.props.label.concat(this.props.mandatory ? "*" : "")}</label>
                </div>
                <Select
                    noOptionsMessage={this.getNoOptionsMessage}
                    placeholder={this.props.placeholder}
                    onChange={(item: ValueType<IDropdownItem>) => this.onChange(item as IDropdownItem)}
                    isSearchable={false}
                    isDisabled={this.props.disabled}
                    className={this.state.valid ? "" : this.props.errorClassName}
                    options={this.props.availableValues}
                    value={this.resolveValue()}
                    onBlur={() => this.onBlur()}
                />
            </>);
    }

    private resolveValue() {
        return this.props.value !== undefined && this.props.value !== null ? this.props.availableValues.find((item: IDropdownItem) => item.value === this.props.value) : null;
    }

    private onChange(item: IDropdownItem | null | undefined) {
        this.setState({valid: true});
        this.props.onChange(item as IDropdownItem, this.props.fieldName);
    }

    private onBlur() {
        this.validate();
    }

    private validate() {
        this.setState({valid: this.props.checkIfNotEmptyOnBlur ? this.props.value !== null && this.props.value !== undefined : true});
    }
}

const mapStateToProps = (store: any, ownProps: IProps): IResultProps => {
    return {
        availableValues: ownProps.availableValues,
        placeholder: ownProps.placeholder,
        label: ownProps.label,
        fieldName: ownProps.fieldName,
        onChange: ownProps.onChange,
        disabled: ownProps.disabled,
        value: ownProps.value,
        mandatory: ownProps.mandatory,
        errorClassName: ownProps.errorClassName,
        checkIfNotEmptyOnBlur: ownProps.checkIfNotEmptyOnBlur,
        overrideAvailability: ownProps.overrideAvailability,
        recurringMode: ownProps.recurringMode,
        appointmentType: ownProps.appointmentType,

        getMessageForKey: getLocalizedText(store)
    };
};

export default connect<IResultProps, void, IProps>(
    mapStateToProps,
)(Dropdown);
