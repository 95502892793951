import {createSelector} from "reselect";
import {
    AppointmentActionTypes,
    CLEAN_APPOINTMENTS,
    CLEAN_LAST_CREATED_UPDATED_APPOINTMENT,
    FETCH_APPOINTMENTS, FETCH_APPOINTMENTS_FOR_URL,
    FETCH_LAST_CREATED_UPDATED_APPOINTMENT, FETCH_MORE_APPOINTMENTS_FOR_URL,
    FETCH_SINGLE_APPOINTMENT,
    UPDATE_APPOINTMENT,
} from "../actions/appointments.actions";
import {IAppointmentTypeData} from "./appointmentTypes.reducer";
import { ICustomerData } from "./customers.reducer";
import {IRoomData} from "./room.reducer";
import { encodeSquareBrackets } from "../util/url.util";
import { IOpticianData } from "./opticians.reducer";
import { IStoreData } from "./store.reducer";

export interface IAdditionalField {
    label: string;
    value: string;
}

export interface IAppointmentData {
    uuid: string;
    startTime: Date;
    endTime: Date;
    endTimeWithoutWrapTime: Date;
    customer: ICustomerData;
    appointmentType: IAppointmentTypeData;
    room: IRoomData | undefined;
    status: string;
    substatus: string;
    updateComment: string;
    customerComment: string;
    preferredChannelOfCommunication: string;
    preferredLanguage: string;
    notificationRequired: boolean;
    additionalFields: IAdditionalField[];
    manualOverride: boolean;
    appointmentSeriesId: string | null;
    optician: IOpticianData | undefined;
    store: IStoreData | undefined;
}

export interface IAppointmentsPayload {
    appointments: IAppointmentData[];
    nextLink: string | undefined;
    prevLink: string | undefined;
}

export interface IAppointmentsReducer {
    appointments: IAppointmentData[];
    lastCreatedOrUpdatedAppointment?: IAppointmentData;
    nextLink: string | undefined;
    prevLink: string | undefined;
}

const initialState: IAppointmentsReducer = {
    appointments: [],
    nextLink: undefined,
    prevLink: undefined,
};

export default function appointmentReducer(state = initialState, action: AppointmentActionTypes): IAppointmentsReducer {
    switch (action.type) {
        case FETCH_APPOINTMENTS:
            return {
                appointments: action.payload,
                nextLink: undefined,
                prevLink: undefined,
            };
        case CLEAN_APPOINTMENTS:
            return {
                appointments: [],
                nextLink: undefined,
                prevLink: undefined,
            };
        case FETCH_APPOINTMENTS_FOR_URL:
            return {
                appointments: action.payload.appointments,
                nextLink: action.payload.nextLink,
                prevLink: action.payload.prevLink,
            };
        case FETCH_MORE_APPOINTMENTS_FOR_URL :
            return {
                appointments: state.appointments.concat(action.payload.appointments),
                nextLink: action.payload.nextLink,
                prevLink: action.payload.prevLink,
            };
        case UPDATE_APPOINTMENT:
            for (let i = 0; i < state.appointments.length; i++) {
                if (state.appointments[i].uuid === action.payload.uuid) {
                    state.appointments[i] = action.payload;
                }
            }
            return {
                ...state,
                lastCreatedOrUpdatedAppointment: action.payload,
            };
        case FETCH_LAST_CREATED_UPDATED_APPOINTMENT:
            return {
                ...state,
                lastCreatedOrUpdatedAppointment: action.payload,
            };
        case FETCH_SINGLE_APPOINTMENT:
            return{
                ...state,
                lastCreatedOrUpdatedAppointment: action.payload,
            };
        case CLEAN_LAST_CREATED_UPDATED_APPOINTMENT:
            return {
                ...state,
                lastCreatedOrUpdatedAppointment: undefined,
            };
        default:
            return state;
    }
}

const getAppointmentsState = (state: any) => state.appointments;
export const getAppointments = createSelector(getAppointmentsState,
    (state: IAppointmentsReducer) => state.appointments);

export const getAppointmentsToDisplayOnCalendar = createSelector(getAppointmentsState,
    (state: IAppointmentsReducer) => {
        const result: IAppointmentData[] = [];
        const allowedSubstatuses: string[] = ["CONFIRMED", "RESERVATION", "SEEN", "DID_NOT_ATTEND", "FIELDS", "IN_CONSULTATION",
            "WAITING", "INITIAL_EXAM", "ARRIVED", "CANCEL"];
        state.appointments.forEach((appointment) => {
            if (allowedSubstatuses.includes(appointment.substatus)) {
                result.push(appointment);
            }
        });
        return result;
    });

export const getNonCancelledAppointments = createSelector(getAppointmentsState,
    (state: IAppointmentsReducer) => {
        const result: IAppointmentData[] = [];
        state.appointments.forEach((appointment) => {
            if (appointment.status !== "CANCEL" && appointment.status !== "RESCHEDULE") {
                result.push(appointment);
            }
        });
        return result;
    });

export const getLastCreatedOrUpdatedAppointment = createSelector(getAppointmentsState,
    (state: IAppointmentsReducer) => state.lastCreatedOrUpdatedAppointment);

export const getNextLink = createSelector(getAppointmentsState,
    (state: IAppointmentsReducer) => state.nextLink !== undefined ? encodeSquareBrackets(state.nextLink) : state.nextLink);
