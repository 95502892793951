import React, {FormEvent} from "react";

interface IProps {
    fieldName: string;
    onChange: (event: FormEvent<HTMLInputElement>) => void;
    checked: boolean;
    description: string;
    isValid: (checked: boolean) => boolean;
    label?: string;
    value?: any;
    mandatory?: boolean;
    className?: string;
    errorClassName?: string;
    overrideAvailability?: boolean; // prop to detect change in parent component
}

interface IState {
    valid: boolean;
}

export default class Checkbox extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps>) {
        super(props);
        this.state = {
            valid: true,
        };
    }

    public componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (this.overrideAvailabilityChanged(prevProps)) {
            if (this.props.checked) {
                this.validate(this.props.checked);
            } else {
                this.setState({valid: true});
            }
        }
    }

    private overrideAvailabilityChanged(prevProps: IProps) {
        return prevProps.overrideAvailability !== this.props.overrideAvailability;
    }

    public render(): React.ReactNode {
        return (
            <>
                {this.props.label ?
                    <div className="group-label">
                        <label>{this.props.label.concat(this.props.mandatory ? "*" : "")}</label>
                    </div> : <></>}
                <div className={"checkbox " + this.props.className}>
                    <label htmlFor={this.props.fieldName}>
                        <input type="checkbox"
                            name={this.props.fieldName}
                            id={this.props.fieldName}
                            value={this.props.value}
                            checked={this.props.checked}
                            onChange={(event) => this.onChange(event)}/>
                        <span
                            className={this.state.valid ? "" : this.props.errorClassName} dangerouslySetInnerHTML={{__html: this.props.description}}></span>
                    </label>
                </div>
            </>);
    }

    private onChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.validate(event.currentTarget.checked);
        this.props.onChange(event);
    }

    private validate(checked: boolean) {
        this.setState({valid: this.props.isValid(checked)});
    }

}
