import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {decreaseCurrentPageAction, increaseCurrentPageAction} from "../../../actions/pagination.actions";
import {getNextLink, getPrevLink} from "../../../reducers/customers.reducer";
import {getLocalizedText} from "../../../reducers/locale.reducer";
import {getButtonsEnabled, getCurrentPage, getNumberOfPages} from "../../../reducers/pagination.reducer";
import {getCustomersForUrl} from "../../../thunks/customer.thunk";

interface IProps {
    tenant: string;
}

interface IDispatchFromProps {
    decreaseCurrentPage: () => void;
    increaseCurrentPage: () => void;
    getCustomersForUrl: (nextLink: string, tenant: string) => void;
}

interface IConnectProps {
    buttonsEnabled: boolean;
    currentPage: number;
    getMessageForKey: (key: string) => string;
    numberOfPages: number | undefined;
    nextLink: string | undefined;
    prevLink: string | undefined;
}

interface IDataProps extends IProps, IConnectProps {
}

interface IResultProps extends IDataProps, IDispatchFromProps {
}

export class Pagination extends React.Component<IResultProps> {

    private currentPage: number;

    constructor(props: IResultProps, context: any) {
        super(props, context);
        this.currentPage = 1;
    }

    public render(): React.ReactNode {
        return this.renderPagination();
    }

    private renderPagination(): ReactNode {
        return (
            <div className="pagination-container">
                <input type="button"
                       className="button-blue-full-bg prev"
                       value={this.props.getMessageForKey("customerSearch.modal.pagination.previous")}
                       disabled={this.prevButtonDisabled()}
                       onClick={() => this.previousPageClickHandler()}/>
                <span>{this.props.currentPage} {this.props.getMessageForKey("customerSearch.modal.pagination.from")} {this.props.numberOfPages}</span>
                <input type="button"
                       className="button-blue-full-bg next"
                       value={this.props.getMessageForKey("customerSearch.modal.pagination.next")}
                       disabled={this.nextButtonDisabled()}
                       onClick={() => this.nextPageClickHandler()}/>
            </div>
        );
    }

    private prevButtonDisabled(): boolean {
        return this.props.prevLink === undefined;
    }

    private nextButtonDisabled(): boolean {
        return this.props.nextLink === undefined;
    }

    private nextPageClickHandler() {
        if (this.props.buttonsEnabled) {
            this.props.increaseCurrentPage();
            this.props.getCustomersForUrl(this.props.nextLink ? this.props.nextLink : "", this.props.tenant);
        }
    }

    private previousPageClickHandler() {
        if (this.props.buttonsEnabled) {
            this.props.decreaseCurrentPage();
            this.props.getCustomersForUrl(this.props.prevLink ? this.props.prevLink : "", this.props.tenant);
        }
    }

}

const mapStateToProps = (store: any, ownProps: IProps): IDataProps => {
    return {
        buttonsEnabled: getButtonsEnabled(store),
        currentPage: getCurrentPage(store),
        getMessageForKey: getLocalizedText(store),
        nextLink: getNextLink(store),
        numberOfPages: getNumberOfPages(store),
        prevLink: getPrevLink(store),
        tenant: ownProps.tenant,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): IDispatchFromProps => {
    return {
        decreaseCurrentPage: () => dispatch(decreaseCurrentPageAction()),
        getCustomersForUrl: ((url, tenant) => dispatch(getCustomersForUrl(url, tenant))),
        increaseCurrentPage: () => dispatch(increaseCurrentPageAction()),
    };
};

export default connect<IDataProps, IDispatchFromProps, IProps>(
    mapStateToProps,
    mapDispatchToProps,
)(Pagination);
