import {createSelector} from "reselect";
import {IArrayStringIndex} from "../../@types/types";
import {
    AdditionalFieldsActionTypes,
    CLEAN_ADDITIONAL_FIELDS,
    FETCH_ADDITIONAL_FIELDS,
} from "../actions/additionalFields.action";

export enum AdditionalFieldDataType {
    NUMBER = "NUMBER",
    TEXT = "TEXT",
    BOOLEAN = "BOOLEAN",
    CHILE_RUT = "CHILE_RUT",
    DROPDOWN_ADDITIONAL_FIELDS = "DROPDOWN_ADDITIONAL_FIELDS",
}

export interface IAdditionalFieldData {
    descriptions: IArrayStringIndex;
    messages: IArrayStringIndex;
    active: boolean;
    mandatory: boolean;
    dataType: AdditionalFieldDataType;
    label: string;
    parameters: string[];
    hidden: boolean;
}

export interface IAdditionalFieldsReducer {
    additionalFields: IAdditionalFieldData[];
}

const initialState: IAdditionalFieldsReducer = {
    additionalFields: [],
};

export default function additionalFieldsReducer(state = initialState, action: AdditionalFieldsActionTypes): IAdditionalFieldsReducer {
    switch (action.type) {
        case FETCH_ADDITIONAL_FIELDS:
            return {
                additionalFields: action.payload,
            };
        case CLEAN_ADDITIONAL_FIELDS:
            return {
                additionalFields: [],
            };
        default:
            return state;
    }
}

const getAdditionalFieldsState = (state: any) => state.additionalFields;

export const getAdditionalFields = createSelector(getAdditionalFieldsState,
    (state: IAdditionalFieldsReducer) => state.additionalFields);
