import React from "react";
import BodyContent from "../components/BodyContent";
import Header from "../components/Header";
import GenericModal from "../modal/GenericModal";
import ApplicationErrorModal from "../modal/ApplicationErrorModal/ApplicationErrorModal";
import {isLoadingData} from "../reducers/generic.modal.reducer";
import {connect} from "react-redux";

interface IProps {
    tenant: string;
}

interface IConnectProps {
    isLoadingData: boolean;
}

interface IResultProps extends IProps, IConnectProps {
}

export class Body extends React.Component<IResultProps> {

    public render(): React.ReactNode {
        return (
            <>
                <ApplicationErrorModal></ApplicationErrorModal>
                {this.props.isLoadingData ? <GenericModal
                    tenant={this.props.tenant}
                    closeModal={()=>{}}
                    handleErrorModal={()=>{}} />
                : null}
                <Header tenant={this.props.tenant}/>
                <BodyContent tenant={this.props.tenant}/>
            </>
        );
    }
}

const mapStateToProps = (store: any, ownProps: IProps): IResultProps => {
    return {
        tenant: ownProps.tenant,
        isLoadingData: isLoadingData(store)
    };
};

export default connect<IResultProps, void, IProps>(
    mapStateToProps,
)(Body);
