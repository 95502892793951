import {createSelector} from "reselect";
import {IArrayStringIndex} from "../../@types/types";
import {
    FETCH_AVAILABLE_LOCALE,
    FETCH_CALENDAR_LOCALE,
    FETCH_FALLBACK_LOCALE,
    FETCH_FALLBACK_MESSAGES,
    FETCH_MESSAGES,
    LocaleActionTypes,
    SELECT_LOCALE,
} from "../actions/locale.actions";

export interface ILocaleReducer {
    messages?: IArrayStringIndex;
    fallbackMessages?: IArrayStringIndex;
    selectedLocale: string;
    fallbackLocale: string;
    availableLocales: string[];

    calendarLocale: Locale | undefined;
    calendarLocaleFormat: string | undefined;
}

const initialState: ILocaleReducer = {
    fallbackMessages: {},
    messages: {},
    selectedLocale: "",
    fallbackLocale: "",
    availableLocales: [],

    calendarLocale: undefined,
    calendarLocaleFormat: undefined,
};

export default function localeReducer(state = initialState, action: LocaleActionTypes): ILocaleReducer {
    switch (action.type) {
        case FETCH_AVAILABLE_LOCALE:
            return {
                ...state,
                availableLocales: action.payload,
            };
        case SELECT_LOCALE:
            return {
                ...state,
                selectedLocale: action.payload,
            };
        case FETCH_MESSAGES:
            return {
                ...state,
                messages: action.payload,
            };
        case FETCH_FALLBACK_MESSAGES:
            return {
                ...state,
                fallbackMessages: action.payload,
            };
        case FETCH_FALLBACK_LOCALE:
            return {
                ...state,
                fallbackLocale: action.payload,
            };
        case FETCH_CALENDAR_LOCALE:
            return {
                ...state,
                calendarLocale: action.payload.calendarLocale,
                calendarLocaleFormat: action.payload.calendarLocaleFormat,
            };
        default:
            return state;
    }
}

const getLocaleState = (state: any) => state.locale;
export const getSelectedLocale = createSelector(getLocaleState, (state: ILocaleReducer) => state.selectedLocale);
export const getFallbackLocale = createSelector(getLocaleState, (state: ILocaleReducer) => state.fallbackLocale);
export const getAvailableLocales = createSelector(getLocaleState, (state: ILocaleReducer) => state.availableLocales);
export const getLocalizedText = createSelector(getLocaleState, (state: ILocaleReducer) => {
    return (key: string): string => {
        if (state.messages !== undefined) {
            let text = state.messages[key];
            if (text === undefined && state.fallbackMessages !== undefined) {
                text = state.fallbackMessages[key];
            }
            return text ? text : "";
        } else {
            return "";
        }

    };
});
export const getCalendarLocale = createSelector(getLocaleState, (state: ILocaleReducer) => state.calendarLocale);
export const getCalendarLocaleFormat = createSelector(getLocaleState, (state: ILocaleReducer) => state.calendarLocaleFormat);
