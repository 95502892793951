import {Dispatch} from "redux";
import {fetchCustomersAction, fetchMoreCustomersForUrlAction,} from "../actions/customer.actions";
import {
    calculateNumberOfPagesAndResetCurrentPageAction,
    togglePaginationButtonsAction
} from "../actions/pagination.actions";
import {CustomerSearchModal} from "../components/search/modal/CustomerSearchModal";
import {ICustomerData, ICustomerSearchPayload} from "../reducers/customers.reducer";
import {searchCustomersForAttributes, searchCustomersForUrl} from "../services/customer.service";
import {
    decreaseLoadingDataCounterAction,
    increaseLoadingDataCounterAction,
} from "../actions/genericModal.actions";

export const getCustomersForAttrs = (firstName: string | null,
                                     lastName: string | null,
                                     email: string | null,
                                     phoneNumber: string | null,
                                     tenant: string,
                                     pageLimit: number) => {
    return (dispatch: Dispatch) => {
        dispatch(increaseLoadingDataCounterAction());
        return searchCustomersForAttributes(firstName, lastName, email, phoneNumber, tenant, pageLimit)
            .then((data) => {
                dispatch(fetchCustomersAction(transformIntoCustomerData(data.data, data.links)));
                dispatch(calculateNumberOfPagesAndResetCurrentPageAction(calculateNumberOfPages(data.meta)));
            })
            .finally(() => dispatch(decreaseLoadingDataCounterAction()));
    };
};

export const getMoreCustomersForUrl = (url: string, tenant: string) => {
    return (dispatch: Dispatch) => {
        dispatch(increaseLoadingDataCounterAction());
        return searchCustomersForUrl(url, tenant)
            .then((data) => {
                dispatch(fetchMoreCustomersForUrlAction(transformIntoCustomerData(data.data, data.links)));
            })
            .finally(() => dispatch(decreaseLoadingDataCounterAction()));
    };
};

export const getCustomersForUrl = (url: string, tenant: string) => {
    return (dispatch: Dispatch) => {
        dispatch(togglePaginationButtonsAction());
        dispatch(increaseLoadingDataCounterAction());
        return searchCustomersForUrl(url, tenant)
            .then((data) => {
                dispatch(fetchCustomersAction(transformIntoCustomerData(data.data, data.links)));
                dispatch(togglePaginationButtonsAction());
            })
            .finally(() => dispatch(decreaseLoadingDataCounterAction()));
    };
};

const transformIntoCustomerData = (data: any[], links: any): ICustomerSearchPayload => {
    const result: ICustomerSearchPayload = {customers: [], nextLink: undefined, prevLink: undefined};
    result.nextLink = links ? links.next : undefined;
    result.prevLink = links ? links.prev : undefined;
    data.forEach((value) => {
        result.customers.push(transform(value));
    });
    return result;
};

function calculateNumberOfPages(meta: any): number {
    let result: number = 1;
    if (meta && meta.totalResourceCount > CustomerSearchModal.CUSTOMER_SEARCH_RESULTS_MODAL_PAGE_LIMIT) {
        result = Math.ceil(Number(meta.totalResourceCount) / CustomerSearchModal.CUSTOMER_SEARCH_RESULTS_MODAL_PAGE_LIMIT);
    }
    return result;
}

const transform = (customer: any): ICustomerData => {
    return {
        dateOfBirth: customer.attributes.dateOfBirth,
        email: customer.attributes.email,
        firstName: customer.attributes.firstName,
        gender: customer.attributes.title,
        id: customer.id,
        lastName: customer.attributes.lastName,
        phoneNumber: customer.attributes.phoneNumber,
    };
};
