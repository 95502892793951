import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {BrowserRouter, Switch} from "react-router-dom";
import Main from "./app/Main";
import store from "./app/store";
import "react-datepicker/dist/react-datepicker.css";

const app = document.getElementById("root");

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <Switch>
                <Main/>
            </Switch>
        </BrowserRouter>
    </Provider>,
    app,
);
