import {AxiosRequestConfig} from "axios";

let baseUrl: string;
let bearerToken: string = "";
let refreshToken: string = "";

export const setBaseUrl = (baseUrlValue: string): void => {
    baseUrl = baseUrlValue;
};

export const setBearerToken = (bearerTokenValue: string): void => {
    bearerToken = bearerTokenValue;
};

export const setRefreshToken = (refreshTokenValue: string): void => {
    refreshToken = refreshTokenValue;
};

export const getRefreshToken = (): string => {
    return refreshToken;
};

export const getBearerToken = (): string => {
    return bearerToken;
};

export const getConfigPerEnv = (tenant: string): AxiosRequestConfig => {
    if (bearerToken === "") {
        return {
            baseURL: baseUrl,
            headers: {
                "Content-Type": "application/vnd.api+json",
                "role": "admin",
                "tenant": tenant,
            },
            withCredentials: true,
        };
    } else {
        return {
            baseURL: baseUrl,
            headers: {
                "Content-Type": "application/vnd.api+json",
                "role": "admin",
                "tenant": tenant,
                "authorization": "Bearer " + bearerToken,
            },
            withCredentials: true,
        };
    }

};

export const preparePatchPostRequest = (resourceType: string, attributes: any) => {
    return {
        data: {
            type: resourceType,
            attributes,
        },
    };
};

export const preparePatchPostWithRelationsRequest = (resourceType: string, attributes: any, relationships: any) => {
    return {
        data: {
            type: resourceType,
            attributes,
            relationships,
        },
    };
};

export const addFilterIfValuePresent = (filterName: string, filterValue: string | null): string => {
    if (filterValue != null) {
        return "&filter%5B" + filterName + "%5D=" + filterValue;
    } else {
        return "";
    }
};

export const addFilterWithWildcardIfValuePresent = (filterName: string, filterValue: string | null): string => {
    if (filterValue != null) {
        return "&filter%5B" + filterName + "%5D%5BLIKE%5D=%25" + filterValue + "%25";
    } else {
        return "";
    }
};
