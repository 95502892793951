import {ITimeslotAvailabilityPerDay, ITimeslotDataForDay} from "../reducers/timeslots.reducer";
import {IEmptyAction} from "./action.actions";

export const FETCH_TIMESLOTS = "FETCH_TIMESLOTS";
export const CLEAN_TIMESLOTS = "CLEAN_TIMESLOTS";
export const FETCH_TIMESLOTS_AVAILABILITY = "FETCH_TIMESLOTS_AVAILABILITY";

interface IFetchTimeslots {
    type: typeof FETCH_TIMESLOTS;
    payload: ITimeslotDataForDay[];
}

interface ICleanTimeslots {
    type: typeof CLEAN_TIMESLOTS;
}

interface IFetchTimeslotsAvailability {
    type: typeof FETCH_TIMESLOTS_AVAILABILITY;
    payload: ITimeslotAvailabilityPerDay[];
}

export type TimeslotsActionsTypes = IFetchTimeslots | ICleanTimeslots | IFetchTimeslotsAvailability | IEmptyAction;

export function fetchTimeslotAvailabilityAction(timeslotAvailabilityPerDays: ITimeslotAvailabilityPerDay[]): IFetchTimeslotsAvailability {
    return {
        payload: timeslotAvailabilityPerDays,
        type: FETCH_TIMESLOTS_AVAILABILITY,
    };

}

export function fetchTimeslotsAction(timeslots: ITimeslotDataForDay[]): IFetchTimeslots {
    return {
        payload: timeslots,
        type: FETCH_TIMESLOTS,
    };
}

export function cleanTimeslotsAction(): ICleanTimeslots {
    return {
        type: CLEAN_TIMESLOTS,
    };
}
