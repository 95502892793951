import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { ICustomerData } from "../../../reducers/customers.reducer";
import { getLocalizedText } from "../../../reducers/locale.reducer";

interface IConnectProps {
    getMessageForKey: (key: string) => string;
}

interface IProps {
    customer: ICustomerData;
    newAppointmentHandler: (customer: ICustomerData) => void;
    tenant: string;
    showResults?: () => void;
    pageLimit: number;
}

interface IDataProps extends IProps, IConnectProps {
}

class CustomerInformation extends React.Component<IDataProps> {

    public render(): React.ReactNode {
        const genderTranslations: any[] = Array.from(this.props.getMessageForKey("appointment.modal.form.gender.options"))
        const genderTranslation: any = genderTranslations.find((genderType : any) => genderType.id ===  this.props.customer.gender)
        return (
            <div className="customer_information">
                <fieldset>
                    <legend>{this.props.getMessageForKey("customerSearch.customerInformation.legend")}</legend>
                    <div className="row">
                        <div className="col">
                            <div className="form_input-wrapper">
                                <p>{this.props.getMessageForKey("customerSearch.form.gender")}</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form_input-wrapper">
                                <p>{genderTranslation !== undefined ? genderTranslation.label : this.props.customer.gender}</p>
                            </div>
                        </div>
                        <div className="col"/>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form_input-wrapper">
                                <p>{this.props.getMessageForKey("customerSearch.form.firstName")}</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form_input-wrapper">
                                <p>{this.props.customer.firstName}</p>
                            </div>
                        </div>
                        <div className="col">{this.renderNewAppointmentButton()}</div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form_input-wrapper">
                                <p>{this.props.getMessageForKey("customerSearch.form.lastName")}</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form_input-wrapper">
                                <p>{this.props.customer.lastName}</p>
                            </div>
                        </div>
                        <div className="col"></div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form_input-wrapper">
                                <p>{this.props.getMessageForKey("customerSearch.form.email")}</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form_input-wrapper">
                                <p>{this.props.customer.email}</p>
                            </div>
                        </div>
                        <div className="col"></div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form_input-wrapper">
                                <p>{this.props.getMessageForKey("customerSearch.form.phoneNumber")}</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form_input-wrapper">
                                <p>{this.props.customer.phoneNumber}</p>
                            </div>
                        </div>
                        <div className="col"></div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form_input-wrapper">
                                <p>{this.props.getMessageForKey("customerSearch.form.dateOfBirth")}</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form_input-wrapper">
                                <p>{this.props.customer.dateOfBirth}</p>
                            </div>
                        </div>
                        <div className="col"></div>
                    </div>
                    <div className="row">
                        <div className="col">
                        </div>
                    </div>
                </fieldset>
            </div>
        );
    }

    private renderNewAppointmentButton(): ReactNode {
        return <input type="button" className="button-blue-full-bg view_more-btn new-appointment-info-btn"
                                            value={this.props.getMessageForKey("customerSearch.customerInformation.newAppointment")}
                                            onClick={() => this.newAppointmentClickHandler()}/>
            ;
    }

    private newAppointmentClickHandler() {
        this.props.newAppointmentHandler(this.props.customer);
    }

}

const mapStateToProps = (store: any, ownProps: IProps): IDataProps => {
    return {
        customer: ownProps.customer,
        getMessageForKey: getLocalizedText(store),
        newAppointmentHandler: ownProps.newAppointmentHandler,
        pageLimit: ownProps.pageLimit,
        showResults: ownProps.showResults,
        tenant: ownProps.tenant,
    };
};

export default connect<IDataProps, any, IProps>(
    mapStateToProps,
)(CustomerInformation);
