import { createSelector } from "reselect";
import {
    CHANGE_DATE_RANGE,
    SELECT_CURRENT_WEEK,
    SELECTED_DATE_CHANGE,
    SelectedDateActionTypes,
    SET_ADJUST_SIDEBAR_TO_DATEPICKER_FLAG,
    SIDEBAR_SELECTED_DATE_CHANGE,
} from "../actions/selecteddate.action";
import { findMonday } from "../util/date.util";

export interface ISelectedDateAndRange {
    selectedDate: Date;
    startRangeDate: Date;
    range: number;
}

interface ISelectedDateReducer {
    selectedDate: ISelectedDateAndRange;
    adjustSidebarToDatepicker: boolean;
}

const initialState: ISelectedDateReducer = {
    adjustSidebarToDatepicker: false,
    selectedDate: {
        selectedDate: new Date(),
        startRangeDate: findMonday(new Date()),
        range: 7,
    },
};

export default function selectedDateReducer(state = initialState, action: SelectedDateActionTypes): ISelectedDateReducer {
    switch (action.type) {
        case SIDEBAR_SELECTED_DATE_CHANGE:
            return {
                ...state,
                selectedDate: action.payload,
            };
        case SELECTED_DATE_CHANGE:
            return {
                ...state,
                selectedDate: action.payload,
            };
        case SELECT_CURRENT_WEEK:
            return {
                ...state,
                selectedDate: {
                    selectedDate: action.payload,
                    startRangeDate: findMonday(action.payload),
                    range: 7,
                },
            };
        case SET_ADJUST_SIDEBAR_TO_DATEPICKER_FLAG:
            return {
                ...state,
                adjustSidebarToDatepicker: action.payload,
            };
        case CHANGE_DATE_RANGE:
            return {
                ...state,
                selectedDate: action.payload,
            };
        default:
            return {
                ...state,
            };
    }
}

const getSelectedDateState = (state: any) => state.selectedDate;
export const getSelectedDate = createSelector(getSelectedDateState, (state: ISelectedDateReducer) => state.selectedDate);
export const getAdjustSidebarToDatepicker = createSelector(getSelectedDateState, (state: ISelectedDateReducer) => state.adjustSidebarToDatepicker);
