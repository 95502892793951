import {Dispatch} from "redux";
import {
    fetchAppointmentsAction,
    fetchAppointmentsForUrlAction,
    fetchLastCreatedUpdatedAppointmentAction,
    fetchMoreAppointmentsForUrlAction,
    fetchSingleAppointmentAction,
    updateAppointmentAction,
    updateAppointmentWithCustomerAction,
} from "../actions/appointments.actions";
import {
    decreaseLoadingDataCounterAction,
    errorModalAction,
    increaseLoadingDataCounterAction,
} from "../actions/genericModal.actions";
import {togglePaginationButtonsAction} from "../actions/pagination.actions";
import {IAppointmentData, IAppointmentsPayload} from "../reducers/appointments.reducer";
import {IAppointmentTypeData} from "../reducers/appointmentTypes.reducer";
import {ICustomerData} from "../reducers/customers.reducer";
import {IRoomData} from "../reducers/room.reducer";
import {
    createAppointment,
    getAppointmentsCrossWeek,
    getAppointmentsForUrl,
    getAppointmentsForUser,
    getAppointmentsInWeek,
    getAppointmentsOverWeek,
    getSingleAppointment,
    patchAppointment,
} from "../services/appointments.service";
import {createCustomer, patchCustomer} from "../services/customer.service";
import {IOpticianData} from "../reducers/opticians.reducer";
import {IStoreData} from "../reducers/store.reducer";

export const fetchAppointments = (storeId: string, roomId: number, startTime: Date, endTime: Date, tenant: string,
                                  dispatch?: Dispatch) => {
    if (dispatch) {
        return getAppointments(storeId, roomId, startTime, endTime, tenant, dispatch);
    } else {
        return (localDispatch: Dispatch) => {
            return getAppointments(storeId, roomId, startTime, endTime, tenant, localDispatch);
        };
    }
};

function getAppointments(storeId: string, roomId: number, startTime: Date, endTime: Date, tenant: string,
                         dispatch: Dispatch): Promise<any> {
    dispatch(increaseLoadingDataCounterAction());
    return getAppointmentsInWeek(storeId, roomId, startTime, endTime, tenant)
        .then((inWeekData) => {
            return getAppointmentsCrossWeek(storeId, roomId, startTime, endTime, tenant)
                .then((crossWeekData) => {
                    return getAppointmentsOverWeek(storeId, roomId, startTime, endTime, tenant)
                        .then((overWeekData) => {
                            const resultData: [] = [];
                            if (inWeekData.data) {
                                resultData.push(...inWeekData.data as []);
                            }
                            if (crossWeekData.data) {
                                resultData.push(...crossWeekData.data as []);
                            }
                            if (overWeekData.data) {
                                resultData.push(...overWeekData.data as []);
                            }
                            const includeData: [] = [];
                            if (inWeekData.included) {
                                includeData.push(...inWeekData.included as []);
                            }
                            if (crossWeekData.included) {
                                includeData.push(...crossWeekData.included as []);
                            }
                            if (overWeekData.included) {
                                includeData.push(...overWeekData.included as []);
                            }
                            dispatch(fetchAppointmentsAction(transformIntoAppointmentData(resultData, includeData)));
                        });
                });
        })
        .finally(() => dispatch(decreaseLoadingDataCounterAction()));
}

export const getAppointmentsForCustomer = (customerUuid: string, tenant: string, pageLimit: number) => {
    return (dispatch: Dispatch) => {
        dispatch(togglePaginationButtonsAction());
        dispatch(increaseLoadingDataCounterAction());
        return getAppointmentsForUser(customerUuid, tenant, pageLimit)
            .then((data) => {
                dispatch(fetchAppointmentsForUrlAction(transformIntoAppointmentDataFromPayload(data.data, data.links, data.included)));
                dispatch(togglePaginationButtonsAction());
            })
            .finally(() => dispatch(decreaseLoadingDataCounterAction()));
    };
};

export const getMoreAppointmentsForUrl = (url: string, tenant: string) => {
    return (dispatch: Dispatch) => {
        dispatch(increaseLoadingDataCounterAction());
        return getAppointmentsForUrl(url, tenant)
            .then((data) => {
                dispatch(fetchMoreAppointmentsForUrlAction(transformIntoAppointmentDataFromPayload(data.data, data.links, data.included)));
                dispatch(togglePaginationButtonsAction());
            })
            .finally(() => dispatch(decreaseLoadingDataCounterAction()));
    };
};

const transformIntoAppointmentDataFromPayload = (data: any[], links: any, included: []): IAppointmentsPayload => {
    const result: IAppointmentsPayload = {appointments: [], nextLink: undefined, prevLink: undefined};
    result.nextLink = links ? links.next : undefined;
    result.prevLink = links ? links.prev : undefined;
    data.forEach((value) => {
        result.appointments.push(transform(value, included));
    });
    return result;
};

export const updateAppointment = (appointmentId: string, attrs: any, tenant: string, dispatch?: Dispatch) => {
    return (localDispatch: Dispatch) => {
        patchAppointment(appointmentId, tenant, attrs)
            .then((data) => {
                localDispatch(updateAppointmentAction(transform(data.data, data.included)));
            });
    };
};

export const updateCustomer = (customerId: string, appointmentId: string, attrs: any, tenant: string, dispatch?: Dispatch) => {
    return (localDispatch: Dispatch) => {
        patchCustomer(customerId, tenant, attrs)
            .then((data) => {
                getSingleAppointment(appointmentId, tenant)
                    .then((appData) => {
                        localDispatch(updateAppointmentWithCustomerAction(transform(appData.data, appData.included)));
                    });
            });
    };
};

export const updateAppointmentWithCustomer =
    (appointmentId: string, customerId: string, appointmentAttrs: any,
     customerAttrs: any, tenant: string, dispatch?: Dispatch) => {
        return (localDispatch: Dispatch) => {
            patchCustomer(customerId, tenant, customerAttrs)
                .then(() => {
                    patchAppointment(appointmentId, tenant, appointmentAttrs)
                        .then((data) => {
                            localDispatch(updateAppointmentWithCustomerAction(transform(data.data, data.included)));
                        });
                });
        };
    };

export const createAppointmentWithCustomer = (appointmentAttributes: any, appointmentRelationships: any, customerAttributes: any, tenant: string) => {
    return (dispatch: Dispatch) => {
        createCustomer(customerAttributes, tenant)
            .then((customerData) => {
                appointmentRelationships.customer.data.id = customerData.data.id;

                createAppointment(appointmentAttributes, appointmentRelationships, tenant)
                    .then((appointmentData) => {
                        getSingleAppointment(appointmentData.data.id, tenant)
                            .then((secondCallAppointmentData) =>
                                dispatch(fetchLastCreatedUpdatedAppointmentAction(transform(secondCallAppointmentData.data, []))));
                    })
                    .catch(() => dispatch(errorModalAction()));
            })
            .catch(() => dispatch(errorModalAction()));
    };
};

export const createAppointmentWithExistingCustomer = (appointmentAttributes: any, appointmentRelationships: any, customerId: string, tenant: string) => {
    return (dispatch: Dispatch) => {
        appointmentRelationships.customer.data.id = customerId;
        createAppointment(appointmentAttributes, appointmentRelationships, tenant)
            .then((appointmentData) => {
                getSingleAppointment(appointmentData.data.id, tenant)
                    .then((secondCallAppointmentData) =>
                        dispatch(fetchLastCreatedUpdatedAppointmentAction(transform(secondCallAppointmentData.data, []))));
            })
            .catch(() => dispatch(errorModalAction()));

    };
};

export const updateCustomerAndRescheduleOrCreateAppointment = (appointmentAttributes: any, appointmentRelationships: any,
                                                               updatedCustomer: any, tenant: string, customerId: string) => {
    return (dispatch: Dispatch) => {
        appointmentRelationships.customer.data.id = customerId;
        if (updatedCustomer == null) {
            createAppointment(appointmentAttributes, appointmentRelationships, tenant)
                .then((appointmentData) => {
                    getSingleAppointment(appointmentData.data.id, tenant)
                        .then((secondCallAppointmentData) =>
                            dispatch(fetchLastCreatedUpdatedAppointmentAction(transform(secondCallAppointmentData.data, []))));
                })
                .catch(() => dispatch(errorModalAction()));
        } else {
            patchCustomer(customerId, tenant, updatedCustomer)
                .then(() => {
                    createAppointment(appointmentAttributes, appointmentRelationships, tenant)
                        .then((appointmentData) => {
                            getSingleAppointment(appointmentData.data.id, tenant)
                                .then((secondCallAppointmentData) =>
                                    dispatch(fetchLastCreatedUpdatedAppointmentAction(transform(secondCallAppointmentData.data, []))));
                        })
                        .catch(() => dispatch(errorModalAction()));
                })
                .catch(() => dispatch(errorModalAction()));
        }
    };
};

export const fetchSingleAppointment = (appointmentId: string, tenant: string) => {
    return (dispatch: Dispatch) => {
        getSingleAppointment(appointmentId, tenant)
            .then((appointmentData) => dispatch(fetchSingleAppointmentAction(transform(appointmentData.data, []))));
    };
};

const transformIntoAppointmentData = (data: any[], included: any[]): IAppointmentData[] => {
    const result: IAppointmentData[] = [];
    data.forEach((value) => {
        result.push(transform(value, included));
    });
    return result;
};

const transform = (appointment: any, included: any[]): IAppointmentData => {
    return {
        uuid: appointment.id,
        status: appointment.attributes.status,
        substatus: appointment.attributes.substatus,
        // Use string parsing because we're dealing with ISO 8601 strings
        startTime: new Date(appointment.attributes.startTime),
        endTime: new Date(appointment.attributes.endTime),
        endTimeWithoutWrapTime: new Date(appointment.attributes.appointmentEndTime),
        customerComment: appointment.attributes.customerComment,
        updateComment: appointment.attributes.updateComment,
        appointmentType: getAppointmentTypeForAppointment(appointment.relationships.appointmentType.data.id, included),
        customer: getCustomerForAppointment(appointment.relationships.customer.data ?
            appointment.relationships.customer.data.id : "", included),
        room: getRoomForAppointment(appointment.relationships.room.data ? appointment.relationships.room.data.id : "", included),
        preferredChannelOfCommunication: appointment.attributes.preferredChannelOfCommunication,
        preferredLanguage: appointment.attributes.preferredLanguage,
        notificationRequired: appointment.attributes.notificationRequired,
        additionalFields: appointment.attributes.fields,
        manualOverride: appointment.attributes.manualOverride,
        appointmentSeriesId: appointment.relationships.appointmentsSeries.data ? appointment.relationships.appointmentsSeries.data.id : null,
        optician: getOpticianForAppointment(appointment.relationships.optician && appointment.relationships.optician.data ? appointment.relationships.optician.data.id : "", included),
        store: getStoreForAppointment(appointment.relationships.store && appointment.relationships.store.data ? appointment.relationships.store.data.id : "", included),
    };
};

const getOpticianForAppointment = (id: string, included: any[]): IOpticianData => {
    const result: IOpticianData = {
        id: "",
        title: "",
        firstName: "",
        lastName: "",
    };
    included.forEach((value) => {
        if (value.type === "opticians" && value.id === id) {
            result.id = id;
            result.title = value.attributes.title;
            result.firstName = value.attributes.firstName;
            result.lastName = value.attributes.lastName;
        }
    });
    return result;
};

const getStoreForAppointment = (id: string, included: any[]): IStoreData => {
    const result: IStoreData = {
        id: "",
        code: "",
        name: {},
        url: "",
        phoneNumber: "",
        timezone: "",
    };
    included.forEach((value) => {
        if (value.type === "stores" && value.id === id) {
            result.id = id;
            result.code = value.attributes.code;
            result.name = value.attributes.name;
        }
    });
    return result;
};

const getCustomerForAppointment = (customerId: string, included: any[]): ICustomerData => {
    let result: ICustomerData = {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        gender: "",
        dateOfBirth: "",
    };
    included.forEach((value) => {
        if (value.type === "customers" && value.id === customerId) {
            result = {
                id: value.id,
                firstName: value.attributes.firstName,
                lastName: value.attributes.lastName,
                email: value.attributes.email,
                phoneNumber: value.attributes.phoneNumber,
                gender: value.attributes.title,
                dateOfBirth: value.attributes.dateOfBirth,
            };
        }
    });
    return result;
};

const getAppointmentTypeForAppointment = (appointmentTypeId: number, included: any[]): IAppointmentTypeData => {
    let result: IAppointmentTypeData = {
        id: "",
        name: {},
        duration: {amount: 0, unit: ""},
        maxFutureBookingDays: 0,
        roomRequired: false,
        opticianRequired: false,
        allowRecurringPattern: false,
    };
    included.forEach((value) => {
        if (value.type === "appointment-types" && value.id === appointmentTypeId) {
            result = {
                id: value.id,
                name: value.attributes.name,
                duration: {
                    amount: value.attributes.duration.amount,
                    unit: value.attributes.duration.unit,
                },
                allowRecurringPattern: value.attributes.allowRecurringPattern,
                maxFutureBookingDays: value.attributes.duration.maxFutureBookingDays,
                roomRequired: value.attributes.requirements.roomRequired,
                opticianRequired: value.attributes.requirements.opticianRequired,
            };
        }
    });
    return result;
};

const getRoomForAppointment = (roomId: string, included: any[]): IRoomData => {
    let result: IRoomData = {id: 0, name: "", appointmentTypeIds: []};
    included.forEach((value) => {
        if (value.type === "rooms" && value.id === roomId) {
            result = {
                id: value.id,
                name: value.attributes.name,
                appointmentTypeIds: [],
            };
        }

    });
    return result;
};
