import {createSelector} from "reselect";
import {CLEAN_OPTICIANS, FETCH_OPTICIANS, OpticianActionTypes} from "../actions/opticians.actions";

export interface IOpticianData {
    id: string;
    title: string;
    firstName: string;
    lastName: string;
}

export interface IOpticiansReducer {
    opticians: IOpticianData[];
}

const initialState: IOpticiansReducer = {
    opticians: [],
};

export default function opticiansReducer(state = initialState, action: OpticianActionTypes): IOpticiansReducer {
    switch (action.type) {
        case FETCH_OPTICIANS:
            return {
                opticians: action.payload,
            };
        case CLEAN_OPTICIANS:
            return {
                opticians: [],
            };
        default:
            return state;
    }
}

const getOpticiansState = (state: any) => state.opticians;
export const getOpticians = createSelector(getOpticiansState,
    (state: IOpticiansReducer) => state.opticians);
