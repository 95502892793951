import {createSelector} from "reselect";
import {IArrayStringIndex} from "../../@types/types";
import {
    AppointmentTypeActionTypes,
    FETCH_APPOINTMENT_TYPES,
    SELECT_APPOINTMENT_TYPE,
} from "../actions/appointmentTypes.actions";

export interface IAppointmentTypeDuration {
    amount: number;
    unit: string;
}

export interface IAppointmentTypeData {
    id: string;
    name: IArrayStringIndex;
    duration: IAppointmentTypeDuration;
    maxFutureBookingDays: number;
    roomRequired: boolean;
    opticianRequired: boolean;
    allowRecurringPattern: boolean;
}

export interface IAppointmentTypeReducer {
    appointmentTypes: IAppointmentTypeData[];
    selectedAppointmentType?: IAppointmentTypeData;
}

const initialState: IAppointmentTypeReducer = {
    appointmentTypes: [],
    selectedAppointmentType: undefined,
};

export default function appointmentTypeReducer(state = initialState, action: AppointmentTypeActionTypes): IAppointmentTypeReducer {
    switch (action.type) {
        case SELECT_APPOINTMENT_TYPE:
            return {
                ...state,
                selectedAppointmentType: action.payload,
            };
        case FETCH_APPOINTMENT_TYPES:
            return {
                selectedAppointmentType: undefined,
                appointmentTypes: action.payload,
            };
        default:
            return state;
    }
}

const getAppointmentTypeState = (state: any) => state.appointmentTypes;

export const getSelectedAppointmentType = createSelector(getAppointmentTypeState,
    (state: IAppointmentTypeReducer) => state.selectedAppointmentType);
export const getAppointmentTypes = createSelector(getAppointmentTypeState,
    (state: IAppointmentTypeReducer) => state.appointmentTypes);
