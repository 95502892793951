import React from "react";
import RoomSelector from "../RoomSelector";
import { ISelectedDateAndRange } from "../../reducers/selecteddate.reducer";
import CalendarDay from "../calendar/CalendarDay";

interface IProps {
    startDate: ISelectedDateAndRange | undefined;
    tenant: string;
}

export class CalendarDayGrid extends React.Component<IProps> {

  public render(): React.ReactNode {
    return (
        <div className="calendar_day_grid">
        <RoomSelector tenant={this.props.tenant}/>
            {this.props.startDate ? <CalendarDay tenant={this.props.tenant}
                                                 startDate={this.props.startDate}
                                                 cellHeight={30}/> : null}
      </div>
    );
  }

}
