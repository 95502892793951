import React, {ReactNodeArray} from "react";
import {IStoreData} from "../reducers/store.reducer";
import {getStoreName} from "../util/storeName.util";
import HeaderIcon from "./HeaderIcon";
import "./StoreDropdown.scss";

interface IProps {
    selectedStoreName: string;
    storeList: IStoreData[] | undefined;
    locale: string | undefined;
    fallbackLocale: string | undefined;
    onStoreSelect: (store: IStoreData) => void;
}

interface IState {
    dropdownVisible: boolean;
}

export default class StoreDropdown extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            dropdownVisible: false,
        };
    }

    public render(): React.ReactNode {
        return (
            <div className="store-dropdown">
                <HeaderIcon
                    image={this.getImageUrl()}
                    name={this.getDisplayStoreName(this.props.selectedStoreName)} alt="store"
                    onClick={() => this.onIconClick()} highlight={this.isHighlight()}/>
                <div className={this.getDropdownContentClass()}>
                    <div className="store-dropdown-canvas">
                        {this.getStoreList()}
                    </div>
                </div>
            </div>
        );
    }

    private isHighlight(): boolean {
        return this.props.storeList ? this.state.dropdownVisible && this.props.storeList.length > 1 : false;
    }

    private onItemClick(item: IStoreData) {
        return () => {
            this.setState({dropdownVisible: false});
            this.props.onStoreSelect(item);
        };
    }

    private onIconClick(): void {
        this.setState({dropdownVisible: !this.state.dropdownVisible});
    }

    private getDropdownContentClass(): string {
        return this.state.dropdownVisible && this.props.storeList && this.props.storeList.length > 1 ?
            "store-dropdown-content show" : "store-dropdown-content";
    }

    private getStoreList(): ReactNodeArray {
        const nodes: ReactNodeArray = [];
        if (this.props.storeList !== undefined) {
            this.props.storeList.forEach(((value) => {
                if (this.props.locale !== undefined) {
                    nodes.push(<div className="dropdown-item" onClick={this.onItemClick(value)}
                                    key={value.code}>
                        {getStoreName(value, this.props.locale, this.props.fallbackLocale)}
                    </div>);
                }
            }));
        }
        return nodes;
    }

    private getImageUrl(): string {
        return this.props.storeList && this.props.storeList.length > 0 ?
            process.env.PUBLIC_URL + "/statics/img/store.svg" :
            process.env.PUBLIC_URL + "/statics/img/store-disabled.svg";
    }

    private getDisplayStoreName(name: string): string {
        let result = "";
        if (name) {
            const words = name.split(" ");
            result = words[0];
            for (let i = 1; i < words.length; i++) {
                if (result.length + words[i].length + 1 < 11) {
                    result += " " + words[i];
                } else {
                    break;
                }
            }
        }
        return result;
    }
}
