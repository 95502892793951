import React from "react";
import "./CalendarDayBody.scss";
import {calculateScrollOffset} from "./GridColumn.util";
import GridDayColumn, {IAppointmentsPerDate, IOpeningHoursPerDate} from "./GridDayColumn";
import HourColumn from "./HourColumn";

interface IProps {
    tenant: string;
    cellHeight: number;
    appointmentsPerDay: IAppointmentsPerDate;
    closingHoursPerDay: IOpeningHoursPerDate;
}

export default class CalendarDayBody extends React.Component<IProps> {
    private calendarBodyRef: HTMLDivElement | null = null;

    public componentDidMount(): void {
        if (this.calendarBodyRef) {
            this.calendarBodyRef.scrollTop = calculateScrollOffset(this.calendarBodyRef, this.props.cellHeight, new Date());
        }
    }
    public render(): React.ReactNode {
        return (
            <div className="calendar_day_body" ref={(ref) => this.calendarBodyRef = ref}>
                <div className="calendar_day_canvas">
                    <HourColumn cellHeight={this.props.cellHeight}/>
                    <GridDayColumn cellHeight={this.props.cellHeight}
                                   appointmentsPerDate={this.props.appointmentsPerDay}
                                   closingHoursPerDate={this.props.closingHoursPerDay}
                                   tenant={this.props.tenant}/>
                </div>
            </div>
        );
    }
}
