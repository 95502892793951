import {ICustomerSearchPayload} from "../reducers/customers.reducer";

export const FETCH_CUSTOMERS = "FETCH_CUSTOMERS";
export const FETCH_MORE_CUSTOMERS_FOR_URL = "FETCH_MORE_CUSTOMERS_FOR_URL";

interface IFetchCustomers {
    type: typeof FETCH_CUSTOMERS;
    payload: ICustomerSearchPayload;
}

interface IFetchMoreCustomersForUrl {
    type: typeof FETCH_MORE_CUSTOMERS_FOR_URL;
    payload: ICustomerSearchPayload;
}

export type CustomerActionTypes = IFetchCustomers | IFetchMoreCustomersForUrl;

export function fetchCustomersAction(customersPayload: ICustomerSearchPayload): IFetchCustomers {
    return {
        payload: customersPayload,
        type: FETCH_CUSTOMERS,
    };
}

export function fetchMoreCustomersForUrlAction(customersPayload: ICustomerSearchPayload): IFetchMoreCustomersForUrl {
    return {
        payload: customersPayload,
        type: FETCH_MORE_CUSTOMERS_FOR_URL,
    };
}
