import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { IAppointmentData } from "../../../reducers/appointments.reducer";
import { getLocalizedText, getSelectedLocale } from "../../../reducers/locale.reducer";

interface IProps {
    appointment: IAppointmentData;
}

interface IConnectProps {
    getMessageForKey: (key: string) => string;
    selectedLocale: string | undefined;
}

interface IResultProps extends IProps, IConnectProps {
}

export class AppointmentRow extends React.Component<IResultProps> {

    public render(): React.ReactNode {
        return (this.renderCustomer());
    }

    private renderCustomer(): ReactNode {
        return (
            <div className="row customer-row">
                <div className="col">
                    {this.getDateRange(this.props.appointment.startTime, this.props.appointment.endTime, "en-GB")}
                </div>
                <div className="col">
                    {this.getTimeRange(this.props.appointment.startTime, this.props.appointment.endTime, "en-GB")}
                </div>
                <div className="col">
                    {this.props.selectedLocale && this.props.appointment.store ? this.props.appointment.store.name[this.props.selectedLocale] : ""}
                </div>
                <div className="col">
                    {this.props.selectedLocale ? this.props.appointment.appointmentType.name[this.props.selectedLocale] : ""}
                </div>
                <div className="col">
                    {this.renderOpticianName()}
                </div>
                <div className="col">
                    {this.props.getMessageForKey("appointment.substatus." + this.props.appointment.substatus) || this.props.appointment.substatus}
                </div>
            </div>
        );
    }

    private renderOpticianName() {
        if (this.props.appointment.optician !== undefined) {
           return <>{this.props.appointment.optician.firstName + " " + this.props.appointment.optician.lastName}</>;
        } else {
            return this.props.getMessageForKey("customerSearch.appointmentHistory.noInformation");
        }
    }

    private getDateRange(startTime: Date, endTime: Date, locales: string): string {
        const startDate = startTime.toLocaleDateString(locales);
        const endDate = endTime.toLocaleDateString(locales);
        if (startDate === endDate) {
            return startDate;
        } else {
            return startDate + " - " + endDate;
        }
    }

    private getTimeRange(startTime: Date, endTime: Date, locales: string): string {
        return this.getTimeFormatted(startTime, locales) + " - " + this.getTimeFormatted(endTime, locales);
    }

    private getTimeFormatted(time: Date, locales: string): string {
        return time.toLocaleTimeString(locales, {
            hour: "2-digit",
            minute: "2-digit",
        });
    }
}

const mapStateToProps = (store: any, ownProps: IProps): IResultProps => {
    return {
        appointment: ownProps.appointment,
        getMessageForKey: getLocalizedText(store),
        selectedLocale: getSelectedLocale(store),
    };
};

export default connect<IResultProps, any, any>(
    mapStateToProps,
    undefined,
)(AppointmentRow);
