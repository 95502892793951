import {createSelector} from "reselect";
import {IArrayStringIndex} from "../../@types/types";
import {FETCH_STORES, SELECT_STORE, StoreActionTypes} from "../actions/store.action";

export interface IStoreData {
    id: string;
    code: string;
    name: IArrayStringIndex;
    url: string;
    phoneNumber: string;
    timezone: string;
}

export interface IStoreReducer {
    selectedStore: IStoreData | undefined;
    availableStores: IStoreData[];
}

const initialState: IStoreReducer = {
    availableStores: [],
    selectedStore: undefined,
};

export default function storeReducer(state = initialState, action: StoreActionTypes): IStoreReducer {
    switch (action.type) {
        case SELECT_STORE:
            return {
                ...state,
                selectedStore: action.payload,
            };
        case FETCH_STORES:
            return {
                ...state,
                availableStores: action.payload,
            };
        default:
            return state;
    }
}

const getStoreState = (state: any) => state.store;
export const getSelectedStore = createSelector(getStoreState, (state: IStoreReducer) => state.selectedStore);
export const getAvailableStores = createSelector(getStoreState, (state: IStoreReducer) => state.availableStores);
