import {createSelector} from "reselect";
import {FETCH_ROOMS, RoomActionTypes, SELECT_ROOM} from "../actions/room.actions";

export interface IRoomData {
    id: number;
    name: string;
    appointmentTypeIds: string[];
}

export interface IRoomReducer {
    selectedRoom?: IRoomData;
    availableRooms?: IRoomData[];
}

const initialState: IRoomReducer = {
    availableRooms: [],
    selectedRoom: undefined,
};

export default function roomReducer(state = initialState, action: RoomActionTypes): IRoomReducer {
    switch (action.type) {
        case SELECT_ROOM:
            return {
                ...state,
                selectedRoom: action.payload,
            };
        case FETCH_ROOMS:
            return {
                availableRooms: action.payload,
                selectedRoom: undefined,
            };
        default:
            return state;
    }
}

const getRoomState = (state: any) => state.room;

export const getSelectedRoom = createSelector(getRoomState, (state: IRoomReducer) => state.selectedRoom);
export const getAvailableRooms = createSelector(getRoomState, (state: IRoomReducer) => state.availableRooms);
export const getRoomsForAppointmentType = createSelector(getRoomState, (state: IRoomReducer) => {
    return (appointmentTypeId: string): IRoomData[] => {
        const result: IRoomData[] = [];
        if (state.availableRooms) {
            state.availableRooms.forEach((room) => {
                if (room.appointmentTypeIds.includes(appointmentTypeId)) {
                    result.push(room);
                }
            });
        }
        return result;
    };
});
