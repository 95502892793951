import React, {FormEvent} from "react";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {getLocalizedText} from "../../../reducers/locale.reducer";
import {getCustomersForAttrs} from "../../../thunks/customer.thunk";

interface IDispatchFromProps {
    fetchCustomers: (firstName: string | null,
                     lastName: string | null,
                     email: string | null,
                     endTime: string | null,
                     tenant: string,
                     pageLimit: number) => void;
}

interface IConnectProps {
    getMessageForKey: (key: string) => string;
}

interface IProps {
    tenant: string;
    showResults: (formState: IFormState) => void;
    pageLimit: number;
    formState?: IFormState | null;
}

interface IDataProps extends IProps, IConnectProps {
}

export interface IResultProps extends IDataProps, IDispatchFromProps {
}

export interface IFormState {
    email?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
}

export class CustomerSearchForm extends React.Component<IResultProps, IFormState> {

    private static fieldContentTooShort(field: string | undefined): boolean {
        let isTooShort: boolean = true;
        if (field && field.length >= 2) {
            isTooShort = false;
        }
        return isTooShort;
    }

    constructor(props: any) {
        super(props);
        this.state = {
            email: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
        };
    }

    public render(): React.ReactNode {
        return (
            <div className="customer_search-form">
                <fieldset>
                    <legend><img src={process.env.PUBLIC_URL + "/statics/img/customer-lookup.svg"}
                                 alt=""/>{this.props.getMessageForKey("customerSearch.form.legend")}</legend>
                    <form>
                        <div className="row">
                            <div className="col">
                                <div className="form_input-wrapper">
                                    <p>{this.props.getMessageForKey("customerSearch.form.lastName")}</p>
                                    <input
                                        value={this.state.lastName}
                                        type="text"
                                        name="lastName"
                                        onChange={(event) => this.onFormInputChange(event)}
                                    />
                                </div>
                                <div className="form_input-wrapper">
                                    <p>{this.props.getMessageForKey("customerSearch.form.email")}</p>
                                    <input
                                        value={this.state.email}
                                        type="text"
                                        name="email"
                                        onChange={(event) => this.onFormInputChange(event)}
                                    />
                                </div>
                            </div>
                            <div className="col">
                                <div className="form_input-wrapper">
                                    <p>{this.props.getMessageForKey("customerSearch.form.firstName")}</p>
                                    <input
                                        value={this.state.firstName}
                                        type="text"
                                        name="firstName"
                                        onChange={(event) => this.onFormInputChange(event)}
                                    />
                                </div>
                                <div className="form_input-wrapper">
                                    <p>{this.props.getMessageForKey("customerSearch.form.phoneNumber")}</p>
                                    <input
                                        value={this.state.phoneNumber}
                                        type="text"
                                        name="phoneNumber"
                                        onChange={(event) => this.onFormInputChange(event)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <input type="button" className="button-blue-full-bg"
                                       value={this.props.getMessageForKey("customerSearch.form.button.findCustomer.label")}
                                       disabled={this.submitDisabled()}
                                       onClick={() => this.onFormSubmit()}/>
                            </div>
                        </div>
                    </form>
                </fieldset>
            </div>
        );
    }

    public componentDidMount(): void {
        if (this.props.formState !== null  && this.props.formState !== undefined) {
            this.setState({
                firstName: this.props.formState.firstName,
                lastName: this.props.formState.lastName,
                email: this.props.formState.email,
                phoneNumber: this.props.formState.phoneNumber,
            });
        }
    }

    private submitDisabled(): boolean {
        return CustomerSearchForm.fieldContentTooShort(this.state.firstName)
            && CustomerSearchForm.fieldContentTooShort(this.state.lastName)
            && CustomerSearchForm.fieldContentTooShort(this.state.phoneNumber)
            && CustomerSearchForm.fieldContentTooShort(this.state.email);
    }

    private onFormInputChange(event: FormEvent<HTMLInputElement>): void {
        const inputName = event.currentTarget.name;
        const inputValue = event.currentTarget.value;
        this.setState({
            [inputName]: inputValue,
        });
    }

    private onFormSubmit(): void {
        this.props.fetchCustomers(
            this.state.firstName ? this.state.firstName : null,
            this.state.lastName ? this.state.lastName : null,
            this.state.email ? this.state.email : null,
            this.state.phoneNumber ? this.state.phoneNumber : null,
            this.props.tenant,
            this.props.pageLimit);
        this.props.showResults(this.state);
    }

}

const mapStateToProps = (store: any, ownProps: IProps): IDataProps => {
    return {
        getMessageForKey: getLocalizedText(store),
        pageLimit: ownProps.pageLimit,
        showResults: ownProps.showResults,
        tenant: ownProps.tenant,
        formState: ownProps.formState,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): IDispatchFromProps => {
    return {
        fetchCustomers: ((firstName, lastName, email, phoneNumber, tenant, pageLimit) =>
            dispatch(getCustomersForAttrs(firstName, lastName, email, phoneNumber, tenant, pageLimit))),
    };
};

export default connect<IDataProps, IDispatchFromProps, IProps>(
    mapStateToProps,
    mapDispatchToProps,
)(CustomerSearchForm);
