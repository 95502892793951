import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {ICustomerData} from "../../../reducers/customers.reducer";
import {getLocalizedText} from "../../../reducers/locale.reducer";

interface IProps {
    customer: ICustomerData;
    selectCustomer: (customer: ICustomerData) => void;
}

interface IConnectProps {
    getMessageForKey: (key: string) => string;
}

interface IResultProps extends IProps, IConnectProps {
}

export class CustomerModalRow extends React.Component<IResultProps> {

    public render(): React.ReactNode {
        return (this.renderCustomer());
    }

    private renderCustomer(): ReactNode {
        return (
            <div className="row customer-row">
                <div className="col">
                    {this.props.customer.firstName}
                </div>
                <div className="col">
                    {this.props.customer.lastName}
                </div>
                <div className="col">
                    {this.props.customer.email}
                </div>
                <div className="col">
                    {this.props.customer.phoneNumber}
                </div>
                <div className="col">
                    {this.props.customer.dateOfBirth}
                </div>
                <div className="col">
                    <img onClick={() => this.selectCustomer()}
                         src={process.env.PUBLIC_URL + "/statics/img/right-arrow.svg"}
                         alt=""/>
                </div>
            </div>
        );
    }

    private selectCustomer() {
        this.props.selectCustomer(this.props.customer);
    }
}

const mapStateToProps = (store: any, ownProps: IProps): IResultProps => {
    return {
        customer: ownProps.customer,
        getMessageForKey: getLocalizedText(store),
        selectCustomer: ownProps.selectCustomer,
    };
};

export default connect<IResultProps, any, any>(
    mapStateToProps,
    undefined,
)(CustomerModalRow);
